import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader } from 'components/Card';
import { Button } from 'components/CustomButtons';
import { RouteComponentProps }from 'react-router-dom';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doLoadSecretShoppers } from 'domains/secretShopper/actions';
import SecretShopperTable from 'domains/secretShopper/components/SecretShopperTable';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	}
});

const SecretShopperList: React.FC<RouteComponentProps> = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		dispatch(doLoadSecretShoppers());
	}, [dispatch]);

	const onSearchTextChange = (e: any) => {
		e.preventDefault();
		setSearchText(e.target.value);
	};

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Secret Shopper CCNs</h2>
				<GridContainer spacing={0}>
					<GridItem>
						<Button color="primary" onClick={() => history.push('/consumerInteractions/secretShoppers/add')}>Add Ccn</Button>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg xl>
						<TextField
							id="secretShopperSearchInput"
							label="Search"
							placeholder="Type here..."
							fullWidth
							onChange={onSearchTextChange}
							value={searchText}
						/>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<GridContainer>
				<GridItem xs={12}>
					<SecretShopperTable filterText={searchText} />
				</GridItem>
			</GridContainer>
		</Card>
	);
}

export default SecretShopperList;
