import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/companies/actionTypes';

export interface State {
	isSavingCompany: boolean;
	companyDetails: {
		companyId: number;
		activeUserCount?: number;
		inactiveUserCount?: number;
	};
	companyListDetails: {
		companyCount?: number;
	};
	isLoadingCompanies: boolean;
}

export const initialState: State = {
	isSavingCompany: false,
	companyDetails: {
		companyId: 0,
		activeUserCount: undefined,
		inactiveUserCount: undefined
	},
	companyListDetails: {
		companyCount: undefined
	},
	isLoadingCompanies: false
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.CREATE_COMPANY:
			return {
				...state,
				isSavingCompany: true
			};

		case actionTypes.CREATE_COMPANY_SUCCESS:
			return {
				...state,
				isSavingCompany: false
			};

		case actionTypes.CREATE_COMPANY_FAIL:
			return {
				...state,
				isSavingCompany: false
			};

		case actionTypes.LOAD_COMPANY_DETAIL:
			return {
				...state,
				companyDetails: {
					...state.companyDetails,
					companyId: 0,
					activeUserCount: undefined,
					inactiveUserCount: undefined
				}
			};

		case actionTypes.LOAD_COMPANY_DETAIL_SUCCESS:
			return {
				...state,
				companyDetails: {
					...state.companyDetails,
					companyId: action.result.result.company,
					activeUserCount: action.result.result.activeUserCount,
					inactiveUserCount: action.result.result.inactiveUserCount
				}
			};

		case actionTypes.LOAD_COMPANY_DETAIL_FAIL:
			return {
				...state,
				companyDetails: {
					...state.companyDetails,
					companyId: 0,
					activeUserCount: undefined,
					inactiveUserCount: undefined
				}
			};

		case actionTypes.LOAD_COMPANIES:
			return {
				...state,
				isLoadingCompanies: true
			}

		case actionTypes.LOAD_COMPANIES_SUCCESS:
			return {
				...state,
				isLoadingCompanies: false,
				companyListDetails: {
					companyCount: action.result.result.recordCount
				}
			};

		case actionTypes.LOAD_COMPANIES_FAIL:
			return {
				...state,
				isLoadingCompanies: false
			};

		default:
			return state;
	}
}
