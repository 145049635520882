import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/users/actionTypes';
import { CompanyUserDetail, StaffingPlacementScheduleDto } from 'domains/users/models';

export interface State {
	isLoadingUsers: boolean;
	companyUserDetails: CompanyUserDetail | undefined;
	companyUserListDetails: {
		companyUserCount?: number;
	};
	isLoadingUserDependencies: boolean;
	isLoadingDefaultStaffingPlacementSchedule: boolean;
	defaultStaffingPlacementSchedule: StaffingPlacementScheduleDto | undefined;
}

export const initialState: State = {
	isLoadingUsers: false,
	companyUserDetails: undefined,
	companyUserListDetails: {
		companyUserCount: undefined
	},
	isLoadingUserDependencies: false,
	isLoadingDefaultStaffingPlacementSchedule: false,
	defaultStaffingPlacementSchedule: undefined
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_COMPANY_USERS:
			return {
				...state,
				isLoadingUsers: true
			};

		case actionTypes.LOAD_COMPANY_USERS_SUCCESS:
			return {
				...state,
				isLoadingUsers: false,
				companyUserListDetails: {
					companyUserCount: action.result.result.recordCount
				}
			};

		case actionTypes.LOAD_COMPANY_USERS_FAIL:
			return {
				...state,
				isLoadingUsers: false
			};

		case actionTypes.LOAD_COMPANY_USER_DETAIL_SUCCESS:
			return {
				...state,
				companyUserDetails: action.result.result
			};

		case actionTypes.LOAD_USER_AND_DEPENDENCIES:
			return {
				...state,
				isLoadingUserDependencies: true
			};

		case actionTypes.LOAD_USER_AND_DEPENDENCIES_SUCCESS:
		case actionTypes.LOAD_USER_AND_DEPENDENCIES_FAIL:
			return {
				...state,
				isLoadingUserDependencies: false
			};

		case actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE:
			return {
				...state,
				isLoadingDefaultStaffingPlacementSchedule: true
			};

		case actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE_SUCCESS:
			return {
				...state,
				defaultStaffingPlacementSchedule: action.result,
				isLoadingUserDependencies: false
			};

		case actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE_FAIL:
			return {
				...state,
				isLoadingUserDependencies: false
			};

		default:
			return state;
	}
}
