import React, { useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';
import orderBy from 'lodash/orderBy';
import { useHistory } from 'react-router-dom';
import { Table } from 'components/Table';
import { Discount } from 'domains/core/models';
import { getDiscounts } from 'domains/discounts/selectors';
import { DiscountSortColumn } from 'domains/discounts/models';
import TableColumnListValue from 'domains/core/components/TableColumnListValue';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';
import { useStyles } from 'styles';
import { useSelector } from 'react-redux';

const style = {
	...headerTableStyle,
	firstColumnStyle: {
		width: '25%',
	},
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
	},
	tablePadding: {
		paddingLeft: '10px',
	},
	headerStyle: {
		paddingLeft: '10px',
		cursor: 'pointer'
	},
	extraLeftPadding: {
		paddingLeft: '30px !important'
	},
	sortColumn: {
		cursor: 'pointer',
	},
};

interface Props {
	active: boolean;
	setActive: (value: boolean) => void;
	sortOrderDesc: boolean;
	sortColumn: DiscountSortColumn;
	changeSortOrder: (sortDirection: string) => void;
	changeSortColumn: (column: DiscountSortColumn) => void;
}

export default function Discounts(props: Props) {
	const { changeSortColumn, changeSortOrder, sortColumn, sortOrderDesc, active, setActive } = props;
	const history = useHistory();
	const classes = useStyles(style);

	const discounts = useSelector(state => getDiscounts(state));

	useEffect(() => {
		setActive(active);
	}, [active, setActive]);

	const routeToDiscountDetails = useCallback((discountId: number) => () => {
		history.push(`/pricing/discounts/view/${discountId}`);
	}, [history]);

	const sort = useCallback((column: DiscountSortColumn) => () => {

		if (sortColumn === column) {
			changeSortOrder(sortOrderDesc ? 'asc' : 'desc');
			changeSortColumn(column);
		} else {
			changeSortOrder('asc');
			changeSortColumn(column);

		}
	}, [changeSortColumn, changeSortOrder, sortColumn, sortOrderDesc]);

	const decorateSortHeader = useCallback((column: DiscountSortColumn) => () => {
		if (column !== sortColumn) {
			return undefined;
		}
		return sortOrderDesc ? classes.sortDesc : classes.sortAsc;
	}, [classes, sortColumn, sortOrderDesc]);

	const formatDateString = useCallback((date?: Moment | string) => {
		if (date) {
			return moment(date).format('MM/DD/YYYY')
		}
		return "n/a"
	}, []);


	const tableData = discounts && discounts.length > 0
		? orderBy(discounts, sortColumn, sortOrderDesc ? 'desc' : 'asc').map((discount: Discount) => [
			<span key={1} className={`${classes.rowButton} ${classes.tablePadding} ${classes.extraLeftPadding}`} onClick={routeToDiscountDetails(discount.id)}>{discount.name}</span>,
			<span key={2} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={routeToDiscountDetails(discount.id)}>{discount.displayName}</span>,
			<span key={3} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={routeToDiscountDetails(discount.id)}>{discount.programName}</span>,
			<span key={4} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={routeToDiscountDetails(discount.id)}>{discount.discountType}</span>,
			<span key={5} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={routeToDiscountDetails(discount.id)}><TableColumnListValue list={discount.discountMarketList} />{ }</span>,
			<span key={6} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={routeToDiscountDetails(discount.id)}>{formatDateString(discount.effectiveStartDate)} - {formatDateString(discount.effectiveEndDate)}</span>
		])
		: [[
			<span key={1} />,
			<span key={2} />,
			<span key={3}><p className={classes.tablePadding}>No Records Found</p></span>,
			<span key={4} />,
			<span key={5} />,
			<span key={6} />,
		]];

	const tableHeader = [
		<h6 key={1} onClick={sort(DiscountSortColumn.InternalName)} className={`${classes.headerStyle} ${classes.extraLeftPadding} ${decorateSortHeader(DiscountSortColumn.InternalName)}`}>Internal Name</h6>,
		<h6 key={2} onClick={sort(DiscountSortColumn.DisplayName)} className={`${classes.headerStyle} ${decorateSortHeader(DiscountSortColumn.DisplayName)}`}>Display Name</h6>,
		<h6 key={3} onClick={sort(DiscountSortColumn.Program)} className={`${classes.headerStyle} ${decorateSortHeader(DiscountSortColumn.Program)}`}>Program</h6>,
		<h6 key={4} onClick={sort(DiscountSortColumn.DiscountType)} className={`${classes.headerStyle} ${decorateSortHeader(DiscountSortColumn.DiscountType)}`}>Type</h6>,
		<h6 key={5} onClick={sort(DiscountSortColumn.Market)} className={`${classes.headerStyle} ${decorateSortHeader(DiscountSortColumn.Market)}`}>Market(s)</h6>,
		<h6 key={6} onClick={sort(DiscountSortColumn.ActiveDate)} className={`${classes.headerStyle} ${decorateSortHeader(DiscountSortColumn.ActiveDate)}`}>Active Dates</h6>
	];

	return (
		<Table
			striped
			tableData={tableData}
			tableHead={tableHeader}
			customCellClasses={[classes.firstColumnStyle]}
			customClassesForCells={[0]}
			customHeadCellClasses={[classes.firstColumnStyle]}
			customHeadClassesForCells={[0]}
		/>
	);
}
