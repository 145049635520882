
import CompanyIcon from '@material-ui/icons/Business';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import People from '@material-ui/icons/People';
import Tablet from '@material-ui/icons/Tablet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DashboardRoute, DashboardView } from 'routes';
import { CompanyList, CreateCompany, CompanyDetails } from 'domains/companies/containers';
import { CompanyUserList, CompanyUser, CreateUser, EditUser, EditUserAccess, EmployeeImportSchedulePage } from 'domains/users/containers';
import { UserRoleList, UserRoleDetails, EditUserRoleActions,  } from 'domains/roles/containers';
import { TerminalMappingSummary, EditTerminalMappingForm, TerminalMappingDetailsView } from 'domains/devices/containers';
import { CurrentUser } from 'domains/core/models';
import { AuditHistory } from 'domains/audits/containers';
import { DiscountList, DiscountDetails, CreateDiscount, EditDiscount } from 'domains/discounts/containers';
import { ExecutionTagList, ExecutionTagDetails, ExecutionTagCreate, ExecutionTagEdit } from 'domains/executionTags/containers';
import { UserActions, DeviceManagementActions, PricingActions } from 'domains/core/enums';
import { SecretShopperList, SecretShopperAdd, SecretShopperDetails } from 'domains/secretShopper/containers';
import EditCompany from 'domains/companies/containers/EditCompany';

export const getRoutes = (user: CurrentUser | null | undefined) => {
	const routes: (DashboardRoute & DashboardView)[] = [];

	if (user) {
		if (user.canDoAction(PricingActions.CanUseDiscountManager)) {
			routes.push(
				{ path: '/pricing', name: 'Pricing' },
				discountListView,
				discountDetailsPage,
				createDiscountPage,
				discountEditPage,
			);
		}

		if (user.canDoAction(DeviceManagementActions.CanUseDeviceManagement)) {
			routes.push(
				{ path: '/devices', name: 'Device Management' },
				terminalMappingListView,
				terminalMappingAdd,
				terminalMappingDetailView,
				terminalMappingDetailEdit
			);
		}

		if (user.canDoAction(UserActions.CanViewExecutionTags)) {
			routes.push(
				executionTagListPage,
				executionTagCreatePage,
				executionTagEditPage,
				executionTagDetailsPage
			);
		}

		if (user.canDoAction(UserActions.CanViewSecretShopperAdmin)) {
			routes.push(
				secretShopperListView,
				secretShopperAddPage,
				secretShopperDetailsPage
			);
		}

		if (user.canDoAction(UserActions.CanModifyEmployeeImportSchedule)) {
			routes.push(employeeImportSchedulePage);
		}
		
		if (user.canDoAction(UserActions.CanCreateEditCompanies)) {
			routes.push(
				{ path: '/users', name: 'User Administration' },
				{ path: '/consumerInteractions', name: 'Consumer Interactions' },
				companyListPage,
				userAuditHistory,
				createCompanyPage,
				companyDetailsPage(),
				editCompanyPage,
				companyUserPage(),
				createUserPage(),
				userDetailsPage(),
				editUserPage(),
				editUserAccessPage(),
			);
			if (user.canDoAction(UserActions.CanManageUserRoles)) {
				routes.push(userRolesView, userRoleDetailsPage, editUserRoleActionsPage);
			}
			routes.push(redirectToCompaniesList);
		} else {
			routes.push(
				companyDetailsPage(),
				companyUserPage(user.companyId),
				createUserPage(user.companyId),
				userDetailsPage(user.companyId),
				editUserPage(user.companyId),
				editUserAccessPage(user.companyId),
				redirectToCompany(user.companyId),
			);
		}
	}

	return routes;
};

export const getNavigationRoutes = (user: CurrentUser | null | undefined) => {
	const routes: DashboardRoute[] = [];

	if (user) {
		const userManagementMenu = getUserManagementMenu(user);
		if (userManagementMenu) {
			routes.push(userManagementMenu);
		}

		const consumerInteractionsMenu = getconsumerInteractionsMenu(user);
		if (consumerInteractionsMenu) {
			routes.push(
				consumerInteractionsMenu
			);
		}

		const deviceManagementMenu = getDeviceManagementMenu(user);
		if (deviceManagementMenu) {
			routes.push(deviceManagementMenu);
		}

		const pricingManagementMenu = getPricingMenu(user);
		if (pricingManagementMenu) {
			routes.push(pricingManagementMenu);
		}
	}

	return routes;
};

// Views with Menu Nav
const companyListPage: DashboardView = { path: '/users/companies', name: 'Companies', component: CompanyList };
const userAuditHistory: DashboardView = { path: '/users/userAudit', name: 'User Audit', component: AuditHistory };
const employeeImportSchedulePage: DashboardView = { path: '/users/employeeImportSchedule', name: 'Employee Import Schedule', component: EmployeeImportSchedulePage };
const userRolesView: DashboardView = { path: '/users/roles', name: 'User Roles & Actions', component: UserRoleList };
const executionTagListView: DashboardView = { path: '/consumerInteractions/executionTags', name: 'Special Execution Tags', component: ExecutionTagList };
const terminalMappingListView: DashboardView = { path: '/devices/terminalMapping', name: 'Terminal Mapping', component: TerminalMappingSummary };
const discountListView: DashboardView = { path: '/pricing/discounts', name: 'Discounts', component: DiscountList };
const terminalMappingAdd: DashboardView = { path: '/devices/terminalMapping/add', name: 'Add Tablet', component: EditTerminalMappingForm };
export const terminalMappingDetailEdit: DashboardView = { path: '/devices/terminalMapping/edit/:id', name: '', component: EditTerminalMappingForm };
export const terminalMappingDetailView: DashboardView = { path: '/devices/terminalMapping/view/:id', name: '', component: TerminalMappingDetailsView };
const secretShopperListView: DashboardView = { path: '/consumerInteractions/secretShoppers', name: 'Secret Shoppers', component: SecretShopperList };

// Routes without Menu Nav
export const companyDetailsPage: (companyId?: number) => DashboardView = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}`, name: 'Company Details', component: CompanyDetails, icon: CompanyIcon, collapse: false }; };
export const userDetailsPage: (companyId?: number) => DashboardRoute = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}/users/:userPrincipalName`, name: 'Expert Details', component: CompanyUser }; };
const companyUserPage: (companyId?: number) => DashboardRoute = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}/users`, name: 'Users', component: CompanyUserList }; };
const createUserPage: (companyId?: number) => DashboardRoute = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}/users/create`, name: 'Create User', component: CreateUser, icon: CompanyIcon }; };
const editUserPage: (companyId?: number) => DashboardRoute = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}/users/:userPrincipalName/edit`, name: 'Edit User', component: EditUser, icon: CompanyIcon }; };
const editUserAccessPage: (companyId?: number) => DashboardRoute = (companyId?: number) => { return { path: `/users/companies/${companyId || ':companyId'}/users/:userPrincipalName/edit/access`, name: 'Edit User', component: EditUserAccess, icon: CompanyIcon }; };

const createCompanyPage: DashboardRoute = { path: '/users/companies/create', name: 'Create Company', component: CreateCompany, icon: CompanyIcon };
const editCompanyPage: DashboardRoute = { path: '/users/companies/:companyId/edit/', name: 'Edit Company', component: EditCompany, icon: CompanyIcon };
export const editUserRoleActionsPage: DashboardRoute = { path: '/users/roles/:system/:id/edit', name: 'Edit Role Actions', component: EditUserRoleActions };
export const userRoleDetailsPage: DashboardRoute = { path: '/users/roles/:system/:id', name: '', component: UserRoleDetails };
export const discountEditPage: DashboardRoute = { path: '/pricing/discounts/:id/edit', name: 'Edit Discount', component: EditDiscount };
export const discountDetailsPage: DashboardView = { path: '/pricing/discounts/view/:id', name: 'Details', component: DiscountDetails };
const createDiscountPage: DashboardRoute = { path: '/pricing/discounts/create', name: 'Add Discount', component: CreateDiscount };

const executionTagListPage: DashboardRoute = { path: '/consumerInteractions/executionTags', name: 'Special Execution Tags', component: ExecutionTagList };
export const executionTagDetailsPage: DashboardView = { path: '/consumerInteractions/executionTags/view/:id', name: 'Details', component: ExecutionTagDetails };
const executionTagCreatePage: DashboardRoute = { path: '/consumerInteractions/executionTags/create', name: 'Add Special Execution Tag', component: ExecutionTagCreate };
export const executionTagEditPage: DashboardRoute = { path: '/consumerInteractions/executionTags/:id/edit', name: 'Edit Special Execution Tag', component: ExecutionTagEdit };

const secretShopperAddPage: DashboardRoute = { path: '/consumerInteractions/secretShoppers/add', name: 'Add CCN', component: SecretShopperAdd };
const secretShopperDetailsPage: DashboardRoute = { path: '/consumerInteractions/secretShoppers/view/:id', name: 'Details', component: SecretShopperDetails };

// Default Redirects
const redirectToCompaniesList: DashboardRoute = { redirect: true, path: '/', pathTo: '/users/companies', name: 'Companies' };
const redirectToCompany: (companyId: number) => DashboardRoute = (companyId: number) => {
	return { redirect: true, path: '/', pathTo: `/users/companies/${companyId}`, name: 'Company' };
};

const getconsumerInteractionsMenu = (user: CurrentUser) => {
	const consumerInteractionsViews: DashboardView[] = [];

	if (user.canDoAction(UserActions.CanViewExecutionTags)) {
		consumerInteractionsViews.push(executionTagListView);
	}

	if (user.canDoAction(UserActions.CanViewSecretShopperAdmin)) {
		consumerInteractionsViews.push(secretShopperListView);
	}

	if (consumerInteractionsViews.length === 0) {
		return null;
	}

	const consumerInteractions: DashboardRoute = { path: '/consumerInteractions', name: 'Consumer Interactions', icon: AssignmentInd, collapse: true, state: 'openconsumerInteractions', views: consumerInteractionsViews };
	return consumerInteractions;
};

const getUserManagementMenu = (user: CurrentUser) => {
	const userManagementViews: DashboardView[] = [];
	if (user.canDoAction(UserActions.CanCreateEditCompanies)) {
		userManagementViews.push(companyListPage);
	} else {
		userManagementViews.push(companyDetailsPage(user.companyId));
	}
	if (user.canDoAction(UserActions.CanCreateEditCompanies)) { // TODO: Add user action for viewing audit history
		userManagementViews.push(userAuditHistory);
	}

	if (user.canDoAction(UserActions.CanManageUserRoles)) {
		userManagementViews.push(userRolesView);
	}

	if (user.canDoAction(UserActions.CanModifyEmployeeImportSchedule)) {
		userManagementViews.push(employeeImportSchedulePage);
	}

	if (userManagementViews.length === 0) {
		return null;
	}

	const userManagement: DashboardRoute = { path: '/users', name: 'User Administration', icon: People, collapse: true, state: 'openUserAdministration', views: userManagementViews, isDefault: true };
	return userManagement;
}

const getDeviceManagementMenu = (user: CurrentUser) => {
	const views: DashboardView[] = [];
	if (user.canDoAction(DeviceManagementActions.CanUseDeviceManagement)) {
		views.push(terminalMappingListView);
	}

	if (views.length === 0) {
		return null;
	}

	const deviceManagement: DashboardRoute = { path: '/devices', name: 'Device Management', icon: Tablet, collapse: true, state: 'openDeviceManagement', views };
	return deviceManagement;
}

const getPricingMenu = (user: CurrentUser) => {
	const views: DashboardView[] = [];
	if (user.canDoAction(PricingActions.CanUseDiscountManager)) {
		views.push(discountListView);
	}

	if (views.length === 0) {
		return null;
	}

	const pricingManagement: DashboardRoute = { path: '/pricing', name: 'Pricing', icon: AttachMoneyIcon, collapse: true, state: 'openPricing', views };
	return pricingManagement;
}
