import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import { useHistory } from 'react-router-dom';
import { Field, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';

import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import FormInput from 'domains/core/components/FormInput';
import DiscountGroups from 'domains/discounts/components/DiscountGroups';
import DiscountAmountGroups from 'domains/discounts/components/DiscountAmountGroups';

import { doLoadItemRetails } from 'domains/discounts/actions';
import { DiscountEffectType, ProofOfPurchaseType, RetailLocationTypeEnum, DiscountFormValues } from 'domains/discounts/models';
import { SourceSystemMarket } from 'domains/core/models'
import { DiscountTypeEnum, DiscountRetailLocation } from 'domains/core/models';
import { getCeaPrograms, getDiscountTypes, getItemRetailTypes, getItemRetails, getDiscountTimeFrameTypes, getDiscountMarkets, getRetailLocationTypes, getRetailLocationLocationTypes, getRetailLocationMruTypes, getDiscountRetailLocations } from 'domains/discounts/selectors';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';
import * as formatters from 'utils/fieldFormatters';
import { CheckboxAdapter } from 'domains/core/components/FinalForm';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import { useWatchForChange } from 'utils/React';

const style = {
	sectionHeader: {
		fontWeight: 800,
		fontSize: '12px'
	},
	sectionMarginTop: {
		marginTop: '24px'
	},
	noteSection: {
		marginTop: '8px'
	},
	cartLimitContainer: {
		marginLeft: '32px',
		marginTop: '-20px',
		width: '75px'
	},
	cartMinimumPriceContainer: {
		marginLeft: '32px',
		marginTop: '-20px',
		width: '200px'
	},
	consumerLimitContainer: {
		marginLeft: '32px',
		marginTop: '-20px',
		width: '300px'
	},
	consumerLimitText: {
		marginTop: '30px'
	},
	proofOfPurchaseContainer: {
		marginLeft: '32px',
		marginTop: '-10px',
		width: '200px'
	},
	hidden: {
		display: 'none'
	},
};

interface Props extends FormRenderProps<DiscountFormValues> {
	editMode: boolean;
}

export default function CreateEditDiscountForm(props: Props) {
	const { editMode, handleSubmit, initialValues, values, invalid, pristine, form: { change } } = props;
	const classes = useStyles(style);
	const history = useHistory();
	const dispatch = useDispatch();

	const ceaPrograms = useSelector(state => getCeaPrograms(state));
	const discountTypes = useSelector(state => getDiscountTypes(state));
	const itemRetailTypes = useSelector(state => getItemRetailTypes(state));
	const itemRetails = useSelector(state => getItemRetails(state));
	const discountTimeFrameTypes = useSelector(state => getDiscountTimeFrameTypes(state));
	const discountMarkets = useSelector(state => getDiscountMarkets(state));
	const retailLocationTypes = useSelector(state => getRetailLocationTypes(state));
	const physicalRetailLocationSubtypes = useSelector(state => getRetailLocationLocationTypes(state));
	const mruSubtypes = useSelector(state => getRetailLocationMruTypes(state));
	const retailLocations = useSelector(state => getDiscountRetailLocations(state));
	const isLoading = useSelector(state => state.domains.discounts.isLoadingDiscountDependencies);

	const programIdSelected = useMemo(() => values?.programId, [values?.programId]);
	const programSelected = useMemo(() => programIdSelected ? ceaPrograms.filter(x => x.id === values?.programId)[0] : undefined, [ceaPrograms, programIdSelected, values?.programId]);
	const isCopeSelected = useMemo(() => programSelected?.name?.startsWith('Copenhagen'), [programSelected?.name]);
	const selectedDiscountTypeId = useMemo(() => values?.discountTypeId, [values?.discountTypeId]);
	const manualPromoCodeSelected = useMemo(() => values?.manualApplyPromoCode, [values?.manualApplyPromoCode]);
	const receiptCaptureSelected = useMemo(() => values?.isPhotoCaptureRequired, [values?.isPhotoCaptureRequired]);
	const selectedDiscountEffectType = useMemo(() => values?.discountEffectTypeId, [values?.discountEffectTypeId]);
	const discountGroups = useMemo(() => values?.discountGroups, [values?.discountGroups]);

	const isVirtualBundleSelected = useMemo(() => selectedDiscountTypeId === DiscountTypeEnum.virtualBundle, [selectedDiscountTypeId]);
	const isPriceDiscountSelected = useMemo(() => selectedDiscountTypeId === DiscountTypeEnum.priceDiscount, [selectedDiscountTypeId]);
	const showCartLimitField = useMemo(() => !!values?.isMaxLimitEnabled, [values?.isMaxLimitEnabled]);
	const showConsumerLimitField = useMemo(() => !!values?.hasTimeFrameLimit, [values?.hasTimeFrameLimit]);
	const showCcnManualField = useMemo(() => !!values?.manualApplyPromoCode, [values?.manualApplyPromoCode]);
	const showCcnAutomaticField = useMemo(() => !!values?.shouldAutoApply, [values?.shouldAutoApply]);
	const showProofOfPurchaseTypeField = useMemo(() => receiptCaptureSelected, [receiptCaptureSelected]);
	const showCopeExclusiveItemField = useMemo(() => isCopeSelected, [isCopeSelected]);
	const showCartMinimumPriceField = useMemo(() => !!values?.isCartMinimumPriceEnabled, [values?.isCartMinimumPriceEnabled]);
	const promoCodeRequired = useMemo(() => receiptCaptureSelected || manualPromoCodeSelected, [manualPromoCodeSelected, receiptCaptureSelected]);
	const manualPromoCodeRequired = useMemo(() => receiptCaptureSelected, [receiptCaptureSelected]);
	const showDiscountDollarAmountField = useMemo(() => selectedDiscountEffectType === DiscountEffectType.dollarOffCart || selectedDiscountEffectType === DiscountEffectType.cartDiscountWithConditions, [selectedDiscountEffectType]);
	const showDiscountPercentAmountField = useMemo(() => selectedDiscountEffectType === DiscountEffectType.percentOffCart || selectedDiscountEffectType === DiscountEffectType.cartPercentageDiscountWithConditions, [selectedDiscountEffectType]);
	const showDollarOffItemsDiscountField = useMemo(() => selectedDiscountEffectType === DiscountEffectType.dollarOffItems || selectedDiscountEffectType === DiscountEffectType.percentOffItems || selectedDiscountEffectType === DiscountEffectType.itemFinalPriceDiscount, [selectedDiscountEffectType]);
	const selectedProgram = useMemo(() => programIdSelected, [programIdSelected]);
	const discountTotal = useMemo(() => sumBy(discountGroups, item => Number(item.discountAmount)), [discountGroups]);
	const selectedLocationTypes = useMemo(() => values?.retailLocationTypeIds, [values?.retailLocationTypeIds]);
	const selectedRetailSubtypes = useMemo(() => values?.retailLocationLocationTypeIds, [values?.retailLocationLocationTypeIds]);
	const selectedMruSubtypes = useMemo(() => values?.retailLocationMruTypeIds, [values?.retailLocationMruTypeIds]);
	const selectedMarkets = useMemo(() => values?.discountMarketIds, [values?.discountMarketIds]);
	const selectedDiscountGroups = useMemo(() => values?.discountGroups, [values?.discountGroups]);
	const isPercentageDiscount = useMemo(() => selectedDiscountEffectType === DiscountEffectType.percentOffItems || selectedDiscountEffectType === DiscountEffectType.percentOffCart, [selectedDiscountEffectType]);
	const isFinalPriceDiscount = useMemo(() => selectedDiscountEffectType === DiscountEffectType.itemFinalPriceDiscount, [selectedDiscountEffectType]);
	const isDollarOffDiscount = useMemo(() => selectedDiscountEffectType === DiscountEffectType.dollarOffCart || selectedDiscountEffectType === DiscountEffectType.dollarOffItems, [selectedDiscountEffectType]);

	useWatchForChange({
		watch: { selectedProgram },
		onChange: (nextValues, oldValues) => {
			if (nextValues.selectedProgram && oldValues.selectedProgram !== nextValues.selectedProgram) {
				dispatch(doLoadItemRetails(nextValues.selectedProgram));
			}
		},
	})

	useEffect(() => {
		if (!isVirtualBundleSelected && isPriceDiscountSelected) {
			change('discountEffectTypeId', undefined);
		}
	}, [change, isPriceDiscountSelected, isVirtualBundleSelected]);

	useWatchForChange({
		watch: { required: values?.specificItemsRequired },
		onChange: (nextValues, oldValues) => {
			if (nextValues.required !== oldValues.required) {
				const value = nextValues.required ? values?.discountGroups ?? initialValues?.discountGroups : undefined;
				change('discountGroups', value);
			}
		}
	});

	const getProgramMenuItems = useMemo(() => {
		return ceaPrograms.map(value => ({ value: value.id, text: value.name }));
	}, [ceaPrograms]);

	const getDiscountTypesMenuItems = useMemo(() => {
		return discountTypes.map(value => ({ value: value.id, text: value.displayName }));
	}, [discountTypes]);

	const getDiscountTimeFrameTypesMenuItems = useMemo(() => {
		return discountTimeFrameTypes.map(value => ({ value: value.id, text: value.displayName }));
	}, [discountTimeFrameTypes]);

	const getDiscountMarketsForProgram = useMemo(() => {
		let markets: SourceSystemMarket[] = [];
		if (selectedProgram) {
			markets = discountMarkets.filter(r => r.programId === selectedProgram);
		}
		return markets.map(x => ({ value: x.marketId, text: x.shortName }));
	}, [discountMarkets, selectedProgram]);

	const getLocationTypes = useMemo(() => {
		return retailLocationTypes.map(l => ({ value: l.id, text: l.displayName }));
	}, [retailLocationTypes]);

	const getPhysicalRetailLocationSubtypes = useMemo(() => {
		return physicalRetailLocationSubtypes.map(s => ({ value: s.id, text: s.displayName }));
	}, [physicalRetailLocationSubtypes]);

	const getMruSubtypes = useMemo(() => {
		return mruSubtypes.map(s => ({ value: s.id, text: s.displayName }));
	}, [mruSubtypes]);

	const minimumPricingEligible = useMemo(() => {
		let hasMinimumPricing = false;
		selectedDiscountGroups?.forEach((group) => {
			if (group.conditions) {
				group.conditions.forEach((condition) => {
					const retailType = itemRetailTypes.find(x => x.id === condition.itemRetailTypeId);
					if (retailType && retailType.canOverrideMinimumPrice) {
						hasMinimumPricing = true;
					}
				});
			}
		});

		return hasMinimumPricing;
	}, [itemRetailTypes, selectedDiscountGroups]);

	const getRetailLocations = useMemo(() => {
		let locations: DiscountRetailLocation[] = [];
		let physicalLocations: DiscountRetailLocation[] = [];
		let mruLocations: DiscountRetailLocation[] = [];

		//filter retail locations by program
		if (selectedProgram) {
			locations = retailLocations.filter(r => r.programId === selectedProgram);
		}

		//filter retail locations by markets
		if (selectedMarkets && selectedMarkets.length > 0) {
			locations = locations.filter(r => selectedMarkets.indexOf(r.marketId) >= 0);
		}

		if (selectedLocationTypes) {
			//find physical retail locations
			if (selectedLocationTypes.indexOf(RetailLocationTypeEnum.physicalLocation) >= 0) {
				const filteredPhysicalLocations = locations.filter(r => r.storeType === RetailLocationTypeEnum.physicalLocation);
				if (selectedRetailSubtypes && selectedRetailSubtypes.length > 0) {
					physicalLocations = filteredPhysicalLocations.filter(r => r.locationType === undefined || selectedRetailSubtypes.includes(r.locationType));
				}
			}

			//find mru locations
			if (selectedLocationTypes.indexOf(RetailLocationTypeEnum.mobileRetailUnit) >= 0) {
				const filteredMruLocations = locations.filter(r => r.storeType === RetailLocationTypeEnum.mobileRetailUnit);
				if (selectedMruSubtypes && selectedMruSubtypes.length > 0) {
					mruLocations = filteredMruLocations.filter(r => r.retailLocationMruType === undefined || selectedMruSubtypes.includes(r.retailLocationMruType));
				}
			}
		}

		const allLocations = concat(physicalLocations, mruLocations);
		return orderBy(allLocations, x => x.storeName).map(l => ({ value: l.id, text: l.storeName }));
	}, [retailLocations, selectedLocationTypes, selectedMarkets, selectedMruSubtypes, selectedProgram, selectedRetailSubtypes]);

	const retailSubtypeSelected = useMemo(() => {
		if (selectedLocationTypes && selectedLocationTypes.indexOf(RetailLocationTypeEnum.physicalLocation) >= 0) {
			return true;
		}
		return false;
	}, [selectedLocationTypes]);

	const mruSubtypeSelected = useMemo(() => {
		if (selectedLocationTypes && selectedLocationTypes.indexOf(RetailLocationTypeEnum.mobileRetailUnit) >= 0) {
			return true;
		}
		return false;
	}, [selectedLocationTypes]);

	const showRetailLocationDropdown = useMemo(() => {
		if (!selectedLocationTypes || selectedLocationTypes.length === 0) {
			return false;
		}
		if (selectedLocationTypes.length > 0 && selectedLocationTypes.every(x => x !== -1)) {
			if (!retailSubtypeSelected && !mruSubtypeSelected) {
				return true;
			}
		}
		if (mruSubtypeSelected && retailSubtypeSelected && selectedRetailSubtypes && selectedRetailSubtypes.length > 0 && selectedMruSubtypes && selectedMruSubtypes.length > 0) {
			return true;
		}
		if (retailSubtypeSelected && !mruSubtypeSelected) {
			if (selectedRetailSubtypes && selectedRetailSubtypes.length > 0) {
				return true;
			}
		}
		if (mruSubtypeSelected && !retailSubtypeSelected) {
			if (selectedMruSubtypes && selectedMruSubtypes.length > 0) {
				return true;
			}
		}
		return false;
	}, [mruSubtypeSelected, retailSubtypeSelected, selectedLocationTypes, selectedMruSubtypes, selectedRetailSubtypes]);

	const onManualPromoCodeChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('isCcnRequiredForManualApply', false);
			change('promoCode', '');
		}
	}, [change]);

	const onAutoApplyChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('isCcnRequiredForAutoApply', checked);
		}
	}, [change]);

	const onCartLimitChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('maxLimitPerCart', null);
		}
	}, [change]);

	const onCartMinimumPriceChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('cartMinimumPrice', null);
		}
	}, [change]);

	const onConsumerLimitChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('timeFrameLimit', null);
			change('discountTimeFrameTypeId', null);
		}
	}, [change]);

	const onPhotoCaptureChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		if (!checked) {
			change('proofOfPurchaseTypeId', null);
		}
	}, [change]);

	const validateEndDateAfterStartDate = useCallback((endDate?: string, allValues?: DiscountFormValues) => {
		const startDate = allValues?.effectiveStartDate
			? moment(allValues.effectiveStartDate).format('MM/DD/YYYY')
			: null;
		return validators.isEndDateAfterStartDate(startDate, endDate);
	}, []);

	const handleSelectAllRetailLocations = useCallback((allSelected: boolean) => {
		change('allRetailLocationsSelected', allSelected);
	}, [change]);

	const handleDiscountTypeUpdate = useCallback(() => {
		change('retailLocationTypeIds', retailLocationTypes.map(x => x.id));
		change('retailLocationLocationTypeIds', physicalRetailLocationSubtypes.map(x => x.id));
		change('retailLocationMruTypeIds', mruSubtypes.map(x => x.id));
		change('retailLocationIds', retailLocations.map(x => x.id));
		change('allRetailLocationsSelected', true);
	}, [change, mruSubtypes, physicalRetailLocationSubtypes, retailLocationTypes, retailLocations]);

	const handleDiscountTypeChange = useCallback((value) => {
		if (value === DiscountTypeEnum.consumerReferral) {
			handleDiscountTypeUpdate();
		}
	}, [handleDiscountTypeUpdate]);

	const handleMarketChange = useCallback(() => {
		if (selectedDiscountTypeId === DiscountTypeEnum.consumerReferral) {
			handleDiscountTypeUpdate();
		}
	}, [handleDiscountTypeUpdate, selectedDiscountTypeId]);

	const submitDisabled = useMemo(() => invalid || pristine || initialValues?.isLockedDown, [initialValues?.isLockedDown, invalid, pristine]);
	const isConsumerReferral = useMemo(() => selectedDiscountTypeId === DiscountTypeEnum.consumerReferral, [selectedDiscountTypeId]);
	const filteredRetailLocations = useMemo(() => showRetailLocationDropdown ? getRetailLocations : [], [getRetailLocations, showRetailLocationDropdown]);

	if (isLoading) {
		return null;
	}

	return (
		<form onSubmit={handleSubmit} >
			<GridContainer>
				<GridItem xs={12} md={12} lg={12}><div className={classes.sectionHeader}>AVAILABILITY</div></GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="programId"
						validate={validators.required}
					>
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Program"
								required
								disabled={editMode}
								options={getProgramMenuItems}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="discountMarketIds"
						validate={validators.required}
					>
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Market(s)"
								disabled={selectedProgram === undefined && !editMode}
								multiple
								selectAllLabel="All Markets"
								options={getDiscountMarketsForProgram}
								customOnChange={handleMarketChange}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="retailLocationTypeIds"
						validate={validators.required}
					>
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Location Type(s)"
								disabled={selectedProgram === undefined || isConsumerReferral}
								multiple
								required
								selectAllLabel="All Location Types"
								options={getLocationTypes}
							/>
						)}
					</Field>
				</GridItem>
				{retailSubtypeSelected &&
					<GridItem xs={12} md={12} lg={12}>
						<Field
							name="retailLocationLocationTypeIds"
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Physical Location Subtype(s)"
									disabled={selectedProgram === undefined || isConsumerReferral}
									multiple
									required
									selectAllLabel="All Physical Location Subtypes"
									options={getPhysicalRetailLocationSubtypes}
								/>
							)}
						</Field>
					</GridItem>
				}
				{mruSubtypeSelected &&
					<GridItem xs={12} md={12} lg={12}>
						<Field
							name="retailLocationMruTypeIds"
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="MRU Subtype(s)"
									disabled={selectedProgram === undefined || isConsumerReferral}
									multiple
									required
									selectAllLabel="All MRU Subtypes"
									options={getMruSubtypes}
								/>
							)}
						</Field>
					</GridItem>
				}
				{showRetailLocationDropdown &&
					<GridItem xs={12} md={12} lg={12}>
						<Field
							name="retailLocationIds"
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Location(s)"
									disabled={selectedProgram === undefined || isConsumerReferral}
									multiple
									required={filteredRetailLocations?.length ? true : false}
									selectAllLabel="All Locations"
									options={filteredRetailLocations}
									handleSelectAll={handleSelectAllRetailLocations}
								/>
							)}
						</Field>
					</GridItem>
				}
				<GridItem xs={12} md={6} lg={6}>
					<Field
						component={FormInput}
						name="effectiveStartDate"
						labelText="Start Date"
						formControlProps={{
							fullWidth: true,
							required: true
						}}
						validate={editMode ? validators.isDate : validators.composeValidators(validators.isDate, validators.isFutureDate)}
						parse={normalizers.date}
						format={formatters.date}
					>
					</Field>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<Field
						component={FormInput}
						name="effectiveEndDate"
						labelText="End Date"
						formControlProps={{
							fullWidth: true,
							required: false
						}}
						validate={editMode ? validators.composeValidators(validators.isDate, validateEndDateAfterStartDate) : validators.composeValidators(validators.isDate, validators.isFutureDate, validateEndDateAfterStartDate)}
						parse={normalizers.date}
						format={formatters.date}
					>
					</Field>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}><div className={`${classes.sectionHeader} ${classes.sectionMarginTop}`}>DISCOUNT DETAILS</div></GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="discountTypeId"
						validate={validators.required}
					>
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Discount Type"
								required
								disabled={editMode}
								customOnChange={handleDiscountTypeChange}
								options={getDiscountTypesMenuItems}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						component={FormInput}
						name="name"
						labelText="Internal Name"
						formControlProps={{
							fullWidth: true,
							required: true,
							disabled: editMode
						}}
						validate={validators.maxLength50}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						component={FormInput}
						name="displayName"
						labelText="Display Name"
						formControlProps={{
							fullWidth: true,
							required: true
						}}
						validate={validators.maxLength50}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						component={FormInput}
						name="promoCode"
						labelText="Promo Code"
						formControlProps={{
							fullWidth: true,
							required: promoCodeRequired
						}}
						validate={validators.composeValidators(validators.minLength10, validators.maxLength25, validators.isPromoCode)}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						component={FormInput}
						name="description"
						labelText="Description"
						formControlProps={{
							fullWidth: true,
							required: false
						}}
						inputProps={{
							multiline: true
						}}
						validate={validators.maxLength250}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}><div className={`${classes.sectionHeader} ${classes.sectionMarginTop}`}>APPLICATION</div></GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="manualApplyPromoCode"
						component={CheckboxAdapter}
						handleOnChange={onManualPromoCodeChange}
						label={`Can apply manually with Promo Code`}
						required={manualPromoCodeRequired}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="displayInCea"
						component={CheckboxAdapter}
						label={`Display discount in CEA`}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="shouldAutoApply"
						component={CheckboxAdapter}
						handleOnChange={onAutoApplyChange}
						label={`Auto apply when cart conditions are met`}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="isMaxLimitEnabled"
						component={CheckboxAdapter}
						handleOnChange={onCartLimitChange}
						label={`Limit number of applications per cart`}
					/>
					{showCartLimitField &&
						<div className={classes.cartLimitContainer}>
							<Field
								component={FormInput}
								name="maxLimitPerCart"
								labelText="Limit"
								formControlProps={{
									fullWidth: false,
									required: true
								}}
								parse={normalizers.numeric}
							/>
						</div>
					}
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="hasTimeFrameLimit"
						component={CheckboxAdapter}
						handleOnChange={onConsumerLimitChange}
						label={`Limit number of applications per consumer`}
					/>
				</GridItem>
				{showConsumerLimitField &&
					<GridItem xs={12} md={12} lg={12}>
						<div className={classes.consumerLimitContainer}>
							<GridContainer>
								<GridItem xs={3} md={3} lg={3}>
									<Field
										component={FormInput}
										name="timeFrameLimit"
										labelText="Limit"
										formControlProps={{
											fullWidth: true,
											required: true
										}}
										parse={normalizers.limitTwoDigits}
										validate={validators.maxLength2}
									/>
								</GridItem>
								<GridItem xs={2} md={2} lg={2}>
									<div className={classes.consumerLimitText}>per</div>
								</GridItem>
								<GridItem xs={6} md={6} lg={6}>
									<Field
										name="discountTimeFrameTypeId"
										validate={validators.required}
									>
										{(fieldRenderProps) => (
											<FormSelectAdapter
												{...fieldRenderProps}
												labelText="Time Frame"
												options={getDiscountTimeFrameTypesMenuItems}
											/>
										)}
									</Field>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				}
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="isExclusiveItemDiscount"
						component={CheckboxAdapter}
						label={`Can't be combined with other discounts on the same item`}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<div className={showCopeExclusiveItemField ? undefined : classes.hidden}>
						<Field
							name="isCopeExclusiveItemDiscount"
							component={CheckboxAdapter}
							label={`Can't be combined with other discounts with this option selected`}
						/>
					</div>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="isPhotoCaptureRequired"
						component={CheckboxAdapter}
						handleOnChange={onPhotoCaptureChange}
						label={`Trigger receipt capture upon application`}
					/>
					{showProofOfPurchaseTypeField &&
						<div className={classes.proofOfPurchaseContainer}>
							<Field
								name="proofOfPurchaseTypeId"
								validate={validators.required}
							>
								{(fieldRenderProps) => (
									<FormSelectAdapter
										{...fieldRenderProps}
										labelText="Proof Of Purchase"
										required
										options={[
											{ value: ProofOfPurchaseType.internationalExchange, text: 'International Exchange' },
											{ value: ProofOfPurchaseType.manualBundle, text: 'Manual Bundle' }
										]}
									/>
								)}
							</Field>
						</div>
					}
				</GridItem>
				<GridItem xs={12} md={12} lg={12}><div className={`${classes.sectionHeader} ${classes.sectionMarginTop}`}>CART CONDITIONS</div></GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<div className={showCcnManualField ? undefined : classes.hidden}>
						<Field
							name="isCcnRequiredForManualApply"
							component={CheckboxAdapter}
							label={`CCN required for manual application`}
						/>
					</div>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<div className={showCcnAutomaticField ? undefined : classes.hidden}>
						<Field
							name="isCcnRequiredForAutoApply"
							component={CheckboxAdapter}
							label={`CCN required for automatic application`}
						/>
					</div>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="specificItemsRequired"
						component={CheckboxAdapter}
						label={`Specific items required`}
					/>

				</GridItem>
				{values?.specificItemsRequired &&
					<GridItem xs={12} md={12} lg={12}>
						<FieldArray name="discountGroups">
							{fieldProps => <DiscountGroups {...fieldProps} itemRetails={itemRetails} />}
						</FieldArray>
					</GridItem>
				}
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="isCartMinimumPriceEnabled"
						component={CheckboxAdapter}
						handleOnChange={onCartMinimumPriceChange}
						label={`Minimum cart price required`}
					/>
					{showCartMinimumPriceField &&
						<div className={classes.cartMinimumPriceContainer}>
							<Field
								component={FormInput}
								name="cartMinimumPrice"
								labelText="Amount"
								formControlProps={{
									fullWidth: true,
									required: true
								}}
								parse={normalizers.currency}
								format={formatters.currency}
								validate={validators.isGreaterThanZero}
							/>
						</div>
					}
				</GridItem>
				<GridItem xs={12} md={12} lg={12}><div className={`${classes.sectionHeader} ${classes.sectionMarginTop}`}>EFFECT</div></GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="discountEffectTypeId"
					>
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								id="discountEffectTypeSelectField"
								labelText="Discount Effect Type"
								required
								disabled={isVirtualBundleSelected || editMode}
								options={[
									{ value: DiscountEffectType.dollarOffCart, text: '$ off cart' },
									{ value: DiscountEffectType.dollarOffItems, text: '$ off item(s)' },
									{ value: DiscountEffectType.percentOffCart, text: '% off cart' },
									{ value: DiscountEffectType.percentOffItems, text: '% off item(s)' },
									{ value: DiscountEffectType.itemFinalPriceDiscount, text: 'Item final price' }
								]}
							/>
						)}
					</Field>
				</GridItem>
				{showDollarOffItemsDiscountField &&
					<GridItem xs={12} md={12} lg={12}>
						<FieldArray name="discountGroups">
							{fieldProps => (
								<DiscountAmountGroups
									{...fieldProps}
									itemRetails={itemRetails}
									editMode={editMode}
									isDollarOffDiscount={isDollarOffDiscount}
									isFinalPriceDiscount={isFinalPriceDiscount}
									isPercentageDiscount={isPercentageDiscount}
									minimumPricingEligible={minimumPricingEligible}
									discountTotal={discountTotal}
								/>
							)}
						</FieldArray>
					</GridItem>
				}
				{showDiscountDollarAmountField &&
					<GridItem xs={12} md={12} lg={12}>
						<Field
							component={FormInput}
							name="discountAmount"
							labelText="Total Discount"
							formControlProps={{
								fullWidth: true,
								required: true,
								disabled: editMode
							}}
							parse={normalizers.currency}
							format={formatters.currency}
							validate={validators.isGreaterThanZero}
						/>
					</GridItem>
				}
				{showDiscountPercentAmountField &&
					<GridItem xs={12} md={12} lg={12}>
						<Field
							component={FormInput}
							name="discountPercentage"
							labelText="Total Discount"
							formControlProps={{
								fullWidth: true,
								required: true,
								disabled: editMode
							}}
							parse={normalizers.limitThreeDigits}
							format={formatters.percentage}
							validate={validators.composeValidators(validators.isGreaterThanZero, validators.isLessThanOrEqualTo(100))}
						/>
					</GridItem>
				}
				<GridItem>
					<Button color="primary" type="submit" disabled={submitDisabled}>{editMode ? "Save" : "Add"}</Button>
					<Button simple color="primary" onClick={history.goBack}>Cancel</Button>
				</GridItem>
				{!editMode &&
					<GridItem xs={12} md={12} lg={12}><div className={classes.noteSection}>Note: Discount will be added as inactive.</div></GridItem>
				}
			</GridContainer>
		</form >
	);
}
