import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { ApplicationState } from 'applicationTypes';
import { UserAuditFilters, UserAudit } from 'domains/audits/models';
import { userAuditSchema } from 'domains/audits/schemas';

const getEntities = (state: ApplicationState) => state.entities;
export const getFilters = (state: ApplicationState): UserAuditFilters => state.domains.audits.userAuditFilters;

export const getAudits = createSelector(
	[getEntities],
	(entities): UserAudit[] => {
		const schema = { userAudits: [userAuditSchema] };
		const result = denormalize({ userAudits: Object.keys(entities.userAudits) }, schema, entities).userAudits;
		return result;
	}
);
