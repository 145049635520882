import { schema } from 'normalizr';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import * as actionTypes from 'domains/companies/actionTypes';
import * as companySchemas from 'domains/companies/schemas';
import { Company, UpdateCompanyActiveStatusRequest } from 'domains/companies/models';
import { SearchRequest } from 'domains/core/models';

export const doLoadCompanies = (request: SearchRequest): Action => {
	return {
		resetEntity: 'companies',
		type: actionTypes.LOAD_COMPANIES,
		payload: request
	};
};

export const doLoadCompaniesNoOverlay = (request: SearchRequest): Action =>  {
	return {
		type: actionTypes.LOAD_COMPANIES_NO_OVERLAY,
		payload: request
	};
}

export const loadCompanies = (request: SearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/Company/Search', {
				schema: {
					companies: new schema.Array(companySchemas.companySchema)
				},
				data: request
			});
		}
	};
};

export const doSaveCompany = (request: Company): Action => {
	return {
		type: actionTypes.CREATE_COMPANY,
		payload: request
	};
};

export const saveCompany = (company: Company): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/Company/Save', {
				schema: companySchemas.companySchema,
				data: company
			});
		}
	};
};

export const doLoadCompany = (companyId: number): Action => {
	return {
		type: actionTypes.LOAD_COMPANY,
		payload: companyId
	};
};

export const loadCompany = (companyId: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/Company/${companyId}`, {
				schema: companySchemas.companySchema
			});
		}
	};
};

export const doLoadCompanyDetail = (companyId: number): Action => {
	return {
		type: actionTypes.LOAD_COMPANY_DETAIL,
		payload: companyId
	};
};

export const loadCompanyDetail = (companyId: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/Company/CompanyDetail/${companyId}`, {
				schema: {
					company: companySchemas.companySchema
				}
			});
		}
	};
};

export const doActivateCompany = (request: UpdateCompanyActiveStatusRequest): Action => {
	return {
		type: actionTypes.ACTIVATE_COMPANY,
		payload: request
	};
};

export const activateCompany = (request: UpdateCompanyActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/Company/Activate', {
				schema: companySchemas.companySchema,
				data: request
			});
		}
	};
};

export const doDeactivateCompany = (request: UpdateCompanyActiveStatusRequest): Action => {
	return {
		type: actionTypes.DEACTIVATE_COMPANY,
		payload: request
	};
};

export const deactivateCompany = (request: UpdateCompanyActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/Company/Deactivate', {
				schema: companySchemas.companySchema,
				data: request
			});
		}
	};
};

export const doFilterCompanies = (filter: string): Action => {
	return {
		type: actionTypes.FILTER_COMPANIES,
		payload: filter
	};
};
