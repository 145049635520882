import { schema } from 'normalizr';
import * as actionTypes from 'domains/roles/actionTypes';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import { roleSchema, userActionSchema } from 'domains/roles/schemas';
import { Role, RoleExportRequest, LoadUserActionsRequest, UpdateRoleActionsRequest } from 'domains/roles/models';

export const doLoadRoles = (): Action => {
	return {
		type: actionTypes.LOAD_ROLES
	};
}

export const loadRoles = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/roles', {
				schema: new schema.Array(roleSchema)
			});
		}
	};
}

export const doLoadUserActions = (request: LoadUserActionsRequest): Action => {
	return {
		type: actionTypes.LOAD_USER_ACTIONS,
		payload: request
	}
}

export const loadUserActions = (request: LoadUserActionsRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/roles/actions/${request.system}`, {
				schema: new schema.Array(userActionSchema)
			});
		}
	};
}

export const doExportRoleActions = (request: RoleExportRequest, role: Role): Action => {
	return {
		type: actionTypes.EXPORT_ROLE,
		payload: {
			request,
			role
		}
	};
}

export const exportRoleActions = (request: RoleExportRequest, filename: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/roles/export', {
				data: request,
				filename
			});
		}
	};
}

export const doUpdateRoleActions = (request: UpdateRoleActionsRequest): Action => {
	return {
		type: actionTypes.UPDATE_ROLE_USER_ACTIONS,
		payload: request
	};
}

export const updateRoleActions = (request: UpdateRoleActionsRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/roles/actions/update', {
				data: request,
				schema: roleSchema
			});
		}
	};
}
