import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import Muted from 'components/Typography/Muted';
import { doLoadSecretShopper, doDeactivateSecretShopper } from 'domains/secretShopper/actions';
import Modal from 'domains/core/components/Modal';
import { getSecretShopperDetails } from 'domains/secretShopper/selectors';

const formatDateString = (date: string) => {
	return moment(date).format('MM/DD/YYYY');
};

const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	}
});

interface Params {
	id: string;
}

const SecretShopperDetails: React.FC<RouteComponentProps<Params>> = (props) => {
	const secretShopperId = props.match.params.id;

	const dispatch = useDispatch();
	const classes = useStyles();
	const secretShopper = useSelector(state => getSecretShopperDetails(state, Number(secretShopperId)));
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		dispatch(doLoadSecretShopper(secretShopperId));
	}, [dispatch, secretShopperId])

	const handleRemoveSecretShopper = () => {
		if (secretShopper) {
			dispatch(doDeactivateSecretShopper(secretShopper.id));
		}
		closeModal();
	}

	const openModal = () => {
		setModalOpen(true);
	}

	const closeModal = () => {
		setModalOpen(false);
	}

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Details</h2>
				<GridContainer>
					<GridItem>
						<Button fullWidth color="primary" onClick={openModal}>Remove CCN</Button>
					</GridItem>
				</GridContainer>
			</CardHeader>
			{secretShopper && 
			<CardBody>
				<GridContainer>
					<GridItem xs={12}>
						<Muted>
							<small>CCN</small>
						</Muted>
						<p className={classes.noTopMargin}>{secretShopper.ccn}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Description</small>
						</Muted>
						<p className={classes.noTopMargin}>{secretShopper.description || ''}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Date Added</small>
						</Muted>
						<p className={classes.noTopMargin}>{formatDateString(secretShopper.activatedAt)}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Added By</small>
						</Muted>
						<p className={classes.noTopMargin}>{secretShopper.activatedBy}</p>
					</GridItem>
				</GridContainer>	
			</CardBody>
			}
			{modalOpen && secretShopper &&
				<Modal
					modalOpen={modalOpen}
					title={`Remove CCN ${secretShopper.ccn}?`}
					content={'This will remove the CCN from the Secret Shopper program and will be availble to link in the CEA.'}
					closeButtonText="Cancel"
					handleClose={closeModal}
					actionButtonText={'Unblock'}
					handleAction={handleRemoveSecretShopper}
				/>
			}
		</Card>
	);
}

export default SecretShopperDetails;
