/* eslint-disable react/no-string-refs */
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/images/faces/avatar.png";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}

	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		const st = {
			openAvatar: false,
			miniActive: true
		};
		props.routes.map(route => {
			if (route.state) {
				st[route.state] = this.activeRoute(route.path) || (props.location.pathname === '/' && (!!route.isDefault));
			}
			return true;
		})

		this.state = st;
		this.activeRoute.bind(this);
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.startsWith(routeName) ? true : false;
	}

	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}

	render() {
		const {
			classes,
			color,
			image,
			routes,
			bgColor,
			rtlActive,
			userName,
			userActions
		} = this.props;
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive
			});
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		const caret =
			classes.caret +
			" " +
			cx({
				[classes.caretRTL]: rtlActive
			});
		const collapseItemMini =
			classes.collapseItemMini +
			" " +
			cx({
				[classes.collapseItemMiniRTL]: rtlActive
			});
		const photo =
			classes.photo +
			" " +
			cx({
				[classes.photoRTL]: rtlActive
			});
		var user = (
			<div className={userWrapperClass}>
				<div className={photo}>
					<img src={avatar} className={classes.avatarImg} alt="..." />
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"#"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<ListItemText
								primary={userName}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openAvatar ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							{userActions ?
								<List className={classes.list + " " + classes.collapseList}>
									{userActions.map((action, key) => {
										const shortName = action.name.match(/\b(\w)/g).join('');
										return <ListItem key={key} className={classes.collapseItem}>
											<NavLink to={action.to} className={classes.itemLink + " " + classes.userCollapseLinks} onClick={action.onClick}>
												<span className={collapseItemMini}>{shortName}</span>
												<ListItemText primary={action.name} disableTypography={true} className={collapseItemText} />
											</NavLink>
										</ListItem>;
									})}
								</List> :
								<List className={classes.list + " " + classes.collapseList}>
									<ListItem className={classes.collapseItem}>
										<NavLink
											to="#"
											className={
												classes.itemLink + " " + classes.userCollapseLinks
											}
										>
											<span className={collapseItemMini}>
												{rtlActive ? "مع" : "MP"}
											</span>
											<ListItemText
												primary={rtlActive ? "ملفي" : "My Profile"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									</ListItem>
									<ListItem className={classes.collapseItem}>
										<NavLink
											to="#"
											className={
												classes.itemLink + " " + classes.userCollapseLinks
											}
										>
											<span className={collapseItemMini}>
												{rtlActive ? "هوع" : "EP"}
											</span>
											<ListItemText
												primary={
													rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"
												}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									</ListItem>
									<ListItem className={classes.collapseItem}>
										<NavLink
											to="#"
											className={
												classes.itemLink + " " + classes.userCollapseLinks
											}
										>
											<span className={collapseItemMini}>
												{rtlActive ? "و" : "S"}
											</span>
											<ListItemText
												primary={rtlActive ? "إعدادات" : "Settings"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									</ListItem>
								</List>
							}
						</Collapse>
					</ListItem>
				</List>
			</div>
		);
		var links = (
			<List className={classes.list}>
				{routes.map((prop, key) => {
					if (prop.redirect) {
						return null;
					}
					if (prop.collapse) {
						const navLinkClasses =
							classes.itemLink +
							" " +
							cx({
								[" " + classes.collapseActive]: this.activeRoute(prop.path)
							});
						const itemText =
							classes.itemText +
							" " +
							cx({
								[classes.itemTextMini]:
									this.props.miniActive && this.state.miniActive,
								[classes.itemTextMiniRTL]:
									rtlActive && this.props.miniActive && this.state.miniActive,
								[classes.itemTextRTL]: rtlActive
							});
						const collapseItemText =
							classes.collapseItemText +
							" " +
							cx({
								[classes.collapseItemTextMini]:
									this.props.miniActive && this.state.miniActive,
								[classes.collapseItemTextMiniRTL]:
									rtlActive && this.props.miniActive && this.state.miniActive,
								[classes.collapseItemTextRTL]: rtlActive
							});
						const itemIcon =
							classes.itemIcon +
							" " +
							cx({
								[classes.itemIconRTL]: rtlActive
							});
						const caret =
							classes.caret +
							" " +
							cx({
								[classes.caretRTL]: rtlActive
							});
						return (
							<ListItem key={key} className={classes.item}>
								<NavLink
									to={"#"}
									className={navLinkClasses}
									onClick={() => this.openCollapse(prop.state)}
								>
									<ListItemIcon className={itemIcon}>
										{typeof prop.icon === "string" ? (
											<Icon>{prop.icon}</Icon>
										) : (
											<prop.icon />
										)}
									</ListItemIcon>
									<ListItemText
										primary={prop.name}
										secondary={
											<b
												className={
													caret +
													" " +
													(this.state[prop.state] ? classes.caretActive : "")
												}
											/>
										}
										disableTypography={true}
										className={itemText}
									/>
								</NavLink>
								<Collapse in={this.state[prop.state]} unmountOnExit>
									<List className={classes.list + " " + classes.collapseList}>
										{prop.views.map((prop, key) => {
											if (prop.redirect) {
												return null;
											}
											const navLinkClasses =
												classes.collapseItemLink +
												" " +
												cx({
													[" " + classes[color]]: this.activeRoute(prop.path)
												});
											const collapseItemMini =
												classes.collapseItemMini +
												" " +
												cx({
													[classes.collapseItemMiniRTL]: rtlActive
												});
											return (
												<ListItem key={key} className={classes.collapseItem}>
													<NavLink to={prop.path} className={navLinkClasses}>
														{prop.mini &&
															<span className={collapseItemMini}>
																{prop.mini}
															</span>
														}
														<ListItemText
															primary={prop.name}
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											);
										})}
									</List>
								</Collapse>
							</ListItem>
						);
					}
					const navLinkClasses =
						classes.itemLink +
						" " +
						cx({
							[" " + classes[color]]: this.activeRoute(prop.path)
						});
					const itemText =
						classes.itemText +
						" " +
						cx({
							[classes.itemTextMini]:
								this.props.miniActive && this.state.miniActive,
							[classes.itemTextMiniRTL]:
								rtlActive && this.props.miniActive && this.state.miniActive,
							[classes.itemTextRTL]: rtlActive
						});
					const itemIcon =
						classes.itemIcon +
						" " +
						cx({
							[classes.itemIconRTL]: rtlActive
						});
					return (
						<ListItem key={key} className={classes.item}>
							<NavLink to={prop.path} className={navLinkClasses}>
								<ListItemIcon className={itemIcon}>
									{typeof prop.icon === "string" ? (
										<Icon>{prop.icon}</Icon>
									) : (
										<prop.icon />
									)}
								</ListItemIcon>
								<ListItemText
									primary={prop.name}
									disableTypography={true}
									className={itemText}
								/>
							</NavLink>
						</ListItem>
					);
				})}
			</List>
		);

		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
		return (
			<div ref="mainPanel">
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={rtlActive ? "left" : "right"}
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<HeaderLinks rtlActive={rtlActive} />}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={rtlActive ? "right" : "left"}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
					>
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "blue"
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose"
	]),
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	userName: PropTypes.string,
	userActions: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Sidebar);
