import moment from 'moment';

export type Validator = (value: any) => string | undefined;

type Nullable<T> = null | undefined | T;

export const composeValidators = (...validators: any[]) => (value: any, allValues: any): Validator =>
	validators.reduce((error, validator) => error || validator(value, allValues), undefined as any);

export const required = (value: any) => {
	let returnValue: string | undefined = 'Required';
	const isBoolean = typeof value === 'boolean'; // to support boolean where false is the value
	if (value || isBoolean) {
		if (Array.isArray(value) && value.length < 1) {
			return returnValue;
		}
		returnValue = undefined;
	}
	return returnValue;
};

const zipCodeRegex = /^\d{5,6}(?:[-\s]\d{4})?$/;

const regexCheckConstructor = (regex: RegExp, errorMessage: string, value: any) => (!value || regex.exec(value) ? undefined : errorMessage);

const maxLength = (max: number) => (value: any) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const minLength = (min: number) => (value: any) => (value && value.length < min ? `Must be at least ${min} characters` : undefined);

const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{1,50}\.)+[a-zA-Z]{2,7}))$/;
const northAmericanPhoneNumberRegex = /^([(])?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*(?:[).-]\s*)??([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;
const promoCodeRegex = /^[a-zA-Z0-9]*$/;
const integerRegex = /^[0-9]+$/;

const noLeadingWhitespace = /^\S.*/;
export const hasNoLeadingWhitespace = (value: any) => regexCheckConstructor(noLeadingWhitespace, 'Must not have leading whitespace', value);

export const maxLength2 = maxLength(2);
export const maxLength3 = maxLength(3);
export const maxLength4 = maxLength(4);
export const maxLength12 = maxLength(12);
export const maxLength25 = maxLength(25);
export const maxLength30 = maxLength(30);
export const maxLength50 = maxLength(50);
export const maxLength100 = maxLength(100);
export const maxLength255 = maxLength(255);
export const maxLength250 = maxLength(250);
export const maxLength500 = maxLength(500);
export const maxLength1600 = maxLength(1600);
export const maxLength2000 = maxLength(2000);
export const minLength1 = minLength(1);
export const minLength8 = minLength(8);
export const minLength10 = minLength(10);
export const minLength14 = minLength(14);
export const isZipCode = (value: any) => regexCheckConstructor(zipCodeRegex, 'Invalid Zip', value);

export const requireEmailOrMobilePhone = (allValues: any) => (allValues && allValues.azureAdUser && allValues.azureAdUser.otherMails && !allValues.azureAdUser.mobilePhone && !allValues.azureAdUser.otherMails[0] ? "Email or Mobile Number is required." : undefined);

export const isEmail = (value: any, allValues: any) => {
	return requireEmailOrMobilePhone(allValues) || regexCheckConstructor(email, 'Must be a valid email', value);
}

export const isNorthAmericanPhoneNumber = (value: any, allValues: any) => {
	return requireEmailOrMobilePhone(allValues) || regexCheckConstructor(northAmericanPhoneNumberRegex, 'Must be a valid phone number', value);
}

export const isPromoCode = (value: Nullable<string>) => {
	if (value) {
		return regexCheckConstructor(promoCodeRegex, 'Must be alphanumeric characters only', value);
	}
	return undefined;
}

export const isDate = (value: Nullable<string>) => {
	if (value) {
		if (moment.isMoment(value)) {
			return moment(value).isValid() ? undefined : 'Must be a valid date (MM/DD/YYYY)';
		}

		const dateString = value.toString();

		if (dateString.length >= 10) {
			if (moment(dateString, 'YYYY-MM-DD').isValid()) {
				return undefined
			}
		}

		if (value.length !== 10 || !moment(value, 'MM-DD-YYYY').isValid()) {
			return 'Must be a valid date (MM/DD/YYYY)';
		}
	}
	return undefined;
}

export const isFutureDate = (date: Nullable<string>) => {
	return moment(date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? undefined : 'Date has to be in the future';
}

export const isEndDateAfterStartDateFinalForm = (value: Nullable<string>, allValues: any) => {
	return isEndDateAfterStartDate(allValues?.startDate, value);
}

export const isEndDateAfterStartDate = (startDate: Nullable<string>, endDate: Nullable<string>) => {
	if (startDate && endDate) {
		return moment(endDate).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD') ? undefined : 'Date has to be greater than or equal to start date';
	}

	return undefined;
}

export const isInteger = (value: number) => regexCheckConstructor(integerRegex, 'Integer required', value);

export const isGreaterThanZero = (value: string) => {
	if(value) {
		return Number(value) > 0 ? undefined : 'Amount must be greater than 0';
	}
}

export const isGreaterThanOrEqualToZero = (value: string) => {
	if (value) {
		return Number(value) >= 0 ? undefined : 'Amount has to be positive';
	}
}

export const isLessThanOrEqualTo = (max: number) => (value: number) => {
	if (value) {
		return value <= max ? undefined : `Must be ${max} or less.`
	}
}

export const assetTagWebFormat = (value: any, allValues: any) => 
{
	// device OS type: Web
	if (allValues.deviceOSType === 3 && value) {
		var str = value.split('-');
		var valid = str.length >= 2 && value.toLowerCase().startsWith('web-') && !!(str[1].trim()) && value.indexOf(' ') < 0
		return valid ? undefined : 'Must be in this format: web-username';
	}
	
	return undefined;
}
