import { Action, ApiAction, ApiClient } from 'applicationTypes';
import { SaveDeviceTerminalDetailsRequest } from 'domains/devices/models';
import * as actionTypes from './actionTypes';

export const doLoadDeviceTerminalSummary = (): Action => {
	return {
		type: actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY
	};
};

export const getDeviceTerminalMappings = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/DeviceManagement/GetDeviceTerminalMappings');
		}
	};
};

export const getDeviceTerminalMapping = (id: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/DeviceManagement/GetDeviceTerminalMapping/${id}`)
		}
	};
};

export const doLoadDeviceTerminalDetails = (deviceTerminalMappingId: number | undefined): Action => {
	return {
		type: actionTypes.LOAD_DEVICE_TERMINAL_DETAILS,
		payload: deviceTerminalMappingId
	};
}

export const getOnlineMerchantTerminals = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/DeviceManagement/GetOnlineMerchantTerminals');
		}
	};
};

export const doSaveDeviceTerminalDetails = (request: SaveDeviceTerminalDetailsRequest): Action => {
	return {
		type: actionTypes.SAVE_DEVICE_TERMINAL_DETAILS,
		payload: request
	};
};

export const saveDeviceTerminalDetails = (request: SaveDeviceTerminalDetailsRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/DeviceManagement/SaveDeviceTerminalDetails', {
				data: request
			});
		}
	};
};

export const doDeactivateDeviceTerminal = (id: number): Action => {
	return {
		type: actionTypes.DEACTIVATE_DEVICE_TERMINAL,
		payload: id
	};
};

export const deactivateDeviceTerminal = (id: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post(`/admin/DeviceManagement/DeactivateDeviceTerminalMapping/${id}`)
		}
	};
};
