export const LOAD_COMPANY_USERS = 'users/LOAD_COMPANY_USERS';
export const LOAD_COMPANY_USERS_SUCCESS = 'users/LOAD_COMPANY_USERS_SUCCESS';
export const LOAD_COMPANY_USERS_FAIL = 'users/LOAD_COMPANY_USERS_FAIL';

export const SAVE_USER = 'users/SAVE_USER';
export const SAVE_USER_SUCCESS = 'users/SAVE_USER_SUCCESS';
export const SAVE_USER_FAIL = 'users/SAVE_USER_FAIL';

export const LOAD_COMPANY_USER_DETAIL = 'users/LOAD_COMPANY_USER_DETAIL';
export const LOAD_COMPANY_USER_DETAIL_SUCCESS = 'users/LOAD_COMPANY_USER_DETAIL_SUCCESS';
export const LOAD_COMPANY_USER_DETAIL_FAIL = 'users/LOAD_COMPANY_USER_DETAIL_FAIL';

export const DEACTIVATE_COMPANY_USER = 'users/DEACTIVATE_COMPANY_USER';
export const DEACTIVATE_COMPANY_USER_SUCCESS = 'users/DEACTIVATE_COMPANY_USER_SUCCESS';
export const DEACTIVATE_COMPANY_USER_FAIL = 'users/DEACTIVATE_COMPANY_USER_FAIL';

export const ACTIVATE_COMPANY_USER = 'users/ACTIVATE_COMPANY_USER';
export const ACTIVATE_COMPANY_USER_SUCCESS = 'users/ACTIVATE_COMPANY_USER_SUCCESS';
export const ACTIVATE_COMPANY_USER_FAIL = 'users/ACTIVATE_COMPANY_USER_FAIL';

export const LOAD_USER = 'users/LOAD_USER';
export const LOAD_USER_SUCCESS = 'users/LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'users/LOAD_USER_FAIL';

export const LOAD_METRIRWEB_MARKETS = 'users/LOAD_METRIRWEB_MARKETS';
export const LOAD_METRIXWEB_MARKETS_SUCCESS = 'users/LOAD_METRIXWEB_MARKETS_SUCCESS';
export const LOAD_METRIXWEB_MARKETS_FAIL = 'users/LOAD_METRIXWEB_MARKETS_FAIL';

export const LOAD_METRIXWEB_PROGRAMS = 'users/LOAD_METRIXWEB_PROGRAMS';
export const LOAD_METRIXWEB_PROGRAMS_SUCCESS = 'users/LOAD_METRIXWEB_PROGRAMS_SUCCESS';
export const LOAD_METRIXWEB_PROGRAMS_FAIL = 'users/LOAD_METRIXWEB_PROGRAMS_FAIL';

export const LOAD_METRIXWEB_ROLES = 'users/LOAD_METRIXWEB_ROLES';
export const LOAD_METRIXWEB_ROLES_SUCCESS = 'users/LOAD_METRIXWEB_ROLES_SUCCESS';
export const LOAD_METRIXWEB_ROLES_FAIL = 'users/LOAD_METRIXWEB_ROLES_FAIL';

export const LOAD_METRIXWEB_MANAGERS = 'users/LOAD_METRIXWEB_MANAGERS';
export const LOAD_METRIXWEB_MANAGERS_SUCCESS = 'users/LOAD_METRIXWEB_MANAGERS_SUCCESS';
export const LOAD_METRIXWEB_MANAGERS_FAIL = 'users/LOAD_METRIXWEB_MANAGERS_FAIL';

export const RESET_PIN = 'users/RESET_PIN';
export const RESET_PIN_SUCCESS = 'users/RESET_PIN_SUCCESS';
export const RESET_PIN_FAIL = 'users/RESET_PIN_FAIL';

export const LOAD_USER_AND_DEPENDENCIES = 'users/LOAD_USER_AND_DEPENDENCIES';
export const LOAD_USER_AND_DEPENDENCIES_SUCCESS = 'users/LOAD_USER_AND_DEPENDENCIES_SUCCESS';
export const LOAD_USER_AND_DEPENDENCIES_FAIL = 'users/LOAD_USER_AND_DEPENDENCIES_FAIL';

export const LOAD_USER_FILTER_DEPENDENCIES = 'users/LOAD_USER_FILTER_DEPENDENCIES';
export const LOAD_USER_FILTER_DEPENDENCIES_SUCCESS = 'users/LOAD_USER_FILTER_DEPENDENCIES_SUCCESS';
export const LOAD_USER_FILTER_DEPENDENCIES_FAIL = 'users/LOAD_USER_FILTER_DEPENDENCIES_FAIL';

export const EXPORT_USER_LIST = 'users/EXPORT_USER_LIST';
export const EXPORT_USER_LIST_SUCCESS = 'users/EXPORT_USER_LIST_SUCCESS';
export const EXPORT_USER_LIST_FAIL = 'users/EXPORT_USER_LIST_FAIL';

export const LOAD_STAFFING_PLACEMENT_SCHEDULE = 'users/LOAD_STAFFING_PLACEMENT_SCHEDULE';
export const LOAD_STAFFING_PLACEMENT_SCHEDULE_SUCCESS = 'users/LOAD_STAFFING_PLACEMENT_SCHEDULE_SUCCESS';
export const LOAD_STAFFING_PLACEMENT_SCHEDULE_FAIL = 'users/LOAD_STAFFING_PLACEMENT_SCHEDULE_FAIL';

export const UPDATE_STAFFING_PLACEMENT_SCHEDULE = 'users/UPDATE_STAFFING_PLACEMENT_SCHEDULE';
export const UPDATE_STAFFING_PLACEMENT_SCHEDULE_SUCCESS = 'users/UPDATE_STAFFING_PLACEMENT_SCHEDULE_SUCCESS';
export const UPDATE_STAFFING_PLACEMENT_SCHEDULE_FAIL = 'users/UPDATE_STAFFING_PLACEMENT_SCHEDULE_FAIL';

export const SCHEDULE_ON_DEMAND_STAFFING_PLACEMENT = 'users/SCHEDULE_ON_DEMAND_STAFFING_PLACEMENT';
