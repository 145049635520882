import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/executionTags/actionTypes';
import { ExecutionTag, ExecutionTagFormOptions } from 'domains/executionTags/models';

export interface State {
	executionTagList?: ExecutionTag[],
	formOptions?: ExecutionTagFormOptions;
}

export const initialState: State = {
	executionTagList: [],
	formOptions: undefined
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_EXECUTION_TAGS_SUCCESS:
			return {
				...state,
				executionTagList: action.result
			};

		case actionTypes.LOAD_EXECUTION_TAG_FORM_OPTIONS_SUCCESS:
			return {
				...state,
				formOptions: action.result
			};

		default:
			return state;
	}
}
