import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const composer = process.env.REACT_APP_DEVTOOLS === 'true'
	? composeWithDevTools(applyMiddleware(...[sagaMiddleware]))
	: applyMiddleware(...[sagaMiddleware]);

const createStoreWithMiddleware = composer(createStore);

export default function configureStore(initialState = {}) {
	const store: any = createStoreWithMiddleware(rootReducer, initialState);
	store.runSaga = sagaMiddleware.run;
	if ((module as any).hot) {
		(module as any).hot.accept('./rootReducer', () => {
			const nextReducer = require('./rootReducer').default;
			store.replaceReducer(nextReducer);
		});
	}
	return store;
}
