import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
	},
	tablePadding: {
		paddingLeft: '10px',
	},
	headerStyle: {
		paddingLeft: '10px',
		cursor: 'pointer'
	},
	extraLeftPadding: {
		paddingLeft: '30px !important'
	},
	nonSortColumn: {
		cursor: 'default',
	},
	sortDesc: {
		'&:after': {
			position: 'relative' as any,
			left: 5,
			content: '"⯆"'
		}
	},
	sortAsc: {
		'&:after': {
			position: 'relative' as any,
			left: 5,
			content: '"⯅"'
		}
	}
});

export interface ColumnDefinition {
	getOnCellClick?: (rowItem: any) => any;
	getColumnText: (rowItem: any) => string;
	headerText: string;
	filter?: (filterValue: string, value: string) => boolean;
	sortBy?: (first: string, second: string) => number; 
}

interface Props {
	columnDefinitions: ColumnDefinition[];
	data: any[];
	filterValue?: string;
	defaultSortColumn?: number;
	defaultSortOrderDesc?: boolean;
	globalFilter?: (filterValue: string, rowItem: any) => boolean;
}

const useTable = ({ columnDefinitions, data, filterValue, defaultSortColumn, defaultSortOrderDesc, globalFilter }: Props): any  => {
	const classes = useStyles();
	const [sortColumnId, setSortColumnId] = useState(defaultSortColumn || (columnDefinitions.find(x => x.sortBy) ? columnDefinitions.indexOf(columnDefinitions.find(x => x.sortBy)!) : undefined));
	const [sortOrderDesc, setSortOrderDesc] = useState(defaultSortOrderDesc || false);

	const decorateSortHeader = (columnId: number) => {
		if (columnId !== sortColumnId) {
			return undefined;
		}
		return sortOrderDesc ? classes.sortDesc : classes.sortAsc;
	}

	const sort = (columnId: number) => {
		if (columnId === sortColumnId) {
			setSortOrderDesc(!sortOrderDesc);
		} else {
			if(columnDefinitions[columnId] && columnDefinitions[columnId].sortBy){
				setSortOrderDesc(false);
				setSortColumnId(columnId);
			}
		}
	}

	const tableHead = columnDefinitions.map((x, i) => {
		return <h6 key={i + 1} onClick={() => sort(i)} className={`${classes.headerStyle} ${x.sortBy ? decorateSortHeader(i): classes.nonSortColumn} ${i + 1 === 1 ? classes.extraLeftPadding : ''}`}>{x.headerText}</h6>;
	});

	// return empty table
	if (!(data && data.length > 0)) {
		const midpoint = Math.floor(columnDefinitions.length / 2);
		const emptyTableData = [columnDefinitions.map((_, i) => {
			if (i + 1 === midpoint) {
				return [<span key={i + 1}><p className={classes.tablePadding}>No Records Found</p></span>];
			}
			return [<span key={i + 1} />];
		})];

		return { tableHead, tableData: emptyTableData };
	}

	// filter data 
	let filteredData = data;
	if (filterValue)
	{
		filteredData = filteredData.filter(rowItem => {
			// global filter
			let matched = globalFilter && globalFilter(filterValue, rowItem);
			
			// column filter
			matched = matched || !!columnDefinitions.find(x => {
				return x.filter && x.getColumnText(rowItem) ? x.filter(filterValue, x.getColumnText(rowItem)) : false;
			});

			return matched;
		});
	}


	// sort data 
	const sortedData =  sortColumnId && sortColumnId < 0 ? filteredData : filteredData.sort((a,b) => {
		const column = columnDefinitions[sortColumnId!];
		if(!column?.sortBy) return 1;

		const result = column?.sortBy(column.getColumnText(a),column.getColumnText(b));
		return sortOrderDesc ? -result : result;
	});

	// return mapped table data
	const tableData = sortedData.map(rowItem => {
		const columns = columnDefinitions.map((x, i) => {
			return [<span key={i + 1} className={`${classes.rowButton} ${classes.tablePadding} ${i + 1 === 1 ? classes.extraLeftPadding : ''}`} onClick={x.getOnCellClick ? x.getOnCellClick(rowItem) : undefined}>{x.getColumnText(rowItem)}</span>];
		});
		return columns;
	});

	return { tableHead, tableData };
};

export default useTable;
