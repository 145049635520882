import React from 'react';
import { compose } from 'recompose';
import { default as MuiCheckbox, CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const styles = {
	root: {
		color: '#666666',
		'&$checked': {
			color: primaryColor,
		},
	},
	checked: {},
};

const Checkbox = (props: CheckboxProps) => (<MuiCheckbox color="default" {...props} />);

export default compose<CheckboxProps, CheckboxProps>(
	withStyles(styles)
)(Checkbox);
