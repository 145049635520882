import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormRenderProps } from 'react-final-form';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader } from 'components/Card';
import { CustomTabs } from 'components/CustomTabs';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { Discounts, DiscountSearchForm, DiscountFilterForm } from 'domains/discounts/components';
import { DiscountFilterValues } from 'domains/core/models';
import { DiscountSearchRequest, DiscountSortColumn } from 'domains/discounts/models';
import { doLoadDiscounts, doExportDiscountList } from 'domains/discounts/actions';
import { doPaginationReset } from 'domains/core/actions';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import SaveAltIcon from 'domains/core/components/SaveAltIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import { useStyles } from 'styles';
import { useOnMount } from 'utils/React';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	tabCardMargin: {
		marginTop: 0
	},
	customPagination: {
		float: 'right' as const,
	}
};

export default function DiscountList() {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const discountCount = useSelector(state => state.domains.discounts.totalDiscountRecords);
	const currentPage = useSelector(state => state.domains.core.pagination.currentPage);
	const recordsPerPage = useSelector(state => state.domains.core.pagination.recordsPerPage);
	const discountSearchRequestValues = useSelector(state => state.domains.discounts.discountSearchRequestValues);

	const [active, setActive] = useState(discountSearchRequestValues?.active ?? true);
	const [sortOrderDesc, setSortOrderDesc] = useState(discountSearchRequestValues?.sortOrderDesc ?? false);
	const [sortColumn, setSortColumn] = useState(discountSearchRequestValues?.sortColumn ?? DiscountSortColumn.InternalName);

	const initialValues = useMemo(() => {
		return discountSearchRequestValues ?? {
			program: 0,
			discountType: 0,
			market: 0,
		};
		// do not reset initialValues if discountSearchRequestValues changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadDiscounts = useCallback((filterValues: DiscountFilterValues) => {
		const request: DiscountSearchRequest = {
			discountName: filterValues?.discountName,
			discountType: filterValues?.discountType,
			market: filterValues?.market,
			program: filterValues?.program,
			active: !!active,
			pageNumber: currentPage,
			pageSize: recordsPerPage,
			sortOrderDesc,
			sortColumn,
		};
		dispatch(doPaginationReset());
		dispatch(doLoadDiscounts(request));
	}, [active, currentPage, dispatch, recordsPerPage, sortColumn, sortOrderDesc]);

	useOnMount(() => {
		loadDiscounts(discountSearchRequestValues ?? {
			program: 0,
			discountType: 0,
			market: 0,
		});
	});

	useEffect(() => () => {
		dispatch(doPaginationReset());
	}, [dispatch]);

	const changeSortOrder = useCallback((sortDirection: string) => {
		dispatch(doPaginationReset());
		setSortOrderDesc(sortDirection === 'desc');
	}, [dispatch]);

	const changeSortColumn = useCallback((column: DiscountSortColumn) => {
		dispatch(doPaginationReset());
		setSortColumn(column);
	}, [dispatch]);

	const toggleActive = useCallback((value: boolean) => {
		dispatch(doPaginationReset());
		setActive(value);
	}, [dispatch]);

	const routeToDiscountCreation = useCallback(() => {
		history.push('/pricing/discounts/create');
	}, [history]);

	const exportList = useCallback(() => {
		const request = {
			discountName: discountSearchRequestValues.discountName,
			discountType: discountSearchRequestValues.discountType,
			market: discountSearchRequestValues.market,
			program: discountSearchRequestValues.program,
			active: !!discountSearchRequestValues.active,
			pageNumber: 1,
			pageSize: 9999,
			sortOrderDesc,
			sortColumn
		};
		dispatch(doExportDiscountList(request));
	}, [discountSearchRequestValues, dispatch, sortColumn, sortOrderDesc]);

	const getContextMenuItems = useMemo(() => {
		const canExport = true;

		const options: JSX.Element[] = [];

		if (canExport) {
			options.push(
				<ContextMenuItem
					icon={<SaveAltIcon />}
					action={exportList}
					text="Export"
				/>
			);
		}

		return options;
	}, [exportList]);

	return (
		<Card>
			<CardHeader>
				<Form onSubmit={loadDiscounts} initialValues={initialValues}>
					{(formRenderProps: FormRenderProps<DiscountFilterValues>) => {
						return (
							<React.Fragment>
								<h2 className={classes.cardIconTitle}>Discounts</h2>
								<GridContainer spacing={0}>
									<GridItem>
										<Button color="primary" onClick={routeToDiscountCreation}>Add Discount</Button>
									</GridItem>
									<GridItem xs={3} sm={3} md={2} lg={2} xl={2}>
										<ContextMenu menuItems={getContextMenuItems} />
									</GridItem>
									<GridItem xs={12} sm={3} md={3} lg xl>
										<DiscountSearchForm {...formRenderProps} />
									</GridItem>
								</GridContainer>
								<GridContainer spacing={0}>
									<GridItem xs={12} sm={8} md={8} lg={8} xl={8}>
										<DiscountFilterForm {...formRenderProps} />
									</GridItem>
									<GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
										<div className={classes.customPagination}>
											<PaginationWrapper recordCount={discountCount!} />
										</div>
									</GridItem>
								</GridContainer>
							</React.Fragment>
						);
					}}
				</Form>
			</CardHeader>
			<CustomTabs
				plainTabs
				headerColor="info"
				tabs={
					[
						{
							tabName: 'ACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<Discounts
											active
											setActive={toggleActive}
											changeSortOrder={changeSortOrder}
											changeSortColumn={changeSortColumn}
											sortOrderDesc={sortOrderDesc}
											sortColumn={sortColumn}
										/>
									</GridItem>
								</GridContainer>
							)
						},
						{
							tabName: 'INACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<Discounts
											active={false}
											setActive={toggleActive}
											changeSortOrder={changeSortOrder}
											changeSortColumn={changeSortColumn}
											sortOrderDesc={sortOrderDesc}
											sortColumn={sortColumn}
										/>
									</GridItem>
								</GridContainer>
							)
						}
					]
				}
			/>
			<GridItem xs={12}>
				<div className={classes.customPagination}>
					<PaginationWrapper recordCount={discountCount!} />
				</div>
			</GridItem>
		</Card>
	);
}

