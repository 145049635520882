import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { useStyles } from 'styles';

import IconButton from '@material-ui/core/IconButton';
import { GridItem, GridContainer } from 'components/Grid';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import CancelIcon from 'domains/core/components/CancelIcon';

import { ItemRetailType, ItemRetailCategory, ItemRetail } from 'domains/core/models';
import { DiscountGroupConditionFormValues, DiscountGroupType } from 'domains/discounts/models'
import { getItemRetails } from 'domains/discounts/selectors';
import * as validators from 'utils/fieldValidators';

const style = {
	discountSelectItemContainer: {
		marginTop: '12px'
	},
	removeConditionContainer: {
		marginTop: '30px',
		marginLeft: '-20px'
	},
	addConditionContainer: {
		marginLeft: '-10px'
	},
	orGroupDivider: {
		marginTop: '5px',
		marginBottom: '-5px',
		fontWeight: 700,
	},
	discountGroupConditionContainer: {
		marginLeft: '-15px'
	}
};

interface Props {
	discountGroupCondition: DiscountGroupConditionFormValues;
	groupConditionName: string;
	itemRetailTypes: ItemRetailType[];
	itemRetailCategories: ItemRetailCategory[];
	itemRetails: ItemRetail[];
	index: number;
	selectedGroupTypeId?: number;
	totalDiscountGroupConditions: number;
	handleRemoveCondition: () => void;
}


export default function DiscountGroupConditionForm(props: Props) {
	const classes = useStyles(style);
	const { groupConditionName, selectedGroupTypeId, index, totalDiscountGroupConditions, handleRemoveCondition, itemRetailTypes, itemRetailCategories } = props;

	const itemRetailList = useSelector(state => getItemRetails(state));

	const getItemRetailMenuItems = useMemo(() => {
		return itemRetailList.map(x => ({ value: x.metadata.upc, text: x.metadata.name }));
	}, [itemRetailList]);

	const getItemRetailTypeMenuItems = useMemo(() => {
		return itemRetailTypes.map(x => ({ value: x.id, text: x.displayName }));
	}, [itemRetailTypes]);

	const getItemRetailCategoryMenuItems = useMemo(() => {
		return itemRetailCategories.map(x => ({ value: x.id, text: x.displayName }));
	}, [itemRetailCategories]);

	const showItemRetailsSelect = useMemo(() => itemRetailList?.length && selectedGroupTypeId === DiscountGroupType.itemRetail, [itemRetailList?.length, selectedGroupTypeId]);
	const showItemRetailTypeSelect = useMemo(() => itemRetailTypes?.length && selectedGroupTypeId === DiscountGroupType.itemRetailType || selectedGroupTypeId === undefined, [itemRetailTypes?.length, selectedGroupTypeId]);
	const showItemRetailCategorySelect = useMemo(() => itemRetailCategories?.length && selectedGroupTypeId === DiscountGroupType.itemRetailCategory, [itemRetailCategories?.length, selectedGroupTypeId]);
	const showOrDivider = useMemo(() => index !== (totalDiscountGroupConditions - 1), [index, totalDiscountGroupConditions]);

	return (
		<div className={classes.discountGroupConditionContainer}>
			<GridContainer>
				<GridItem xs={11} md={11} lg={11}>
					{showItemRetailsSelect &&
						<Field
							name={`${groupConditionName}.upc`}
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Required Item"
									required
									options={getItemRetailMenuItems}
								/>
							)}
						</Field>
					}
				</GridItem>
				<GridItem xs={1} md={1} lg={1}>
					{showItemRetailsSelect &&
						<div className={classes.removeConditionContainer}>
							<IconButton onClick={handleRemoveCondition}>
								<CancelIcon />
							</IconButton>
						</div>
					}
				</GridItem>
				<GridItem xs={11} md={11} lg={11}>
					{showItemRetailTypeSelect &&
						<Field
							name={`${groupConditionName}.itemRetailTypeId`}
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Required Item"
									required
									options={getItemRetailTypeMenuItems}
								/>
							)}
						</Field>
					}
				</GridItem>
				<GridItem xs={1} md={1} lg={1}>
					{showItemRetailTypeSelect &&
						<div className={classes.removeConditionContainer}>
							<IconButton onClick={handleRemoveCondition}>
								<CancelIcon />
							</IconButton>
						</div>
					}
				</GridItem>
				<GridItem xs={11} md={11} lg={11}>
					{showItemRetailCategorySelect &&
						<Field
							name={`${groupConditionName}.itemRetailCategoryId`}
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Required Item"
									required
									options={getItemRetailCategoryMenuItems}
								/>
							)}
						</Field>
					}
				</GridItem>
				<GridItem xs={1} md={1} lg={1}>
					{showItemRetailCategorySelect &&
						<div className={classes.removeConditionContainer}>
							<IconButton onClick={handleRemoveCondition}>
								<CancelIcon />
							</IconButton>
						</div>
					}
				</GridItem>
				{showOrDivider &&
					<GridItem xs={12} md={12} lg={12}>
						<div className={classes.orGroupDivider}>
							<span>OR</span>
						</div>
					</GridItem>
				}
			</GridContainer>
		</div>
	);
}
