import React from 'react';
import intersection from 'lodash/intersection';
import startCase from 'lodash/startCase';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import Muted from 'components/Typography/Muted';

import { getCompanyFromCompanyUser, getUserFromRoute, getCompanyUserDetail, getMetrixWebPrograms, getCeaRolesForCompany } from 'domains/users/selectors';
import { User, MetrixUser, CeaUser, Role, SystemAccessType } from 'domains/core/models';
import { getCurrentUser } from 'domains/core/selectors';
import { UserActions, Roles } from 'domains/core/enums';
import { SourceSystem } from 'domains/core/models';
import { useStyles } from 'styles';
import { useSelector } from 'react-redux';

interface MatchParams {
	companyId: string;
	userPrincipalName: string;
}

const style = {
	customCardContentClass: {
		paddingLeft: 0,
		paddingRight: 0
	},
	cardTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	},
	customCard: {
		marginBottom: '5px'
	},
	buttonPadding: {
		padding: '15px'
	},
	customLinkClass: {
		paddingLeft: '0px',
		paddingRight: '0px'
	},
	markets: {
		fontSize: 12,
		margin: '0 20px'
	},
	noMargin: {
		margin: 0
	}
};

export default function CompanyUserAccess() {
	const history = useHistory();
	const classes = useStyles(style);

	const params = useParams<MatchParams>();

	const companyUser = useSelector(state => getUserFromRoute(state, { match: { params } }));
	const companyUserDetail = useSelector(state => getCompanyUserDetail(state));
	const company = useSelector(state => getCompanyFromCompanyUser(state));
	const currentUser = useSelector(state => getCurrentUser(state));
	const programs = useSelector(state => getMetrixWebPrograms(state));
	const isLoadingUserDependencies = useSelector(state => state.domains.users.isLoadingUserDependencies);
	const ceaRoles = useSelector(state => getCeaRolesForCompany(state, { match: { params } }));


	const navigateToEditCompanyUser = () => {
		history.push(`${history.location.pathname}/edit/access`);
	}

	const checkUserIsCompanyAdmin = () => {
		if (companyUser && companyUser.ceaUser && currentUser) {
			return currentUser.hasRole(Roles.CompanyAdmin) && (companyUser.ceaUser.roleNames.indexOf(Roles.CompanyAdmin) > -1 || companyUser.ceaUser.roleNames.indexOf(Roles.GlobalAdmin) > -1);
		}
		return true;
	}

	const getCeaAdminSystemRoles = (ceaUser?: CeaUser) => {
		if (ceaUser) {
			return intersection(ceaUser.roleNames, ceaRoles.filter(r => r.sourceSystemId === SourceSystem.CeaAdmin).map(r => r.name)).map(r => startCase(r)).join(', ') || 'None Assigned';
		}
		return 'None Assigned';
	}

	const getCeaSystemRoles = (ceaUser?: CeaUser) => {
		if (ceaUser) {
			return intersection(ceaUser.roleNames, ceaRoles.filter(r => r.sourceSystemId === SourceSystem.Cea && !r.isAddOn).map(r => r.name)).map(r => startCase(r)).join(', ') || 'None Assigned';
		}
		return 'None Assigned';
	}

	const getCeadSystemRoles = (ceaUser?: CeaUser) => {
		if (ceaUser) {
			return intersection(ceaUser.roleNames, ceaRoles.filter(r => r.sourceSystemId === SourceSystem.Cead).map(r => r.name)).map(r => startCase(r)).join(', ') || 'None Assigned';
		}
		return 'None Assigned';
	}

	const getCeaAddOnRoles = (ceaUser?: CeaUser) => {
		if (ceaUser) {
			return intersection(ceaUser.roleNames, ceaRoles.filter(r => r.sourceSystemId === SourceSystem.Cea && r.isAddOn).map(r => r.name)).map(r => startCase(r)).join(', ') || null;
		}
		return null;
	}

	const getMetrixManagerName = (metrixUser?: MetrixUser) => {
		if (metrixUser && metrixUser.manager) {
			return `${metrixUser.manager.firstName} ${metrixUser.manager.lastName}`
		}
		return 'None Assigned';
	}

	const getMetrixRole = (metrixUser?: MetrixUser): string => {
		if (metrixUser && metrixUser.role) {
			return (metrixUser.role as Role).name;
		}
		return 'None Assigned';
	}

	const getProgramMarkets = (metrixUser?: MetrixUser) => {
		if (metrixUser && metrixUser.employeePrograms && !isLoadingUserDependencies) {
			return (
				metrixUser.employeePrograms.map(x => {
					const program = programs?.find(y => y.id === x.programId);
					if (program && x.isActive) {
						// Filter out markets that are not assigned to the user
						const markets = program.marketIds.map(y => metrixUser.markets?.includes(y?.marketId) ? y.shortName : null)
							.filter(m => m)
							.join(', ');
						return (
							<React.Fragment key={x.programId as number}>
								<GridItem xs={12}>
									<p className={classes.noMargin}>{program!.name}</p>
								</GridItem>
								<GridItem xs={12}>
									<p className={classes.markets}>{markets || 'No Markets Assigned'}</p>
								</GridItem>
							</React.Fragment>
						);
					}
				})
			);
		}
		return (
			<GridItem xs={12}>
				<p className={classes.noMargin}>None Assigned</p>
			</GridItem>
		);
	}

	const getActiveAccounts = (companyUser?: User) => {
		var accounts = "";

		if (companyUser && companyUser.ceaUser) {
			accounts = companyUser.ceaUser.active ? 'CEA (Active)' : 'CEA (Inactive)';
		}
		if (companyUser && companyUser.metrixUser) {
			if (companyUser.metrixUser.isActive) {
				accounts = accounts.length > 0 ? `${accounts}, MetrixWeb (Active)` : 'MetrixWeb (Active)';
			} else {
				accounts = accounts.length > 0 ? `${accounts}, MetrixWeb (Inactive)` : 'MetrixWeb (Inactive)';
			}
		}
		if (accounts.length === 0) {
			return 'None';
		}

		return accounts;
	}

	if (!companyUser || !currentUser || !company || !companyUserDetail || !companyUser.azureAdUser || isLoadingUserDependencies) {
		return null;
	}

	const canAssignMetrixWebRoles = !!(company && company.externalSystems && company.externalSystems.some(x => x.name === 'MetrixWeb'));
	const addOnRoles = getCeaAddOnRoles(companyUser.ceaUser);

	return (
		<Card className={classes.customCard}>
			<CardHeader>
				<h2 className={classes.cardTitle}>Access</h2>
			</CardHeader>
			{currentUser.canDoAction(UserActions.CanCreateEditUsers) && !checkUserIsCompanyAdmin() &&
				<GridContainer className={classes.buttonPadding}>
					<GridItem>
						<Button fullWidth color="primary" onClick={navigateToEditCompanyUser}>Edit Access</Button>
					</GridItem>
				</GridContainer>
			}
			<CardBody>
				<GridContainer>
					<GridItem xs={12}>
						<Muted>
							<small>Accounts in this Environment</small>
						</Muted>
					</GridItem>
					<GridItem xs={12}>
						<p className={classes.noTopMargin}>{getActiveAccounts(companyUser)}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Admin Roles</small>
						</Muted>
					</GridItem>
					<GridItem xs={12}>
						<p className={classes.noTopMargin}>{getCeaAdminSystemRoles(companyUser.ceaUser)}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>CEA Roles</small>
						</Muted>
					</GridItem>
					<GridItem xs={12}>
						<p className={classes.noTopMargin}>{getCeaSystemRoles(companyUser.ceaUser)}</p>
					</GridItem>
					{addOnRoles &&
						<>
							<GridItem xs={12}>
								<Muted>
									<small>CEA Add-On Roles</small>
								</Muted>
							</GridItem>
							<GridItem xs={12}>
								<p className={classes.noTopMargin}>{addOnRoles}</p>
							</GridItem>
						</>
					}
					<GridItem xs={12}>
						<Muted>
							<small>CEA-D Roles</small>
						</Muted>
					</GridItem>
					<GridItem xs={12}>
						<p className={classes.noTopMargin}>{getCeadSystemRoles(companyUser.ceaUser)}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>CEA System Access</small>
						</Muted>
					</GridItem>
					<GridItem xs={12}>
						<p className={classes.noTopMargin}>{companyUser.ceaUser ? SystemAccessType[companyUser.ceaUser.systemAccessTypeId] : 'None'}</p>
					</GridItem>
					{canAssignMetrixWebRoles &&
						<>
							<GridItem xs={12}>
								<Muted>
									<small>MetrixWeb Roles</small>
								</Muted>
							</GridItem>
							<GridItem xs={12}>
								<p className={classes.noTopMargin}>{getMetrixRole(companyUser.metrixUser)}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>MetrixWeb Manager</small>
								</Muted>
							</GridItem>
							<GridItem xs={12}>
								<p className={classes.noTopMargin}>{getMetrixManagerName(companyUser.metrixUser)}</p>
							</GridItem>
						</>
					}
					<GridItem xs={12}>
						<Muted>
							<small>Programs &amp; Markets</small>
						</Muted>
					</GridItem>
					{getProgramMarkets(companyUser.metrixUser)}
				</GridContainer>
			</CardBody>
		</Card>
	);
}
