const customTabsStyle = {
	cardTitle: {
		padding: 0
	},
	cardHeaderTabs: {
		padding: "0 !important",
		margin: "0 !important"
	},
	tabIndicator: {
		backgroundColor: "white"
	},
	tabWrapper: {
		display: "inline-block",
	}
};

export default customTabsStyle;
