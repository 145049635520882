import { Theme } from "@material-ui/core";

const pagesStyle = (theme: Theme) => ({
	wrapper: {
		height: "auto",
		minHeight: "100vh",
		position: "relative" as const,
		top: "0"
	},
	fullPage: {
		padding: "120px 0",
		position: "relative" as const,
		minHeight: "100vh",
		display: "flex!important",
		margin: "0",
		border: "0",
		color: "#fff",
		alignItems: "center",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		height: "100%",
		[theme.breakpoints.down("sm")]: {
			minHeight: "fit-content!important"
		},
		"& footer": {
			position: "absolute" as const,
			bottom: "0",
			width: "100%",
			border: "none !important"
		},
		"&:before": {
			backgroundColor: "rgba(0, 0, 0, 0.65)"
		},
		"&:before,&:after": {
			display: "block",
			content: '""',
			position: "absolute" as const,
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			zIndex: "2"
		}
	}
});

export default pagesStyle;
