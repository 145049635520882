import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/pagesStyle';
import bgImage from 'assets/images/register.jpeg';

import { doLogin } from 'domains/core/actions';
import LoginForm from 'domains/core/containers/LoginForm';
import { getUserNameFormatted } from 'domains/core/helpers';
import { useStyles } from 'styles';
import { LoginFormValues } from 'domains/core/models';

export default function Login() {
	const dispatch = useDispatch();
	const classes = useStyles(pagesStyle);

	const initialValues = useMemo(() => {
		return {
			username: '',
			password: ''
		};
	}, []);

	const handleSubmit = useCallback((data: LoginFormValues) => {
		dispatch(doLogin(getUserNameFormatted(data.username), data.password));
	}, [dispatch]);


	return (
		<div className={classes.wrapper}>
			<div
				className={classes.fullPage}
				style={{ backgroundImage: `url(${bgImage})`, padding: 0 }}
			>
				<LoginForm
					initialValues={initialValues}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
}
