import { Action } from 'applicationTypes';
import * as actionTypes from './actionTypes';
import { DeviceTerminalMapping, MerchantTerminal } from './models';

export interface State {
	deviceTerminalMappings: DeviceTerminalMapping[];
	onlineMerchantTerminals: MerchantTerminal[];
	existingDeviceTerminalMapping: DeviceTerminalMapping | undefined;
}

export const initialState: State = {
	deviceTerminalMappings: [],
	onlineMerchantTerminals: [],
	existingDeviceTerminalMapping: undefined
};

export default function reducer(state: State = initialState, action: Action): State {
	switch (action.type) {
		case actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY: {
			return {
				...state,
				deviceTerminalMappings: []
			};
		}

		case actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY_SUCCESS: {
			return {
				...state,
				deviceTerminalMappings: action.result
			};
		}

		case actionTypes.LOAD_DEVICE_TERMINAL_DETAILS: {
			return {
				...state,
				onlineMerchantTerminals: [],
				existingDeviceTerminalMapping: undefined
			};
		}

		case actionTypes.LOAD_DEVICE_TERMINAL_DETAILS_SUCCESS: {
			return {
				...state,
				onlineMerchantTerminals: action.result.onlineMerchantTerminals,
				existingDeviceTerminalMapping: action.result.existingDeviceTerminalMapping
			};
		}

		case actionTypes.SAVE_DEVICE_TERMINAL_DETAILS_SUCCESS: {
			return {
				...state,
				existingDeviceTerminalMapping: undefined
			};
		}

		default:
			return state;
	}
}
