import { call, CallEffect, put } from 'redux-saga/effects';
import ApiClient from 'utils/apiClient';
import { ApiAction } from 'applicationTypes';

import * as coreActionTypes from 'domains/core/actionTypes';
import { doRefreshToken } from 'domains/core/actions';

export function* performRequest(action: ApiAction): object {
	yield put(doRefreshToken() as any);
	const client = new ApiClient();
	let response;
	let isSessionExpired = false;

	yield action.promise!(client).then((result: any) => {
		response = result;
	}, (error: any) => {
		if (error.status === 401) {
			isSessionExpired = true;
		} else {
			throw error;
		}
	});

	if (isSessionExpired) {
		// Update store to reflect expired session
		yield put({ type: coreActionTypes.SESSION_EXPIRED, result: true });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	}
	return response;
}

export function callApi(action: ApiAction): CallEffect<object> {
	return call(performRequest, action);
}

export function performRequestPromise(action: ApiAction): Promise<any> {
	const client = new ApiClient();

	return action.promise!(client).then((result: any) => {
		return result;
	}, (error: any) => {
		throw error;
	});
}
