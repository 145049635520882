import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStyles } from 'styles';
import { useDispatch, useSelector } from 'react-redux';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doSaveUser, doLoadUserAndDependencies } from 'domains/users/actions';
import { CreateEditUserForm, SetUserAccessForm } from 'domains/users/components';
import { getCompanyFromRouteOrUser } from 'domains/companies/selectors';
import { CeaUser, MetrixUser, EmployeeProgram, MetrixAddressType } from 'domains/core/models';
import { getMetrixWebPrograms } from 'domains/users/selectors';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { CreateEditUserFormValues } from 'domains/users/models';

interface MatchProps {
	userPrincipalName?: string;
	companyId: string;
}

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function CreateUser() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const { userPrincipalName, companyId } = useParams<MatchProps>();

	const company = useSelector(state => getCompanyFromRouteOrUser(state, { match: { params: { companyId } } }));
	const programs = useSelector(state => getMetrixWebPrograms(state));


	useEffect(() => {
		dispatch(doLoadUserAndDependencies(Number(companyId), userPrincipalName));
	}, [companyId, dispatch, userPrincipalName]);

	const userDetailsInitialValues = useMemo(() => {
		const user = {
			id: '',
			companyId: Number(companyId),
			metrixUser: {
				firstName: null,
				lastName: null,
				email: null,
				username: null,
				mobilePhone: null,
				isActive: true,
				externalUserId: '',
				employeePrograms: [] as EmployeeProgram[],
				homeAddress: {
					addressLine1: company ? company.addressLine1 : undefined,
					addressLine2: company ? company.addressLine2 : undefined,
					city: company ? company.city : undefined,
					state: company ? company.state : undefined,
					postalCode: company ? company.zipCode : undefined,
					type: company ? MetrixAddressType.Home : undefined,
				}
			}
		};
		return user;
	}, [company, companyId]);

	const handleSaveUser = useCallback((data: CreateEditUserFormValues) => {
		const newUser = data;
		newUser.companyId = Number(companyId);
		newUser.ceaUser = newUser.ceaUser || {} as CeaUser;
		newUser.ceaUser.companyId = Number(companyId);
		newUser.ceaUser.firstName = newUser.azureAdUser!.givenName;
		newUser.ceaUser.lastName = newUser.azureAdUser!.surname;
		newUser.ceaUser.email = newUser.azureAdUser!.otherMails && newUser.azureAdUser!.otherMails[0];
		newUser.ceaUser.mobilePhone = newUser.azureAdUser!.mobilePhone;
		newUser.ceaUser.roleNames = (data.ceaRoleNames ?? [])
			.concat(data.adminRoleNames ?? [])
			.concat(data.ceaAddonRoleNames ?? [])
			.concat(data.ceaDirectRoleNames ?? []);

		newUser.metrixUser = newUser.metrixUser || {} as MetrixUser;

		newUser.metrixUser.isActive = true;
		newUser.metrixUser.firstName = newUser.azureAdUser!.givenName;
		newUser.metrixUser.lastName = newUser.azureAdUser!.surname;
		newUser.metrixUser.email = newUser.azureAdUser!.otherMails && newUser.azureAdUser!.otherMails[0];
		newUser.metrixUser.mobilePhone = newUser.azureAdUser!.mobilePhone;
		newUser.metrixUser.employeePrograms = data.employeePrograms ?? [];
		newUser.metrixUser.markets = data.markets;
		newUser.metrixUser.manager = data.metrixManager;
		newUser.metrixUser.role = data.metrixRole;

		dispatch(doSaveUser(newUser));

	}, [companyId, dispatch]);

	return (
		<Form initialValues={userDetailsInitialValues} onSubmit={handleSaveUser} mutators={{ ...arrayMutators }}>
			{(formRenderProps) => (
				<form onSubmit={formRenderProps.handleSubmit}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6}>
							<Card>
								<CardHeader>
									<h2 className={classes.cardIconTitle}>Create User</h2>
								</CardHeader>
								<CardBody className={classes.cardBody}>
									<CreateEditUserForm {...formRenderProps} company={company} />
								</CardBody>

							</Card>
						</GridItem>
						<GridItem xs={12} sm={12} md={6}>
							<Card>
								<CardHeader>
									<h2 className={classes.cardIconTitle}>Set Access</h2>
								</CardHeader>
								<CardBody className={classes.cardBody}>
									<SetUserAccessForm {...formRenderProps} programs={programs} />
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</form>
			)}
		</Form>
	);
}
