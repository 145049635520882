import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { Audits } from 'domains/audits/components';
import { doPaginationReset } from 'domains/core/actions';
import { doExportUserAudits } from 'domains/audits/actions';
import SearchBox from 'domains/core/components/SearchBox';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import { LogSystem } from 'domains/audits/models';
import { useStyles } from 'styles';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	customPagination: {
		float: 'right' as const,
	}
};

export default function AuditHistory() {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const { totalAuditRecords, userAuditFilters } = useSelector(state => state.domains.audits);

	const [filter, setFilter] = useState('');
	const [debouncedFilter, setDebouncedFilter] = useState(filter);

	useEffect(() => {
		const delayFilterTimeout = setTimeout(() => {
			setDebouncedFilter(filter);
		}, 300);
		return () => clearTimeout(delayFilterTimeout);
	}, [filter]);

	useEffect(() => () => {
		dispatch(doPaginationReset());
	}, [dispatch]);

	const exportRecords = useCallback(() => {
		if (userAuditFilters?.filterValues || debouncedFilter?.trim()?.length) {
			dispatch(doExportUserAudits({
				pageNumber: 1,
				pageSize: totalAuditRecords,
				logSystem: userAuditFilters?.filterValues?.logSystem ?? LogSystem.Cea,
				username: debouncedFilter,
				dateRange: moment.utc().add(-1, userAuditFilters?.filterValues?.dateRange).toDate(),
				company: userAuditFilters?.filterValues?.company,
				sourceSystem: userAuditFilters?.filterValues?.sourceSystem,
				userAuditType: userAuditFilters?.filterValues?.userAuditType,
			}));
		}
	}, [dispatch, debouncedFilter, userAuditFilters?.filterValues, totalAuditRecords]);

	const filterUsers = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const filterValue = event.target.value;
		setFilter(filterValue ?? '');
		dispatch(doPaginationReset());
	}, [dispatch]);

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>History</h2>
				<GridContainer spacing={0}>
					<GridItem xs sm md lg xl>
						<Button color="primary" onClick={exportRecords}>Export</Button>
					</GridItem>
					<GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
						<SearchBox
							placeholder="Search for user"
							initialValues={{ filter: '' }}
							filterHandler={filterUsers}
						/>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<GridContainer>
				<GridItem xs={12}>
					<Audits userFilter={debouncedFilter} />
				</GridItem>
			</GridContainer>
			<GridItem xs={12}>
				<div className={classes.customPagination}>
					<PaginationWrapper recordCount={totalAuditRecords} />
				</div>
			</GridItem>
		</Card>
	);
}
