import React, { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';
import * as formatters from 'utils/fieldFormatters';

import { GridContainer, GridItem } from 'components/Grid';
import FormInput from 'domains/core/components/FormInput';
import CheckboxAdapter from 'domains/core/components/FinalForm/CheckboxAdapter';

import { ItemRetail } from 'domains/core/models';
import { DiscountGroupFormValues, DiscountGroupType } from 'domains/discounts/models';
import { getItemRetailCategories, getItemRetailTypes } from 'domains/discounts/selectors';
import { getDisplayPrice } from 'domains/core/helpers';

interface Props extends FieldArrayRenderProps<DiscountGroupFormValues, any> {
	isFinalPriceDiscount: boolean;
	minimumPricingEligible: boolean;
	isPercentageDiscount: boolean;
	isDollarOffDiscount: boolean;
	editMode: boolean;
	discountTotal: number;
	itemRetails: ItemRetail[];
}

const styles = () => ({
	discountSummaryContainer: {
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#D2D2D2',
		padding: '12px',
		marginTop: '15px',
		marginBottom: '15px'
	},
	discountSummaryDivider: {
		marginLeft: '0px',
		marginRight: '0px',
		borderStyle: 'solid',
		borderTopWidth: '1px',
		borderColor: '#D2D2D2',
	},
	discountSummaryHeader: {
		fontSize: "14px",
		fontWeight: 700,
	},
	discountSummaryFooter: {
		fontSize: "14px",
		fontWeight: 700,
	},
	noBorderTop: {
		borderTop: 'none',
		marginTop: '-15px'
	},
	noTopBottomPadding: {
		paddingTop: '0px !important',
		marginBottom: '0px !important',
	},
	overrideMinPricing: {
		minWidth: "160px",
	},
});

export default function DiscountAmountGroups(props: Props) {
	const { fields, isFinalPriceDiscount, minimumPricingEligible, isPercentageDiscount, isDollarOffDiscount, editMode, discountTotal, itemRetails } = props;
	const classes = useStyles(styles);

	const itemRetailCategories = useSelector(state => getItemRetailCategories(state));
	const itemRetailTypes = useSelector(state => getItemRetailTypes(state));

	const getItemRetailTypeDescription = useCallback((value: number) => {
		const itemRetailType = itemRetailTypes.find(x => x.id === value);
		if (itemRetailType?.displayName) {
			return itemRetailType?.displayName;
		}
		return "";
	}, [itemRetailTypes]);

	const getItemRetailPriceByItemRetailType = useCallback((value: number) => {
		const itemRetail = itemRetails.find(x => x.metadata.itemRetailTypeId === value);
		if (itemRetail) {
			return itemRetail.price;
		}
		return 0;
	}, [itemRetails]);

	const getItemRetailCategoryDescription = useCallback((value: number) => {
		const itemRetailCategory = itemRetailCategories.find(x => x.id === value);
		if (itemRetailCategory?.displayName) {
			return itemRetailCategory?.displayName;
		}
		return "";
	}, [itemRetailCategories]);

	const getItemRetailPriceByItemRetailCategory = useCallback((value: number) => {
		const itemRetail = itemRetails.find(x => x.metadata.itemRetailCategoryId === value);
		if (itemRetail) {
			return itemRetail.price;
		}
		return 0;
	}, [itemRetails]);

	const getItemRetailDescription = useCallback((value: string) => {
		const itemRetail = itemRetails.find(x => x.metadata.upc === value);
		if (itemRetail?.metadata.name) {
			return itemRetail?.metadata.name;
		}
		return "";
	}, [itemRetails]);

	const getItemRetailPriceByUpc = useCallback((value: string) => {
		const itemRetail = itemRetails.find(x => x.metadata.upc === value);
		if (itemRetail) {
			return itemRetail.price;
		}
		return 0;
	}, [itemRetails]);

	const discountColumnHeaderText = useMemo(() => isFinalPriceDiscount ? "Final Price" : "Discount Amount", [isFinalPriceDiscount]);
	const itemColumnWidth = useMemo(() => minimumPricingEligible ? 5 : 7, [minimumPricingEligible]);

	return (
		<>
			<div className={classes.discountSummaryContainer}>
				<GridContainer>
					<GridItem xs={itemColumnWidth} md={itemColumnWidth} lg={itemColumnWidth}>
						<div className={classes.discountSummaryHeader}>Item</div>
					</GridItem>
					<GridItem xs={5} md={5} lg={5}>
						<div className={classes.discountSummaryHeader}>{discountColumnHeaderText}</div>
					</GridItem>
					{minimumPricingEligible &&
						<GridItem xs={2} md={2} lg={2}>
							<div className={`${classes.discountSummaryHeader} ${classes.overrideMinPricing}`}>Override<br />Min<br />Pricing</div>
						</GridItem>
					}
				</GridContainer>
			</div>
			{fields.map((discountGroup: string, index: number) => {
				const currentGroup = fields.value[index];

				const itemColumnWidth = minimumPricingEligible ? 5 : 7;
				let groupDescription = "";
				let groupSuffix = isFinalPriceDiscount ? "of " : "off of ";
				let groupPrice = 0;
				let groupItemHasMinimumPricing = false;
				currentGroup.conditions?.forEach((condition, index) => {
					if (condition.itemRetailTypeId && currentGroup.discountGroupTypeId === DiscountGroupType.itemRetailType) {
						groupDescription += getItemRetailTypeDescription(condition.itemRetailTypeId);
						groupPrice = getItemRetailPriceByItemRetailType(condition.itemRetailTypeId);
						const retailType = itemRetailTypes.find(x => x.id === condition.itemRetailTypeId);
						if (retailType && retailType.canOverrideMinimumPrice) {
							groupItemHasMinimumPricing = true;
						}
					}
					if (condition.itemRetailCategoryId && currentGroup.discountGroupTypeId === DiscountGroupType.itemRetailCategory) {
						groupDescription += getItemRetailCategoryDescription(condition.itemRetailCategoryId);
						groupPrice = getItemRetailPriceByItemRetailCategory(condition.itemRetailCategoryId);
					}
					if (condition.upc && currentGroup.discountGroupTypeId === DiscountGroupType.itemRetail) {
						groupDescription += getItemRetailDescription(condition.upc);
						groupPrice = getItemRetailPriceByUpc(condition.upc);
					}
					if (index < currentGroup.conditions!.length - 1) {
						groupDescription += " or ";
					}
				});

				groupSuffix += getDisplayPrice(groupPrice);

				if (groupDescription.includes("HeatStick")) {
					groupSuffix = "variable";
				}

				return (
					<div key={index} className={`${classes.discountSummaryContainer} ${classes.noBorderTop}`}>
						<GridContainer>
							<GridItem xs={itemColumnWidth} md={itemColumnWidth} lg={itemColumnWidth}>
								{groupDescription}
							</GridItem>
							{isPercentageDiscount &&
								<GridItem xs={2} md={2} lg={2}>
									<Field
										component={FormInput}
										name={`${discountGroup}.discountPercentage`}
										formControlProps={{
											className: classes.noTopBottomPadding,
											disabled: editMode
										}}
										parse={normalizers.limitThreeDigits}
										format={formatters.percentage}
										validate={validators.composeValidators(validators.isGreaterThanZero, validators.isLessThanOrEqualTo(100))}
										required
									>
									</Field>
								</GridItem>
							}
							{isDollarOffDiscount &&
								<GridItem xs={2} md={2} lg={2}>
									<Field
										component={FormInput}
										name={`${discountGroup}.discountAmount`}
										formControlProps={{
											className: classes.noTopBottomPadding,
											disabled: editMode
										}}
										validate={validators.composeValidators(validators.isGreaterThanOrEqualToZero, validators.isLessThanOrEqualTo(groupPrice))}
										parse={normalizers.currency}
										format={formatters.currencyZero}
										required
									>
									</Field>
								</GridItem>
							}
							{isFinalPriceDiscount &&
								<GridItem xs={2} md={2} lg={2}>
									<Field
										component={FormInput}
										name={`${discountGroup}.finalPrice`}
										formControlProps={{
											className: classes.noTopBottomPadding,
											disabled: editMode
										}}
										validate={validators.isGreaterThanOrEqualToZero}
										parse={normalizers.currency}
										format={formatters.currencyZero}
										required
									>
									</Field>
								</GridItem>
							}
							<GridItem xs={3} md={3} lg={3}>
								{groupSuffix}
							</GridItem>
							{groupItemHasMinimumPricing &&
								<GridItem xs={2} md={2} lg={2}>
									<Field
										component={CheckboxAdapter}
										id={`${discountGroup}.canOverrideMinimumPrice`}
										name={`${discountGroup}.canOverrideMinimumPrice`}
										checked={currentGroup.canOverrideMinimumPrice}
									/>
								</GridItem>
							}
						</GridContainer>
					</div>
				);
			})}
			{isDollarOffDiscount &&
				<div className={`${classes.discountSummaryContainer} ${classes.noBorderTop}`}>
					<GridContainer>
						<GridItem xs={itemColumnWidth} md={itemColumnWidth} lg={itemColumnWidth}>
							<div className={classes.discountSummaryFooter}>Total</div>
						</GridItem>
						<GridItem xs={5} md={5} lg={5}>
							<div className={classes.discountSummaryFooter}>{getDisplayPrice(discountTotal)}</div>
						</GridItem>
					</GridContainer>
				</div>
			}
		</>
	);
}
