import React from 'react';
// material-ui components
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Button } from 'components/CustomButtons';

import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

interface ComponentProps {
	modalOpen: boolean;
	title?: string;
	content?: string;
	closeButtonText?: string;
	handleClose: () => void;
	actionButtonText: string;
	handleAction: () => void;
}

export type Props = ComponentProps & WithStyles;
export const Modal = (props: Props) => {
	const {
		classes,
		modalOpen,
		title,
		content,
		closeButtonText,
		handleClose,
		actionButtonText,
		handleAction
	} = props;

	return (
		<>
			<Dialog
				classes={{
					root: classes.center,
					paper: classes.modal
				}}
				open={modalOpen}
				keepMounted
				onClose={handleClose}
				aria-labelledby="modal-slide-title"
				aria-describedby="modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					className={classes.modalHeader}
					disableTypography
				>
					<Button
						className={classes.modalCloseButton}
						justIcon
						key="close"
						aria-label="Close"
						color="transparent"
						onClick={handleClose}
					>
						<Close className={classes.modalClose} />
					</Button>
					{title && <h2 className={classes.modalTitle}>{title}</h2>}
				</DialogTitle>
				<DialogContent
					id="modal-slide-description"
					className={classes.modalBody}
				>
					{content && <p>{content}</p>}
				</DialogContent>
				<DialogActions
					className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
				>
					{closeButtonText && <Button onClick={handleClose} simple color="primary">{closeButtonText}</Button>}
					<Button onClick={handleAction} color="primary">{actionButtonText}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withStyles(modalStyle)(Modal);
