import React, { ReactElement } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import history from 'adminHistory';
import Dashboard from 'domains/core/containers/Dashboard';
import NotFound from 'domains/core/containers/NotFound';
import AppLoading from 'domains/core/containers/AppLoading';
import { markLoggedIn } from 'domains/core/actions';
import { ApplicationState } from 'applicationTypes';
import { getCeaJwt } from 'utils/apiHelpers';
import Toaster from 'domains/core/components/Toaster';
import Login from 'domains/core/containers/Login';

interface StateProps {
	isAuthenticated: boolean;
	isAuthorized: boolean;
	authorizing: boolean;
	authenticating: boolean;
}

interface DispatchProps {
	markLoggedIn: typeof markLoggedIn;
}

export type Props = StateProps & DispatchProps;
const isReauthenticating = (props: Props) => {
	const token = getCeaJwt();
	const { isAuthenticated, markLoggedIn } = props;
	if (!isAuthenticated && token) {
		markLoggedIn();
		return true;
	}
	return false;
};

export function App(props: Props): ReactElement {
	if (isReauthenticating(props)) {
		return <AppLoading />;
	}

	return (
		<AppLoading>
			<Router history={history}>
				{props.isAuthenticated && props.isAuthorized ?
					<Switch>
						<Route path="/" component={Dashboard} />
						<Route component={NotFound} />
					</Switch> :
					<Switch>
						<Route path="/" component={Login} />
					</Switch>
				}
			</Router>
			<Toaster />
		</AppLoading>
	);
}

const container = compose<Props, {}>(
	connect<StateProps, DispatchProps, {}, ApplicationState>(
		(state: ApplicationState) => {
			return {
				isAuthenticated: state.domains.core.isAuthenticated,
				isAuthorized: state.domains.core.isAuthorized,
				authorizing: state.domains.core.authorizing,
				authenticating: state.domains.core.authenticating,
			};
		},
		{ markLoggedIn }
	)
)(App);
export default container;
