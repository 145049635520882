import React, { ReactElement } from 'react';
import { compose } from 'recompose';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { arrowGenerator } from 'utils/arrowGenerator';

interface OwnProps {
	list?: any[];
	displayProp?: string;
	propValue?: any;
	getPropDisplayValue?: (val: any) => string;
}

const style = (theme: Theme) => ({
	tablePadding: {
		paddingLeft: '30px',
		cursor: 'pointer'
	},
	plusButtonStyle: {
		color: '#666666',
		cursor: 'pointer'
	},
	tooltip: {
		maxWidth: '300px',
		padding: '14px',
		fontSize: '12px',
		fontWeight: 300,
		lineHeight: '18px',
		borderRadius: '8px',
		backgroundColor: theme.palette.grey[900]
	},
	arrowPopper: arrowGenerator(theme.palette.grey[900]),
	arrow: {
		position: 'absolute' as const,
		fontSize: 6,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	}
});

type Props = OwnProps & WithStyles;

const getDisplay = (item: any, props: Props) => {

	if (props.getPropDisplayValue) {
		return props.getPropDisplayValue(props.propValue ? item[props.propValue] : item);
	} else if (props.displayProp) {
		return item[props.displayProp];
	}
	return item;
}

export function TableColumnListValue(props: Props): ReactElement {
	const { classes, list } = props;
	if (!list?.length) {
		return <p />;
	}

	const listDisplay = list.map(item => getDisplay(item, props)).join(', ');

	const plusButton = list.length > 1 ?
		<Tooltip
			disableFocusListener
			title={
				<React.Fragment>
					{listDisplay}
				</React.Fragment>
			}
			classes={{
				popper: classes.arrowPopper,
				tooltip: classes.tooltip
			}}

		>
			<span className={classes.plusButtonStyle}>&nbsp;+{list.length - 1}</span>
		</Tooltip>
		: '';
	return (
		<p className={classes.tablePadding}>{getDisplay(list[0], props)}{plusButton}</p>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(style)
)(TableColumnListValue);

export default container;
