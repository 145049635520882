export const phoneNumber = (value: any) => {
	if (!value) {
		return '';
	}
	let onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 7) {
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
	}
	if (onlyNums.length > 10 && onlyNums.indexOf('1') === 0) {
		onlyNums = onlyNums.slice(1);
	}
	return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

// Note: On input with type="number" 'e' or '.' seems to break normalization
// The fix is to prefer type"tel" to prevent this. Type specification triggers ipad numeric keypad
export const getOnlyNumbers = (value: any) => value.replace(/[^\d]/g, '');

const maxLength = (max: number) => (value: any) => (value ? value.trimStart().slice(0, max) : value.trimStart());
export const maxLength2 = maxLength(2);
export const maxLength3 = maxLength(3);
export const maxLength50 = maxLength(50);
export const maxLength255 = maxLength(255);
export const maxLength1600 = maxLength(1600);

export const getOnlyDecimal = (value: any) => {
	if (!value) {
		return '';
	}
	return value.replace(/[^\d.]/g, '');
}

export const currencyWholeDollars = (value: any) => {
	if (!value) {
		return 0;
	}
	const onlyNums = getOnlyNumbers(value);
	const limitedValue = onlyNums.slice(0,3)
	
	if (limitedValue)
		return limitedValue;
	else
		return 0;
};

export const currency = (value: any) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	const limitedValue = onlyNums.slice(0, 5);
	if (limitedValue.length === 1) {
		return `.0${limitedValue}`;
	}
	return `${limitedValue.slice(0, -2)}.${limitedValue.slice(-2)}`;
};

export const percentage = (value: any) => {
	if (!value) {
		return '';
	}

	let onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 4) {
		return limitOneDecimal(value);
	}

	onlyNums = onlyNums.slice(0, -1)

	return limitOneDecimal(`${onlyNums.slice(0, -1)}.${onlyNums.slice(-1)}`)
}

export const limitTwoDecimal = (value: string) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length === 1) {
		return `.0${onlyNums}`;
	}
	return `${onlyNums.slice(0, -2)}.${onlyNums.slice(-2)}`;
}

export const limitOneDecimal = (value: string) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length === 1) {
		return `.${onlyNums}`;
	}
	return `${onlyNums.slice(0, -1)}.${onlyNums.slice(-1)}`;
}

export const limitTwoDigits = (value: string) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 2) {
		return `${onlyNums}`;
	}
	return `${onlyNums.slice(0, 2)}`;
}

export const limitThreeDigits = (value: string) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 3) {
		return `${onlyNums}`;
	}
	return `${onlyNums.slice(0, 3)}`;
}

export const limitFourDigits = (value: string) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 4) {
		return `${onlyNums}`;
	}
	return `${onlyNums.slice(0, 4)}`;
}

export const date = (value: any) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	if (onlyNums.length <= 2) {
		return onlyNums;
	}
	if (onlyNums.length <= 4) {
		return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2,4)}`;
	}
	return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
};

export const numeric = (value: any) => {
	if (!value) {
		return '';
	}
	const onlyNums = getOnlyNumbers(value);
	return onlyNums;
};
