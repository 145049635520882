import { fork, all, put, actionChannel, take, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/secretShopper/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import * as actions from 'domains/secretShopper/actions';
import { callApi } from 'utils/apiSaga';
import { errorToast } from 'domains/core/components/Toaster';
import { SecretShopper } from 'domains/secretShopper/models';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadSecretShoppers(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_SECRET_SHOPPERS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadSecretShoppers);
	}
}

export function* handleLoadSecretShoppers(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadSecretShoppers());
		yield put({ type: actionTypes.LOAD_SECRET_SHOPPERS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_SECRET_SHOPPERS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error loading the secret shopper list.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForSaveSecretShopper(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.SAVE_SECRET_SHOPPER);
	while (true) {
		const action = yield take(requestChan);
		yield call(handleSaveSecretShopper, action.payload);
	}
}

export function* handleSaveSecretShopper(request: SecretShopper): SagaIterator {
	try {
		yield callApi(actions.saveSecretShopper(request));
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: '/consumerInteractions/secretShoppers' } });
	} catch (error) {
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: '/consumerInteractions/secretShoppers' } });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the secret shopper.');
	}
}

export function* watchForLoadSecretShopper(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_SECRET_SHOPPER);
	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadSecretShopper, action.payload);
	}
}

export function* handleLoadSecretShopper(id: string): SagaIterator {
	try {
		const result = yield callApi(actions.loadSecretShopper(id));
		yield put({ type: actionTypes.LOAD_SECRET_SHOPPER_SUCCESS, result });
	} catch (error) {
		errorToast(error.status === 400 ? error.message : 'There was an error loading the secret shopper.');
	}
}

export function* watchForDeactivateSecretShopper(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.DEACTIVATE_SECRET_SHOPPER);
	while (true) {
		const action = yield take(requestChan);
		yield call(handleDeactivateSecretShopper, action.payload);
	}
}

export function* handleDeactivateSecretShopper(id: number): SagaIterator {
	try {
		yield callApi(actions.deactivateSecretShopper(id));
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: '/consumerInteractions/secretShoppers' } });
	} catch (error) {
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: '/consumerInteractions/secretShoppers' } });
		errorToast(error.status === 400 ? error.message : 'There was an error deactivating the secret shopper.');
	}
}

export default function* watchForExecutionTagSagas() {
	yield all([
		fork(watchForLoadSecretShoppers),
		fork(watchForSaveSecretShopper),
		fork(watchForLoadSecretShopper),
		fork(watchForDeactivateSecretShopper)
	]);
}
