import React from 'react';

import { CustomDropdown } from 'components/CustomDropdown';
import { MoreHoriz } from '@material-ui/icons';

interface ComponentProps {
	menuItems: JSX.Element[];
}

export type Props = ComponentProps;
export const ContextMenu = React.forwardRef(function menu(props: Props, _ref): JSX.Element | null {
	if (!props.menuItems.length) {
		return null;
	}

	return (
		<CustomDropdown
			buttonText={<span style={{ color: 'black' }}>MORE</span>}
			buttonProps={{
				color: 'primary',
				simple: true
			}}
			buttonIcon={MoreHoriz}
			caret={false}
			dropdownList={props.menuItems}
			dropPlacement="right"
		/>
	);
});

export default ContextMenu;
