import { take, put, all, fork, call, select } from 'redux-saga/effects';
import moment from 'moment';
import { setCeaJwt, clearCeaJwt, setCeaJwtExpiration, setCeaRefreshToken, getCeaRefreshToken, getCeaJwtExpiration } from 'utils/apiHelpers';
import { callApi } from 'utils/apiSaga';
import * as actionTypes from 'domains/core/actionTypes';
import * as actions from 'domains/core/actions';
import { CeaAuthenticationRequest, CeaAuthenticationResponse, CurrentUser } from 'domains/core/models';
import { Action, ApplicationState } from 'applicationTypes';
import { buildCurrentUser } from 'domains/core/helpers';
import { errorToast } from 'domains/core/components/Toaster';
import { getCurrentUser } from './selectors';
import { SagaIterator } from 'redux-saga';

export function* watchForLogin(): SagaIterator {
	while (true) {
		const action = yield take(actionTypes.LOGIN);
		yield call(handleLogin, action);
	}
}

export function* handleLogin(action: Action): SagaIterator {
	try {
		const result: CeaAuthenticationResponse = yield callApi(actions.login(action.payload));
		if (result && result.accessToken) {
			setCeaJwt(result.accessToken);
			setCeaJwtExpiration(result.tokenExpiresAt);
			setCeaRefreshToken(result.refreshToken);
			yield put({ type: actionTypes.LOGIN_SUCCESS, result });
		} else {
			throw new Error('Invalid username or password.');
		}
	} catch (error) {
		errorToast(error.message);
		yield put({ type: actionTypes.LOGIN_FAIL, error });
	}
}

export function* watchForLoginSuccess(): SagaIterator {
	while (true) {
		yield take(actionTypes.LOGIN_SUCCESS);
		yield call(handleLoginSuccess);
	}
}

export function* handleLoginSuccess(): SagaIterator {
	try {
		yield put({ type: actionTypes.GET_USER });
		const userEntity = yield callApi(actions.getUser());
		if (userEntity) {
			const user: CurrentUser | undefined = buildCurrentUser(userEntity);
			yield put({ type: actionTypes.AUTHORIZE_USER_SUCCESS, result: user });
		}
	} catch (error) {
		clearCurrentUser();
		errorToast(error.message);
		yield put({ type: actionTypes.LOGIN_FAIL, error });
		yield put({ type: actionTypes.AUTHORIZE_USER_FAIL, error });
	}
}

export function* watchForRefreshToken(): SagaIterator {
	while (true) {
		const action = yield take(actionTypes.CHECK_REFRESH_TOKEN);
		const ceaJwtExpiration = getCeaJwtExpiration();

		if (ceaJwtExpiration && ceaJwtExpiration !== '') {
			const refreshThreshold = Number(process.env.REACT_APP_TOKEN_REFRESH_THRESHOLD_MINUTES);
			const shouldRefreshToken = moment(ceaJwtExpiration).diff(moment(), 'minute') < refreshThreshold;
			const isRefreshingToken = yield select((state: ApplicationState) => state.domains.core.refreshingToken);

			// only refresh if we are below the token refresh threshold and are not currently refreshing already.
			if (shouldRefreshToken && !isRefreshingToken) {
				yield put({ type: actionTypes.REFRESH_TOKEN });
				try {
					const username = (yield select(state => getCurrentUser(state)))?.azureUID;
					const refreshToken = getCeaRefreshToken();

					const request: CeaAuthenticationRequest = {
						...action.payload,
						username,
						refreshToken,
					};

					const result: CeaAuthenticationResponse = yield callApi(actions.refreshToken(request));

					if (result.accessToken) {
						setCeaJwt(result.accessToken);
						setCeaJwtExpiration(result.tokenExpiresAt);
						setCeaRefreshToken(result.refreshToken);
					}

					yield put({ type: actionTypes.REFRESH_TOKEN_SUCCESS, result });
				} catch (error) {
					yield put({ type: actionTypes.REFRESH_TOKEN_FAIL, error });
				}
			}
		}
	}
}

export function* watchForLogout(): SagaIterator {
	while (true) {
		yield take([actionTypes.LOGOUT, actionTypes.SESSION_EXPIRED]);
		yield call(handleLogout);
	}
}

export function* handleLogout(): SagaIterator {
	try {
		clearCurrentUser();
		yield put({ type: actionTypes.LOGOUT_SUCCESS });
	} catch (error) {
		yield put({ type: actionTypes.LOGOUT_FAIL, error });
	}
}

export function* watchForLoadUsers(): SagaIterator {
	while (true) {
		yield take(actionTypes.LOAD_USERS);
		yield call(handleLoadUsers);
	}
}

export function* handleLoadUsers(): SagaIterator {
	try {
		const result = yield callApi(actions.loadUsers());
		yield put({ type: actionTypes.LOAD_USERS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_USERS_FAIL, error });
	}
}

const clearCurrentUser = () => {
	clearCeaJwt();
};

export default function* watchForCoreSagas() {
	yield all([
		fork(watchForLogin),
		fork(watchForLoginSuccess),
		fork(watchForLoadUsers),
		fork(watchForLogout),
		fork(watchForRefreshToken),
	]);
}
