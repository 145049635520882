import { SourceSystem } from 'domains/core/models';

export interface Role {
	id: string;
	roleId: number;
	system: SourceSystem;
	active: boolean;
	name: string;
	roleActions: RoleUserAction[];
}

export interface RoleUserAction {
	id: number;
	roleId: number;
	action: UserAction;
	createdByUsername: string;
	createdDateTime: Date;
}

export interface UserAction {
	id: string;
	actionId: number;
	systems: SourceSystem[];
	active: boolean;
	name: string;
	category?: string;
}

export enum UserRoleSortColumn {
	RoleName = 'name',
	System = 'system'
}

export enum RoleActionSortColumn {
	Category = 'category',
	AssignedAction = 'name'
}

export interface RoleExportRequest {
	roleId: number;
	system: SourceSystem;
	actionFilter?: string;
}

export interface RoleRouteMatchProps {
	system?: string;
	id?: string;
}

export interface LoadUserActionsRequest {
	system: SourceSystem;
}

export interface UpdateRoleActionsRequest {
	system: SourceSystem;
	roleId: number;
	selectedActions: UserAction[];
}
