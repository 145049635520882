import { take, put, fork, all, actionChannel, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/audits/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import { callApi } from 'utils/apiSaga';
import * as actions from 'domains/audits/actions';
import { UserAuditSearchRequest } from 'domains/audits/models';
import { errorToast } from 'domains/core/components/Toaster';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadUserAuditFilters(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_AUDIT_FILTER);

	while (true) {
		yield take(requestChan);
		yield call(handleLoadUserAuditFilters);
	}
}

export function* handleLoadUserAuditFilters(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadFilters());
		yield put({ type: actionTypes.LOAD_AUDIT_FILTER_SUCCESS, result });
	} catch (error) {
		// TODO: Add Toaster messaging for errors
		yield put({ type: actionTypes.LOAD_AUDIT_FILTER_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForSearchUserAuditFilters(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.SEARCH_USER_AUDITS);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleSearchUserAuditFilters, request.payload);
	}
}

export function* handleSearchUserAuditFilters(request: UserAuditSearchRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.searchUserAudits(request));
		yield put({ type: actionTypes.SEARCH_USER_AUDITS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.SEARCH_USER_AUDITS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error searching user audits.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForExportUserAudits(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.EXPORT_USER_AUDITS);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleExportUserAudits, result.payload);
	}
}

export function* handleExportUserAudits(request: UserAuditSearchRequest): SagaIterator {
	try {
		const result = yield callApi(actions.exportUserAudits(request));
		yield put({ type: actionTypes.EXPORT_USER_AUDITS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.EXPORT_USER_AUDITS_FAIL, error });
	}
}

export default function* watchForCompaniesSagas() {
	yield all([
		fork(watchForLoadUserAuditFilters),
		fork(watchForSearchUserAuditFilters),
		fork(watchForExportUserAudits),
	]);
}
