import React from 'react';
import { Cancel } from '@material-ui/icons';

import { withStyles, WithStyles } from '@material-ui/core/styles';

const style = {
	customIconStyle: {
		fontSize: '14px'
	}
};

export type CustomProps = {
	disabled?: boolean;
};

export type Props = CustomProps & WithStyles;

export const CancelIcon = (props: Props) => <Cancel color={!props.disabled ? 'error' : 'disabled'} className={props.classes.customIconStyle} />;

export default withStyles(style)(CancelIcon);
