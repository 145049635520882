import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import isMatch from 'lodash/isMatch';
import { denormalize } from 'normalizr';
import { ApplicationState } from 'applicationTypes';
import { getUserNameFormatted } from 'domains/core/helpers';
import { User, Program, Market, Role, ManagerEmployee, CurrentUser } from 'domains/core/models';
import { getCompanyIdFromRouteOrUser, getCompanies } from 'domains/companies/selectors';
import { usersSchema, programSchema, marketSchema, metrixWebRolesSchema, metrixWebManagers } from 'domains/users/schemas';
import { Company } from 'domains/companies/models';
import { Roles, UserActions } from 'domains/core/enums';
import { EntityState } from 'utils/entityReducer';
import { getCurrentUser } from 'domains/core/selectors';

const getEntities = (state: ApplicationState) => state.entities;
export const getCompanyUserDetail = (_state: ApplicationState) => _state.domains.users.companyUserDetails;
const getUserPrincipalNameFromProps = (_state: any, props: any) => props.match && props.match.params && props.match.params.userPrincipalName;
export const getIsLoadingUsers = (state: any) => state.domains.users.isLoadingUsers;

export const getUsers = createSelector(
	[getEntities],
	(entities: EntityState): User[] => {
		const schema = { users: [usersSchema] };
		const result: User[] = denormalize({ users: Object.keys(entities.users) }, schema, entities).users;
		return result;
	}
);

export const getCompanyUsers = createSelector(
	[getUsers, getCompanyIdFromRouteOrUser],
	(users, companyId): User[] => {
		const companyUsers = users.filter(x => x.companyId === companyId);
		return companyUsers;
	}
);

export const getCompanyFromCompanyUser = createSelector(
	[getCompanies, getCompanyUserDetail],
	(companies, companyUser): Company | undefined => {
		if (companyUser) {
			return companies.find(x => x.id === companyUser.company);
		}
		return undefined;
	}
);

export const getUserFromRoute = createSelector(
	[getUsers, getUserPrincipalNameFromProps],
	(users, userNameFromRoute): User | undefined => {
		if (users) {
			return users.find(x => x.azureAdUser!.userPrincipalName === getUserNameFormatted(userNameFromRoute));
		}
		return undefined;
	}
);

export const getAdminUsers = createSelector(
	[getUsers, getCompanyIdFromRouteOrUser],
	(users, companyId): User[] | undefined => {
		return users.filter(
			(user: User) => {
				return isMatch(user, {
					companyId,
					ceaUser: {
						roleNames: [Roles.CompanyAdmin]
					}
				});
			}
		);
	}
);

export const getMetrixWebPrograms = createSelector(
	[getEntities],
	(entities: EntityState): Program[] => {
		const schema = { programs: [programSchema] };
		const result: Program[] = denormalize({ programs: Object.keys(entities.programs) }, schema, entities).programs;
		return orderBy(result, x => x.name);
	}
);

export const getMetrixWebMarkets = createSelector(
	[getEntities],
	(entities: EntityState): Market[] => {
		const schema = { markets: [marketSchema] };
		const result: Market[] = denormalize({ markets: Object.keys(entities.markets) }, schema, entities).markets;
		return orderBy(result.filter(x => x.shortName !== null), x => x.shortName);
	}
);

export const getMetrixWebRoles = createSelector(
	[getEntities, getCompanyIdFromRouteOrUser, getCurrentUser],
	(entities: EntityState, companyId: number, user?: CurrentUser | null): Role[] => {
		if (!user) {
			return [];
		}
		const schema = { metrixWebRoles: [metrixWebRolesSchema] };
		const roles: Role[] = denormalize({ metrixWebRoles: Object.keys(entities.metrixWebRoles) }, schema, entities).metrixWebRoles;
		const result: Role[] = user.canDoAction(UserActions.CanAssignAnyExternalSystemRole)
			? roles
			: roles.filter(x => x.companies && x.companies.some(c => c.id === companyId)) as Role[];
		return orderBy(result, x => x.name);
	}
);

export const getCeaRolesForCompany = createSelector(
	[getEntities, getCompanyIdFromRouteOrUser],
	(entities: EntityState, companyId: number): Role[] => {
		const company = Object.values(entities.companies).find(x => x.id === companyId);
		if (company) {
			return orderBy(company.companyRoles, x => x.displayName)
		}
		return [];
	}
);

export const getMetrixWebManagers = createSelector(
	[getEntities],
	(entities: EntityState): ManagerEmployee[] => {
		const schema = { managers: [metrixWebManagers] };
		const result: ManagerEmployee[] = denormalize({ managers: Object.keys(entities.managers) }, schema, entities).managers;
		return orderBy(result, [x => x.firstName, x => x.lastName]);
	}
);
