import { createSelector } from 'reselect';
import { State as CoreState } from 'domains/core/reducer';
import { denormalize } from 'normalizr';
import { ApplicationState } from 'applicationTypes';
import { Company } from 'domains/companies/models';
import { companySchema } from 'domains/companies/schemas';
import { getCoreState } from 'domains/core/selectors';

const getEntities = (state: ApplicationState) => state.entities;
const getCompanyIdFromProps = (_state: any, props: any) => props?.match?.params && parseInt(props.match.params.companyId, 10);

export const getCompanyActiveUserCount = (_state: ApplicationState) => _state.domains.companies.companyDetails.activeUserCount;
export const getCompanyInactiveUserCount = (_state: ApplicationState) => _state.domains.companies.companyDetails.inactiveUserCount;

export const isLoadingCompanies = (_state: ApplicationState) => _state.domains.companies.isLoadingCompanies;

export const getCompanies = createSelector(
	[getEntities],
	(entities): Company[] => {
		const schema = { companies: [companySchema] };
		const result = denormalize({ companies: Object.keys(entities.companies) }, schema, entities).companies;

		return result;
	}
);

export const getCompany = createSelector(
	[getCompanies, (_state: ApplicationState, id: number) => id],
	(companies, id) => {
		return companies.find(x => x.id === id);
	}
);

export const getCompanyIdFromRouteOrUser = createSelector(
	[getCoreState, getCompanyIdFromProps],
	(coreState: CoreState, companyId) => {
		return companyId ? Number(companyId) : coreState!.user!.companyId;
	}
);

export const getCompanyFromRouteOrUser = createSelector(
	[getCompanies, getCompanyIdFromRouteOrUser],
	(companies, companyIdFromRoute): Company | undefined => {
		const companyId = companyIdFromRoute;
		return companies.find(x => x.id === companyId);
	}
);

export const getCompanyFromRoute = createSelector(
	[getCompanies, getCompanyIdFromProps],
	(companies, companyIdFromRoute): Company | undefined => {
		const companyId = companyIdFromRoute && Number(companyIdFromRoute);
		return companies.find(x => !!companyId && x.id === companyId);
	}
);
