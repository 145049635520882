export const REROUTE = 'core/REROUTE';
export const RESET_REROUTE = 'core/RESET_REROUTE';

export const LOGIN = 'core/LOGIN';
export const LOGIN_SUCCESS = 'core/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'core/LOGIN_FAIL';

export const CHECK_REFRESH_TOKEN = 'core/CHECK_REFRESH_TOKEN';
export const REFRESH_TOKEN = 'core/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'core/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'core/REFRESH_TOKEN_FAIL';

export const GET_USER = 'core/GET_USER';

export const LOGOUT = 'core/LOGOUT';
export const LOGOUT_SUCCESS = 'core/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'core/LOGOUT_FAIL';
export const SESSION_EXPIRED = 'core/SESSION_EXPIRED';

export const CLEAR_LOGIN_ERROR = 'core/CLEAR_LOGIN_ERROR';

export const AUTHORIZE_USER = 'core/AUTHORIZE_USER';
export const AUTHORIZE_USER_SUCCESS = 'core/AUTHORIZE_USER_SUCCESS';
export const AUTHORIZE_USER_FAIL = 'core/AUTHORIZE_USER_FAIL';

export const LOAD_USERS = 'core/LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'core/LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'core/LOAD_USERS_FAIL';

export const APP_LOADING = 'core/APP_LOADING';
export const APP_LOADING_COMPLETE = 'core/APP_LOADING_COMPLETE';

export const SHOW_TOASTER_MESSAGE = 'core/SHOW_TOASTER_MESSAGE';

export const PAGINATION_SET_PAGE = 'core/PAGINATION_SET_PAGE';
export const PAGINATION_RESET = 'core/PAGINATION_RESET';
export const PAGINATION_SCROLL_RESET = 'core/PAGINATION_SCROLL_RESET';
