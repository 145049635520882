import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doPaginationReset } from 'domains/core/actions';
import { ExecutionTagForm } from 'domains/executionTags/components';
import { Form, FormRenderProps } from 'react-final-form';
import { doLoadFormOptions, doSaveExecutionTag } from 'domains/executionTags/actions';
import { ExecutionTagFormValues, ExecutionTagSaveRequest } from 'domains/executionTags/models';
import { getValidMarketOptions, getValidRetailLocationOptions } from 'domains/executionTags/helpers';

const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	}
});

const ExecutionTagCreate: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const options = useSelector(state => state.domains.executionTags.formOptions);
	const onCancel = () => history.push('/consumerInteractions/executionTags');

	useEffect(() => {
		dispatch(doPaginationReset());
		if (!options) {
			dispatch(doLoadFormOptions());
		}
	}, [dispatch, options])

	const onSubmit = (values: ExecutionTagFormValues) => {
		const validMarketOptions = getValidMarketOptions(values, options);
		const validRetailLocationOptions = getValidRetailLocationOptions(values, options);

		const request: ExecutionTagSaveRequest = {
			...values,
			marketIds: isEqual(values.marketIds?.sort(), validMarketOptions.map(x => x.marketId).sort()) ? undefined : values.marketIds,
			retailLocationTypeIds: isEqual(values.retailLocationTypeIds?.sort(), options?.retailLocationTypes.map(x => x.id).sort()) ? undefined : values.retailLocationTypeIds,
			physicalLocationSubTypeIds: isEqual(values.physicalLocationSubTypeIds?.sort(), options?.physicalLocationSubTypes.map(x => x.id).sort()) ? undefined : values.physicalLocationSubTypeIds,
			mruSubTypeIds: isEqual(values.mruSubTypeIds?.sort(), options?.mruSubTypes.map(x => x.id).sort()) ? undefined : values.mruSubTypeIds,
			retailLocationIds: isEqual(values.retailLocationIds?.sort(), validRetailLocationOptions.map(x => x.id).sort()) ? undefined : values.retailLocationIds
		}

		dispatch(doSaveExecutionTag(request));
	};

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Add Special Execution Tag</h2>
					</CardHeader>
					<CardBody>
						{options && (
							<Form
								subscription={{ pristine: true, invalid: true }}
								onSubmit={onSubmit}
								render={(props: FormRenderProps<ExecutionTagFormValues>) => (
									<ExecutionTagForm
										{...props}
										onCancel={onCancel}
										options={options}
									/>
								)}
							/>
						)}
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}

export default ExecutionTagCreate;
