import React, { useCallback, useEffect } from 'react';
import FilterList from '@material-ui/icons/FilterList';
import Cancel from '@material-ui/icons/Cancel';
import { dangerColor, primaryColor } from 'assets/jss/material-dashboard-pro-react';
import { Theme } from '@material-ui/core/styles';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { doLoadUserFilterDependencies } from 'domains/users/actions';
import { getMetrixWebPrograms, getMetrixWebRoles, getCeaRolesForCompany, getMetrixWebMarkets } from 'domains/users/selectors';
import { CeaSourceSystemDictionary } from 'domains/core/models';
import { UserFilter } from 'domains/core/models';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { Field, FormRenderProps } from 'react-final-form';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import { useParams } from 'react-router-dom';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' },
		applyFilterButton: {
			position: 'relative' as const,
			top: 14,
			'& button': {
				maxWidth: 140,
				color: primaryColor
			}
		},
		clearFilterButton: {
			position: 'relative' as const,
			top: 14,
			'& span': {
				color: 'black' as const,
			},
			'& svg': {
				color: dangerColor
			},
			'& button': {
				maxWidth: 140,
			}
		},
		padBottomLess: {
			paddingBottom: '12px'
		}
	};
};

const systems = Object.keys(CeaSourceSystemDictionary).map(k => ({ value: Number(k), text: CeaSourceSystemDictionary[k] }));

export default function UserFilterForm(props: FormRenderProps<UserFilter>) {
	const { handleSubmit, values, form: { reset, change } } = props;
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const { companyId } = useParams<{ companyId: string }>();

	const programs = useSelector(state => getMetrixWebPrograms(state))?.map(program => ({ value: program.id, text: program.name }));
	const markets = useSelector(state => getMetrixWebMarkets(state))?.map(market => ({ value: market.marketId, text: market.shortName }));
	const mwRoles = useSelector(state => getMetrixWebRoles(state, props))?.map(role => ({ value: role.id, text: role.displayName ?? role.name }));
	const ceaRoles = useSelector(state => getCeaRolesForCompany(state, { match: { params: { companyId } } }));

	useEffect(() => {
		dispatch(doLoadUserFilterDependencies());
	}, [dispatch]);

	const getFilteredCeaRoles = useCallback((selectedSourceSystemIds?: number[]) => {
		if (selectedSourceSystemIds?.length) {
			return ceaRoles
				.filter(x => x.sourceSystemId && selectedSourceSystemIds.includes(x.sourceSystemId))
				.map(role => ({ value: role.id, text: role.displayName ?? role.name }));
		}
		return ceaRoles.map(role => ({ value: role.id, text: role.displayName ?? role.name }));
	}, [ceaRoles]);

	const clearRolesOnSourceSystemChange = useCallback(() => {
		change('ceaRoles', []);
	}, [change]);

	const clearFilters = useCallback(() => {
		reset();
		handleSubmit();
	}, [handleSubmit, reset]);

	return (
		<form onSubmit={handleSubmit} className={classes.padBottomLess}>
			<GridContainer>
				<GridItem lg={2} md={2} xs={2}>
					<Field name="sourceSystemIds">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="sourceSystemIds"
								labelText="CEA Application(s)"
								multiple
								options={systems}
								customOnChange={clearRolesOnSourceSystemChange}
							/>)}
					</Field>
				</GridItem>
				<GridItem lg={2} md={2} xs={2}>
					<Field name="ceaRoles">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="ceaRoles"
								labelText="CEA Role(s)"
								multiple
								options={getFilteredCeaRoles(values.sourceSystemIds)}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem lg={2} md={2} xs={2}>
					<Field name="metrixRoles">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="metrixRoles"
								labelText="MW Roles(s)"
								multiple
								options={mwRoles}
							/>)}
					</Field>
				</GridItem>
				<GridItem lg={2} md={2} xs={2}>
					<Field name="programs">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="programs"
								labelText="Program(s)"
								multiple
								options={programs}
							/>)}
					</Field>
				</GridItem>
				<GridItem lg={2} md={2} xs={2}>
					<Field name="markets">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="markets"
								labelText="Market(s)"
								multiple
								options={markets}
							/>)}
					</Field>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem lg={2} md={2} xs={2}>
					<div className={classes.applyFilterButton}>
						<Button color="white" type="submit" fullWidth><FilterList />APPLY FILTERS</Button>
					</div>
				</GridItem>
				<GridItem lg={2} md={2} xs={2}>
					<div className={classes.clearFilterButton}>
						<Button color="white" type="button" onClick={clearFilters} fullWidth><Cancel />CLEAR FILTERS</Button>
					</div>
				</GridItem>
			</GridContainer>
		</form >
	);
}
