import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormRenderProps, Field } from 'react-final-form';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';
import { usaStateDict } from 'utils/constants';
import { Company, Companies } from 'domains/companies/models';
import { CreateEditUserFormValues } from 'domains/users/models';


interface Props extends FormRenderProps<CreateEditUserFormValues> {
	company?: Company;
}

export default function CreateEditUserForm(props: Props) {
	const history = useHistory();
	const { invalid, pristine, initialValues, company, values } = props;

	const editFieldsDisabled = useMemo(() => initialValues && initialValues.azureAdUser && initialValues.azureAdUser.importedFromStaffing, [initialValues]);
	const submitDisabled = useMemo(() => invalid || pristine, [invalid, pristine]);

	const stateSelectOptions = useMemo(() => {
		return Object.keys(usaStateDict).map((prop: string) => ({ value: prop, text: usaStateDict[prop as keyof typeof usaStateDict] }));
	}, []);

	return (
		<GridContainer>
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="azureAdUser.givenName"
					labelText="First Name"
					formControlProps={{
						fullWidth: true,
						required: true,
						disabled: editFieldsDisabled
					}}
					validate={validators.maxLength255}
				/>
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="azureAdUser.surname"
					labelText="Last Name"
					formControlProps={{
						fullWidth: true,
						required: true,
						disabled: editFieldsDisabled
					}}
					validate={validators.maxLength255}
				/>
			</GridItem>
			{company?.id === Companies.GmrMarketing &&
				<GridItem xs={12} md={12} lg={12}>
					<Field
						component={CustomInputAdapter}
						name="azureAdUser.employeeId"
						labelText="Employee ID"
						formControlProps={{
							fullWidth: true,
							required: true,
							disabled: editFieldsDisabled
						}}
						validate={validators.maxLength255}
					/>
				</GridItem>
			}
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="azureAdUser.otherMails[0]"
					labelText="Email"
					type="email"
					formControlProps={{
						fullWidth: true,
						required: !values?.metrixUser?.mobilePhone
					}}
					validate={validators.composeValidators(validators.maxLength500, validators.isEmail)}
				/>
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="azureAdUser.mobilePhone"
					labelText="Mobile Number"
					type="tel"
					formControlProps={{
						fullWidth: true,
						required: !values?.metrixUser?.email,
						disabled: editFieldsDisabled
					}}
					validate={validators.isNorthAmericanPhoneNumber}
					format={normalizers.phoneNumber}
					parse={normalizers.phoneNumber}
				/>
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="metrixUser.homeAddress.addressLine1"
					labelText="Address Line 1"
					formControlProps={{
						fullWidth: true,
						required: true,
						disabled: editFieldsDisabled
					}}
					validate={validators.maxLength255}
				/>
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<Field
					component={CustomInputAdapter}
					name="metrixUser.homeAddress.addressLine2"
					labelText="Address Line 2"
					formControlProps={{
						fullWidth: true,
						required: false,
						disabled: editFieldsDisabled
					}}
					validate={validators.maxLength255}
				/>
			</GridItem>
			<GridItem xs={12} md={5} lg={5}>
				<Field
					component={CustomInputAdapter}
					name="metrixUser.homeAddress.city"
					labelText="City"
					formControlProps={{
						fullWidth: true,
						required: true,
						disabled: editFieldsDisabled
					}}
					validate={validators.maxLength255}
				/>
			</GridItem>
			<GridItem xs={12} md={4} lg={4}>
				<Field name="metrixUser.homeAddress.state">
					{(fieldRenderProps) => (
						<FormSelectAdapter
							{...fieldRenderProps}
							id="stateField"
							labelText="State"
							disabled={editFieldsDisabled}
							required
							options={stateSelectOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs={12} md={3} lg={3}>
				<Field
					component={CustomInputAdapter}
					name="metrixUser.homeAddress.postalCode"
					labelText="Zip"
					formControlProps={{
						fullWidth: true,
						required: true,
						disabled: editFieldsDisabled
					}}
					validate={validators.isZipCode}
				/>
			</GridItem>
			<GridItem>
				<Button color="primary" type="submit" disabled={submitDisabled}>Save</Button>
				<Button simple color="primary" onClick={history.goBack}>Cancel</Button>
			</GridItem>
			{editFieldsDisabled && <GridItem xs={12} md={12} lg={12}><div>* Imported from Staffing</div></GridItem>}
		</GridContainer>
	);
}
