import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'components/Table';
import useTable from 'hooks/useTable';
import { ExecutionTag } from 'domains/executionTags/models';

const formatDateString = (date?: string) => {
	if (date) {
		return moment(date).format('MM/DD/YYYY');
	}
	return "--";
};

interface Props {
	active: boolean;
	filterValue?: string;
}

const filter = (filterValue: string, value: string) => value.toUpperCase().includes(filterValue.toUpperCase());

const sortString = (first: string, second: string) => {
	return first.toUpperCase() > second.toUpperCase() ? 1 : -1;
}

const sortDateString = (first: string, second: string) => {
	var firstDate = moment(first, 'MM/DD/YYYY');
	var secondDate = moment(second, 'MM/DD/YYYY');

	if (!firstDate.isValid())
	{
		return -1;
	}

	if (!secondDate.isValid())
	{
		return 1;
	}

	return firstDate > secondDate ? 1 : -1;
}

const getMarketNamesDisplayText = (marketNames?: string) => {
	if (!marketNames) {
		return 'All';
	}

	const nameArray = marketNames.split(/[ ,]+/);
	const name =  nameArray.length > 1 ? nameArray[0] + ` +${nameArray.length - 1}` : nameArray[0];
	return name;
};

const ExecutionTagsTable: React.FC<Props> = ({ active, filterValue }) => {
	const history = useHistory();
	const executionTags = useSelector(state => state.domains.executionTags.executionTagList?.filter(x => x.isActive === active) || []);

	const goToDetailsPage = (tag: ExecutionTag) => () => {
		history.push(`/consumerInteractions/executionTags/view/${tag.id}`);
	}

	const globalFilter = (filterValue: string, exeuctionTag: ExecutionTag) => {
		return exeuctionTag.marketNames?.toUpperCase().includes(filterValue.toUpperCase()) || false;
	}

	const columnDefinitions = [
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => tag.name, headerText: "Internal Name", filter: filter, sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => tag.displayName || '', headerText: "Display Name", filter: filter, sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => tag.programName, headerText: "Program", filter: filter, sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => getMarketNamesDisplayText(tag.marketNames), headerText: "Market(s)", sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => formatDateString(tag.startDate), headerText: "Start Date", filter: filter, sortBy: sortDateString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (tag: ExecutionTag) => formatDateString(tag.endDate), headerText: "End Date", filter: filter, sortBy: sortDateString },
	] as any;

	const { tableHead, tableData } = useTable({ columnDefinitions, data: executionTags, filterValue: filterValue, globalFilter: globalFilter });

	return (
		<Table
			striped
			tableHead={tableHead}
			tableData={tableData}
			customClassesForCells={[0]}
			customHeadClassesForCells={[0]}
		/>
	);
}

export default ExecutionTagsTable;
