import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GridContainer, GridItem } from 'components/Grid';
import { Table } from 'components/Table';
import { doSearchUserAudits } from 'domains/audits/actions';
import { UserAuditSearchRequest, SourceSystem, LogSystem } from 'domains/audits/models';
import { getAudits } from 'domains/audits/selectors';
import { AuditFilters } from 'domains/audits/components';
import { useStyles } from 'styles';

const style = {
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer'
	},
	tablePadding: {
		paddingLeft: '30px'
	}
};

interface Props {
	userFilter?: string;
}

export default function Audits(props: Props) {
	const { userFilter } = props;
	const dispatch = useDispatch();

	const classes = useStyles(style);

	const audits = useSelector(state => getAudits(state));
	const { currentPage, recordsPerPage } = useSelector(state => state.domains.core.pagination);
	const loading = useSelector(state => state.domains.audits.loading);

	const handleFilterChange = useCallback((request: UserAuditSearchRequest) => {
		dispatch(doSearchUserAudits({
			...request,
			pageNumber: currentPage,
			pageSize: recordsPerPage,
		}));
	}, [currentPage, dispatch, recordsPerPage]);

	const initialValues = useMemo(() => {
		return {
			sourceSystem: SourceSystem.All,
			logSystem: LogSystem.Cea,
			company: 0,
			dateRange: 'day' as const,
			userAuditType: 0, // All
		};
	}, []);

	const tableData = useMemo(() => (audits && audits.length > 0
		? audits.map(c => [
			<span key={1}><p className={classes.tablePadding}>{`${moment(c.date).format('L')} at ${moment(c.date).format('hh:mm:ss A')}`}</p></span>,
			<span key={2}><p className={classes.tablePadding}>{c.sourceSystem}</p></span>,
			<span key={3}><p className={classes.tablePadding}>{c.userAuditType}</p></span>,
			<span key={4}><p className={classes.tablePadding}>{c.company}</p></span>,
			<span key={5}><p className={classes.tablePadding}>{c.affectedUser}</p></span>,
			<span key={6}><p className={classes.tablePadding}>{c.performedBy}</p></span>,
			<span key={7}><p className={classes.tablePadding}>{c.isSuccess ? 'Yes' : 'No'}</p></span>,
			<span key={8}><p className={classes.tablePadding}>{c.errorMessage}</p></span>,
		])
		: [[
			<span key={1} />, <span key={2} />, <span key={3} />, <span key={4} />, <span key={5}><p className={classes.tablePadding}>{loading ? 'Loading...' : 'No Records Found'}</p></span>, <span key={6} />, <span key={7} />, <span key={8} />
		]]), [audits, classes.tablePadding, loading]);

	return (
		<>
			<AuditFilters handleFilterChange={handleFilterChange} initialValues={initialValues} userFilter={userFilter} />
			<GridContainer>
				<GridItem xs={12}>
					<Table
						striped
						tableData={tableData}
						tableHead={[
							<h6 key={1} className={classes.tablePadding}>Date</h6>,
							<h6 key={2} className={classes.tablePadding}>System</h6>,
							<h6 key={3} className={classes.tablePadding}>Action</h6>,
							<h6 key={4} className={classes.tablePadding}>Company</h6>,
							<h6 key={5} className={classes.tablePadding}>Affected User</h6>,
							<h6 key={6} className={classes.tablePadding}>Performed By</h6>,
							<h6 key={7} className={classes.tablePadding}>Successful</h6>,
							<h6 key={8} className={classes.tablePadding}>Error Message</h6>,
						]}
					/>
				</GridItem>
			</GridContainer>
		</>
	);
}
