// ##############################
// // // Modal component styles
// #############################

import { Theme } from "@material-ui/core";

const modalStyle = (theme: Theme) => ({
	modalRoot: {
		overflow: "auto" as const,
		alignItems: "unset",
		justifyContent: "unset"
	},
	modal: {
		[theme.breakpoints.up("sm")]: {
			maxWidth: "500px",
			margin: "1.75rem auto"
		},
		borderRadius: "6px",
		marginTop: "100px !important",
		overflow: "visible" as const,
		maxHeight: "unset",
		position: "relative" as const,
		height: "fit-content"
	},
	modalHeader: {
		borderBottom: "none",
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "0",
		paddingLeft: "24px",
		minHeight: "16.43px"
	},
	modalTitle: {
		margin: "0",
		lineHeight: "1.42857143"
	},
	modalCloseButton: {
		color: "#999999",
		marginTop: "-12px",
		WebkitAppearance: "none" as const,
		padding: "0",
		cursor: "pointer",
		background: "0 0",
		border: "0",
		fontSize: "inherit",
		opacity: ".9",
		textShadow: "none" as const,
		fontWeight: 700,
		lineHeight: "1",
		float: "right" as const
	},
	modalClose: {
		width: "16px",
		height: "16px"
	},
	modalBody: {
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "16px",
		paddingLeft: "24px",
		position: "relative" as const,
		overflow: "visible" as const
	},
	modalFooter: {
		padding: "15px",
		textAlign: "right" as const,
		paddingTop: "0",
		margin: "0"
	},
	modalFooterCenter: {
		marginLeft: "auto",
		marginRight: "auto"
	},
	instructionNoticeModal: {
		marginBottom: "25px"
	},
	imageNoticeModal: {
		maxWidth: "150px"
	},
	modalSmall: {
		width: "300px"
	},
	modalSmallBody: {
		paddingTop: "0"
	},
	modalSmallFooterFirstButton: {
		margin: "0",
		paddingLeft: "16px",
		paddingRight: "16px",
		width: "auto"
	},
	modalSmallFooterSecondButton: {
		marginBottom: "0",
		marginLeft: "5px"
	}
});

export default modalStyle;
