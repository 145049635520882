import { take, put, fork, all, actionChannel, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/users/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import { callApi } from 'utils/apiSaga';
import * as actions from 'domains/users/actions';
import { getUserNameNoSuffix } from 'domains/core/helpers';
import { User } from 'domains/core/models';
import { StaffingPlacementScheduleDto, UpdateStaffingPlacementScheduleRequest, UpdateUserRequest, UsersSearchRequest } from 'domains/users/models';
import { errorToast, successToast } from 'domains/core/components/Toaster';
import { handleLoadCompanyCall } from "domains/companies/sagas";
import { SagaIterator } from 'redux-saga';

export function* watchForLoadCompanyUsers(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANY_USERS);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleLoadCompanyUsers, request.payload);
	}
}

export function* handleLoadCompanyUsers(request: UsersSearchRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadCompanyUsers(request));
		yield put({ type: actionTypes.LOAD_COMPANY_USERS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_COMPANY_USERS_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForLoadCompanyUserDetail(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANY_USER_DETAIL);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadCompanyUserDetail, action.payload.companyId, action.payload.userPrincipalName);
	}
}

export function* handleLoadCompanyUserDetail(companyId: number, userPrincipalName: string): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadCompanyUserDetail(companyId, userPrincipalName));
		yield put({ type: actionTypes.LOAD_COMPANY_USER_DETAIL_SUCCESS, result });
	} catch (error) {
		// TODO: Add Toaster messaging for errors
		yield put({ type: actionTypes.LOAD_COMPANY_USER_DETAIL_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForSaveCompanyUser(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.SAVE_USER);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleSaveCompanyUser, action.payload);
	}
}

export function* handleSaveCompanyUser(user: User): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const response = yield callApi(actions.saveUser(user));
		yield put({ type: actionTypes.SAVE_USER_SUCCESS, response });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: `/users/companies/${user.companyId}/users/${getUserNameNoSuffix(response.azureAdUser.userPrincipalName)}` } });
	} catch (error) {
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: actionTypes.SAVE_USER_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the user.');
	}
}

export function* watchForActivateCompanyUser(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.ACTIVATE_COMPANY_USER);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleActivateCompanyUser, request.payload);
	}
}

export function* handleActivateCompanyUser(request: UpdateUserRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.activateCompanyUser(request));
		yield put({ type: actionTypes.ACTIVATE_COMPANY_USER_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.ACTIVATE_COMPANY_USER_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error activating the user.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForDeactivateCompanyUser(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.DEACTIVATE_COMPANY_USER);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleDeactivateCompanyUser, request.payload);
	}
}

export function* handleDeactivateCompanyUser(request: UpdateUserRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.deactivateCompanyUser(request));
		yield put({ type: actionTypes.DEACTIVATE_COMPANY_USER_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_COMPANY_USER_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error deactivating the user.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* handleLoadUser(userPrincipalName: string): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadUser(userPrincipalName));
		yield put({ type: actionTypes.LOAD_USER_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_USER_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForLoadUser(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_USER);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadUser, action.payload);
	}
}

export function* watchForLoadMarkets(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_METRIRWEB_MARKETS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadMarkets);
	}
}

export function* handleLoadMarkets(): SagaIterator {
	try {
		const result = yield callApi(actions.loadMarkets());
		yield put({ type: actionTypes.LOAD_METRIXWEB_MARKETS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_METRIXWEB_MARKETS_FAIL, error });
	}
}

export function* watchForLoadPrograms(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_METRIXWEB_PROGRAMS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadPrograms);
	}
}

export function* handleLoadPrograms(): SagaIterator {
	try {
		const result = yield callApi(actions.loadPrograms());
		yield put({ type: actionTypes.LOAD_METRIXWEB_PROGRAMS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_METRIXWEB_PROGRAMS_FAIL, error });
	}
}

export function* watchForLoadRoles(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_METRIXWEB_ROLES);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadRoles);
	}
}

export function* handleLoadRoles(): SagaIterator {
	try {
		const result = yield callApi(actions.loadAllMetrixRoles());
		yield put({ type: actionTypes.LOAD_METRIXWEB_ROLES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_METRIXWEB_ROLES_FAIL, error });
	}
}

export function* watchForLoadManagers(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_METRIXWEB_MANAGERS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadManagers);
	}
}

export function* handleLoadManagers(): SagaIterator {
	try {
		const result = yield callApi(actions.loadAllMetrixManagers());
		yield put({ type: actionTypes.LOAD_METRIXWEB_MANAGERS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_METRIXWEB_MANAGERS_FAIL, error });
	}
}

export function* watchForResetPin(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.RESET_PIN);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleResetPin, result.payload);
	}
}

export function* handleResetPin(userPrincipal: string): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.resetPin(userPrincipal));
		yield put({ type: actionTypes.RESET_PIN_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.RESET_PIN_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForLoadUserAndDependencies(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_USER_AND_DEPENDENCIES);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleLoadUserAndDependencies, result.payload.companyId, result.payload.userPrincipal);
	}
}

export function* handleLoadUserAndDependencies(companyId: number, userPrincipal?: string): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		yield call(handleLoadCompanyCall, companyId);
		yield call(handleLoadMarkets);
		yield call(handleLoadPrograms);
		yield call(handleLoadManagers);
		yield call(handleLoadRoles);

		if (userPrincipal) {
			yield call(handleLoadUser, userPrincipal);
		} else {
			yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		}

		yield put({ type: actionTypes.LOAD_USER_AND_DEPENDENCIES_SUCCESS });
	} catch (err) {
		yield put({ type: actionTypes.LOAD_USER_AND_DEPENDENCIES_FAIL });
	}
}

export function* watchForLoadUserFilterDependencies(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_USER_FILTER_DEPENDENCIES);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadUserFilterDependencies);
	}
}

export function* handleLoadUserFilterDependencies() {
	try {
		// Load CEA Roles
		yield call(handleLoadMarkets);
		yield call(handleLoadPrograms);
		yield call(handleLoadRoles);

		yield put({ type: actionTypes.LOAD_USER_FILTER_DEPENDENCIES_SUCCESS });
	} catch (err) {
		yield put({ type: actionTypes.LOAD_USER_FILTER_DEPENDENCIES_FAIL });
	}
}

export function* watchForExportUserList(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.EXPORT_USER_LIST);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleExportUserList, result.payload.request);
	}
}

export function* handleExportUserList(request: UsersSearchRequest): SagaIterator {
	try {
		const result = yield callApi(actions.exportUserList(request));
		yield put({ type: actionTypes.EXPORT_USER_LIST_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.EXPORT_USER_LIST_FAIL, error });
	}
}

export function* watchForLoadDefaultStaffingPlacementSchedule(): SagaIterator {
	const requestChannel = yield actionChannel(actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE);

	while (true) {
		yield take(requestChannel);
		yield call(handleLoadDefaultStaffingPlacementSchedule);
	}
}

export function* handleLoadDefaultStaffingPlacementSchedule(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result: StaffingPlacementScheduleDto = yield callApi(actions.getDefaultStaffingPlacementSchedule());
		yield put({ type: actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE_SUCCESS, result });
	} catch (error) {
		errorToast('Something went wrong loading the default staffing placement schedule');
		yield put({ type: actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForUpdateDefaultStaffingPlacementSchedule(): SagaIterator {
	const requestChannel = yield actionChannel(actionTypes.UPDATE_STAFFING_PLACEMENT_SCHEDULE);

	while (true) {
		const request = yield take(requestChannel);
		yield call(handleUpdateDefaultStaffingPlacementSchedule, request.payload);
	}
}

export function* handleUpdateDefaultStaffingPlacementSchedule(request: UpdateStaffingPlacementScheduleRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result: StaffingPlacementScheduleDto = yield callApi(actions.updateDefaultStaffingPlacementSchedule(request));
		successToast('Schedule updated');
		yield put({ type: actionTypes.UPDATE_STAFFING_PLACEMENT_SCHEDULE_SUCCESS, result });
	} catch (error) {
		errorToast(error.status === 400 ? error.message : 'Something went wrong updating the default staffing placement schedule');
		yield put({ type: actionTypes.UPDATE_STAFFING_PLACEMENT_SCHEDULE_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForScheduleOnDemandStaffingPlacement(): SagaIterator {
	const requestChannel = yield actionChannel(actionTypes.SCHEDULE_ON_DEMAND_STAFFING_PLACEMENT);

	while (true) {
		yield take(requestChannel);
		yield call(handleScheduleOnDemandStaffingPlacement);
	}
}

export function* handleScheduleOnDemandStaffingPlacement(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		yield callApi(actions.scheduleOnDemandStaffingPlacement());
		successToast('On Demand Run Scheduled');
	} catch (error) {
		errorToast(error.status === 400 ? error.message : 'Something went wrong creating an on demand run');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export default function* watchForUsersSagas() {
	yield all([
		fork(watchForLoadCompanyUsers),
		fork(watchForLoadCompanyUserDetail),
		fork(watchForActivateCompanyUser),
		fork(watchForDeactivateCompanyUser),
		fork(watchForSaveCompanyUser),
		fork(watchForLoadUser),
		fork(watchForLoadMarkets),
		fork(watchForLoadPrograms),
		fork(watchForLoadRoles),
		fork(watchForLoadManagers),
		fork(watchForResetPin),
		fork(watchForLoadUserAndDependencies),
		fork(watchForLoadUserFilterDependencies),
		fork(watchForExportUserList),
		fork(watchForLoadDefaultStaffingPlacementSchedule),
		fork(watchForUpdateDefaultStaffingPlacementSchedule),
		fork(watchForScheduleOnDemandStaffingPlacement),
	]);
}
