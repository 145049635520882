import React from 'react';
import { Toast, ToastInfo, ToastType } from 'domains/core/models';
import { Snackbar } from 'components/Snackbar';
import { WithStyles, withStyles } from '@material-ui/core/styles';

const style = {
	snackBar: {
		'& > div': {
			minWidth: 344
		}
	}
};

interface ComponentState {
	open: boolean;
	toast: Toast | undefined;
}

interface OwnProps {
	onClose?: () => void;
}

export type Props = OwnProps & WithStyles;
export class Toaster extends React.Component<Props, ComponentState> {
	private static toastQueue: Toast[] = [];
	private static toastEvent = new CustomEvent('makeToast');

	public constructor(props: Props) {
		super(props);
		this.state = {
			open: false,
			toast: undefined
		};
	}

	public componentDidMount() {
		window.addEventListener('makeToast', this.makeToast);
	}

	public componentWillUnmount() {
		window.removeEventListener('makeToast', this.makeToast);
	}

	private makeToast = () => {
		const { open } = this.state;
		if (!open) {
			const newToast = Toaster.toastQueue.shift();
			if (newToast) {
				this.setState({
					open: true,
					toast: newToast
				});
			}
		} else {
			this.setState({ open: false });
			setTimeout(this.makeToast, 300);
		}
	}

	public static success = (message: string, info: ToastInfo = {}) => {
		Toaster.queueToast(message, info, ToastType.Success);
	}

	public static error = (message: string, info: ToastInfo = {}) => {
		Toaster.queueToast(message, info, ToastType.Error);
	}

	private static queueToast = (message: string, info: ToastInfo, type: ToastType) => {
		const toast: Toast = { message, info, type };
		Toaster.toastQueue.push(toast);
		Toaster.loadToaster();
	}

	private static loadToaster = () => {
		window.dispatchEvent(Toaster.toastEvent);
	}

	private handleClose = () => {
		const { onClose } = this.props;
		if (onClose) { onClose(); }
		this.setState({ open: false });
	}

	public render() {
		const { classes } = this.props;
		const { open, toast } = this.state;

		if (!toast) {
			return null;
		}

		return (
			<div className={classes.snackBar}>
				<Snackbar
					place="bl"
					color={toast.type === ToastType.Error ? 'danger' : 'primary'}
					message={toast.message}
					open={open}
					close
					closeNotification={this.handleClose}
				/>
			</div>
		);
	}
}

const successToast = Toaster.success;
const errorToast = Toaster.error;

export { successToast, errorToast };
export default withStyles(style)(Toaster);
