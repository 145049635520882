import { User, SearchResponse } from 'domains/core/models';
import { Role } from 'domains/core/models';

export interface Company {
	id: number;
	name: string;
	description?: string;
	addressLine1?: string;
	addressLine2?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	active: boolean;
	rowVersion?: string;
	users: User[];
	externalSystems: ExternalSystem[];
	companyRoles: Role[];
}

export interface ExternalSystem {
	id: number;
	name: string;
	description: string;
	active: boolean;
}

export interface CompanyDetail {
	company: Company;
	activeUserCount: number;
	inactiveUserCount: number;
}

export interface CompaniesSearchResponse extends SearchResponse {
	companies: Company[];
}

export interface UpdateCompanyActiveStatusRequest {
	companyId: number;
	rowVersion?: string;
}

export enum Companies {
	GmrMarketing = 1,
}

export interface CompanySearchFormValues {
	filter?: string;
}
