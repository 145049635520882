import React, { useCallback, useEffect, useMemo } from 'react';
import { Table } from 'components/Table';
import { useHistory } from 'react-router-dom';
import { getCompanies } from 'domains/companies/selectors';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles';

const style = {
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer'
	},
	tablePadding: {
		paddingLeft: '30px'
	}
};

export interface Props {
	activeCompanies?: boolean;
	active: boolean;
	setActive: (value: boolean) => void;
}


export default function Companies(props: Props) {
	const { active, setActive } = props;
	const history = useHistory();
	const classes = useStyles(style);

	useEffect(() => {
		setActive(active);
	}, [active, setActive]);
	
	const companies = useSelector(state => getCompanies(state));

	const routeToCompanyDetails = useCallback((id: number) => () => {
		history.push(`/users/companies/${id}`);
	}, [history]);

	const tableData = useMemo(() => companies
		? companies.filter(c => c.active === active).map(c => [<span key={c.id} className={classes.rowButton} onClick={routeToCompanyDetails(c.id)}><p className={classes.tablePadding}>{c.name}</p></span>])
		: [], [active, companies, classes, routeToCompanyDetails]);

	return (
		<Table striped tableHead={[<h6 key={1} className={classes.tablePadding}>Company Name</h6>]} tableData={tableData} />
	);
}

