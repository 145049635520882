import React, { ComponentClass } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import configureStore from 'createStore';
import rootSaga from 'rootSaga';

import 'assets/css/App.css';
import 'assets/scss/material-dashboard-pro-react.scss';
import 'assets/scss/material-dashboard-pro-react.css';

import App from 'domains/core/containers/App';

const store = configureStore();
(store as any).runSaga(rootSaga);


const render = (Component: ComponentClass) => {
	const AppBundle = () => (
		<Provider store={store}>
			{<Component />}
		</Provider>
	);
	ReactDOM.render(<AppBundle />, document.getElementById('root'))
};

render(App);

if ((module as any).hot) {
	(module as any).hot.accept('domains/core/containers/App', () => {
		const NextApp = require('domains/core/containers/App').default;
		render(NextApp);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
