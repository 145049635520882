import React from 'react';
import { AddCircle } from '@material-ui/icons';

import { withStyles, WithStyles } from '@material-ui/core/styles';

const style = {
	customIconStyle: {
		fontSize: '16px'
	},
};

export type CustomProps = {
	disabled?: boolean;
};

export type Props = CustomProps & WithStyles;

export const AddIcon = (props: Props) => <AddCircle color={!props.disabled ? 'primary' : 'disabled'} className={props.classes.customIconStyle} />;

export default withStyles(style)(AddIcon);
