import { combineReducers } from 'redux';
import entityReducer from 'utils/entityReducer';
import coreReducer from 'domains/core/reducer';
import companiesReducer from 'domains/companies/reducer';
import devicesReducer from 'domains/devices/reducer';
import usersReducer from 'domains/users/reducer';
import userAuditsReducer from 'domains/audits/reducer';
import rolesReducer from 'domains/roles/reducer';
import discountReducer from 'domains/discounts/reducer';
import executionTagReducer from 'domains/executionTags/reducer';
import secretShopperReducer from 'domains/secretShopper/reducer';

export default combineReducers({
	entities: entityReducer,
	domains: combineReducers({
		core: coreReducer,
		companies: companiesReducer,
		devices: devicesReducer,
		users: usersReducer,
		audits: userAuditsReducer,
		roles: rolesReducer,
		discounts: discountReducer,
		executionTags: executionTagReducer,
		secretShoppers: secretShopperReducer
	})
});
