import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import orderBy from 'lodash/orderBy';

import { GridContainer, GridItem } from 'components/Grid';
import { Table } from 'components/Table';
import { DeviceTerminalMapping } from 'domains/devices/models';
import { doLoadDeviceTerminalSummary } from 'domains/devices/actions';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';

interface Props {
	filter: string | undefined;
	onRecordCountUpdated: (value: number) => void;
}

const style = {
	...headerTableStyle,
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer'
	},
	tablePadding: {
		paddingLeft: '30px'
	},
};

export default function TerminalMappingTable(props: Props) {
	const { filter, onRecordCountUpdated } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles(style);

	const [sortProperty, setSortProperty] = useState<keyof DeviceTerminalMapping>();
	const [sortIsAscending, setSortIsAscending] = useState(true);

	const deviceTerminalMappings = useSelector(state => state.domains.devices.deviceTerminalMappings);
	const currentPage = useSelector(state => state.domains.core.pagination.currentPage);
	const recordsPerPage = useSelector(state => state.domains.core.pagination.recordsPerPage);

	const getFilteredItems = useCallback((allMappings: DeviceTerminalMapping[], filter: string | undefined) => {
		const result = filter ?
			allMappings.filter(x => x.deviceName.toLowerCase().includes(filter.toLowerCase()) 
				|| x.terminalName.toLowerCase().includes(filter.toLowerCase())
				|| x.terminalAssetTag?.toLowerCase()?.includes(filter.toLowerCase())) :
			allMappings;

		return result;
	}, []);

	useEffect(() => {
		dispatch(doLoadDeviceTerminalSummary());
	}, [dispatch]);

	useEffect(() => {

		const recordCount = getFilteredItems(deviceTerminalMappings, filter)?.length ?? 0;
		onRecordCountUpdated(recordCount);
	}, [deviceTerminalMappings, filter, getFilteredItems, onRecordCountUpdated]);

	const getColumn = useCallback((key: number, value: any, id: number) => {
		return (
			<span key={key} className={classes.rowButton} onClick={() => history.push(`/devices/terminalMapping/view/${id}`)}><p className={classes.tablePadding}>{value}</p></span>
		)
	}, [classes, history]);

	const setSortPropertyValue = useCallback((property: keyof DeviceTerminalMapping) => () => {
		setSortIsAscending(sortProperty === property ? !sortIsAscending : true);
		setSortProperty(property);
	}, [sortIsAscending, sortProperty]);

	const decorateSortHeader = (property: keyof DeviceTerminalMapping) => {
		if (sortProperty !== property) {
			return null;
		}

		return sortIsAscending ? classes.sortAsc : classes.sortDesc;
	}

	const getDisplayItems = useCallback((allMappings: DeviceTerminalMapping[], filter: string | undefined, sortProperty: keyof DeviceTerminalMapping | undefined, sortIsAscending: boolean) => {
		let result = getFilteredItems(allMappings, filter);

		if (sortProperty) {
			const sortDirection = sortIsAscending ? 'asc' : 'desc';
			result = orderBy(result, [sortProperty], [sortDirection]);
		}
		return result;
	}, [getFilteredItems]);

	const displayItems = getDisplayItems(deviceTerminalMappings, filter, sortProperty, sortIsAscending)?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

	const tableData = displayItems?.length > 0 ?
		displayItems.map(x => [
			getColumn(1, x.deviceName, x.id),
			getColumn(2, x.osName, x.id),
			getColumn(3, x.terminalName, x.id),
			getColumn(4, x.terminalAssetTag || '', x.id),
			getColumn(5, moment(x.modifiedDateTime).format('L'), x.id),
			getColumn(6, x.modifiedUserName, x.id)
		])
		: [[
			<span key={1} />,
			<span key={2} />,
			<span key={3}><p className={classes.tablePadding}>No Records Found</p></span>,
			<span key={4} />,
			<span key={5} />,
			<span key={6} />
		]];

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Table
					striped
					tableData={tableData}
					tableHead={[
						<h6 key={1} onClick={setSortPropertyValue('deviceName')} className={`${classes.tablePadding} ${decorateSortHeader('deviceName')}`}>Tablet Asset Tag</h6>,
						<h6 key={2} className={classes.tablePadding}>OS</h6>,
						<h6 key={3} onClick={setSortPropertyValue('terminalName')} className={`${classes.tablePadding} ${decorateSortHeader('terminalName')}`}>Terminal</h6>,
						<h6 key={4} onClick={setSortPropertyValue('terminalAssetTag')} className={`${classes.tablePadding} ${decorateSortHeader('terminalAssetTag')}`}>Terminal Asset Tag</h6>,
						<h6 key={5} className={classes.tablePadding}>Last Edited</h6>,
						<h6 key={6} className={classes.tablePadding}>Last Edited By</h6>
					]}
				/>
			</GridItem>
		</GridContainer>
	)
}
