import { takeEvery, fork, all, put } from 'redux-saga/effects';
import history from 'adminHistory';
import * as actionTypes from 'domains/roles/actionTypes';
import { APP_LOADING, APP_LOADING_COMPLETE } from 'domains/core/actionTypes';
import * as actions from 'domains/roles/actions';
import { callApi } from 'utils/apiSaga';
import { Action } from 'applicationTypes';
import { Role, RoleExportRequest, LoadUserActionsRequest, UpdateRoleActionsRequest } from 'domains/roles/models';
import { errorToast } from 'domains/core/components/Toaster';
import { SourceSystemDictionary } from 'domains/core/models';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadRoles(): SagaIterator {
	yield takeEvery(actionTypes.LOAD_ROLES, handleLoadRoles);
}

export function* handleLoadRoles(): SagaIterator {
	try {
		yield put({ type: APP_LOADING });
		const result = yield callApi(actions.loadRoles());
		yield put({ type: actionTypes.LOAD_ROLES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_ROLES_FAIL, error });
	}
	yield put({ type: APP_LOADING_COMPLETE });
}

export function* watchForExportRoleActions(): SagaIterator {
	yield takeEvery(actionTypes.EXPORT_ROLE, handleExportRoleActions);
}

export function* handleExportRoleActions(action: Action): SagaIterator {
	try {
		yield put({ type: APP_LOADING });
		const request: RoleExportRequest = action.payload.request;
		const role: Role = action.payload.role;
		const filename = `${SourceSystemDictionary[role.system]} ${role.name} Actions.csv`;
		const result = yield callApi(actions.exportRoleActions(request, filename));
		yield put({ type: actionTypes.EXPORT_ROLE_SUCCESS, result });
	} catch (error) {
		errorToast('Export failed.');
		yield put({ type: actionTypes.EXPORT_ROLE_FAIL, error });
	}
	yield put({ type: APP_LOADING_COMPLETE });
}

export function* watchForLoadUserActions(): SagaIterator {
	yield takeEvery(actionTypes.LOAD_USER_ACTIONS, handleLoadUserActions);
}

export function* handleLoadUserActions(action: Action): SagaIterator {
	const request: LoadUserActionsRequest = action.payload;
	try {
		yield put({ type: APP_LOADING });
		const result = yield callApi(actions.loadUserActions(request));
		yield put({ type: actionTypes.LOAD_USER_ACTIONS_SUCCESS, result });
	} catch (error) {
		errorToast(`Unable to load all user actions for ${SourceSystemDictionary[request.system]}`);
		yield put({ type: actionTypes.LOAD_USER_ACTIONS_FAIL, error });
	}
	yield put({ type: APP_LOADING_COMPLETE });
}

export function* watchForUpdateRoleUserActions(): SagaIterator {
	yield takeEvery(actionTypes.UPDATE_ROLE_USER_ACTIONS, handleUpdateRoleUserActions);
}

export function* handleUpdateRoleUserActions(action: Action): SagaIterator {
	const request: UpdateRoleActionsRequest = action.payload;
	try {
		yield put({ type: APP_LOADING });
		const result = yield callApi(actions.updateRoleActions(request));
		yield put({ type: actionTypes.UPDATE_ROLE_USER_ACTIONS_SUCCESS, result });
		yield put({ type: APP_LOADING_COMPLETE });
		history.push(history.location.pathname.replace('/edit', ''));
	} catch (error) {
		errorToast(`Failed to update the user actions for this role.`);
		yield put({ type: actionTypes.UPDATE_ROLE_USER_ACTIONS_FAIL, error });
		yield put({ type: APP_LOADING_COMPLETE });
	}
}

export default function* watchForRoleSagas() {
	yield all([
		fork(watchForLoadRoles),
		fork(watchForExportRoleActions),
		fork(watchForLoadUserActions),
		fork(watchForUpdateRoleUserActions),
	]);
}
