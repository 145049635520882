import React, { useCallback, useEffect, useMemo, useState } from 'react';
import intersection from 'lodash/intersection';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader } from 'components/Card';
import { CustomTabs } from 'components/CustomTabs';
import { GridContainer, GridItem } from 'components/Grid';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { UserRoles } from 'domains/roles/components';
import { getCeaRoles, getCeaActions } from 'domains/roles/selectors';
import { doLoadRoles } from 'domains/roles/actions';
import { Role } from 'domains/roles/models';
import { SourceSystemDictionary, SourceSystem } from 'domains/core/models';
import SearchTextField from 'domains/core/components/SearchTextField';
import SearchSelectField from 'domains/core/components/SearchSelectField';
import SearchAutosuggestField from 'domains/core/components/SearchAutosuggestField';
import { useStyles } from 'styles';

const style = {
	title: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	}
};


const availableSourceSystemValues = Object.keys(SourceSystemDictionary).map(k => ({ key: k, value: SourceSystemDictionary[k] }));

export default function UserRoleList() {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const roles = useSelector(state => getCeaRoles(state));
	const userActions = useSelector(state => getCeaActions(state));

	const [nameFilter, changeNameFilter] = useState<string | undefined>();
	const [systemFilter, changeSystemFilter] = useState(availableSourceSystemValues.map(s => s.value));
	const [hasActionFilter, changeHasActionFilter] = useState<string | undefined>();

	const filterActionsBySystem = useCallback((systems: SourceSystem[], systemFilter: string[]) => {
		return intersection(systems.map(x => SourceSystemDictionary[x]), systemFilter).length > 0;
	}, []);

	const availableUserActionValues = useMemo(() => userActions
		? userActions
			.filter(u => !systemFilter || systemFilter.length === availableSourceSystemValues.length || filterActionsBySystem(u.systems, systemFilter))
			.map(u => u.name)
		: [], [filterActionsBySystem, systemFilter, userActions]);

	useEffect(() => {
		dispatch(doLoadRoles());
	}, [dispatch]);

	const filterRoles = useCallback((role: Role) => {
		return (!nameFilter || nameFilter === '' || nameFilter.trim() === '' || role.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1)
			&& (!systemFilter || systemFilter.length === availableSourceSystemValues.length || systemFilter.indexOf(SourceSystemDictionary[role.system]) > -1)
			&& (!hasActionFilter || hasActionFilter === '' || hasActionFilter.trim() === '' || role.roleActions.some(a => hasActionFilter.toLowerCase() === a.action.name.toLowerCase()));
	}, [hasActionFilter, nameFilter, systemFilter]);

	const filteredRoles = useMemo(() => roles && roles.length ? roles.filter(filterRoles) : [], [filterRoles, roles]);

	const activeRoles = useMemo(() => filteredRoles && filteredRoles.length ? filteredRoles.filter(r => r.active) : [], [filteredRoles]);
	const inactiveRoles = useMemo(() => filteredRoles && filteredRoles.length ? filteredRoles.filter(r => !r.active) : [], [filteredRoles]);

	const resetNameFilter = () => {
		changeNameFilter(undefined);
	}

	const tabs = useMemo(() => [
		{
			tabName: 'ACTIVE',
			tabContent: (
				<GridContainer>
					<GridItem xs={12}>
						<UserRoles roles={activeRoles} />
					</GridItem>
				</GridContainer>
			)
		},
		{
			tabName: 'INACTIVE',
			tabContent: (
				<GridContainer>
					<GridItem xs={12}>
						<UserRoles roles={inactiveRoles} />
					</GridItem>
				</GridContainer>
			)
		}
	], [activeRoles, inactiveRoles]);

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.title}>User Roles</h2>
				<GridContainer>
					<GridItem lg={7} md={12}>
						<GridContainer alignItems="flex-end">
							<GridItem lg={5} md={4} sm={12}>
								<SearchTextField id="SearchNameTextField" filterValue={nameFilter} change={changeNameFilter} clearFilter={resetNameFilter} />
							</GridItem>
							<GridItem lg={3} md={4} sm={12}>
								<SearchSelectField id="systemFilterSearchSelectField" multiple label="System(s)" value={systemFilter} change={changeSystemFilter} availableValues={availableSourceSystemValues} />
							</GridItem>
							<GridItem lg={4} md={4} sm={12}>
								<SearchAutosuggestField id="hasActionSearchAutosuggestField" allowClear value={hasActionFilter} placeholder="Has Action" onChange={changeHasActionFilter} lookupData={availableUserActionValues} />
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<CustomTabs plainTabs headerColor="info" tabs={tabs} />
		</Card>
	);
}
