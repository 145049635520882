import * as normalizers from 'utils/fieldNormalizers';
import moment from 'moment';

export const currency = (value: any) => {
	if (!value) {
		return '';
	}
	const strippedValue = normalizers.getOnlyDecimal(value.toString());
	const num = Number(strippedValue);
	if (!num && num !== 0) {
		return '';
	}
	return displayPrice(num);
};

export const currencyWholeDollars = (value: any) => {
	if (!value) {
		displayPriceWholeDollar(0);
	}
	//const strippedValue = normalizers.getOnlyDecimal(value.toString());
	const num = Number(value);
	if (!num && num !== 0) {
		return '';
	}
	return displayPriceWholeDollar(num);
};


export const percentage = (value: any) => {
	if (!value) {
		return '';
	}

	return value + '%';
};

export const currencyZero = (value: any) => {
	if (!value) {
		return displayPrice(0);
	}

	const strippedValue = normalizers.getOnlyDecimal(value.toString());
	const num = Number(strippedValue);

	if (!num && num !== 0) {
		return '$0.00';
	}
	if (num === 0) {
		return '$0.00';
	}
	return displayPrice(num);
};

export const displayPriceWholeDollar = (price?: number) => {
	if (!price && price !== 0) {
		return `$${(0).toFixed(0)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	if (price < 0) {
		return `-$${(price * -1).toFixed(0)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return `$${price.toFixed(0)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const displayPrice = (price?: number) => {
	if (!price && price !== 0) {
		return `$${(0).toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	if (price < 0) {
		return `-$${(price * -1).toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return `$${price.toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const date = (value: any) => {
	if (!value) {
		return '';
	}
	if (moment.isMoment(value)) {
		return value.format('MM/DD/YYYY');
	}

	const dateString = value.toString();
	if (dateString.length >= 10) {
		if (moment(dateString, 'YYYY-MM-DD').isValid()) {
			return moment(dateString).format('MM/DD/YYYY');
		}
	}

	const onlyNums = normalizers.getOnlyDecimal(dateString);
	if (onlyNums.length <= 2) {
		return onlyNums;
	}
	if (onlyNums.length <= 4) {
		return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
	}
	return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
};
