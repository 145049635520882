import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

interface Props {
	children?: any;
}

export default function AppLoading(props: Props) {
	const { children } = props;
	const show = useSelector(state => state.domains.core.appLoading);

	return (
		<div>
			{show &&
				<header className="App-loading">
					<CircularProgress />
				</header>
			}
			{children}
		</div>
	);
}
