import { schema } from 'normalizr';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import * as actionTypes from 'domains/audits/actionTypes';
import * as userAuditSchemas from 'domains/audits/schemas';
import { UserAuditSearchRequest } from 'domains/audits/models';

export const doLoadAuditFilters = (): Action => {
	return {
		type: actionTypes.LOAD_AUDIT_FILTER,
	};
};

export const loadFilters = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/UserAudit/Filters');
		}
	};
};

export const doSearchUserAudits = (request: UserAuditSearchRequest): Action => {
	return {
		resetEntity: 'userAudits',
		type: actionTypes.SEARCH_USER_AUDITS,
		payload: request,
	};
};

export const searchUserAudits = (request: UserAuditSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/UserAudit/search', {
				schema: {
					userAudits: new schema.Array(userAuditSchemas.userAuditSchema)
				},
				data: request,
			});
		}
	};
};

export const doExportUserAudits = (request: UserAuditSearchRequest): Action => {
	return {
		type: actionTypes.EXPORT_USER_AUDITS,
		payload: request,
	};
};


export const exportUserAudits = (request: UserAuditSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post(`/admin/UserAudit/export`, {
				data: request
			});
		}
	};
}
