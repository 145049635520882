import React from 'react';
import { CustomInput, CustomInputProps } from 'components/CustomInput';
import { FieldRenderProps } from 'react-final-form';

type Props = (FieldRenderProps<any>) & CustomInputProps;

export const FormInput = (props: Props) => {
	const { input, inputProps, meta: { error, touched }, ...rest } = props;
	const customInputProps = {
		...input,
		...inputProps
	};

	return (
		<CustomInput
			inputProps={customInputProps}
			error={touched ? !!error : false}
			helpText={touched ? error : ''}
			{...rest}
		/>
	);
};

export default FormInput;
