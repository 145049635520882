import React, { useCallback, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { getIsLoadingUsers } from 'domains/users/selectors';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Search, Clear } from '@material-ui/icons';
import { UserFilter } from 'domains/core/models';

import * as validators from 'utils/fieldValidators';
import { Field, FormRenderProps } from 'react-final-form';
import { useStyles } from 'styles';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';
import { useSelector } from 'react-redux';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' }
	};
};

export default function UserSearchForm(props: FormRenderProps<UserFilter>) {
	const { handleSubmit, pristine, invalid, values, form: { change } } = props;
	const classes = useStyles(style);

	const isLoadingUsers = useSelector(state => getIsLoadingUsers(state));
	const hasValue = useMemo(() => values?.username && values.username !== '', [values?.username]);

	const resetForm = useCallback(() => {
		change('username', undefined);
		handleSubmit();
	}, [change, handleSubmit]);

	const buttonDisabled = useMemo(() => isLoadingUsers || pristine || invalid, [invalid, isLoadingUsers, pristine]);
	const fieldDisabled = useMemo(() => isLoadingUsers, [isLoadingUsers]);

	return (

		<form onSubmit={handleSubmit}>
			<Field
				className={classes.noPadding}
				name="username"
				id="filterInput"
				component={CustomInputAdapter}
				formControlProps={{ style: { paddingTop: '14px' } }}
				validate={validators.minLength1}
				inputProps={{
					placeholder: 'Search',
					inputProps: {
						'aria-label': 'Search'
					},
					disabled: fieldDisabled,
					endAdornment:
						<InputAdornment position="end">
							{hasValue ?
								<IconButton onClick={resetForm} disabled={buttonDisabled}>
									<Clear />
								</IconButton> :
								<IconButton type="submit" disabled={buttonDisabled}>
									<Search />
								</IconButton>
							}
						</InputAdornment>
				}}
			/>
		</form>
	);
}
