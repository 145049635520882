import { createSelector } from 'reselect';
import { values } from 'lodash';
import { ApplicationState } from 'applicationTypes';
import { State as CoreState } from 'domains/core/reducer';
import { User } from './models';

export const getCoreState = (state: ApplicationState) => state.domains.core;
const getUsers = (state: ApplicationState) => state.entities.users;

export const isAuthenticating = createSelector(
	[getCoreState],
	(state: CoreState) => {
		return state.authenticating;
	}
);

export const isAuthenticated = createSelector(
	[getCoreState],
	(state: CoreState) => {
		return state.isAuthenticated;
	}
);

export const getCurrentUser = createSelector(
	[getCoreState],
	(state: CoreState) => {
		return state.user;
	}
);

export const getAllUsers = createSelector(
	[getUsers],
	(users: {[id: number]: User}) => {
		return values(users);
	}
);
