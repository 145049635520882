import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/CustomButtons';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { RadioButtonListItem } from 'domains/core/models';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import * as validators from 'utils/fieldValidators';
import { doLoadDeviceTerminalDetails, doSaveDeviceTerminalDetails } from 'domains/devices/actions';
import { MerchantTerminal } from 'domains/devices/models';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';
import RadioButtonListAdapter from 'domains/core/components/FinalForm/RadioButtonListAdapter';
import { useStyles } from 'styles';
import { Field, Form } from 'react-final-form';
import AutosuggestAdapter from 'domains/core/components/FinalForm/AutosuggestAdapter';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function EditTerminalMappingForm() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const history = useHistory();

	const { id } = useParams<{ id: string }>();

	const onlineMerchantTerminals = useSelector(state => state.domains.devices.onlineMerchantTerminals);
	const existingMapping = useSelector(state => state.domains.devices.existingDeviceTerminalMapping);

	const initialValues = useMemo(() => {
		let initialValues = {};
		if (existingMapping) {
			initialValues = {
				id: existingMapping.id,
				deviceName: existingMapping.deviceName,
				terminalName: existingMapping.terminalName,
				terminalAssetTag: existingMapping.terminalAssetTag || '',
				deviceOSType: existingMapping.deviceOSType
			};
		}
		return initialValues;
	}, [existingMapping]);

	useEffect(() => {
		dispatch(doLoadDeviceTerminalDetails(Number(id)));
	}, [dispatch, id]);

	const radioButtons: RadioButtonListItem[] = useMemo(() => ([{
		value: 1,
		label: 'iOS'
	}, {
		value: 2,
		label: 'Android'
	}, {
		value: 3,
		label: 'Web'
	}]), []);

	const submitForm = useCallback((values: any) => {
		dispatch(doSaveDeviceTerminalDetails(values));
	}, [dispatch]);

	const isEdit = useMemo(() => history.location.pathname.includes("terminalMapping/edit"), [history.location.pathname]);

	return (
		<GridContainer>
			<GridItem md={12} lg={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>{`${isEdit ? 'Edit' : 'Add'} Tablet`}</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<Form onSubmit={submitForm} initialValues={initialValues}>
							{({ invalid, handleSubmit, form: { change } }) => {
								return (
									<form onSubmit={handleSubmit}>
										<GridContainer>
											<GridItem xs={12} md={12} lg={12}>
												<Field
													name="deviceName"
													validate={validators.composeValidators(validators.maxLength30, validators.assetTagWebFormat)}
													component={CustomInputAdapter}
													labelText="Tablet Asset Tag"
													id="deviceName"
													formControlProps={{
														fullWidth: true,
														required: true
													}}
												/>
											</GridItem>
											<GridItem xs={12} md={12} lg={12}>
												<Field
													name="deviceOSType"
													validate={validators.required}
													component={RadioButtonListAdapter}
													labelText="OS"
													id="os"
													formControlProps={{
														fullWidth: true,
														required: true
													}}
													row
												>
													{radioButtons}
												</Field>
											</GridItem>
											<GridItem xs={12} md={12} lg={12}>
												<Field
													id="terminalName"
													validate={validators.required}
													name="terminalName"
													component={AutosuggestAdapter}
													labelText="Terminal"
													lookupData={onlineMerchantTerminals}
													change={(inputName: string, mt: MerchantTerminal) => change(inputName, mt ? mt.terminalName : null)}
													suggestionLabelFormat={(mt: MerchantTerminal) => `${mt.terminalName} (${mt.merchant})`}
													fullWidth
													displayAllSuggestions
													allowClear
												/>
											</GridItem>
											<GridItem xs={12} md={12} lg={12}>
												<Field
													name="terminalAssetTag"
													validate={validators.maxLength255}
													component={CustomInputAdapter}
													labelText="Terminal Asset Tag"
													id="terminalAssetTag"
													formControlProps={{
														fullWidth: true,
														required: true
													}}
												/>
											</GridItem>
											<GridItem xs={12} md={12} lg={12}>
												<Button color="primary" type="submit" disabled={invalid}>Save</Button>
												<Button simple color="primary" onClick={() => history.goBack()}>Cancel</Button>
											</GridItem>
										</GridContainer>
									</form>
								);
							}}
						</Form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
