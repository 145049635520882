export const LOAD_ROLES = 'roles/LOAD_ROLES';
export const LOAD_ROLES_SUCCESS = 'roles/LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_FAIL = 'roles/LOAD_ROLES_FAIL';

export const EXPORT_ROLE = 'roles/EXPORT_ROLE';
export const EXPORT_ROLE_SUCCESS = 'roles/EXPORT_ROLE_SUCCESS';
export const EXPORT_ROLE_FAIL = 'roles/EXPORT_ROLE_FAIL';

export const LOAD_USER_ACTIONS = 'roles/LOAD_USER_ACTIONS';
export const LOAD_USER_ACTIONS_SUCCESS = 'roles/LOAD_USER_ACTIONS_SUCCESS';
export const LOAD_USER_ACTIONS_FAIL = 'roles/LOAD_USER_ACTIONS_FAIL';

export const UPDATE_ROLE_USER_ACTIONS = 'roles/UPDATE_ROLE_USER_ACTIONS';
export const UPDATE_ROLE_USER_ACTIONS_SUCCESS = 'roles/UPDATE_ROLE_USER_ACTIONS_SUCCESS';
export const UPDATE_ROLE_USER_ACTIONS_FAIL = 'roles/UPDATE_ROLE_USER_ACTIONS_FAIL';
