import { schema } from 'normalizr';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import * as actionTypes from 'domains/core/actionTypes';
import * as coreSchemas from 'domains/core/schemas';
import { SourceSystem, CeaAuthenticationRequest } from 'domains/core/models';

export function doResetReroute() {
	return {
		type: actionTypes.RESET_REROUTE
	};
}

export function doLogin(username: string, password: string): Action {
	const payload: CeaAuthenticationRequest = { username, password, sourceSystem: SourceSystem.CeaAdmin };
	return {
		type: actionTypes.LOGIN,
		payload
	};
}

export function login(ceaAuthenticationRequest: CeaAuthenticationRequest): ApiAction {
	return {
		promise: (client: ApiClient) => {
			return client.post('/api/Authentication/Login', {
				data: ceaAuthenticationRequest
			});
		}
	};
}

export function doRefreshToken(): Action {
	return {
		type: actionTypes.CHECK_REFRESH_TOKEN,
	};
}

export function refreshToken(request: CeaAuthenticationRequest): ApiAction {
	return {
		promise: (client: ApiClient) => {
			return client.post('/api/Authentication/Refresh', {
				data: request,
				withCredentials: true,
				requestTimeout: 30000,
			});
		},
	};
}

export function doClearLoginError() {
	return {
		type: actionTypes.CLEAR_LOGIN_ERROR
	};
}

export function markLoggedIn(): Action {
	return {
		type: actionTypes.LOGIN_SUCCESS
	};
}

export function doLogout(): Action {
	return {
		type: actionTypes.LOGOUT
	};
}

export function getUser(): ApiAction {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/userAdmin/GetCurrentUser');
		}
	};
}

export function doLoadUsers(): Action {
	return {
		type: actionTypes.LOAD_USERS
	};
}

export function loadUsers(): ApiAction {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/userAdmin', {
				schema: new schema.Array(coreSchemas.userSchema)
			});
		}
	};
}

export function doSetCurrentPage(page: number): Action {
	return {
		type: actionTypes.PAGINATION_SET_PAGE,
		payload: page
	};
}

export function doPaginationReset(): Action {
	return { type: actionTypes.PAGINATION_RESET };
}

export function doPaginationScrollTopReset(): Action {
	return { type: actionTypes.PAGINATION_SCROLL_RESET };
}
