import { isArray, omit, keys, merge, mergeWith } from 'lodash';
import { User, Program, Market, ManagerEmployee, Discount, CeaProgram, ItemRetailType, ItemRetailCategory, ItemRetailUnitOfMeasure, ItemRetail, DiscountTimeFrameType, DiscountType, SourceSystemMarket, DiscountRetailLocation, RetailLocationLocationType, RetailLocationMruType, RetailLocationType } from 'domains/core/models';
import { Role, UserAction } from 'domains/roles/models';
import { Company, CompanyDetail } from 'domains/companies/models';
import { CeaUser } from 'domains/core/models';
import { CompanyUserDetail } from 'domains/users/models';
import { UserAudit } from 'domains/audits/models';
import { Action } from 'applicationTypes';
import { ExecutionTagDetails } from 'domains/executionTags/models';
import { SecretShopper } from 'domains/secretShopper/models';

export interface EntityState {
	core: {
		loggedOut: boolean;
		sessionExpired: boolean;
	};
	ceaUsers: {
		[id: number]: CeaUser;
	};
	users: {
		[id: number]: User;
	};
	companies: {
		[id: number]: Company;
	};
	companyDetails: {
		[id: number]: CompanyDetail;
	};
	companyUserDetails: {
		[id: number]: CompanyUserDetail;
	};
	userAudits: {
		[id: number]: UserAudit;
	};
	programs: {
		[id: number]: Program;
	};
	markets: {
		[id: number]: Market;
	};
	metrixWebRoles: {
		[id: number]: Role;
	};
	managers: {
		[id: number]: ManagerEmployee;
	};
	roles: {
		[id: string]: Role;
	};
	userActions: {
		[id: string]: UserAction;
	};
	executionTags: {
		[id: number]: ExecutionTagDetails;
	};
	discounts: {
		[id: string]: Discount;
	};
	ceaPrograms: {
		[id: number]: CeaProgram;
	};
	discountTypes: {
		[id: number]: DiscountType;
	};
	selectedDiscount: {
		[id: number]: Discount;
	};
	itemRetailTypes: {
		[id: number]: ItemRetailType;
	};
	itemRetailCategories: {
		[id: number]: ItemRetailCategory;
	};
	itemRetailUnitOfMeasure: {
		[id: number]: ItemRetailUnitOfMeasure;
	};
	itemRetails: {
		[id: number]: ItemRetail;
	};
	discountTimeFrameTypes: {
		[id: number]: DiscountTimeFrameType;
	};
	discountMarkets: {
		[id: number]: SourceSystemMarket;
	};
	discountRetailLocations: {
		[id: number]: DiscountRetailLocation;
	};
	retailLocationTypes: {
		[id: number]: RetailLocationType;
	};
	retailLocationLocationTypes: {
		[id: number]: RetailLocationLocationType;
	};
	retailLocationMruTypes: {
		[id: number]: RetailLocationMruType;
	};
	secretShoppers: {
		[id: number]: SecretShopper;
	}
}

export const initialState: EntityState = {
	core: {
		loggedOut: false,
		sessionExpired: false
	},
	ceaUsers: {},
	users: {},
	companies: {},
	companyDetails: {},
	companyUserDetails: {},
	userAudits: {},
	markets: {},
	programs: {},
	metrixWebRoles: {},
	managers: {},
	roles: {},
	userActions: {},
	executionTags: {},
	discounts: {},
	ceaPrograms: {},
	discountTypes: {},
	selectedDiscount: {},
	itemRetailTypes: {},
	itemRetailCategories: {},
	itemRetailUnitOfMeasure: {},
	itemRetails: {},
	discountTimeFrameTypes: {},
	discountMarkets: {},
	discountRetailLocations: {},
	retailLocationTypes: {},
	retailLocationLocationTypes: {},
	retailLocationMruTypes: {},
	secretShoppers: {}
};

const customizer = (objValue: any, srcValue: any) => {
	// The purpose of this is to replace an array entirely with new values to properly update the store
	// when deletion occurs. Without this, stale data is left in the store post-merge as the two arrays are just combined.
	if (isArray(objValue)) {
		return srcValue;
	}
	return undefined;
};

export default function reducer(state: EntityState = initialState, action: Action = {}) {
	const clearAllEntities = state.core && (state.core.loggedOut || state.core.sessionExpired);
	if (clearAllEntities) {
		return initialState;
	}

	if (action.result && action.result.entities && action.purgeEntity) {
		if (state[action.purgeEntity] && action.purgeEntity !== 'core') {
			const newState: EntityState = { ...state };
			const remaining = omit(newState[action.purgeEntity], keys(action.result.entities[action.purgeEntity]));
			// TODO: newState[action.purgeEntity] cannot be properly typed. Will need to find a better option for purging an entity
			(newState[action.purgeEntity] as any) = merge({}, remaining);
			return newState;
		}
	} else if (action.resetEntity) {
		if (state[action.resetEntity]) {
			const newState = { ...state };
			// TODO: newState[action.resetEntity] cannot be properly typed. Will need to find a better option for purging an entity
			(newState[action.resetEntity] as any) = {};
			return newState;
		}
	} else if (action.result && action.result.entities) {
		return mergeWith({}, state, action.result.entities, customizer);
	}
	return state;
}
