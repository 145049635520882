import React, { ReactElement, useState } from 'react';
import { debounce } from 'lodash';
import { compose } from 'recompose';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Autosuggest, { ChangeEvent, RenderSuggestionParams, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem, Paper, Input, FormControl, InputLabel, InputAdornment, InputProps as MuiInputProps, IconButton, Theme } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Clear';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

const styles = (theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		position: 'relative' as const
	},
	suggestionsContainerOpen: {
		position: 'absolute' as const,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
		zIndex: 2
	},
	suggestion: {
		display: 'block' as const
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none' as const,
		maxHeight: 200,
		overflowY: 'scroll' as const,
		zIndex: 500
	},
	divider: {
		height: theme.spacing(2),
	},
	...customInputStyle
});

interface OwnProps {
	id: string;
	lookupData: any[];
	onChange: any;
	allowClear?: boolean;
	disabled?: boolean;
	label?: string;
	value?: string;
	placeholder?: string;
}
type Props = OwnProps & WithStyles;
type InputProps = Omit<MuiInputProps, 'onChange' | 'onBlur'> & Autosuggest.InputProps<any>;

export function SearchAutosuggestField(props: Props) {
	const { id, lookupData, onChange, allowClear, disabled, label, classes, value, placeholder, ...rest } = props;
	const [localValue, setLocalValue] = useState<string>();
	const [suggestions, setSuggestions] = useState(lookupData);

	const renderSuggestionsContainer = (options: any) => {
		return (
			<Paper {...options.containerProps} square>
				{options.children}
			</Paper>
		);
	}

	const renderSuggestion = (suggestion: string, { query, isHighlighted }: RenderSuggestionParams) => {
		const matches = match(suggestion, query);
		const parts = parse(suggestion, matches);

		return (
			<MenuItem selected={isHighlighted} component="div">
				<div>
					{parts.map((part, index) => {
						const style = part.highlight ? { fontWeight: 700 } : { fontWeight: 300 };
						return part.highlight ? (
							<span key={String(index)} style={style}>
								{part.text}
							</span>
						) : (
							<strong key={String(index)} style={style}>
								{part.text}
							</strong>
						);
					})
					}
				</div>
			</MenuItem>
		);
	}

	const escapeRegexCharacters = (str: string) => {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	const getSuggestions = (value: string) => {
		const escapedValue = escapeRegexCharacters(value.trim());
		if (escapedValue === '') {
			return [];
		}

		const regex = new RegExp(escapedValue, 'i');

		return lookupData.filter(val => regex.test(val));
	}

	const getSuggestionValue = (suggestion: string) => {
		onChange(suggestion);
		setLocalValue(suggestion);
		return suggestion;
	}

	// TODO: Update prop type to match "renderInputComponent"
	const renderInputComponent = (autoSuggestInputProps: any) => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		const { inputRef = () => { }, ref, container, suggestionsContainerOpen, suggestion, suggestionsList, divider, classes, ...other } = autoSuggestInputProps;
		const customInputProps = {
			inputRef: (node: ReactElement) => {
				ref(node);
				inputRef(node);
			},
			classes: {
				input: classes.input,
			},
		};
		const clearValue = () => {
			onChange(undefined);
			setLocalValue(undefined);
		}

		const endAdornment = localValue && allowClear ? (
			<InputAdornment position="end">
				<IconButton aria-label="Clear" onClick={clearValue} disabled={disabled}>
					<RemoveIcon color="disabled" />
				</IconButton>
			</InputAdornment>
		) : undefined;

		return (
			<FormControl className={classes.formControl} fullWidth>
				{label && <InputLabel htmlFor={id} className={classes.labelRoot}>{label}</InputLabel>}
				<Input
					id={id}
					{...customInputProps}
					fullWidth
					{...other}
					{...rest}
					disabled={disabled}
					endAdornment={endAdornment}
				/>
			</FormControl>
		);
	}

	const handleChange = () => (_event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
		setLocalValue(newValue);
	};

	const openSuggestions = () => {
		if (value === '') {
			setSuggestions(lookupData);
		}
	}

	const shouldRenderSuggestions = (value: string) => {
		return value.trim().length > 0;
	}

	const handleSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
		setSuggestions(getSuggestions(value));
	};

	const debounceHandleSuggestionsFetchRequested = debounce(handleSuggestionsFetchRequested, 250);

	const handleSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const autosuggestProps = {
		renderInputComponent,
		suggestions: suggestions,
		onSuggestionsFetchRequested: debounceHandleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		shouldRenderSuggestions,
		getSuggestionValue,
		renderSuggestion,
	};

	const inputProps: InputProps = {
		classes,
		placeholder: placeholder,
		value: localValue || '',
		onChange: handleChange(),
		onClick: openSuggestions
	};

	return (
		<Autosuggest
			{...autosuggestProps}
			inputProps={inputProps}
			theme={{
				container: classes.container,
				suggestionsContainerOpen: classes.suggestionsContainerOpen,
				suggestionsList: classes.suggestionsList,
				suggestion: classes.suggestion,
			}}
			renderSuggestionsContainer={renderSuggestionsContainer}
		/>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(styles)
)(SearchAutosuggestField);

export default container;
