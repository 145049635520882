import React from 'react';
import { Radio, FormControlLabel } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

interface OwnProps {
	id: string;
	labelText: string;
	value?: string;
	checked?: boolean;
	handleOnChange: (event: any, checked: boolean) => void;
	onBlur?: () => void;
	disabled?: boolean;
	onDisabledClick?: () => void;
}

const style = {
	checked: {
		color: `${primaryColor} !important`
	},
	colorPrimary: {
		"&:hover": {
			color: primaryColor
		}
	}
};

type Props = OwnProps & WithStyles<keyof typeof style>;
export function RadioButtonWrapper(props: Props) {
	const { id, value, labelText, checked, handleOnChange, onBlur, disabled, onDisabledClick, classes } = props;
	const inputProps = { autoComplete: 'off' };

	const formControlStyle: React.CSSProperties = { color: 'black' };

	return (
		<FormControlLabel
			control={<Radio disabled={disabled} id={id} value={value} checked={checked} onChange={handleOnChange} onBlur={onBlur} inputProps={inputProps} classes={{ checked: classes.checked, colorPrimary: classes.colorPrimary}} color={'primary'} />}
			label={labelText}
			style={formControlStyle}
			onClick={disabled ? onDisabledClick : undefined}
		/>
	);
}

export default withStyles(style)(RadioButtonWrapper);
