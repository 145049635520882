import { schema } from 'normalizr';
import { companySchema } from 'domains/companies/schemas';

export const marketSchema = new schema.Entity('markets', {}, {
	idAttribute: 'marketId'
});

export const programSchema = new schema.Entity('programs', {
	marketIds: [marketSchema]
});

export const metrixWebRolesSchema = new schema.Entity('metrixWebRoles', {
	companies: [companySchema]
});

export const employeePrograms = new schema.Entity('employeePrograms', {}, { idAttribute: 'programId' });

export const metrixWebManagers = new schema.Entity('managers');

export const metrixUserSchema = new schema.Entity('metrixUsers', {
	employeePrograms: [employeePrograms],
	role: metrixWebRolesSchema,
	manager: metrixWebManagers
}, { idAttribute: 'username' });

export const usersSchema = new schema.Entity('users', {
	metrixUser: metrixUserSchema
});

export const ceaUsersSchema = new schema.Entity('ceaUsers');

export const companyUserDetailSchema = new schema.Entity('companyUserDetails');

companyUserDetailSchema.define({
	company: companySchema
});

companySchema.define({
	users: new schema.Array(usersSchema)
});
