import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties, ClassNameMap, CreateCSSProperties, Styles } from '@material-ui/core/styles/withStyles';

type PropsFunc<Props extends object, T> = (props: Props) => T;
export function checkSheet<T extends Record<string, CSSProperties | CreateCSSProperties<any> | PropsFunc<any, CreateCSSProperties<any>>>>(
	styles: T,
): T {
	return styles;
}

/**
 * useStyles hook. Pass in any style factory.
 * @styles custom styles to pass into makeStyles.
 * @example function MyComponent(){ const classes = useStyles(myStyles); }
 */
export function useStyles<
	SelfTheme extends Theme = Theme,
	ClassKey extends string = keyof {}
>(
	styles?: Styles<SelfTheme, {}, ClassKey>
): ClassNameMap<ClassKey> {
	const stylesFactory = React.useCallback((theme: SelfTheme) => {
		return (typeof styles === 'function' ? styles(theme) : styles) ?? {};
	}, [styles]);

	const useStylesFn = React.useMemo(() => {
		return makeStyles(stylesFactory);
	}, [stylesFactory]);

	return useStylesFn(undefined) as any;
}
