import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { CreateEditCompanyForm } from 'domains/companies/components';
import { doLoadCompanyDetail, doSaveCompany } from 'domains/companies/actions';
import { getCompany } from 'domains/companies/selectors';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

interface MatchParams {
	companyId: string;
}

export default function EditCompany() {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const companyId = useParams<MatchParams>()?.companyId;
	const company = useSelector(state => getCompany(state, Number(companyId)));

	useEffect(() => {
		if (companyId && !company) {
			dispatch(doLoadCompanyDetail(Number(companyId)));
		}
	}, [company, companyId, dispatch]);

	const initialValues = useMemo(() => {
		if (!company) {
			return undefined;
		}

		return company;
	}, [company]);

	const handleCreateCompany = useCallback((data: any) => {
		dispatch(doSaveCompany({
			active: true,
			id: data.id,
			name: data.name,
			description: data.description,
			addressLine1: data.addressLine1,
			addressLine2: data.addressLine2,
			city: data.city,
			state: data.state,
			zipCode: data.zipCode,
			rowVersion: company?.rowVersion,
			users: company?.users ?? [],
			externalSystems: company?.externalSystems ?? [],
			companyRoles: company?.companyRoles ?? [],
		}));
	}, [company, dispatch]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Company Details</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<CreateEditCompanyForm initialValues={initialValues} onSubmit={handleCreateCompany} />
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
