export const accessTokenKey = 'ceaJwt';
const accessTokenExpirationKey = 'ceaJwtExpiration';
const refreshTokenKey = 'ceaRefreshToken';

export const getCeaJwt = (): string | null | undefined => {
	return sessionStorage.getItem(accessTokenKey) || '';
};

export const setCeaJwt = (token: string) => {
	sessionStorage.setItem(accessTokenKey, token);
};

export const getCeaJwtExpiration = (): string => {
	return sessionStorage.getItem(accessTokenExpirationKey) || '';
};

export const setCeaJwtExpiration = (tokenExpiration: string): void => {
	sessionStorage.setItem(accessTokenExpirationKey, tokenExpiration);
};

export const getCeaRefreshToken = (): string => {
	return sessionStorage.getItem(refreshTokenKey) || '';
};

export const setCeaRefreshToken = (refreshToken: string): void => {
	sessionStorage.setItem(refreshTokenKey, refreshToken);
};

export const clearCeaJwt = () => {
	sessionStorage.setItem(accessTokenKey, '');
	sessionStorage.setItem(accessTokenExpirationKey, '');
	sessionStorage.setItem(refreshTokenKey, '');
};

export const formatUrl = (path: string) => {
	const endpointUrl = process.env.REACT_APP_WEBAPI_BASEURL;

	const adjustedPath = path[0] !== '/' ? `/${path}` : path;
	return endpointUrl + adjustedPath;
};
