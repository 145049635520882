import React, { useCallback, useEffect, useMemo } from 'react';
import differenceBy from 'lodash/differenceBy';
import concat from 'lodash/concat';
import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useParams } from 'react-router-dom';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doSaveUser, doLoadUserAndDependencies } from 'domains/users/actions';
import { getUserFromRoute, getMetrixWebPrograms } from 'domains/users/selectors';
import { getCompanyFromRouteOrUser } from 'domains/companies/selectors';
import { SetUserAccessForm } from 'domains/users/components';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { CreateEditUserFormValues } from 'domains/users/models';
import { SourceSystem } from 'domains/core/models';

interface MatchProps {
	userPrincipalName: string;
	companyId: string;
}


const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function EditUserAccess() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const { companyId, userPrincipalName } = useParams<MatchProps>();

	const user = useSelector(state => getUserFromRoute(state, { match: { params: { userPrincipalName } } }));
	const programs = useSelector(state => getMetrixWebPrograms(state));
	const company = useSelector(state => getCompanyFromRouteOrUser(state, { match: { params: { companyId } } }));

	useEffect(() => {
		if (userPrincipalName) {
			dispatch(doLoadUserAndDependencies(Number(companyId), userPrincipalName));
		}
	}, [companyId, dispatch, userPrincipalName]);


	const initialValues = useMemo(() => {
		if (user) {
			const adminRoles = company?.companyRoles?.filter(r => r.sourceSystemId === SourceSystem.CeaAdmin);
			const ceaRoles = company?.companyRoles?.filter(r => r.sourceSystemId === SourceSystem.Cea && !r.isAddOn);
			const ceaAddonRoles = company?.companyRoles?.filter(r => r.sourceSystemId === SourceSystem.Cea && r.isAddOn);
			const ceaDirectRoles = company?.companyRoles?.filter(r => r.sourceSystemId === SourceSystem.Cead && !r.isAddOn);
			return {
				adminRoleNames: adminRoles?.filter(r => user.ceaUser?.roleNames.some(cr => cr === r.name)).map(r => r.name) ?? [],
				ceaRoleNames: ceaRoles?.filter(r => user.ceaUser?.roleNames.some(cr => cr === r.name)).map(r => r.name) ?? [],
				ceaAddonRoleNames: ceaAddonRoles?.filter(r => user.ceaUser?.roleNames.some(cr => cr === r.name)).map(r => r.name) ?? [],
				ceaDirectRoleNames: ceaDirectRoles?.filter(r => user.ceaUser?.roleNames.some(cr => cr === r.name)).map(r => r.name) ?? [],
				azureAdUser: user.azureAdUser,
				systemAccessTypeId: user.ceaUser?.systemAccessTypeId,
				metrixRole: user.metrixUser?.role,
				metrixManager: user.metrixUser?.manager,
				markets: user.metrixUser?.markets,
				employeePrograms: user.metrixUser?.employeePrograms,
			};
		}
	}, [company?.companyRoles, user]);

	const handleSaveUser = useCallback((data: CreateEditUserFormValues) => {
		if (user) {
			user.companyId = Number(companyId);

			if (user.ceaUser) {
				user.ceaUser = {
					...user.ceaUser,
					active: true,
					roleNames: (data.ceaRoleNames ?? [])
						.concat(data.adminRoleNames ?? [])
						.concat(data.ceaAddonRoleNames ?? [])
						.concat(data.ceaDirectRoleNames ?? []),
				};
			}

			if (user.metrixUser) {
				// recalculate programs
				const formerPrograms = user.metrixUser.employeePrograms ?? [];
				const removedPrograms = differenceBy(formerPrograms, data?.employeePrograms ?? [], 'programId');
				const currentPrograms = removedPrograms && removedPrograms.length ? concat(data.employeePrograms ?? [], removedPrograms.map(x => {
					return { ...x, isActive: false };
				})) : data.employeePrograms;

				user.metrixUser = {
					...user.metrixUser,
					employeePrograms: currentPrograms ?? [],
					markets: data.markets,
					role: data.metrixRole,
					manager: data.metrixManager,
				};
			}
			dispatch(doSaveUser(user));
		}
	}, [companyId, dispatch, user]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Set Access</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<Form
							initialValues={initialValues}
							onSubmit={handleSaveUser}
							mutators={{ ...arrayMutators }}
						>
							{(formRenderProps: FormRenderProps<CreateEditUserFormValues>) => (
								<form onSubmit={formRenderProps.handleSubmit}>
									<SetUserAccessForm {...formRenderProps} programs={programs} />
								</form>
							)}
						</Form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
