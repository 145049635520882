import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/roles/actionTypes';

export interface State {
	isLoadingRoles: boolean;
	isSavingRole: boolean;
}

export const initialState: State = {
	isLoadingRoles: false,
	isSavingRole: false
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_ROLES:
			return {
				...state,
				isLoadingRoles: true,
			};

		case actionTypes.LOAD_ROLES_SUCCESS:
			return {
				...state,
				isLoadingRoles: false,
			};

		case actionTypes.LOAD_ROLES_FAIL:
			return {
				...state,
				isLoadingRoles: false,
			};

		default:
			return state;
	}
}
