import React, { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { compose } from 'recompose';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

interface OwnProps {
	id: string;
	placeholder?: string;
	filterValue?: string;
	label?: string;
	clearFilter?: () => void;
	change: (value: string | undefined) => void;
}

type Props = WithStyles & OwnProps;

export function SearchTextField(props: Props) {
	const { id, placeholder, filterValue, clearFilter, change, classes, label } = props;
	const searchFilterRef = React.createRef<TextFieldProps>();

	const handleClearFilter = useCallback(() => {
		if (clearFilter) {
			clearFilter();
		}
		if (searchFilterRef.current) {
			searchFilterRef.current.value = '';
		}
	}, [clearFilter, searchFilterRef]);

	const debounceFilterChange = debounce((value: string) => {
		change(value);
	}, 250);

	const handleFilterChange = useCallback((event: any) => {
		if (debounceFilterChange) {
			debounceFilterChange(event.target.value);
		} else {
			change(event.target.value);
		}
	}, [change, debounceFilterChange]);

	const filterEndAdornment = useMemo(() => filterValue && filterValue !== '' && filterValue.trim() !== ''
		? <InputAdornment id={`${id}-ClearFilter`} position="end" style={{ cursor: 'pointer' }} onClick={handleClearFilter}><ClearIcon color="disabled" /></InputAdornment>
		: <InputAdornment position="end"><SearchIcon color="disabled" /></InputAdornment>, [filterValue, handleClearFilter, id]);

	const inputProps = useMemo(() => ({
		endAdornment: filterEndAdornment,
	}), [filterEndAdornment]);

	return (
		<FormControl fullWidth className={classes.formControl} style={{ paddingTop: 0 }}>
			<TextField id={id} inputRef={searchFilterRef} label={label} fullWidth placeholder={placeholder || 'Search'} InputProps={inputProps} onChange={handleFilterChange} />
		</FormControl>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(customInputStyle)
)(SearchTextField);

export default container;
