import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'domains/core/components/Modal';
import { doLogout } from 'domains/core/actions';
import { IdleTimer } from './IdleTimer';
import { IIdleTimer } from 'react-idle-timer';

const fiveMinuteDelay = 5 * 60000;
export default function SessionTimeoutModal(): ReactElement {
	const dispatch = useDispatch();

	const idleTimer = useRef<IIdleTimer>(null);
	const [finalTimer, setFinalTimer] = useState<NodeJS.Timeout>();
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => () => {
		if (idleTimer.current) {
			idleTimer.current.pause();
		}
		clearTimeout(finalTimer);
	}, [finalTimer]);

	const onIdle = () => {
		setModalOpen(true);
		idleTimer.current?.pause();
		setFinalTimer(setTimeout(handleSessionExpired, fiveMinuteDelay));
	}

	const renewSession = () => {
		setModalOpen(false);
		idleTimer.current?.reset();
		clearTimeout(finalTimer);
	}

	const handleSessionExpired = () => {
		clearTimeout(finalTimer);
		dispatch(doLogout());
	}

	const getInitialTimeoutTime = () => {
		const sessionTimeout = process.env.REACT_APP_SESSION_TIMEOUT_MIN;
		if (sessionTimeout) {
			return (Number(sessionTimeout) * 60000) - fiveMinuteDelay;
		}
		return undefined;
	}

	const sessionTimeout = getInitialTimeoutTime();
	return (
		<>
			{
				sessionTimeout &&
				<IdleTimer
					ref={idleTimer}
					element={document}
					onIdle={onIdle}
					debounce={250}
					timeout={sessionTimeout}
				/>
			}
			<Modal
				modalOpen={modalOpen}
				title="Session Timeout"
				handleClose={handleSessionExpired}
				content="Your session is about to expire would you like to renew?"
				actionButtonText="OK"
				handleAction={renewSession}
			/>
		</>
	);
}
