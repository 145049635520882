import React, { DOMAttributes } from 'react';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from 'components/Checkbox';

interface Props {
	id: string;
	label: string;
	checked?: boolean;
	value: string;
	handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	onBlur?: DOMAttributes<any>['onBlur'];
	disabled?: boolean;
	required?: boolean;
}

export default function CheckboxWrapper(props: Props) {
	const { id, label, value, checked, handleOnChange, onBlur, disabled, required } = props;
	const inputProps = { autoComplete: 'off'};

	const formControlStyle: React.CSSProperties = { color: 'black' };

	return (
		<FormControlLabel
			control={<Checkbox id={id} value={value} checked={checked} onChange={handleOnChange} onBlur={onBlur} inputProps={inputProps} disabled={disabled} required={required} />}
			label={label}
			style={formControlStyle}
		/>
	);
}
