import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStyles } from 'styles';
import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays'

import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { CreateEditDiscountForm } from 'domains/discounts/components';

import { doLoadDiscountAndDependencies, doSaveDiscount } from 'domains/discounts/actions';
import { Discount } from 'domains/core/models';
import { DiscountFormValues } from 'domains/discounts/models';
import { getSelectedDiscount } from 'domains/discounts/selectors';
import { useOnMount } from 'utils/React';

interface MatchProps {
	id: string | undefined;
}

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function EditDiscount() {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const { id } = useParams<MatchProps>();

	const discount = useSelector(state => getSelectedDiscount(state, { match: { params: { id } } }));

	useOnMount(() => {
		dispatch(doLoadDiscountAndDependencies(Number(id)));
	});

	const getInitialValues = useMemo(() => {
		if (discount) {
			return {
				...discount,
				effectiveStartDate: moment(discount.effectiveStartDate),
				effectiveEndDate: discount.effectiveEndDate ? moment(discount.effectiveEndDate) : undefined,
				specificItemsRequired: !!discount.discountGroups?.length
			};
		}

		return undefined;
	}, [discount]);

	const handleSaveDiscount = useCallback((data: DiscountFormValues) => {
		const request: Discount = {
			...data,
			id: Number(id),
			discountTypeId: data.discountTypeId ?? 0,
			effectiveStartDate: moment(data.effectiveStartDate),
			isMaxLimitEnabled: !!data.isMaxLimitEnabled,
			manualApplyPromoCode: !!data.promoCode,
			discountGroups: data.discountGroups?.map(g => ({
				...g,
				discountGroupTypeId: g.discountGroupTypeId ?? 0,
				quantity: g.quantity ?? 0,
				canOverrideMinimumPrice: !!g.canOverrideMinimumPrice,
				id: g.id ?? 0,
				discountId: 0,
				conditions: g.conditions?.map(c => ({
					...c,
					id: c.id ?? 0,
				})) ?? [],
			})) ?? []
		}; 

		dispatch(doSaveDiscount(request));
	}, [dispatch, id]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Edit Discount</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<Form
							initialValues={getInitialValues}
							onSubmit={handleSaveDiscount}
							mutators={{ ...arrayMutators }}
						>
							{(formRenderProps: FormRenderProps<DiscountFormValues>) => (
								<CreateEditDiscountForm editMode {...formRenderProps} />
							)}
						</Form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer >
	);
}
