import React, { useCallback } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useStyles } from 'styles';
import { Field, Form } from 'react-final-form';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Search, Clear } from '@material-ui/icons';
import { TerminalMappingSearchFormValues } from 'domains/devices/models';

import * as validators from 'utils/fieldValidators';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' }
	};
};

interface Props {
	handleFilter: (value: string) => void;
}

export default function TerminalMappingSearchForm(props: Props) {
	const { handleFilter } = props;
	const classes = useStyles(style);

	const resetForm = useCallback((reset) => () => {
		reset();
		handleFilter('');
	}, [handleFilter]);

	const onSubmit = useCallback((values: TerminalMappingSearchFormValues) => {
		const { handleFilter } = props;
		handleFilter(values.searchText);
	}, [props]);


	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit, pristine, submitSucceeded, form: { reset } }) => {
				const buttonDisabled = pristine;
				return (
					<form onSubmit={handleSubmit}>
						<Field
							className={classes.noPadding}
							name="searchText"
							id="filterInput"
							component={CustomInputAdapter}
							formControlProps={{ style: { paddingTop: '14px', width: '65%' } }}
							validate={validators.minLength1}
							inputProps={{
								placeholder: 'Search by Tablet, Terminal Name, or Terminal Asset Tag',
								inputProps: {
									'aria-label': 'Search'
								},
								endAdornment:
									<InputAdornment position="end">
										{submitSucceeded ?
											<IconButton onClick={resetForm(reset)} disabled={buttonDisabled}>
												<Clear />
											</IconButton> :
											<IconButton type="submit" disabled={buttonDisabled}>
												<Search />
											</IconButton>
										}
									</InputAdornment>
							}}
						/>
					</form>
				)
			}}
		</Form>
	);
}
