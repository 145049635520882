import { schema } from 'normalizr';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import * as actionTypes from 'domains/users/actionTypes';
import * as usersSchema from 'domains/users/schemas';
import * as companySchemas from 'domains/companies/schemas';
import { User } from 'domains/core/models';
import { UpdateStaffingPlacementScheduleRequest, UpdateUserRequest, UsersSearchRequest } from 'domains/users/models';

export const doLoadCompanyUsers = (request: UsersSearchRequest): Action => {
	return {
		resetEntity: 'users',
		type: actionTypes.LOAD_COMPANY_USERS,
		payload: request
	};
};

export const loadCompanyUsers = (request: UsersSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/User/Search', {
				schema: {
					users: new schema.Array(usersSchema.usersSchema)
				},
				data: request
			});
		}
	};
};

export const doLoadCompanyUserDetail = (companyId: number, userPrincipalName: string): Action => {
	return {
		type: actionTypes.LOAD_COMPANY_USER_DETAIL,
		payload: { companyId, userPrincipalName }
	};
};

export const loadCompanyUserDetail = (companyId: number, userPrincipalName: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/User/Company/${companyId}/UserDetail/${userPrincipalName}`, {
				schema: {
					company: companySchemas.companySchema,
				}
			});
		}
	};
};

export const doSaveUser = (user: User): Action => {
	return {
		type: actionTypes.SAVE_USER,
		payload: user
	};
};

export const saveUser = (user: User): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/User/Save', {
				data: user
			});
		}
	};
};

export const doLoadUser = (userPrincipalName: string): Action => {
	return {
		type: actionTypes.LOAD_USER,
		payload: userPrincipalName
	};
};

export const loadUser = (userPrincipalName: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/User/${userPrincipalName}`, {
				schema: usersSchema.usersSchema,
			});
		}
	};
};

export const doActivateCompanyUser = (request: UpdateUserRequest): Action => {
	return {
		type: actionTypes.ACTIVATE_COMPANY_USER,
		payload: request
	};
};

export const activateCompanyUser = (request: UpdateUserRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/User/Activate', {
				schema: usersSchema.usersSchema,
				data: request
			});
		}
	};
};

export const doDeactivateCompanyUser = (request: UpdateUserRequest): Action => {
	return {
		type: actionTypes.DEACTIVATE_COMPANY_USER,
		payload: request
	};
};

export const deactivateCompanyUser = (request: UpdateUserRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/User/Deactivate', {
				schema: usersSchema.usersSchema,
				data: request
			});
		}
	};
};

export const doLoadMarkets = (): Action => {
	return {
		type: actionTypes.LOAD_METRIRWEB_MARKETS,
	};
};

export const loadMarkets = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/User/GetAllMetrixWebMarkets`, {
				schema: new schema.Array(usersSchema.marketSchema),
			});
		}
	};
};

export const doLoadPrograms = (): Action => {
	return {
		type: actionTypes.LOAD_METRIXWEB_PROGRAMS
	};
};

export const loadPrograms = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/User/GetAllMetrixWebPrograms`, {
				schema: new schema.Array(usersSchema.programSchema),
			});
		}
	};
};

export const doLoadAllMetrixRoles = (): Action => {
	return {
		type: actionTypes.LOAD_METRIXWEB_ROLES
	};
};

export const loadAllMetrixRoles = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/User/GetAllMetrixWebRoles`, {
				schema: [usersSchema.metrixWebRolesSchema],
			});
		}
	};
};

export const doLoadAllMetrixManagers = (): Action => {
	return {
		type: actionTypes.LOAD_METRIXWEB_MANAGERS
	};
};

export const loadAllMetrixManagers = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/User/managers', {
				schema: new schema.Array(usersSchema.metrixWebManagers)
			});
		}
	};
}

export const doResetPin = (userPrincipal: string): Action => {
	return {
		type: actionTypes.RESET_PIN,
		payload: userPrincipal
	};
};

export const resetPin = (userPrincipal: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post(`/admin/User/resetPin/${userPrincipal}`, {
				schema: usersSchema.usersSchema
			});
		}
	};
}

export const doLoadUserAndDependencies = (companyId: number, userPrincipal?: string): Action => {
	return {
		type: actionTypes.LOAD_USER_AND_DEPENDENCIES,
		payload: {
			userPrincipal: userPrincipal,
			companyId
		}
	};
};

export const doLoadUserFilterDependencies = (): Action => {
	return {
		type: actionTypes.LOAD_USER_FILTER_DEPENDENCIES,
	};
};

export const doExportUserList = (request: UsersSearchRequest): Action => {
	return {
		type: actionTypes.EXPORT_USER_LIST,
		payload: {
			request
		}
	};
};

export const exportUserList = (request: UsersSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post(`/admin/User/export`, {
				data: request
			});
		}
	};
}

export const doGetDefaultStaffingPlacementSchedule = (): Action => {
	return {
		type: actionTypes.LOAD_STAFFING_PLACEMENT_SCHEDULE
	};
}

export const getDefaultStaffingPlacementSchedule = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/StaffingPlacementScheduleAdmin/GetDefaultSchedule');
		}
	};
}

export const doUpdateDefaultStaffingPlacementSchedule = (request: UpdateStaffingPlacementScheduleRequest): Action => {
	return {
		type: actionTypes.UPDATE_STAFFING_PLACEMENT_SCHEDULE,
		payload: request
	};
}

export const updateDefaultStaffingPlacementSchedule = (request: UpdateStaffingPlacementScheduleRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/StaffingPlacementScheduleAdmin/UpdateDefaultSchedule', {
				data: request
			});
		}
	};
}

export const doScheduleOnDemandStaffingPlacement = (): Action => {
	return {
		type: actionTypes.SCHEDULE_ON_DEMAND_STAFFING_PLACEMENT
	};
};

export const scheduleOnDemandStaffingPlacement = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/StaffingPlacementScheduleAdmin/ScheduleOnDemandRun');
		}
	};
}
