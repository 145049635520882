import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridContainer, GridItem } from 'components/Grid';
import { getFilters } from 'domains/audits/selectors';
import { doLoadAuditFilters } from 'domains/audits/actions';
import { UserAuditSearchRequest, LogSystem, UserAuditFilterValues } from 'domains/audits/models';
import { doPaginationReset } from 'domains/core/actions';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { useStyles } from 'styles';
import { useWatchForChange } from 'utils/React';

const style = {
	customPagination: {
		float: 'right' as const
	}
};

interface Props {
	initialValues: UserAuditFilterValues;
	userFilter?: string;
	handleFilterChange: (request: UserAuditSearchRequest) => void;
}

interface FormProps extends FormRenderProps<UserAuditFilterValues> {
	userFilter?: string;
	handleFilterChange: (request: UserAuditSearchRequest) => void;
}

function AuditFiltersForm(props: FormProps) {
	const { values, userFilter, handleFilterChange } = props;
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const userAuditFilters = useSelector(state => getFilters(state));
	const totalRecords = useSelector(state => state.domains.audits.totalAuditRecords);
	const page = useSelector(state => state.domains.core.pagination.currentPage);

	useEffect(() => {
		const dateRange = typeof values.dateRange === 'number' ? moment.utc().add(values.dateRange, 'day').toDate() : moment.utc().add(-1, values.dateRange).toDate();

		const currentRequest = {
			logSystem: values.logSystem ?? LogSystem.Cea,
			username: userFilter,
			dateRange,
			company: values.company,
			sourceSystem: values.sourceSystem,
			userAuditType: values.userAuditType,
		};
		
		handleFilterChange(currentRequest);
	}, [dispatch, handleFilterChange, page, userFilter, values]);

	useWatchForChange({
		watch: values,
		onChange: (_nextValues, _oldValues) => {
			dispatch(doPaginationReset());
		}
	})

	const logSystemOptions = useMemo(() => {
		return [
			{ value: LogSystem.Cea, text: 'CEA' },
			{ value: LogSystem.MetrixWeb, text: 'MetrixWeb' }
		];
	}, []);

	const dateRangeOptions = useMemo(() => {
		return [
			{ value: 'hour', text: 'Last hour' },
			{ value: 'day', text: 'Last day' },
			{ value: 'week', text: 'Last week' },
			{ value: 'month', text: 'Last month' },
			{ value: -60, text: 'Last 60 days' },
			{ value: -90, text: 'Last 90 days' }
		];
	}, []);

	const sourceSystemOptions = useMemo(() => {
		const options = userAuditFilters.sourceSystems?.map(s => ({ value: s.id, text: s.displayName }));
		return [
			{ value: 0, text: 'All' },
			...options
		];
	}, [userAuditFilters.sourceSystems]);

	const userAuditTypeOptions = useMemo(() => {
		const options = userAuditFilters.userAuditTypes?.map(s => ({ value: s.id, text: s.displayName }));
		return [
			{ value: 0, text: 'All' },
			...options
		];
	}, [userAuditFilters.userAuditTypes]);

	const companyOptions = useMemo(() => {
		const options = userAuditFilters.companies?.map(s => ({ value: s.id, text: s.displayName }));
		return [
			{ value: 0, text: 'All' },
			...options
		];
	}, [userAuditFilters.companies]);

	if (!userAuditFilters.companies.length) {
		return <div />;
	}

	return (<form style={{ margin: '0 25px' }}>
		<GridContainer>
			<GridItem xs md lg>
				<Field name="logSystem">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							id="logSystem"
							labelText="Log System"
							options={logSystemOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs md lg>
				<Field name="dateRange">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							labelText="Date Range"
							id="dateRange-filter"
							options={dateRangeOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs md lg>
				<Field name="sourceSystem">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							labelText="System"
							id="system-filter"
							options={sourceSystemOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs md lg>
				<Field
					name="userAuditType"
				>
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							labelText="Action"
							id="action-filter"
							options={userAuditTypeOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs md lg>
				<Field
					name="company"
				>
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							labelText="Company"
							id="company-filter"
							options={companyOptions}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs={12} md={4} lg={4}>
				<div className={classes.customPagination}>
					<PaginationWrapper recordCount={totalRecords} />
				</div>
			</GridItem>
		</GridContainer>
	</form>
	);
}

export default function AuditFilters(props: Props) {
	const dispatch = useDispatch();

	const { initialValues, userFilter, handleFilterChange } = props;

	useEffect(() => {
		dispatch(doLoadAuditFilters());
	}, [dispatch]);

	const handleOnSubmit = useCallback(() => {
		// handled by the onChange event, but onSubmit is required by react-final-form.
	}, []);

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
		>
			{(renderProps: FormRenderProps<UserAuditFilterValues>) => (
				<AuditFiltersForm {...renderProps} handleFilterChange={handleFilterChange} userFilter={userFilter} />
			)}
		</Form>
	);
}
