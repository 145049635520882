import React, { useCallback, useEffect, useState } from 'react';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import { useStyles } from 'styles';
import { Field, Form, FormRenderProps } from 'react-final-form';

import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardFooter, CardBody } from 'components/Card';
import { Button } from 'components/CustomButtons';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';

import * as validators from 'utils/fieldValidators';
import { LoginFormValues } from 'domains/core/models';

export interface Props {
	initialValues: LoginFormValues;
	onSubmit: (data: LoginFormValues) => void;
}

export default function LoginForm(props: Props) {
	const classes = useStyles(loginPageStyle);

	// we use this to make the card to appear after the page has been rendered
	const [cardAnimation, setCardAnimation] = useState('cardHidden');
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		const timeout = setTimeout(() => {
			setCardAnimation('');
		}, 700);
		return () => clearTimeout(timeout);
	}, []);

	const togglePassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	return (
		<div className={classes.container}>
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={6} md={4}>
					<Form
						initialValues={props.initialValues}
						onSubmit={props.onSubmit}
					>
						{({ handleSubmit, errors, invalid }: FormRenderProps<LoginFormValues>) => {
							return (
								<form onSubmit={handleSubmit}>
									<Card login className={classes[cardAnimation as keyof typeof loginPageStyle]}>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="primary"
										>
											<h2 className={classes.cardTitle}>Log in</h2>
										</CardHeader>
										<CardBody>
											<Field
												component={CustomInputAdapter}
												name="username"
												labelText="Username..."
												id="username"
												formControlProps={{
													fullWidth: true,
													required: true
												}}
												inputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<Email className={classes.inputAdornmentIcon} />
														</InputAdornment>
													)
												}}
											/>
											<Field
												name="password"
												validate={validators.minLength8}
												component={CustomInputAdapter}
												labelText="Password..."
												id="password"
												formControlProps={{
													fullWidth: true,
													required: true
												}}
												helpText={errors && errors.password ? errors.password : undefined}
												error={errors && !!errors.password}
												inputProps={{
													type: showPassword ? 'text' : 'password',
													endAdornment: (
														<InputAdornment position="end" onClick={togglePassword}>
															{showPassword
																? <VisibilityOff className={`${classes.inputAdornmentIcon} ${classes.passwordShowHide}`} />
																: <Visibility className={`${classes.inputAdornmentIcon} ${classes.passwordShowHide}`} />
															}
														</InputAdornment>
													)
												}}
											/>
										</CardBody>
										<CardFooter className={classes.justifyContentCenter}>
											<GridContainer justifyContent="center" direction="column">
												<GridItem>
													<Button color="primary" size="lg" block type="submit" disabled={invalid}>
														{'Let\'s Go'}
													</Button>
												</GridItem>
												<GridItem>
													<Button color="primary" simple size="lg" block link href={process.env.REACT_APP_FORGOT_PASSWORD_LINK} target="_blank">
														{'Forgot Password'}
													</Button>
												</GridItem>
											</GridContainer>
										</CardFooter>
									</Card>
								</form>
							);
						}}
					</Form>
				</GridItem>
			</GridContainer>
		</div>
	);
}
