import React from 'react';
import { Theme } from '@material-ui/core/styles';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import { useStyles } from 'styles';
import { TextField } from '@material-ui/core';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' }
	};
};

export interface Props {
	initialValues: any;
	filterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
}

export default function SearchBox(props: Props) {
	const { filterHandler, placeholder } = props;
	const classes = useStyles(style);

	return (
		<TextField
			placeholder={placeholder}
			className={classes.noPadding}
			aria-label='Search'
			onChange={filterHandler}
		/>
	);
}
