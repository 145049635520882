import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { Company } from 'domains/companies/models';
import { usaStateDict } from 'utils/constants';
import * as validators from 'utils/fieldValidators';
import { Form, Field } from 'react-final-form';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';

interface Props {
	initialValues?: Company;
	onSubmit: (values: any) => void;
}

export default function CreateEditCompanyForm(props: Props) {
	const { initialValues, onSubmit } = props;
	const history = useHistory();

	const stateSelectOptions = useMemo(() => {
		return Object.keys(usaStateDict).map((prop: string) => ({ value: prop, text: usaStateDict[prop as keyof typeof usaStateDict] }));
	}, []);

	return (
		<Form onSubmit={onSubmit} initialValues={initialValues}>
			{({ handleSubmit, invalid, pristine }) => {
				const disabled = invalid || pristine;
				return (
					<form onSubmit={handleSubmit}>
						<GridContainer>
							<GridItem xs={12} md={12} lg={12}>
								<Field
									component={CustomInputAdapter}
									name="name"
									labelText="Company Name"
									id="company-name"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: true
									}}
									validate={validators.maxLength500}
								/>
							</GridItem>
							<GridItem xs={12} md={12} lg={12}>
								<Field
									component={CustomInputAdapter}
									name="description"
									labelText="Description"
									id="company-description"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: false
									}}
									validate={validators.maxLength2000}
								/>
							</GridItem>
							<GridItem xs={12} md={12} lg={12}>
								<Field
									component={CustomInputAdapter}
									name="addressLine1"
									labelText="Address Line 1"
									id="company-addressLine1"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: true
									}}
									validate={validators.maxLength255}
								/>
							</GridItem>
							<GridItem xs={12} md={12} lg={12}>
								<Field
									component={CustomInputAdapter}
									name="addressLine2"
									labelText="Address Line 2"
									id="company-addressLine2"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: false
									}}
									validate={validators.maxLength255}
								/>
							</GridItem>
							<GridItem xs={12} md={5} lg={5}>
								<Field
									component={CustomInputAdapter}
									name="city"
									labelText="City"
									id="company-city"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: true
									}}
									validate={validators.maxLength255}
								/>
							</GridItem>
							<GridItem xs={12} md={4} lg={4}>
								<Field name="state">
									{(fieldProps) => (
										<FormSelectAdapter
											{...fieldProps}
											options={stateSelectOptions}
											labelText="State"
											id="company-state"
											required
										/>
									)}
								</Field>
							</GridItem>
							<GridItem xs={12} md={3} lg={3}>
								<Field
									component={CustomInputAdapter}
									name="zipCode"
									labelText="Zip"
									id="company-zipcode"
									disabled={disabled}
									formControlProps={{
										fullWidth: true,
										required: true
									}}
									validate={validators.isZipCode}
								/>
							</GridItem>
							<GridItem>
								<Button type="submit" color="primary" disabled={disabled}>Save</Button>
								<Button simple color="primary" onClick={history?.goBack}>Cancel</Button>
							</GridItem>
						</GridContainer>
					</form>
				);
			}}
		</Form>
	);
}
