import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { EmployeeImportUpdateScheduleForm } from 'domains/users/components';
import { Button } from 'components/CustomButtons';
import {
	doGetDefaultStaffingPlacementSchedule,
	doUpdateDefaultStaffingPlacementSchedule,
	doScheduleOnDemandStaffingPlacement
} from 'domains/users/actions';
import { UpdateStaffingPlacementScheduleRequest } from 'domains/users/models';
import { useStyles } from 'styles';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function EmployeeImportSchedulePage() {
	const classes = useStyles(style);
	const dispatch = useDispatch();
	const scheduleDto = useSelector(state => state.domains.users.defaultStaffingPlacementSchedule)

	useEffect(() => {
		dispatch(doGetDefaultStaffingPlacementSchedule());
	}, [dispatch]);

	const submit = (values: UpdateStaffingPlacementScheduleRequest) => {
		dispatch(doUpdateDefaultStaffingPlacementSchedule(values));
	}

	const scheduleOnDemand = () => {
		dispatch(doScheduleOnDemandStaffingPlacement());
	};

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Employee Import Schedule</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<GridContainer>
							<GridItem xs={12} md={12} lg={12}>
								{scheduleDto && <EmployeeImportUpdateScheduleForm onSubmit={submit} initialValues={{ schedule: scheduleDto.schedule }} />}
							</GridItem>
							<GridItem xs={12} md={12} lg={12}>
								<Button onClick={scheduleOnDemand} type="button">Schedule On Demand Run</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
