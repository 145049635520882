import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { ApplicationState } from 'applicationTypes';
import { discountsSchema, ceaProgramsSchema, discountTypesSchema, itemRetailUnitOfMeasureSchema, itemRetailTypesSchema, itemRetailCategoriesSchema, itemRetailsSchema, discountTimeFrameTypesSchema, discountMarketsSchema, discountRetailLocationsSchema, retailLocationTypesSchema, retailLocationLocationTypesSchema, retailLocationMruTypesSchema } from 'domains/discounts/schemas';
import { Discount, CeaProgram, SourceSystem, DiscountType, DiscountTypeEnum, ItemRetailUnitOfMeasure, ItemRetailType, ItemRetailCategory, ItemRetail, DiscountTimeFrameType, SourceSystemMarket, RetailLocationType, DiscountRetailLocation, RetailLocationLocationType, RetailLocationMruType } from 'domains/core/models';

const getEntities = (state: ApplicationState) => state.entities;

export const getIsLoadingDiscounts = (state: ApplicationState) => state.domains.discounts.isLoadingDiscounts;
export const getDiscountFilterOptions = (state: ApplicationState) => state.domains.discounts.discountFilterOptions;
export const getDiscountIdFromProps = (_state: ApplicationState, props: any) => props.match && props.match.params && parseInt(props.match.params.id);
export const getSelectedDiscountEntities = (state: ApplicationState) => state.entities.selectedDiscount;

export const getDiscounts = createSelector(
	[getEntities],
	(entities): Discount[] => {
		const schema = { discounts: [discountsSchema] };
		const result = denormalize({ discounts: Object.keys(entities.discounts) }, schema, entities).discounts;

		return result;
	}
);

export const getCeaPrograms = createSelector(
	[getEntities],
	(entities): CeaProgram[] => {
		const schema = { ceaPrograms: [ceaProgramsSchema] };
		const programs: CeaProgram[] = denormalize({ ceaPrograms: Object.keys(entities.ceaPrograms) }, schema, entities).ceaPrograms;
		const result = programs.filter(x => x.sourceSystemId === SourceSystem.Cea);
		return orderBy(result, x => x.name);
	}
);

export const getDiscountTypes = createSelector(
	[getEntities],
	(entities): DiscountType[] => {
		const schema = { discountTypes: [discountTypesSchema] };
		const result: DiscountType[] = denormalize({ discountTypes: Object.keys(entities.discountTypes) }, schema, entities).discountTypes;
		const filtered = result.filter(x => x.id === DiscountTypeEnum.virtualBundle || x.id === DiscountTypeEnum.priceDiscount || x.id === DiscountTypeEnum.consumerReferral);
		return orderBy(filtered, x => x.displayName);
	}
);

export const getItemRetailUnitOfMeasure = createSelector(
	[getEntities],
	(entities): ItemRetailUnitOfMeasure[] => {
		const schema = { itemRetailUnitOfMeasure: [itemRetailUnitOfMeasureSchema] };
		const result: ItemRetailUnitOfMeasure[] = denormalize({ itemRetailUnitOfMeasure: Object.keys(entities.itemRetailUnitOfMeasure) }, schema, entities).itemRetailUnitOfMeasure;
		return orderBy(result, x => x.displayName);
	}
);

export const getItemRetailTypes= createSelector(
	[getEntities],
	(entities): ItemRetailType[] => {
		const schema = { itemRetailTypes: [itemRetailTypesSchema] };
		const result: ItemRetailType[] = denormalize({ itemRetailTypes: Object.keys(entities.itemRetailTypes) }, schema, entities).itemRetailTypes;
		return orderBy(result, x => x.displayName);
	}
);

export const getItemRetailCategories = createSelector(
	[getEntities],
	(entities): ItemRetailCategory[] => {
		const schema = { itemRetailCategories: [itemRetailCategoriesSchema] };
		const result: ItemRetailCategory[] = denormalize({ itemRetailCategories: Object.keys(entities.itemRetailCategories) }, schema, entities).itemRetailCategories;
		return orderBy(result, x => x.displayName);
	}
);

export const getDiscountTimeFrameTypes = createSelector(
	[getEntities],
	(entities): DiscountTimeFrameType[] => {
		const schema = { discountTimeFrameTypes: [discountTimeFrameTypesSchema] };
		const result: DiscountTimeFrameType[] = denormalize({ discountTimeFrameTypes: Object.keys(entities.discountTimeFrameTypes) }, schema, entities).discountTimeFrameTypes;
		return orderBy(result, x => x.displayName);
	}
);

export const getItemRetails = createSelector(
	[getEntities],
	(entities): ItemRetail[] => {
		const schema = { itemRetails: [itemRetailsSchema] };
		const result: ItemRetail[] = denormalize({ itemRetails: Object.keys(entities.itemRetails) }, schema, entities).itemRetails;
		return orderBy(result, x => x.metadata.name);
	}
);

export const getDiscountMarkets = createSelector(
	[getEntities],
	(entities): SourceSystemMarket[] => {
		const schema = { discountMarkets: [discountMarketsSchema] };
		const result: SourceSystemMarket[] = denormalize({ discountMarkets: Object.keys(entities.discountMarkets) }, schema, entities).discountMarkets;
		return orderBy(result, x => x.shortName);
	}
);

export const getRetailLocationTypes = createSelector(
	[getEntities],
	(entities): RetailLocationType[] => {
		const schema = { retailLocationTypes: [retailLocationTypesSchema] };
		const result: RetailLocationType[] = denormalize({ retailLocationTypes: Object.keys(entities.retailLocationTypes) }, schema, entities).retailLocationTypes;
		return orderBy(result, x => x.displayName);
	}
);

export const getRetailLocationLocationTypes = createSelector(
	[getEntities],
	(entities): RetailLocationLocationType[] => {
		const schema = { retailLocationLocationTypes: [retailLocationLocationTypesSchema] };
		const result: RetailLocationLocationType[] = denormalize({ retailLocationLocationTypes: Object.keys(entities.retailLocationLocationTypes) }, schema, entities).retailLocationLocationTypes;
		return orderBy(result, x => x.displayName);
	}
);

export const getRetailLocationMruTypes = createSelector(
	[getEntities],
	(entities): RetailLocationMruType[] => {
		const schema = { retailLocationMruTypes: [retailLocationMruTypesSchema] };
		const result: RetailLocationMruType[] = denormalize({ retailLocationMruTypes: Object.keys(entities.retailLocationMruTypes) }, schema, entities).retailLocationMruTypes;
		return orderBy(result, x => x.displayName);
	}
);

export const getDiscountRetailLocations = createSelector(
	[getEntities],
	(entities): DiscountRetailLocation[] => {
		const schema = { discountRetailLocations: [discountRetailLocationsSchema] };
		const result: DiscountRetailLocation[] = denormalize({ discountRetailLocations: Object.keys(entities.discountRetailLocations) }, schema, entities).discountRetailLocations;
		return orderBy(result, x => x.storeName);
	}
);

export const getDiscountFromRoute = createSelector(
	[getDiscounts, getDiscountIdFromProps],
	(discounts, discountId): Discount | undefined => {
		if (discounts) {
			return discounts.find(x => x.id === discountId);
		}
		return undefined;
	}
);

export const getSelectedDiscount = createSelector(
	[getSelectedDiscountEntities, getDiscountIdFromProps],
	(discounts, discountId): Discount | undefined => {
		if (discounts) {
			return discounts[discountId];
		}
		return undefined;
	}
);
