import { SearchResponse, SearchRequest, Discount } from 'domains/core/models';
import { Dictionary } from 'lodash';
import { Moment } from 'moment';

export interface DiscountSearchRequest extends SearchRequest {
	sortColumn?: DiscountSortColumn;
	market?: number;
	program?: number;
	discountType?: number;
	discountName?: string;
	active?: boolean;
}

export interface DiscountSearchResponse extends SearchResponse {
	discounts: Discount[];
}

export enum DiscountSortColumn {
	InternalName = 'name',
	DisplayName = 'displayName',
	Program = 'programName',
	DiscountType = 'discountType',
	ActiveDate = 'effectiveStartDate',
	Market = 'market'
}
export interface DiscountFilterOptions {
	programs: FilterOptionDto[];
	markets: FilterOptionDto[];
	discountTypes: FilterOptionDto[];
}

export interface FilterOptionDto {
	id: number;
	displayName: string;
}

export enum DiscountEffectType {
	dollarOffCart = 1,
	dollarOffItems = 2,
	percentOffCart = 3,
	percentOffItems = 4,
	cartDiscountWithConditions = 5,
	cartPercentageDiscountWithConditions = 6,
	itemFinalPriceDiscount = 7
}

export enum ProofOfPurchaseType {
	manualBundle = 1,
	indirectRetailExchange = 2,
	internationalExchange = 3
}

export interface UpdateDiscountActiveStatusRequest {
	discountId: number;
	rowVersion?: string;
}

export enum DiscountGroupType {
	itemRetail = 1,
	itemRetailType = 2,
	itemRetailCategory = 3,
}

export enum RetailLocationTypeEnum {
	physicalLocation = 1,
	mobileRetailUnit = 2,
	expertsStorefront = 3,
}

export const DiscountGroupTypeDictionary: Dictionary<string> = {
	[DiscountGroupType.itemRetail]: 'Item Name / UPC',
	[DiscountGroupType.itemRetailType]: 'Item Retail Type',
	[DiscountGroupType.itemRetailCategory]: 'Item Retail Category'
}

export enum ItemRetailUnitOfMeasureEnum {
	carton = 1,
	roll = 2,
	each = 3
}

export enum ItemRetailTypeEnum {
	heatStick = 1,
	iqosCable = 2,
	iqosCarryingCase = 3,
	iqosCharger = 4,
	iqosCleaner = 5,
	iqosCleaningSticks = 6,
	iqosDeviceKit = 7,
	iqosDisposalUnit = 8,
	iqosHolder = 9,
	iqosHolderCap = 10,
	iqosPowerAdapter = 11,
	iqosSleeve = 12,
	dip = 13,
	merchandise = 14,
	iqosDuoFolio = 15,
	lendingProgram = 16,
}

export enum ItemRetailCategoryEnum {
	device = 1,
	heatStick = 2,
	accessory = 3,
	dip = 4,
	general = 5,
	serviceFee = 6,
}

export interface DiscountFormValues {
	active: boolean;
	discountEffectTypeId: number;
	allRetailLocationsSelected: boolean;
	programId: number;
	discountMarketIds: number[];
	retailLocationTypeIds: number[];
	retailLocationLocationTypeIds: number[];
	retailLocationMruTypeIds: number[];
	retailLocationIds: number[];
	effectiveStartDate?: string | Moment;
	effectiveEndDate?: string | Moment;
	discountTypeId?: number;
	name: string;
	displayName?: string;
	promoCode?: string;
	description?: string;
	manualApplyPromoCode?: boolean;
	displayInCea: boolean;
	shouldAutoApply: boolean;
	isMaxLimitEnabled?: boolean;
	maxLimitPerCart: number | null;
	timeFrameLimit: number | null;
	discountTimeFrameTypeId: number | null;
	isExclusiveItemDiscount: boolean;
	isCopeExclusiveItemDiscount: boolean;
	isPhotoCaptureRequired: boolean;
	proofOfPurchaseTypeId: number | null;
	isCcnRequiredForManualApply: boolean;
	isCcnRequiredForAutoApply: boolean;
	specificItemsRequired?: boolean;
	discountGroups?: DiscountGroupFormValues[];
	discountAmount: number;
	discountPercentage?: number;
	cartMinimumPrice: number | null;
	// control props
	isCartMinimumPriceEnabled: boolean;
	hasCartConditionItems: boolean;
	hasTimeFrameLimit: boolean;
	isLockedDown: boolean;
}

export interface DiscountGroupFormValues {
	id?: number;
	discountGroupTypeId?: number;
	quantity?: number;
	discountPercentage?: number;
	discountAmount?: number;
	finalPrice?: number;
	canOverrideMinimumPrice?: boolean;
	conditions?: DiscountGroupConditionFormValues[];
}

export interface DiscountGroupConditionFormValues {
	id?: number;
	upc?: string;
	itemRetailTypeId?: number;
	itemRetailCategoryId?: number;
	itemRetailUnitOfMeasureId?: number;
}
