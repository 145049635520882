// ##############################
// // // LoginPage view styles
// #############################

import { Theme } from "@material-ui/core";
import {
	container,
	cardTitle
} from "assets/jss/material-dashboard-pro-react";

const loginPageStyle = (theme: Theme) => ({
	container: {
		...container,
		zIndex: 4,
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "100px"
		}
	},
	cardTitle: {
		...cardTitle,
		color: "#FFFFFF"
	},
	textCenter: {
		textAlign: "center" as const
	},
	justifyContentCenter: {
		justifyContent: "center !important"
	},
	customButtonClass: {
		"&,&:focus,&:hover": {
			color: "#FFFFFF"
		},
		marginLeft: "5px",
		marginRight: "5px"
	},
	inputAdornment: {
		marginRight: "18px"
	},
	inputAdornmentIcon: {
		color: "#555",
	},
	passwordShowHide: {
		cursor: 'pointer',
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)"
	},
	cardHeader: {
		marginBottom: "20px"
	},
	socialLine: {
		padding: "0.9375rem 0"
	}
});

export default loginPageStyle;
