export const LOAD_SECRET_SHOPPERS = 'secretShopper/LOAD_SECRET_SHOPPERS';
export const LOAD_SECRET_SHOPPERS_SUCCESS = 'secretShopper/LOAD_SECRET_SHOPPERS_SUCCESS';
export const LOAD_SECRET_SHOPPERS_FAIL = 'secretShopper/LOAD_SECRET_SHOPPERS_FAIL';

export const SAVE_SECRET_SHOPPER = 'secretShopper/SAVE_SECRET_SHOPPER';

export const LOAD_SECRET_SHOPPER = 'secretShopper/LOAD_SECRET_SHOPPER';
export const LOAD_SECRET_SHOPPER_SUCCESS = 'secretShopper/LOAD_SECRET_SHOPPER_SUCCESS';
export const DEACTIVATE_SECRET_SHOPPER = 'secretShopper/DEACTIVATE_SECRET_SHOPPER';

