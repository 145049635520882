import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/audits/actionTypes';
import { UserAuditFilters } from 'domains/audits/models';

export interface State {
	totalAuditRecords: number;
	userAuditFilters: UserAuditFilters;
	loading: boolean;
}

export const initialState: State = {
	totalAuditRecords: 0,
	userAuditFilters: {
		companies: [],
		sourceSystems: [],
		userAuditTypes: [],
	},
	loading: false,
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_AUDIT_FILTER:
			return {
				...state,
				userAuditFilters: initialState.userAuditFilters,
			};

		case actionTypes.LOAD_AUDIT_FILTER_SUCCESS:
			return {
				...state,
				userAuditFilters: action.result,
			};

		case actionTypes.LOAD_AUDIT_FILTER_FAIL:
			return {
				...state,
				userAuditFilters: initialState.userAuditFilters,
			};

		case actionTypes.SEARCH_USER_AUDITS:
			return {
				...state,
				loading: true
			};

		case actionTypes.SEARCH_USER_AUDITS_SUCCESS:
			return {
				...state,
				totalAuditRecords: action.result.result.recordCount,
				loading: false
			};

		case actionTypes.SEARCH_USER_AUDITS_FAIL:
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
}
