import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/core/actionTypes';
import { CurrentUser } from 'domains/core/models';

export interface State {
	appLoading: boolean;
	isAuthenticated: boolean;
	isAuthorized: boolean;
	authenticating: boolean;
	refreshingToken: boolean;
	authorizing: boolean;
	loginError?: string;
	user: CurrentUser | undefined | null;
	routeTo: string | undefined;
	pagination: {
		currentPage: number;
		recordsPerPage: number;
		scrollTop: boolean;
	};
}

export const initialState: State = {
	appLoading: false,
	isAuthenticated: false,
	isAuthorized: false,
	authenticating: false,
	refreshingToken: false,
	authorizing: false,
	loginError: undefined,
	user: null,
	routeTo: undefined,
	pagination: {
		currentPage: 1,
		recordsPerPage: 50,
		scrollTop: false
	}
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.REROUTE:
			return {
				...state,
				routeTo: action.payload.routeTo
			};

		case actionTypes.RESET_REROUTE:
			return {
				...state,
				routeTo: undefined
			};

		case actionTypes.APP_LOADING:
			return {
				...state,
				appLoading: true
			};

		case actionTypes.APP_LOADING_COMPLETE:
			return {
				...state,
				appLoading: false
			};

		case actionTypes.LOGIN:
			return {
				...state,
				appLoading: true,
				authenticating: true,
				loginError: undefined
			};

		case actionTypes.LOGIN_SUCCESS:
			return {
				...state,
				appLoading: false,
				isAuthenticated: true,
				authenticating: false,
				loginError: undefined
			};

		case actionTypes.LOGIN_FAIL:
			return {
				...state,
				appLoading: false,
				isAuthenticated: false,
				authenticating: false,
				loginError: action.error ? action.error.message : 'Unable to log in'
			};

		case actionTypes.REFRESH_TOKEN:
			return {
				...state,
				refreshingToken: true,
			};

		case actionTypes.REFRESH_TOKEN_SUCCESS:
		case actionTypes.REFRESH_TOKEN_FAIL:
			return {
				...state,
				refreshingToken: false,
			};

		case actionTypes.LOGOUT_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				authenticating: false,
				isAuthorized: false,
				authorizing: false,
				user: null,
				loginError: undefined
			};

		case actionTypes.CLEAR_LOGIN_ERROR:
			return {
				...state,
				loginError: undefined
			};

		case actionTypes.GET_USER:
			return {
				...state,
				appLoading: true,
				authorizing: true
			};

		case actionTypes.AUTHORIZE_USER_SUCCESS:
			return {
				...state,
				appLoading: false,
				authorizing: false,
				isAuthorized: true,
				user: action.result
			};

		case actionTypes.AUTHORIZE_USER_FAIL:
			return {
				...state,
				appLoading: false,
				authorizing: false,
				isAuthorized: false,
				isAuthenticated: false,
				loginError: action.error ? action.error.message : 'Unable to log in'
			};

		case actionTypes.PAGINATION_SET_PAGE:
			return {
				...state,
				pagination: {
					...state.pagination,
					currentPage: action.payload,
					scrollTop: true,
				}
			};

		case actionTypes.PAGINATION_RESET:
			return {
				...state,
				pagination: {
					...state.pagination,
					currentPage: 1,
					scrollTop: true,
				}
			};

		case actionTypes.PAGINATION_SCROLL_RESET:
			return {
				...state,
				pagination: {
					...state.pagination,
					scrollTop: false,
				}
			};

		default:
			return state;
	}
}
