import React from 'react';

import { withStyles, WithStyles } from '@material-ui/core/styles';

const style = {
	menuItem: {
		display: 'inline-flex',
		verticalAlign: 'middle',
		alignItems: 'center',
		width: '100%'
	},
	customText: {
		paddingLeft: '5px',
		fontSize: '.9em',
		fontWeight: '500' as any
	}
};

interface ComponentProps {
	icon?: JSX.Element;
	action: () => void;
	text: string;
}

export type Props = ComponentProps & WithStyles;

export const ContextMenuItem = (props: Props) => {
	const { classes, action, icon, text } = props;
	return (
		<div className={classes.menuItem} onClick={action}>
			{icon}
			<h6 className={`${icon ? classes.customText : ''}`}>{text}</h6>
		</div>
	);
};

export default withStyles(style)(ContextMenuItem);
