export const LOAD_EXECUTION_TAGS = 'executionTags/LOAD_EXECUTION_TAGS';
export const LOAD_EXECUTION_TAGS_SUCCESS = 'executionTags/LOAD_EXECUTION_TAGS_SUCCESS';
export const LOAD_EXECUTION_TAGS_FAIL = 'executionTags/LOAD_EXECUTION_TAGS_FAIL';

export const SAVE_EXECUTION_TAG = 'executionTags/SAVE_EXECUTION_TAG';
export const SAVE_EXECUTION_TAG_SUCCESS = 'executionTags/SAVE_EXECUTION_TAG_SUCCESS';
export const SAVE_EXECUTION_TAG_FAIL = 'executionTags/SAVE_EXECUTION_TAG_FAIL';

export const LOAD_EXECUTION_TAG_DETAILS = 'executionTags/LOAD_EXECUTION_TAG_DETAILS';
export const LOAD_EXECUTION_TAG_DETAILS_SUCCESS = 'executionTags/LOAD_EXECUTION_TAG_DETAILS_SUCCESS';
export const LOAD_EXECUTION_TAG_DETAILS_FAIL = 'executionTags/LOAD_EXECUTION_TAG_DETAILS_FAIL';

export const ACTIVATE_EXECUTION_TAG = 'executionTags/ACTIVATE_EXECUTION_TAG';
export const ACTIVATE_EXECUTION_TAG_SUCCESS = 'executionTags/ACTIVATE_EXECUTION_TAG_SUCCESS';
export const ACTIVATE_EXECUTION_TAG_FAIL = 'executionTags/ACTIVATE_EXECUTION_TAG_FAIL';

export const DEACTIVATE_EXECUTION_TAG = 'executionTags/DEACTIVATE_EXECUTION_TAG';
export const DEACTIVATE_EXECUTION_TAG_SUCCESS = 'executionTags/DEACTIVATE_EXECUTION_TAG_SUCCESS';
export const DEACTIVATE_EXECUTION_TAG_FAIL = 'executionTags/DEACTIVATE_EXECUTION_TAG_FAIL';

export const LOAD_EXECUTION_TAG_FORM_OPTIONS = 'executionTags/LOAD_EXECUTION_TAG_FORM_OPTIONS';
export const LOAD_EXECUTION_TAG_FORM_OPTIONS_SUCCESS = 'executionTags/LOAD_EXECUTION_TAG_FORM_OPTIONS_SUCCESS';
export const LOAD_EXECUTION_TAG_FORM_OPTIONS_FAIL = 'executionTags/LOAD_EXECUTION_TAG_FORM_OPTIONS_FAIL';
