import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useStyles } from 'styles';

import FormInput from 'domains/core/components/FormInput';
import { GridContainer, GridItem } from 'components/Grid';
import DiscountGroupConditions from 'domains/discounts/components/DiscountGroupConditions';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';

import { DiscountGroupTypeDictionary, ItemRetailTypeEnum, ItemRetailCategoryEnum, DiscountGroupType, DiscountGroupConditionFormValues } from 'domains/discounts/models';
import { ItemRetail } from 'domains/core/models';
import { getItemRetailUnitOfMeasure } from 'domains/discounts/selectors';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';

const style = {
	discountGroupContainer: {
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#D2D2D2',
		padding: '12px',
		marginTop: '12px'
	},
	removeConditionContainer: {
		marginTop: '30px',
		marginLeft: '-20px'
	},
	multiplierContainer: {
		marginTop: '35px'
	},
	addConditionContainer: {
		marginTop: '10px',
		marginLeft: '-13px'
	},
	andGroupDivider: {
		marginLeft: '6px',
		marginTop: '15px'
	},
	discountConditionContainer: {
		marginTop: '-15px'
	},
	boldText: {
		fontWeight: 700
	},
	removeGroupContainer: {
		textAlign: 'right' as const,
		marginRight: '18px',
		marginTop: '10px'
	}
};

interface Props {
	selectedGroupTypeId?: DiscountGroupType;
	conditions?: DiscountGroupConditionFormValues[];
	groupName: string;
	itemRetails: ItemRetail[];
	totalDiscountGroups: number;
	index: number;
	handleRemoveGroup: () => void;
}

export default function DiscountGroupForm(props: Props) {
	const { selectedGroupTypeId, conditions, groupName, index, totalDiscountGroups, itemRetails, handleRemoveGroup } = props;
	const classes = useStyles(style);

	const itemRetailUnitOfMeasure = useSelector(state => getItemRetailUnitOfMeasure(state));


	const showUnitOfMeasure = useMemo(() => {
		let showUom = false;
		conditions?.forEach((condition: DiscountGroupConditionFormValues) => {
			if (selectedGroupTypeId === DiscountGroupType.itemRetailType
				&& condition.itemRetailTypeId
				&& (condition.itemRetailTypeId === ItemRetailTypeEnum.dip || condition.itemRetailTypeId === ItemRetailTypeEnum.heatStick)) {
				showUom = true;
			}
			if (selectedGroupTypeId === DiscountGroupType.itemRetailCategory
				&& condition.itemRetailCategoryId
				&& (condition.itemRetailCategoryId === ItemRetailCategoryEnum.dip || condition.itemRetailCategoryId === ItemRetailCategoryEnum.heatStick)) {
				showUom = true;
			}
			if (selectedGroupTypeId === DiscountGroupType.itemRetail && condition.upc) {
				const itemRetail = props.itemRetails.find(x => x.metadata.upc === condition.upc);
				if (itemRetail
					&& itemRetail.metadata
					&& itemRetail.metadata.itemRetailTypeId
					&& (itemRetail.metadata.itemRetailTypeId === ItemRetailTypeEnum.dip || itemRetail.metadata.itemRetailTypeId === ItemRetailTypeEnum.heatStick)) {
					showUom = true;
				}
				if (itemRetail && itemRetail.metadata && itemRetail.metadata.itemRetailCategoryId && (itemRetail.metadata.itemRetailCategoryId === ItemRetailCategoryEnum.dip || itemRetail.metadata.itemRetailCategoryId === ItemRetailCategoryEnum.heatStick)) {
					showUom = true;
				}
			}
		});

		return showUom;
	}, [conditions, props.itemRetails, selectedGroupTypeId]);

	const getDiscountGroupTypeMenuItems = useMemo(() => {
		const menuItems = Object.keys(DiscountGroupTypeDictionary).map((key) => {
			const keyNum = Number(key);
			return ({ value: keyNum, text: DiscountGroupTypeDictionary[key] });
		});
		return menuItems;
	}, []);

	const getUnitOfMeasureTypeMenuItems = useMemo(() => {
		return itemRetailUnitOfMeasure.map(x => ({ value: x.id, text: x.displayName }));
	}, [itemRetailUnitOfMeasure]);

	const showAndDivider = useMemo(() => index !== (totalDiscountGroups - 1), [index, totalDiscountGroups]);

	return (
		<div>
			<div className={classes.discountGroupContainer}>
				<GridContainer>
					<GridItem xs={5} md={5} lg={5}>
						<Field
							name={`${groupName}.discountGroupTypeId`}
							validate={validators.required}
						>
							{(fieldRenderProps) => (
								<FormSelectAdapter
									{...fieldRenderProps}
									labelText="Group Type"
									required
									options={getDiscountGroupTypeMenuItems}
								/>
							)}
						</Field>
					</GridItem>
					<GridItem xs={1} md={1} lg={1}>
						<div className={classes.multiplierContainer}>X</div>
					</GridItem>
					<GridItem xs={2} md={2} lg={2}>
						<Field
							component={FormInput}
							name={`${groupName}.quantity`}
							labelText="Qty"
							formControlProps={{
								required: true
							}}
							parse={normalizers.numeric}
							validate={validators.isGreaterThanZero}
						/>
					</GridItem>
					{showUnitOfMeasure &&
						<GridItem xs={3} md={3} lg={3}>
							<Field
								name={`${groupName}.itemRetailUnitOfMeasureId`}
								validate={validators.required}
							>
								{(fieldRenderProps) => (
									<FormSelectAdapter
										{...fieldRenderProps}
										labelText="UOM"
										required
										options={getUnitOfMeasureTypeMenuItems}
									/>
								)}
							</Field>
						</GridItem>
					}
					<FieldArray name={`${groupName}.conditions`}>
						{fieldProps => (
							<DiscountGroupConditions
								{...fieldProps}
								selectedGroupTypeId={selectedGroupTypeId}
								handleRemoveGroup={handleRemoveGroup}
								itemRetails={itemRetails}
							/>
						)}
					</FieldArray>
				</GridContainer>
			</div>
			{showAndDivider &&
				<div className={classes.andGroupDivider}>
					<span className={classes.boldText}>AND</span>
				</div>
			}
		</div>
	);
}
