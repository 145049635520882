import keyBy from 'lodash/keyBy';
import { CurrentUser } from 'domains/core/models';
import { Roles } from 'domains/core/enums';

export const getUserNameFormatted = (userName: string) => {
	let lclUserName = userName;
	if (lclUserName && lclUserName.indexOf('@') < 0) {
		lclUserName += process.env.REACT_APP_LOGIN_SUFFIX;
	}
	return lclUserName;
};

export const getUserNameNoSuffix = (userName: string) => {
	return userName.replace(process.env.REACT_APP_LOGIN_SUFFIX || 'altdig.com', '');
};

export const buildCurrentUser = (userEntity: any): CurrentUser | undefined => {
	if (!userEntity) {
		return undefined;
	}
	const { activeActions, activeRoles, hasPIN, ...userProps } = userEntity; // strip action, roles and pin flag before assignment
	const userActions = keyBy(userEntity.activeActions, 'name');
	const userRoles = keyBy(userEntity.activeRoles, 'name');
	const canDoAction = (actionName: string) => !!userActions[actionName];
	const hasRole = (roleName: string) => !!userRoles[roleName];
	const isAdmin = hasRole(Roles.GlobalAdmin) || hasRole(Roles.CompanyAdmin);
	const currentUser: CurrentUser = {
		...userProps,
		canDoAction,
		hasRole,
		isAdmin,
		pinConfigurationNeeded: false // will need to set this up later
	};
	return currentUser;
};

export const sort = (column: string, sortColumn: string, sortDirection: 'asc' | 'desc', changeSortColumn: React.Dispatch<React.SetStateAction<string>>, changeSortDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>) => {
	if (sortColumn === column) {
		changeSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
	} else {
		changeSortDirection('asc');
		changeSortColumn(column);
	}
}

export const getDisplayPrice = (price?: number) => {
	if (!price && price !== 0) {
		return `$${(0).toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	if (price < 0) {
		return `-$${(price * -1).toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return `$${price.toFixed(2)}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
