export const LOAD_COMPANIES_NO_OVERLAY = 'companies/LOAD_COMPANIES_NO_OVERLAY';
export const LOAD_COMPANIES = 'companies/LOAD_COMPANIES';
export const LOAD_COMPANIES_SUCCESS = 'companies/LOAD_COMPANIES_SUCCESS';
export const LOAD_COMPANIES_FAIL = 'companies/LOAD_COMPANIES_FAIL';

export const CREATE_COMPANY = 'companies/CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'companies/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'companies/CREATE_COMPANY_FAIL';

export const LOAD_COMPANY = 'companies/LOAD_COMPANY';
export const LOAD_COMPANY_SUCCESS = 'companies/LOAD_COMPANY_SUCCESS';
export const LOAD_COMPANY_FAIL = 'companies/LOAD_COMPANY_FAIL';

export const DEACTIVATE_COMPANY = 'companies/DEACTIVATE_COMPANY';
export const DEACTIVATE_COMPANY_SUCCESS = 'companies/DEACTIVATE_COMPANY_SUCCESS';
export const DEACTIVATE_COMPANY_FAIL = 'companies/DEACTIVATE_COMPANY_FAIL';

export const ACTIVATE_COMPANY = 'companies/ACTIVATE_COMPANY';
export const ACTIVATE_COMPANY_SUCCESS = 'companies/ACTIVATE_COMPANY_SUCCESS';
export const ACTIVATE_COMPANY_FAIL = 'companies/ACTIVATE_COMPANY_FAIL';

export const LOAD_COMPANY_DETAIL = 'companies/LOAD_DETAIL_COMPANY';
export const LOAD_COMPANY_DETAIL_SUCCESS = 'companies/LOAD_COMPANY_DETAIL_SUCCESS';
export const LOAD_COMPANY_DETAIL_FAIL = 'companies/LOAD_COMPANY_DETAIL_FAIL';

export const FILTER_COMPANIES = 'users/FILTER_COMPANIES';
export const FILTER_COMPANIES_RESET = 'users/FILTER_COMPANIES_RESET';
