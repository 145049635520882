import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import some from 'lodash/some';
import invert from 'lodash/invert';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { Button } from 'components/CustomButtons';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { UserRoleDetailsTable } from 'domains/roles/components';
import { RoleUserAction, RoleExportRequest } from 'domains/roles/models';
import { SourceSystem, SourceSystemDictionary } from 'domains/core/models';
import { doLoadRoles, doExportRoleActions } from 'domains/roles/actions';
import { getCeaRoles } from 'domains/roles/selectors';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import ContextMenu from 'domains/core/components/ContextMenu';
import SearchTextField from 'domains/core/components/SearchTextField';
import SearchSelectField from 'domains/core/components/SearchSelectField';

import { useStyles } from 'styles';

const style = {
	title: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	cardBody: {
		paddingTop: 0,
		paddingBottom: '24px'
	}
};

interface Params {
	system: string;
	id: string;
}

export default function UserRoleDetails(): ReactElement {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const match = useParams<Params>();
	const roles = useSelector(state => getCeaRoles(state));

	const role = useMemo(() => {
		if (match && roles && 'filter' in roles) {
			const system: SourceSystem = parseInt((invert(SourceSystemDictionary))[match.system], 10);
			const id: number = parseInt(match.id, 10);
			return roles?.filter(role => role.system === system && role.roleId === id)[0];
		}
	}, [match, roles]);

	const [actionFilter, changeActionFilter] = useState<string>();
	const [categoryFilter, changeCategoryFilter] = useState<string>('All');

	useEffect(() => {
		if (!role) {
			dispatch(doLoadRoles());
		}
	}, [dispatch, role]);

	const clearSearchFilter = useCallback(() => {
		changeActionFilter(undefined);
		changeCategoryFilter('All');
	}, []);

	const exportRole = useCallback(() => {
		if (role) {
			const request: RoleExportRequest = {
				roleId: role.roleId,
				system: role.system,
				actionFilter: actionFilter
			};
			dispatch(doExportRoleActions(request, role));
		}
	}, [actionFilter, dispatch, role]);

	const getContextMenuItems = useCallback(() => {
		const options: JSX.Element[] = [];

		options.push(
			<ContextMenuItem
				icon={<SaveAltIcon color="primary" />}
				action={exportRole}
				text="Export"
			/>
		);

		return options;
	}, [exportRole]);

	const routeToEdit = useCallback(() => {
		history.push(`${history.location.pathname}/edit`);
	}, [history]);

	const filterActions = useCallback((roleAction: RoleUserAction) => {
		return (!actionFilter || actionFilter === '' || actionFilter.trim() === '' || roleAction.action.name.toLowerCase().indexOf(actionFilter.toLowerCase()) > -1)
			&& (!categoryFilter || categoryFilter === 'All' || roleAction.action.category?.toLowerCase() === categoryFilter.toLowerCase());
	}, [actionFilter, categoryFilter]);

	const filteredActions = useMemo(() => role && role.roleActions ? role.roleActions.filter(filterActions) : [], [filterActions, role]);

	const hasCategories = useMemo(() => some(role?.roleActions, a => a.action.category), [role?.roleActions]);

	return (
		<>
			<Card>
				<CardHeader>
					<h2 className={classes.title}>Details</h2>
				</CardHeader>
				<CardBody className={classes.cardBody}>
					<GridContainer>
						<GridItem xs={12} md={4} lg={3}>
							<GridContainer>
								<GridItem xs={12}>
									<FormLabel>Role Name</FormLabel>
								</GridItem>
								<GridItem xs={12}>
									{role?.name}
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12} md={4} lg={3}>
							<GridContainer>
								<GridItem xs={12}>
									<FormLabel>System</FormLabel>
								</GridItem>
								<GridItem xs={12}>
									{role && SourceSystemDictionary[role.system]}
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
			<Card>
				<CardHeader>
					<h2 className={classes.title}>Role Actions</h2>
					<GridContainer>
						<GridItem lg={8} md={12} sm={12}>
							<GridContainer alignItems="center">
								<GridItem xl={1} lg={2} md={3} sm={8}>
									<Button color="primary" onClick={routeToEdit}>Edit</Button>
								</GridItem>
								<GridItem xl={2} lg={2} md={3} sm={4}>
									<ContextMenu menuItems={getContextMenuItems()} />
								</GridItem>
								<GridItem xl={5} lg={4} md={3} sm={12}>
									<SearchTextField id="SearchActionFilter" filterValue={actionFilter} label={hasCategories ? ' ' : undefined} change={changeActionFilter} clearFilter={clearSearchFilter} />
								</GridItem>
								<GridItem xl={4} lg={4} md={3} sm={12}>
									{hasCategories && <SearchSelectField id="SearchForCategoryField" label="Category" value={categoryFilter} change={changeCategoryFilter} availableValues={['All']} />}
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardHeader>
				<CardBody className={classes.cardBody}>
					<GridContainer>
						<GridItem xs={12}>
							{role && <UserRoleDetailsTable actions={filteredActions} hasActionsRemovedByFilter={role && role.roleActions && role.roleActions.length > 0} />}
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		</>
	)
}
