import { fork, all } from 'redux-saga/effects';
import watchForCoreSagas from 'domains/core/sagas';
import watchForCompaniesSagas from 'domains/companies/sagas';
import watchForDeviceSagas from 'domains/devices/sagas';
import watchForUsersSagas from 'domains/users/sagas';
import watchForUserAuditSagas from 'domains/audits/sagas';
import watchForRoleSagas from 'domains/roles/sagas';
import watchForDiscountSagas from 'domains/discounts/sagas';
import watchForExecutionTagSagas from 'domains/executionTags/sagas';
import watchForSecretShopperSagas from 'domains/secretShopper/sagas';

export default function* root() {
	yield all([
		fork(watchForCoreSagas),
		fork(watchForCompaniesSagas),
		fork(watchForDeviceSagas),
		fork(watchForUsersSagas),
		fork(watchForUserAuditSagas),
		fork(watchForRoleSagas),
		fork(watchForDiscountSagas),
		fork(watchForExecutionTagSagas),
		fork(watchForSecretShopperSagas),
	]);
}
