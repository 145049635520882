import indexOf from 'lodash/indexOf';
import merge from 'lodash/merge';
import { schema } from 'normalizr';

export const userAuditSchema = new schema.Entity('userAudits', {}, {
	processStrategy: (values, parent) => {
		const id = indexOf(parent.userAudits, values);
		return merge(values, { id });
	}
});
