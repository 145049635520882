import * as actionTypes from 'domains/executionTags/actionTypes';
import * as schema from 'domains/executionTags/schemas';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import { UpdateExecutionTagActiveStatusRequest } from 'domains/executionTags/models';
import { ExecutionTagSaveRequest } from 'domains/executionTags/models';

export const doLoadExecutionTags = (): Action => {
	return {
		type: actionTypes.LOAD_EXECUTION_TAGS,
	};
};

export const loadExecutionTags = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/ExecutionTag/Get');
		}
	};
};

export const doSaveExecutionTag = (request: ExecutionTagSaveRequest): Action => {
	return {
		type: actionTypes.SAVE_EXECUTION_TAG,
		payload: request
	};
};

export const saveExecutionTag = (request: ExecutionTagSaveRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/ExecutionTag/Save', {
				schema: schema.executionTagDetailsSchema,
				data: request
			});
		}
	};
};

export const loadExecutionTagDetails = (executionTagId: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/ExecutionTag/Get/${executionTagId}`, {
				schema: schema.executionTagDetailsSchema
			});
		}
	};
};

export const doActivateExecutionTag = (request: UpdateExecutionTagActiveStatusRequest): Action => {
	return {
		type: actionTypes.ACTIVATE_EXECUTION_TAG,
		payload: request
	};
};

export const activateExecutionTag = (request: UpdateExecutionTagActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/ExecutionTag/Activate', {
				schema: schema.executionTagDetailsSchema,
				data: request
			});
		}
	};
};

export const doDeactivateExecutionTag = (request: UpdateExecutionTagActiveStatusRequest): Action => {
	return {
		type: actionTypes.DEACTIVATE_EXECUTION_TAG,
		payload: request
	};
};

export const deactivateExecutionTag = (request: UpdateExecutionTagActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/ExecutionTag/Deactivate', {
				schema: schema.executionTagDetailsSchema,
				data: request
			});
		}
	};
};

export const doLoadFormOptions = (): Action => {
	return {
		type: actionTypes.LOAD_EXECUTION_TAG_FORM_OPTIONS
	};
};

export const loadFormOptions = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/ExecutionTag/FormOptions');
		}
	};
};

export const doLoadExecutionTagDetails = (executionTagId: string): Action => {
	return {
		type: actionTypes.LOAD_EXECUTION_TAG_DETAILS,
		payload: executionTagId
	};
};
