import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';

import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import Muted from 'components/Typography/Muted';

import { doActivateCompanyUser, doDeactivateCompanyUser, doResetPin } from 'domains/users/actions';
import { UpdateUserRequest } from 'domains/users/models';
import { getCompanyFromCompanyUser, getUserFromRoute } from 'domains/users/selectors';
import { Companies } from 'domains/companies/models';
import { getCompanyIdFromRouteOrUser } from 'domains/companies/selectors';
import { getCurrentUser } from 'domains/core/selectors';
import { UserActions, Roles } from 'domains/core/enums';
import * as normalizers from 'utils/fieldNormalizers';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import CancelIcon from 'domains/core/components/CancelIcon';
import AddIcon from 'domains/core/components/AddIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import AdminIcon from 'domains/core/components/AdminIcon';
import Modal from 'domains/core/components/Modal';

interface MatchParams {
	companyId: string;
	userPrincipalName: string;
}

const style = {
	customCardContentClass: {
		paddingLeft: 0,
		paddingRight: 0
	},
	cardTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	},
	customCard: {
		marginBottom: '5px'
	},
	buttonPadding: {
		padding: '15px'
	},
	customLinkClass: {
		paddingLeft: '0px',
		paddingRight: '0px'
	},
	noMargin: {
		margin: 0
	}
};

export default function CompanyUserDetails() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const history = useHistory();

	const params = useParams<MatchParams>();

	const companyId = useSelector(state => getCompanyIdFromRouteOrUser(state, { match: { params } }));
	const companyUser = useSelector(state => getUserFromRoute(state, { match: { params } }));
	const company = useSelector(state => getCompanyFromCompanyUser(state));
	const currentUser = useSelector(state => getCurrentUser(state));
	const isLoadingUserDependencies = useSelector(state => state.domains.users.isLoadingUserDependencies);


	const [activateModalOpen, setActivateModalOpen] = useState(false);
	const [resetPinOpen, setResetPinOpen] = useState(false);

	const closeModal = useCallback(() => {
		setActivateModalOpen(false);
		setResetPinOpen(false);
	}, []);

	const openDeactivateModal = useCallback(() => {
		setActivateModalOpen(true);
	}, []);

	const openResetPinModal = useCallback(() => {
		setResetPinOpen(true);
	}, []);

	const handleDeactivateCompanyUser = useCallback(() => {
		if (companyUser && companyUser.azureAdUser) {
			const request: UpdateUserRequest = {
				companyId,
				userPrincipalName: companyUser.azureAdUser.userPrincipalName || '',
			};

			dispatch(doDeactivateCompanyUser(request));
		}
		closeModal();
	}, [closeModal, companyId, companyUser, dispatch]);

	const handleActivateCompanyUser = useCallback(() => {
		if (companyUser && companyUser.azureAdUser) {
			const request: UpdateUserRequest = {
				companyId,
				userPrincipalName: companyUser.azureAdUser.userPrincipalName || '',
			};
			dispatch(doActivateCompanyUser(request));
		}
		closeModal();
	}, [closeModal, companyId, companyUser, dispatch]);

	const handleResetPin = useCallback(() => {

		if (companyUser && companyUser.ceaUser) {
			dispatch(doResetPin(companyUser.ceaUser.azureUID));
			closeModal();
		}
	}, [closeModal, companyUser, dispatch]);

	const navigateToEditCompanyUser = useCallback(() => {
		history.push(`${history.location.pathname}/edit`);
	}, [history]);

	const canEditUser = useMemo(() => {
		if (currentUser) {
			return currentUser.canDoAction(UserActions.CanCreateEditUsers) && companyUser?.azureAdUser && !companyUser.azureAdUser.readOnly;
		}

		return false;
	}, [companyUser, currentUser]);

	const showActivateDeactivateButton = useMemo(() => {
		if (!currentUser) {
			return false;
		}

		if (!companyUser?.ceaUser) {
			return true;
		}

		const isAdmin = companyUser.ceaUser.roleNames.indexOf(Roles.CompanyAdmin) > -1
			|| companyUser.ceaUser.roleNames.indexOf(Roles.GlobalAdmin) > -1;

		return currentUser.id !== companyUser.ceaUser.id
			&& (currentUser.canDoAction(UserActions.CanDeactivateCompanyAdmins) || !isAdmin)
	}, [companyUser, currentUser]);


	const getContextMenuItems = useMemo(() => {
		const isActive = !!companyUser?.azureAdUser?.accountEnabled;
		const userHasPin = !!companyUser?.ceaUser?.hasPIN;
		const userCanResetPin = isActive && userHasPin;

		const options: JSX.Element[] = [];
		if (showActivateDeactivateButton) {
			options.push(
				<ContextMenuItem
					icon={companyUser?.azureAdUser?.accountEnabled ? <CancelIcon /> : <AddIcon />}
					action={openDeactivateModal}
					text={companyUser?.azureAdUser?.accountEnabled ? 'Deactivate' : 'Activate'}
				/>
			);
		}

		if (userCanResetPin) {
			options.push(
				<ContextMenuItem
					icon={<AdminIcon color="warning" />}
					action={openResetPinModal}
					text="Reset PIN"
				/>
			);
		}

		return options;
	}, [companyUser, openDeactivateModal, openResetPinModal, showActivateDeactivateButton]);

	const homeAddress = useMemo(() => companyUser && companyUser.metrixUser && companyUser.metrixUser.homeAddress, [companyUser]);
	const isActive = useMemo(() => !!(companyUser && companyUser.azureAdUser && companyUser.azureAdUser.accountEnabled), [companyUser]);

	if (!companyUser || !currentUser || !company || !companyUser.azureAdUser || isLoadingUserDependencies) {
		return null;
	}

	return (
		<>
			<Card className={classes.customCard}>
				<CardHeader>
					<h2 className={classes.cardTitle}>Details</h2>
				</CardHeader>
				{canEditUser &&
					<>
						<GridContainer className={classes.buttonPadding}>
							<GridItem>
								<Button
									fullWidth
									color="primary"
									onClick={navigateToEditCompanyUser}
									disabled={!isActive}
								>
									Edit User
								</Button>
							</GridItem>
							<GridItem>
								<ContextMenu menuItems={getContextMenuItems} />
							</GridItem>
						</GridContainer>
					</>
				}
				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							<Muted>
								<small>Name</small>
							</Muted>
						</GridItem>
						<GridItem xs={12}>
							<p className={classes.noTopMargin}>{companyUser.azureAdUser.displayName}</p>
						</GridItem>
						{company?.id === Companies.GmrMarketing &&
							<>
								<GridItem xs={12}>
									<Muted>
										<small>Employee ID</small>
									</Muted>
								</GridItem>
								<GridItem xs={12}>
									<p className={classes.noTopMargin}>{companyUser.azureAdUser.employeeId || 'Not Set'}</p>
								</GridItem>
							</>
						}
						{companyUser.azureAdUser && companyUser.metrixUser &&
							<>
								<GridItem xs={12}>
									<Muted>
										<small>Employee Type</small>
									</Muted>
								</GridItem>
								<GridItem xs={12}>
									<p className={classes.noTopMargin}>{companyUser.azureAdUser.importedFromStaffing ? companyUser.metrixUser.employeeType : 'Not Set'}</p>
								</GridItem>
							</>
						}
						<GridItem xs={12}>
							<Muted>
								<small>Email</small>
							</Muted>
						</GridItem>
						<GridItem xs={12}>
							<p className={classes.noTopMargin}>{companyUser.azureAdUser.otherMails && companyUser.azureAdUser.otherMails[0] ? companyUser.azureAdUser.otherMails[0] : 'Not Specified'}</p>
						</GridItem>
						<GridItem xs={12}>
							<Muted>
								<small>Mobile Phone</small>
							</Muted>
						</GridItem>
						<GridItem xs={12}>
							<p className={classes.noTopMargin}>{companyUser.azureAdUser.mobilePhone ? normalizers.phoneNumber(companyUser.azureAdUser.mobilePhone) : 'Not Specified'}</p>
						</GridItem>
						<GridItem xs={12}>
							<Muted>
								<small>Username</small>
							</Muted>
						</GridItem>
						<GridItem xs={12}>
							<p className={classes.noTopMargin}>{companyUser.azureAdUser.userPrincipalName}</p>
						</GridItem>
						{homeAddress &&
							<>
								<GridItem xs={12}>
									<Muted>
										<small>Address</small>
									</Muted>
								</GridItem>
								<GridItem xs={12}>
									<p className={classes.noMargin}>
										{homeAddress.addressLine1}<br />
										{homeAddress.addressLine2 && <>{homeAddress.addressLine2}<br /></>}
										{`${homeAddress.city}, ${homeAddress.state} ${homeAddress.postalCode}`}
									</p>
								</GridItem>
							</>
						}
						<GridItem xs={12}>
							<Muted>
								<small>Has Pin</small>
							</Muted>
						</GridItem>
						<GridItem xs={12}>
							<p className={classes.noTopMargin}>{companyUser.ceaUser && companyUser.ceaUser.hasPIN ? 'Yes' : 'No'}</p>
						</GridItem>
						{companyUser.azureAdUser.importedFromStaffing &&
							<GridItem xs={12}>
								<p className={classes.noTopMargin}>* Imported from Staffing</p>
							</GridItem>
						}
						{companyUser.azureAdUser.readOnly &&
							<GridItem xs={12}>
								<p className={classes.noTopMargin}>* Production Account</p>
							</GridItem>
						}
					</GridContainer>
				</CardBody>
			</Card>
			<Modal
				modalOpen={activateModalOpen}
				title={`${companyUser.azureAdUser.accountEnabled ? 'Deactivate' : 'Activate'} ${companyUser.azureAdUser.displayName}?`}
				content={`${companyUser.azureAdUser.displayName} will ${companyUser.azureAdUser.accountEnabled ? 'not' : ''} be able to login.`}
				closeButtonText="Cancel"
				handleClose={closeModal}
				actionButtonText={companyUser.azureAdUser.accountEnabled ? 'Deactivate' : 'Activate'}
				handleAction={companyUser.azureAdUser.accountEnabled ? handleDeactivateCompanyUser : handleActivateCompanyUser}
			/>
			<Modal
				modalOpen={resetPinOpen}
				title="Reset PIN"
				content="Are you sure you want to reset the user's pin?"
				closeButtonText="No"
				actionButtonText="Yes"
				handleClose={closeModal}
				handleAction={handleResetPin}
			/>
		</>
	);
}
