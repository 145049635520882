import React from 'react';
import { FormControl, RadioGroup, FormLabel, FormHelperText } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';

import { RadioButtonListItem } from 'domains/core/models';
import RadioButtonWrapper from 'domains/core/components/RadioButtonWrapper';
import { FieldRenderProps } from 'react-final-form';

interface OwnProps {
	children: RadioButtonListItem[];
	formControlProps?: FormControlProps;
	id: string;
	labelText?: string;
	labelClass?: string;
	fullWidth?: boolean;
	disabled?: boolean;
	margin?: 'none' | 'dense' | 'normal';
	contentRight?: boolean;
	row?: boolean;
	radioChanged?: (value: any) => void;
}

type Props = OwnProps & FieldRenderProps<any>;
export default function RadioButtonListAdapter(props: Props) {
	const handleOnChange = (value: any) => () => {
		const { radioChanged, input: { onChange } } = props;
		onChange(value);
		if (radioChanged) {
			radioChanged(value);
		}
	}

	const getRadioButtons = () => {
		const { children, id, input: { value } } = props;
		const checkboxes = children.map((item: RadioButtonListItem, i: number) => {
			const checked = value === item.value;
			return (
				<RadioButtonWrapper
					key={`${i}-${item.value}`}
					id={`radioButton-${id}-${item.value}`}
					value={item.value.toString()}
					labelText={item.label}
					checked={checked}
					handleOnChange={handleOnChange(item.value)}
					disabled={item.disabled}
				/>
			);
		});
		return checkboxes;
	}

	const { radioChanged, id, fullWidth, contentRight, disabled, margin, labelText, labelClass, formControlProps, row,
		meta: { touched, error }, ...rest } = props;

	const isError = !!(touched && error);
	const helperText = touched ? error : '';
	const radioButtons = getRadioButtons();

	return (
		<FormControl
			id={id}
			fullWidth={fullWidth}
			disabled={disabled}
			margin={margin ?? 'none'}
			style={{ paddingTop: 27 }}
			{...formControlProps}
		>
			{labelText && <FormLabel className={labelClass || ''}>{labelText}</FormLabel>}
			<RadioGroup {...rest} row={row}>
				{radioButtons}
			</RadioGroup>
			{isError && <FormHelperText error>{helperText}</FormHelperText>}
		</FormControl>
	);
}
