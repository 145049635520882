import superagent, { SuperAgentRequest } from 'superagent';
import { normalize } from 'normalizr';
import FileSaver from 'file-saver';
import { getCeaJwt, formatUrl } from './apiHelpers';
import { SourceSystem } from 'utils/constants';
import methods from 'methods';
import noCache from 'superagent-no-cache';

type MethodType = typeof methods[0] | 'del';

export default class ApiClient {
	public get(path: any, options: any) {
		return this.performRequest('get', path, options);
	}

	public post(path: any, options: any) {
		return this.performRequest('post', path, options);
	}

	public put(path: any, options: any) {
		return this.performRequest('put', path, options);
	}

	public patch(path: any, options: any) {
		return this.performRequest('patch', path, options);
	}

	public del(path: any, options: any) {
		return this.performRequest('del', path, options);
	}

	private performRequest(method: MethodType, path: any, options: any) {
		return new Promise((resolve, reject) => {
			const request: SuperAgentRequest = superagent[method](formatUrl(path));

			request.use(noCache);

			const ceaJwt = getCeaJwt();
			if (ceaJwt) {
				// set authentication token on header
				request.set('Authorization', `Bearer ${ceaJwt}`);
			}

			// set default request type
			request.type('application/json');

			if (options && options.type) {
				request.type(options.type);
			}

			if (options && options.responseType) {
				request.responseType(options.responseType);
			}

			if (options && options.params) {
				request.query(options.params);
			}

			if (options && options.data) {
				request.send(options.data);
			}

			request.set('SelectedSourceSystemId', `${SourceSystem.CeaAdmin}`);

			request.end((err: any, res: any) => {
				if (!err) {

					if (res.header['content-type'] === 'text/csv') {
						const blob = new Blob([res.text], { type: res.header['content-type'] });
						FileSaver.saveAs(blob, options && options.filename ? options.filename : 'Export.csv');
						resolve(null);
					}

					if (options && options.schema && res.body) {
						// only normalize when we have a schema and body
						if (res.body.success !== undefined) {
							if (res.body.success) {
								resolve(normalize(res.body.data, options.schema));
							} else {
								reject({
									...res.body.errorMessage,
									status: res.status
								})
							}
						} else {
							resolve(normalize(res.body, options.schema));
						}
					} else {
						resolve(res.body);
					}
				} else if (res && res.body) {
					reject({
						...((res && res.body) || err),
						status: err.status
					});
				} else {
					// superagent always returns a CORS error when there are connection issues
					// until superagent is updated to return a better message, give a generic error.
					const message = res && res.status === 400 && res.text ? res.text : 'Error occurred while making API call.';
					reject({
						...({ status: res && res.status, message })
					});
				}
			});
		});
	}
}
