import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useStyles } from 'styles';

import { Card, CardHeader, CardBody } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { CreateEditDiscountForm } from 'domains/discounts/components';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

import { doLoadDiscountAndDependencies, doSaveDiscount } from 'domains/discounts/actions';
import { Discount } from 'domains/core/models';
import { DiscountFormValues } from 'domains/discounts/models';
import { useOnMount } from 'utils/React';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function CreateDiscount() {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	useOnMount(() => {
		dispatch(doLoadDiscountAndDependencies());
	});

	const getInitialValues = useMemo(() => {
		return {
			active: false,
			shouldAutoApply: false,
			isPhotoCaptureRequired: false,
			isCcnRequiredForAutoApply: false,
			isCcnRequiredForManualApply: false,
			isExclusiveItemDiscount: false,
			isCopeExclusiveItemDiscount: false,
			rowVersion: undefined,
			displayInCea: true,
			isLockedDown: false,
			isCartMinimumPriceEnabled: false,
			hasCartConditionItems: false,
			hasTimeFrameLimit: false,
			discountMarketIds: [],
			retailLocationTypeIds: [],
			discountGroups: [{ conditions: [{}] }]
		};
	}, []);

	const handleSaveDiscount = useCallback((data: DiscountFormValues) => {
		const discount: Discount = {
			...data,
			id: 0,
			discountTypeId: data.discountTypeId ?? 0,
			effectiveStartDate: moment(data.effectiveStartDate),
			isMaxLimitEnabled: !!data.maxLimitPerCart,
			manualApplyPromoCode: !!data.promoCode,
			discountGroups: data.discountGroups?.map(g => ({
				...g,
				discountGroupTypeId: g.discountGroupTypeId ?? 0,
				quantity: g.quantity ?? 0,
				canOverrideMinimumPrice: !!g.canOverrideMinimumPrice,
				id: 0,
				discountId: 0,
				conditions: g.conditions?.map(c => ({
					...c,
					id: 0,
				})) ?? [],
			})) ?? []
		};

		dispatch(doSaveDiscount(discount));
	}, [dispatch]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Add Discount</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<Form
							initialValues={getInitialValues}
							onSubmit={handleSaveDiscount}
							mutators={{ ...arrayMutators }}
						>
							{(formRenderProps: FormRenderProps<DiscountFormValues>) => (
								<CreateEditDiscountForm editMode={false} {...formRenderProps} />
							)}
						</Form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
