import { RetailLocationTypeEnum } from "domains/discounts/models";
import { ExecutionTagDetails, ExecutionTagFormOptions, ExecutionTagFormValues } from "domains/executionTags/models";

export const getValidMarketOptions = (values: ExecutionTagFormValues, options?: ExecutionTagFormOptions) => {
	return options?.markets.filter(x => x.programId === values.programId) || [];
}

export const getValidRetailLocationOptions = (values: ExecutionTagFormValues, options?: ExecutionTagFormOptions) => {
	const retailLocationOptions = options?.retailLocations.filter(x => {
		if (values.programId && x.programId !== values.programId) {
			return false;
		}

		if (values.marketIds && values.marketIds.length > 0 && !values.marketIds?.includes(x.marketId)) {
			return false;
		}

		if (values.retailLocationTypeIds && values.retailLocationTypeIds.length > 0 &&!values.retailLocationTypeIds.includes(x.storeType)) {
			return false;
		}

		if (x.storeType === RetailLocationTypeEnum.physicalLocation && values.physicalLocationSubTypeIds && x.physicalLocationSubType
			&& values.physicalLocationSubTypeIds.length > 0 && !values.physicalLocationSubTypeIds.includes(x.physicalLocationSubType)) {
			return false;
		}

		if (x.storeType === RetailLocationTypeEnum.mobileRetailUnit && values.mruSubTypeIds && x.mruSubType
			&& values.mruSubTypeIds.length > 0 && !values.mruSubTypeIds.includes(x.mruSubType)) {
			return false;
		}

		return true;
	}) || [];

	return retailLocationOptions;
};

export const getInitialValues = (executionTag?: ExecutionTagDetails, options?: ExecutionTagFormOptions): ExecutionTagFormValues | undefined => {
	if (!(executionTag && options)) {
		return undefined;
	}

	const initValues: ExecutionTagFormValues = {
		name: executionTag.name,
		displayName: executionTag.displayName,
		startDate: executionTag.startDate,
		endDate: executionTag.endDate,
		description: executionTag.description,
		programId: executionTag.programId,
		retailLocationTypeIds: executionTag.retailLocationTypeIds ? executionTag.retailLocationTypeIds : options.retailLocationTypes.map(x => x.id),
		physicalLocationSubTypeIds: executionTag.physicalLocationSubTypeIds ? executionTag.physicalLocationSubTypeIds : options.physicalLocationSubTypes.map(x => x.id),
		mruSubTypeIds: executionTag.mruSubTypeIds ? executionTag?.mruSubTypeIds : options.mruSubTypes.map(x => x.id),
	};

	const marketOptions = getValidMarketOptions({ ...initValues } as any, options);
	initValues.marketIds = executionTag.marketIds ? executionTag.marketIds : marketOptions.map(x => x.marketId);

	const retailLocationOptions = getValidRetailLocationOptions({ ...initValues } as any, options);
	initValues.retailLocationIds = executionTag.retailLocationIds ? executionTag.retailLocationIds : retailLocationOptions.map(x => x.id)

	return initValues;
};
