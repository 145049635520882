import { fork, all, put, actionChannel, take, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/discounts/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import * as actions from 'domains/discounts/actions';
import history from 'adminHistory';
import { DiscountSearchRequest, UpdateDiscountActiveStatusRequest } from 'domains/discounts/models';
import { Discount } from 'domains/core/models';
import { callApi } from 'utils/apiSaga';
import { errorToast } from 'domains/core/components/Toaster';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadDiscounts(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_DISCOUNTS);
	while (true) {
		const request = yield take(requestChan);
		yield call(handleLoadDiscounts, request.payload);
	}
}

export function* handleLoadDiscounts(request: DiscountSearchRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadDiscounts(request));
		yield put({ type: actionTypes.LOAD_DISCOUNTS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNTS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error loading discounts.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForExportDiscountList(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.EXPORT_DISCOUNT_LIST);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleExportDiscountList, result.payload.request);
	}
}

export function* handleExportDiscountList(request: DiscountSearchRequest): SagaIterator {
	try {
		const result = yield callApi(actions.exportDiscountList(request));
		yield put({ type: actionTypes.EXPORT_DISCOUNT_LIST_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.EXPORT_DISCOUNT_LIST_FAIL, error });
	}
}

export function* watchForLoadDiscountFilters(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_DISCOUNT_FILTERS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadDiscountFilters);
		yield call(handleLoadDiscountMarkets);
	}
}

export function* handleLoadDiscountFilters(): SagaIterator {
	try {
		const result = yield callApi(actions.loadDiscountFilters());
		yield put({ type: actionTypes.LOAD_DISCOUNT_FILTERS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_FILTERS_FAIL, error });
	}
}

export function* watchForSaveDiscount(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.SAVE_DISCOUNT);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleSaveDiscount, action.payload);
	}
}

export function* handleSaveDiscount(discount: Discount): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const response = yield callApi(actions.saveDiscount(discount));
		yield put({ type: actionTypes.SAVE_DISCOUNT_SUCCESS, response });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		history.push(`/pricing/discounts/view/${response.id}`);
	} catch (error) {
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: actionTypes.SAVE_DISCOUNT_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the discount.');
	}
}

export function* watchForLoadDiscountTypes(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_DISCOUNT_TYPES);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadDiscountTypes);
	}
}

export function* watchForLoadDiscountAndDependencies(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES);
	while (true) {
		const result = yield take(requestChan);
		yield call(handleLoadDiscountAndDependencies, result.payload.discountId);
	}
}

export function* handleLoadDiscountAndDependencies(discountId?: number) {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		yield call(handleLoadCeaPrograms);
		yield call(handleLoadDiscountTypes);
		yield call(handleLoadItemRetailTypes);
		yield call(handleLoadItemRetailCategories);
		yield call(handleLoadItemRetailUnitOfMeasure);
		yield call(handleLoadDiscountTimeFrameTypes);
		yield call(handleLoadDiscountMarkets);
		yield call(handleLoadRetailLocations);
		yield call(handleLoadRetailLocationTypes);
		yield call(handleLoadRetailLocationLocationTypes);
		yield call(handleLoadRetailLocationMruTypes);

		if (discountId) {
			yield call(handleLoadSelectedDiscount, discountId);
		}

		yield put({ type: actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_SUCCESS });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_FAIL });
		errorToast(error.status === 400 ? error.message : 'There was an error loading the discount.');
	}
}

export function* handleLoadCeaPrograms(): SagaIterator {
	try {
		const result = yield callApi(actions.loadCeaPrograms());
		yield put({ type: actionTypes.LOAD_CEA_PROGRAMS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_CEA_PROGRAMS_FAIL, error });
	}
}

export function* handleLoadDiscountTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadDiscountTypes());
		yield put({ type: actionTypes.LOAD_DISCOUNT_TYPES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_TYPES_FAIL, error });
	}
}

export function* handleLoadItemRetailTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadItemRetailTypes());
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_TYPES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_TYPES_FAIL, error });
	}
}

export function* handleLoadItemRetailCategories(): SagaIterator {
	try {
		const result = yield callApi(actions.loadItemRetailCategories());
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_CATEGORIES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_CATEGORIES_FAIL, error });
	}
}

export function* handleLoadDiscountTimeFrameTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadDiscountTimeFrameTypes());
		yield put({ type: actionTypes.LOAD_DISCOUNT_TIME_FRAME_TYPES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_TIME_FRAME_TYPES_FAIL, error });
	}
}

export function* handleLoadItemRetailUnitOfMeasure(): SagaIterator {
	try {
		const result = yield callApi(actions.loadItemRetailUnitOfMeasure());
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_FAIL, error });
	}
}

export function* handleLoadDiscountMarkets(): SagaIterator {
	try {
		const result = yield callApi(actions.loadDiscountMarkets());
		yield put({ type: actionTypes.LOAD_DISCOUNT_MARKETS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_MARKETS_FAIL, error });
	}
}

export function* handleLoadRetailLocations(): SagaIterator {
	try {
		const result = yield callApi(actions.loadRetailLocations());
		yield put({ type: actionTypes.LOAD_DISCOUNT_RETAIL_LOCATIONS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_RETAIL_LOCATIONS_FAIL, error });
	}
}

export function* handleLoadRetailLocationTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadRetailLocationTypes());
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_TYPES, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_TYPES_FAIL, error });
	}
}

export function* handleLoadRetailLocationLocationTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadRetailLocationLocationTypes());
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_LOCATION_TYPES, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_LOCATION_TYPES_FAIL, error });
	}
}

export function* handleLoadRetailLocationMruTypes(): SagaIterator {
	try {
		const result = yield callApi(actions.loadRetailLocationMruTypes());
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_MRU_TYPES, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_RETAIL_LOCATION_MRU_TYPES_FAIL, error });
	}
}

export function* handleLoadSelectedDiscount(discountId: number): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadSelectedDiscount(discountId));
		const discount: Discount = result.entities.selectedDiscount[discountId];

		yield call(handleLoadItemRetails, discount.programId);
		yield put({ type: actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_FAIL, error });
	}
}

export function* watchForLoadItemRetails(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_ITEM_RETAILS);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadItemRetails, action.payload);
	}
}

export function* handleLoadItemRetails(programId: number): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadItemRetails(programId));
		yield put({ type: actionTypes.LOAD_ITEM_RETAILS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_ITEM_RETAILS_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForActivateDiscount(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.ACTIVATE_DISCOUNT);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleActivateDiscount, request.payload);
	}
}

export function* handleActivateDiscount(request: UpdateDiscountActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.activateDiscount(request));
		yield put({ type: actionTypes.ACTIVATE_DISCOUNT_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.ACTIVATE_DISCOUNT_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error activating the discount.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForDeactivateDiscount(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.DEACTIVATE_DISCOUNT);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleDeactivateDiscount, request.payload);
	}
}

export function* handleDeactivateDiscount(request: UpdateDiscountActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.deactivateDiscount(request));
		yield put({ type: actionTypes.DEACTIVATE_DISCOUNT_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_DISCOUNT_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export default function* watchForDiscountSagas() {
	yield all([
		fork(watchForLoadDiscounts),
		fork(watchForExportDiscountList),
		fork(watchForLoadDiscountFilters),
		fork(watchForSaveDiscount),
		fork(watchForLoadDiscountAndDependencies),
		fork(watchForActivateDiscount),
		fork(watchForDeactivateDiscount),
		fork(watchForLoadItemRetails)
	]);
}
