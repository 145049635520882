import React, { useCallback, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import { matchPath, useHistory } from 'react-router-dom';
import { Button } from 'components/CustomButtons';
import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle';
import { getCompanies } from 'domains/companies/selectors';
import { getUsers } from 'domains/users/selectors';
import { companyDetailsPage, userDetailsPage, userRoleDetailsPage, terminalMappingDetailView, terminalMappingDetailEdit, discountDetailsPage, discountEditPage, executionTagDetailsPage, executionTagEditPage } from 'routes/DashboardRoutes';
import { DashboardRoute, DashboardView } from 'routes';
import { User } from 'domains/core/models';
import { doLoadCompaniesNoOverlay } from 'domains/companies/actions';
import { getCeaRoles } from 'domains/roles/selectors';
import { getSelectedDiscountEntities } from 'domains/discounts/selectors';
import { useStyles } from 'styles';

const style = () => {
	return {
		...headerStyle(),
		title: {
			...headerStyle().title,
			padding: '12px',
			fontSize: 16
		}
	};
};

export interface Props {
	routes: (DashboardRoute | DashboardView)[];
	miniActive: boolean;
	sidebarMinimize: () => void;
	handleDrawerToggle: () => void;
}

type Route = DashboardRoute & DashboardView;

export default function AdminHeader(props: Props) {
	const { routes, miniActive, sidebarMinimize, handleDrawerToggle } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles(style);

	const companies = useSelector(state => getCompanies(state));
	const users = useSelector(state => getUsers(state));
	const roles = useSelector(state => getCeaRoles(state));
	const existingDeviceTerminalMapping = useSelector(state => state.domains.devices.existingDeviceTerminalMapping);
	const selectedDiscount = useSelector(state => getSelectedDiscountEntities(state));
	const executionTags = useSelector(state => state.entities.executionTags);

	useEffect(() => {
		if (isEmpty(companies)) {
			dispatch(doLoadCompaniesNoOverlay({}));
		}
	}, [companies, dispatch]);

	const paths = useMemo(() => {
		const names: { name?: string; url?: string }[] = [];
		const tempRoutes = orderBy(orderBy(routes, x => {
			const result = x.path.split("/");
			return result ? result.length : 0;
		}), x => x.path); // Orders routes by number of slashes, so that we can have correct breadcrumb order without sacrificing ability to route to collapsed children
		tempRoutes.map((prop: Route) => {
			const match = matchPath(location.pathname, { path: prop.path, exact: false });
			if (match && !prop.redirect) {
				if (isEmpty(match.params)) {
					names.push({ name: prop.name, url: prop.component ? match.url : undefined });
				} else {
					forOwn(match.params, (value) => {
						// add unique route name only once (if route has more than one param)
						if (Number(value) && !names.find(x => x.name === prop.name)) {
							const user = users.find(x => x.azureAdUser && x.azureAdUser.userPrincipalName && x.azureAdUser.userPrincipalName.split('@')[0] === (match.params as any).userPrincipalName);
							const company = companies.find(x => x.id === parseInt((match.params as any).companyId));
							const role = roles.find(x => x.roleId === parseInt((match.params as any).id));
							const discount = selectedDiscount[(match.params as any).id];
							const executionTag = executionTags[(match.params as any).id];
							if (prop.name === companyDetailsPage().name && company) {
								names.push({ name: company.name, url: match.url });
							} else if (prop.name === userDetailsPage().name && user) {
								names.push({ name: (user as User).azureAdUser!.displayName, url: match.url });
							} else if (prop.name === userRoleDetailsPage.name && role) {
								names.push({ name: role.name, url: match.url });
							} else if ((prop.name === terminalMappingDetailEdit.name || prop.name === terminalMappingDetailView.name) && existingDeviceTerminalMapping?.deviceName) {
								names.push({ name: existingDeviceTerminalMapping.deviceName, url: match.url });
							} else if ((prop.name === discountDetailsPage.name || prop.name === discountEditPage.name) && discount) {
								names.push({ name: discount.name, url: match.url });
							} else if ((prop.name === executionTagDetailsPage.name || prop.name === executionTagEditPage.name) && executionTag) {
								names.push({ name: executionTag.name, url: match.url });
							} else {
								names.push({ name: prop.name, url: prop.component ? match.url : undefined });
							}
						}
					});
				}
			}
			return null;
		});

		return names;
	}, [companies, executionTags, existingDeviceTerminalMapping?.deviceName, roles, routes, selectedDiscount, users]);

	const routeTo = useCallback((url: string) => () => {
		history.push(url);
	}, [history]);

	return (
		<AppBar className={classes.appBar}>
			<Toolbar className={classes.container}>
				<Hidden smDown implementation="css">
					<div className={classes.sidebarMinimize}>
						{miniActive ?
							(
								<Button
									justIcon
									round
									color="white"
									onClick={sidebarMinimize}
								>
									<ViewList className={classes.sidebarMiniIcon} />
								</Button>
							) : (
								<Button
									justIcon
									round
									color="white"
									onClick={sidebarMinimize}									>
									<MoreVert className={classes.sidebarMiniIcon} />
								</Button>
							)}
					</div>
				</Hidden>
				<div className={classes.flex}>
					{
						paths.map((prop, i) => (
							<span key={`${i}-${prop}`}>
								<Button className={classes.title} color="transparent" onClick={prop.url && prop.url !== '' ? routeTo(prop.url) : undefined}>
									{prop.name}
								</Button>
								{paths.length - 1 !== i ? <Button style={{ padding: '12px 0' }} className={classes.title} color="transparent">\</Button> : null}
							</span>
						))
					}
				</div>
				<Hidden mdUp implementation="css">
					<Button
						color="transparent"
						justIcon
						aria-label="open drawer"
						onClick={handleDrawerToggle}
					>
						<Menu />
					</Button>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}
