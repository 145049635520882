import React from 'react';
import { SaveAlt } from '@material-ui/icons';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const style = {
	customIconStyle: {
		color: primaryColor,
		fontSize: '16px'
	},
};

export type Props = WithStyles;

export const SaveAltIcon = (props: Props) => <SaveAlt className={props.classes.customIconStyle} />;

export default withStyles(style)(SaveAltIcon);
