import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useStyles } from 'styles';

import { Card, CardHeader, CardBody } from 'components/Card';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import Muted from 'components/Typography/Muted';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import CancelIcon from 'domains/core/components/CancelIcon';
import AddIcon from 'domains/core/components/AddIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import Modal from 'domains/core/components/Modal';

import { doLoadDiscountAndDependencies, doActivateDiscount, doDeactivateDiscount } from 'domains/discounts/actions';
import { DiscountEffectType, UpdateDiscountActiveStatusRequest } from 'domains/discounts/models';
import { getSelectedDiscount, getItemRetailCategories, getItemRetailTypes, getItemRetailUnitOfMeasure, getItemRetails, getDiscountTimeFrameTypes } from 'domains/discounts/selectors';

const style = {
	customCardContentClass: {
		paddingLeft: 0,
		paddingRight: 0
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	},
	noMargin: {
		margin: '0px'
	},
	customCard: {
		marginBottom: '5px'
	},
	buttonPadding: {
		padding: '0px 15px 15px'
	},
	customLinkClass: {
		paddingLeft: '0px',
		paddingRight: '0px'
	},
	sectionBreak: {
		marginTop: '15px'
	},
	indent: {
		marginLeft: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	},
	moreBottomMargin: {
		marginBottom: '15px'
	},
};

export default function DiscountDetails() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles(style);
	const { id } = useParams<{ id: string }>();

	const [modalOpen, setModalOpen] = useState(false);

	const discount = useSelector(state => getSelectedDiscount(state, { match: { params: { id } } }));
	const itemRetailCategories = useSelector(state => getItemRetailCategories(state));
	const itemRetailTypes = useSelector(state => getItemRetailTypes(state));
	const itemRetailUnitOfMeasure = useSelector(state => getItemRetailUnitOfMeasure(state));
	const itemRetails = useSelector(state => getItemRetails(state));
	const discountTimeFrameTypes = useSelector(state => getDiscountTimeFrameTypes(state));

	useEffect(() => {
		if (id && Number(id) > 0) {
			dispatch(doLoadDiscountAndDependencies(Number(id)));
		}
	}, [dispatch, id]);

	const closeModal = () => {
		setModalOpen(false);
	}

	const openModal = () => {
		setModalOpen(true);
	}

	const handleDeactivateDiscount = useCallback(() => {
		if (discount) {
			const request: UpdateDiscountActiveStatusRequest = {
				discountId: discount.id,
				rowVersion: discount.rowVersion
			};
			doDeactivateDiscount(request);
		}
		closeModal();
	}, [discount]);

	const handleActivateDiscount = useCallback(() => {
		if (discount) {
			const request: UpdateDiscountActiveStatusRequest = {
				discountId: discount.id,
				rowVersion: discount.rowVersion
			};
			dispatch(doActivateDiscount(request));
		}
		closeModal();
	}, [discount, dispatch]);

	const navigateToEditDiscount = useCallback(() => {
		if (discount) {
			history.push(`/pricing/discounts/${discount.id}/edit`);
		}
	}, [discount, history]);

	const getContextMenuItems = useMemo(() => {
		const contextMenuItems: JSX.Element[] = [];

		if (discount) {
			contextMenuItems.push(
				<ContextMenuItem
					icon={discount.active ? <CancelIcon /> : <AddIcon />}
					action={openModal}
					text={discount.active ? 'Deactivate' : 'Activate'}
				/>
			);
		}

		return contextMenuItems;
	}, [discount]);

	const minimumPriceText = useCallback((isMinimumPrice: boolean) => {
		if (isMinimumPrice) {
			return <span>(below minimum price)</span>;
		}
		return "";
	}, []);

	const getItemRetailUnitOfMeasureDescription = useCallback((value: number) => {
		const unitOfMeasure = itemRetailUnitOfMeasure.find(x => x.id === value);
		if (unitOfMeasure?.displayName) {
			return unitOfMeasure?.displayName;
		}
		return "";
	}, [itemRetailUnitOfMeasure]);

	const getTimeFrameTypeDescription = useCallback((value?: number | null) => {
		if (!value) {
			return "";
		}

		const timeFrameType = discountTimeFrameTypes.find(x => x.id === value);
		if (timeFrameType?.displayName) {
			return timeFrameType?.displayName;
		}
		return "";
	}, [discountTimeFrameTypes]);

	const getItemRetailTypeDescription = useCallback((value: number) => {
		const itemRetailType = itemRetailTypes.find(x => x.id === value);
		if (itemRetailType?.displayName) {
			return itemRetailType?.displayName;
		}
		return "";
	}, [itemRetailTypes]);

	const getItemRetailCategoryDescription = useCallback((value: number) => {
		const itemRetailCategory = itemRetailCategories.find(x => x.id === value);
		if (itemRetailCategory?.displayName) {
			return itemRetailCategory?.displayName;
		}
		return "";
	}, [itemRetailCategories]);

	const getItemRetailDescription = useCallback((value: string) => {
		const itemRetail = itemRetails.find(x => x.metadata.upc === value);
		if (itemRetail?.metadata.name) {
			return itemRetail?.metadata.name;
		}
		return "";
	}, [itemRetails]);

	const renderRequiredItems = useMemo(() => {
		const requiredItems: JSX.Element[] = [];

		if (!discount || discount.discountGroups.length === 0) {
			return requiredItems;
		}

		if (discount.discountGroups.length > 0) {
			discount.discountGroups.forEach((group) => {

				let groupDescription = "";
				if (group.quantity) {
					groupDescription += `${group.quantity}x `;
				}

				if (group.conditions.length > 0) {
					group.conditions.forEach((condition, index) => {
						let orDescription = "";
						if (index >= 0 && index < group.conditions.length - 1) {
							orDescription += " or";
						}
						if (index === 0) {
							let groupTypeText = "";
							if (condition.itemRetailTypeId) {
								groupTypeText = "Item Retail Type";
							}
							if (condition.itemRetailCategoryId) {
								groupTypeText = "Item Retail Category";
							}
							if (condition.upc) {
								groupTypeText = "Item Name / UPC";
							}

							requiredItems.push(
								<p key={`${condition.id}-1`} className={classes.noMargin}>{groupDescription} {groupTypeText}</p>
							);
						}

						let unitOfMeasure = "";
						if (condition.itemRetailUnitOfMeasureId) {
							unitOfMeasure = getItemRetailUnitOfMeasureDescription(condition.itemRetailUnitOfMeasureId);
						}
						if (condition.itemRetailTypeId) {
							requiredItems.push(
								<p key={`${condition.id}-2`} className={classes.indent}>{unitOfMeasure} {getItemRetailTypeDescription(condition.itemRetailTypeId)}{orDescription}</p>
							);
						}
						if (condition.itemRetailCategoryId) {
							requiredItems.push(
								<p key={`${condition.id}-3`} className={classes.indent}>{unitOfMeasure} {getItemRetailCategoryDescription(condition.itemRetailCategoryId)}{orDescription}</p>
							);
						}
						if (condition.upc) {
							requiredItems.push(
								<p key={`${condition.id}-4`} className={classes.indent}>{unitOfMeasure} {getItemRetailDescription(condition.upc)}{orDescription}</p>
							);
						}

					});
				}

			});
		}

		return requiredItems;
	}, [classes, discount, getItemRetailCategoryDescription, getItemRetailDescription, getItemRetailTypeDescription, getItemRetailUnitOfMeasureDescription]);

	const renderDiscountAmount = useMemo(() => {
		const discountAmounts: JSX.Element[] = [];

		if (!discount) {
			return discountAmounts;
		}
		const isPercentDiscount = discount.discountEffectTypeId === DiscountEffectType.percentOffCart || discount.discountEffectTypeId === DiscountEffectType.percentOffItems || discount.discountEffectTypeId === DiscountEffectType.cartPercentageDiscountWithConditions;
		const isFinalPriceDiscount = discount.discountEffectTypeId === DiscountEffectType.itemFinalPriceDiscount;

		let groupDiscountAmount = 0;
		if (discount.discountGroups.length > 0) {
			discount.discountGroups.forEach((group) => {
				if (group.discountAmount && group.discountAmount > 0) {
					groupDiscountAmount += group.discountAmount;
				}
			});
		}

		if (discount.discountPercentage) {
			discountAmounts.push(
				<p key={`${discount.id}-1`} className={classes.noTopMargin}>{discount?.discountPercentage}% off Cart</p>
			);
		}

		if (groupDiscountAmount === 0 && !isPercentDiscount && !isFinalPriceDiscount) {
			discountAmounts.push(
				<p key={`${discount.id}-2`} className={classes.noTopMargin}>${discount?.discountAmount.toFixed(2)} off Cart</p>
			);
		}

		if (groupDiscountAmount > 0 || isPercentDiscount || isFinalPriceDiscount) {
			discount.discountGroups.forEach((group) => {
				if ((group.discountAmount && group.discountAmount > 0) || (group.discountPercentage && group.discountPercentage > 0) || isFinalPriceDiscount) {
					if (isFinalPriceDiscount && group.finalPrice !== undefined) {
						discountAmounts.push(
							<p key={`${discount.id}-${group.id}-3`} className={classes.noMargin}>${(group.finalPrice).toFixed(0)} {minimumPriceText(group.canOverrideMinimumPrice)}</p>
						);
					}
					if (isPercentDiscount) {
						if (group.discountPercentage && group.discountPercentage > 0) {
							discountAmounts.push(
								<p key={`${discount.id}-${group.id}-4`} className={classes.noMargin}>{(group.discountPercentage).toFixed(0)}% off {minimumPriceText(group.canOverrideMinimumPrice)}</p>
							);
						}
					}
					if (!isFinalPriceDiscount && !isPercentDiscount) {
						if (group.discountAmount && group.discountAmount > 0) {
							discountAmounts.push(
								<p key={`${discount.id}-${group.id}-5`} className={classes.noMargin}>${group.discountAmount.toFixed(2)} off {minimumPriceText(group.canOverrideMinimumPrice)}</p>
							);
						}
					}

					if (group.conditions.length > 0) {
						group.conditions.forEach((condition, index) => {
							let orDescription = "";
							if (index >= 0 && index < group.conditions.length - 1) {
								orDescription = " or";
							}
							if (condition.itemRetailTypeId) {
								discountAmounts.push(
									<p key={`${discount.id}-${group.id}-${condition.id}-6`} className={classes.indent}>{getItemRetailTypeDescription(condition.itemRetailTypeId)}{orDescription}</p>
								);
							}
							if (condition.itemRetailCategoryId) {
								discountAmounts.push(
									<p key={`${discount.id}-${group.id}-${condition.id}-7`} className={classes.indent}>{getItemRetailCategoryDescription(condition.itemRetailCategoryId)}{orDescription}</p>
								);
							}
							if (condition.upc) {
								discountAmounts.push(
									<p key={`${discount.id}-${group.id}-${condition.id}-8`} className={classes.indent}>{getItemRetailDescription(condition.upc)}{orDescription}</p>
								);
							}

						});
					}
				}
			});
		}

		return discountAmounts
	}, [classes, discount, getItemRetailCategoryDescription, getItemRetailDescription, getItemRetailTypeDescription, minimumPriceText]);

	const modalTitle = useMemo(() => `${discount?.active ? 'Deactivate' : 'Activate'} discount?`, [discount?.active]);
	const content = useMemo(() => `Discount will be set to ${discount?.active ? 'inactive' : 'active'}.`, [discount?.active]);
	const actionHandler = useMemo(() => discount?.active ? handleDeactivateDiscount : handleActivateDiscount, [discount?.active, handleActivateDiscount, handleDeactivateDiscount]);
	const actionButtonText = useMemo(() => discount?.active ? 'Deactivate' : 'Activate', [discount?.active]);

	if (!discount) {
		return null;
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader>
							<h2 className={classes.cardIconTitle}>Details</h2>
							<GridContainer className={classes.buttonPadding}>
								<GridItem>
									<Button color="primary" onClick={navigateToEditDiscount} disabled={discount.isLockedDown}>Edit Discount</Button>
								</GridItem>
								<GridItem xs={2}>
									<ContextMenu menuItems={getContextMenuItems} />
								</GridItem>
							</GridContainer>
						</CardHeader>
						<CardBody className={classes.cardBody}>
							<GridContainer>
								<GridItem xs={12}>
									<Muted>
										<small>Type</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.discountType}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Internal Name</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.name}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Display Name</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.displayName}</p>
								</GridItem>
								{discount.promoCode &&
									<GridItem xs={12}>
										<Muted>
											<small>Promo Code</small>
										</Muted>
										<p className={classes.noTopMargin}>{discount.promoCode}</p>
									</GridItem>
								}
								{discount.description &&
									<GridItem xs={12}>
										<Muted>
											<small>Description</small>
										</Muted>
										<p className={classes.noTopMargin}>{discount.description}</p>
									</GridItem>
								}
								<GridItem xs={12}>
									<Muted>
										<small>Program</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.programName}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Market(s)</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.discountMarketNames}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Location Type(s)</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.retailLocationTypeNames}</p>
								</GridItem>
								{(discount.retailLocationLocationTypeNames && discount.retailLocationLocationTypeNames.length > 0) &&
									<>
										<GridItem xs={12}>
											<Muted>
												<small>Physical Location Subtype(s)</small>
											</Muted>
											<p className={classes.noTopMargin}>{discount.retailLocationLocationTypeNames}</p>
										</GridItem>
									</>
								}
								{(discount.retailLocationMruTypeNames && discount.retailLocationMruTypeNames.length > 0) &&
									<>
										<GridItem xs={12}>
											<Muted>
												<small>MRU Subype(s)</small>
											</Muted>
											<p className={classes.noTopMargin}>{discount.retailLocationMruTypeNames}</p>
										</GridItem>
									</>
								}
								<GridItem xs={12}>
									<Muted>
										<small>Location(s)</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.retailLocationNames}</p>
								</GridItem>
								<GridItem xs={6}>
									<Muted>
										<small>Start Date</small>
									</Muted>
									<p className={classes.noTopMargin}>{moment(discount.effectiveStartDate).format('L')}</p>
								</GridItem>
								<GridItem xs={6}>
									<Muted>
										<small>End Date</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.effectiveEndDate ? moment(discount.effectiveEndDate).format('L') : "-"}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Application Methods</small>
									</Muted>
									{discount.promoCode &&
										<p className={classes.noMargin}>Can apply manually with Promo Code</p>
									}
									{discount.displayInCea ?
										<p className={classes.noMargin}>Discount will display in CEA</p> :
										<p className={classes.noMargin}>Will display in CEA if Promo Code has exact search match</p>
									}
									{(discount.shouldAutoApply) &&
										<p className={classes.noMargin}>Auto apply when cart conditions are met</p>
									}
									{discount.isExclusiveItemDiscount &&
										<p className={classes.noMargin}>Can&#39;t be combined with other discounts on the same item</p>
									}
									{discount.isCopeExclusiveItemDiscount &&
										<p className={classes.noMargin}>Can&#39;t be combined with other discounts with this option selected</p>
									}
									{discount.isPhotoCaptureRequired &&
										<p className={classes.noTopMargin}>Trigger receipt capture upon application ({discount.proofOfPurchaseType})</p>
									}
								</GridItem>

								{(discount.hasTimeFrameLimit || discount.isMaxLimitEnabled) &&
									<>
										<GridItem xs={12}><div className={classes.sectionBreak}></div></GridItem>
										<GridItem xs={12}>
											<Muted>
												<small>Application Limits</small>
											</Muted>
											{discount.isMaxLimitEnabled &&
												<p className={classes.noMargin}>{discount.maxLimitPerCart} applications per cart</p>
											}
											{discount.hasTimeFrameLimit &&
												<p className={classes.noMargin}>{discount.timeFrameLimit} applications per consumer ({getTimeFrameTypeDescription(discount.discountTimeFrameTypeId)})</p>
											}
										</GridItem>
									</>
								}
								<GridItem xs={12}><div className={classes.sectionBreak}></div></GridItem>
								{(discount.isCcnRequiredForManualApply || discount.isCcnRequiredForAutoApply) &&
									<GridItem xs={12}>
										<Muted>
											<small>CCN Requirements</small>
										</Muted>
										{discount.isCcnRequiredForManualApply &&
											<p className={classes.noMargin}>CCN required for manual apply</p>
										}
										{discount.isCcnRequiredForAutoApply &&
											<p className={classes.noMargin}>CCN required for auto apply</p>
										}
										<div className={classes.sectionBreak}></div>
									</GridItem>
								}
								{discount.isCartMinimumPriceEnabled &&
									<GridItem xs={12}>
										<Muted>
											<small>Cart Requirements</small>
										</Muted>
										<p className={classes.noMargin}>{`Minimum Cart Price: $${(discount.cartMinimumPrice ?? 0).toFixed(2)}`}</p>
									</GridItem>
								}
								<GridItem xs={12}><div className={classes.sectionBreak}></div></GridItem>
								{discount.hasCartConditionItems &&
									<GridItem xs={12}>
										<Muted>
											<small>Item Requirements</small>
										</Muted>
										{renderRequiredItems}
									</GridItem>
								}
								{discount.hasCartConditionItems &&
									<GridItem xs={12}><div className={classes.sectionBreak}></div></GridItem>
								}
								<GridItem xs={12}>
									<Muted>
										<small>Effect Type</small>
									</Muted>
									<p className={classes.noTopMargin}>{discount.discountEffectType}</p>
								</GridItem>
								<GridItem xs={12}>
									<Muted>
										<small>Effect Amount</small>
									</Muted>
								</GridItem>
								<GridItem className={classes.moreBottomMargin} xs={12}>
									{renderDiscountAmount}
								</GridItem>
							</GridContainer>
						</CardBody>
						<Modal
							modalOpen={modalOpen}
							title={modalTitle}
							content={content}
							closeButtonText="Cancel"
							handleClose={closeModal}
							actionButtonText={actionButtonText}
							handleAction={actionHandler}
						/>
					</Card>
				</GridItem>
			</GridContainer>
		</>
	);
}
