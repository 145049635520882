import React, { useCallback, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles';

import { FormRenderProps, Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { MenuItem, Chip, AccordionDetails, Select, InputLabel, Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import AutosuggestAdapter from 'domains/core/components/FinalForm/AutosuggestAdapter';
import { Program, Market, EmployeeProgram, Role, MetrixUser, ManagerEmployee, SystemAccessType } from 'domains/core/models';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';

import { ceaAdminRoles } from 'utils/constants';
import { getCurrentUser } from 'domains/core/selectors';
import { getMetrixWebMarkets, getMetrixWebRoles, getMetrixWebManagers } from 'domains/users/selectors';
import { getCompanyFromRouteOrUser } from 'domains/companies/selectors';
import { UserActions } from 'domains/core/enums';
import { SourceSystem } from 'domains/core/models';
import * as validators from 'utils/fieldValidators';
import { CreateEditUserFormValues } from 'domains/users/models';

interface Props extends FormRenderProps<CreateEditUserFormValues> {
	programs?: Program[];
}

interface MatchProps {
	companyId: string;
}


const style = {
	programExpander: {
		fontSize: 12,
		color: '#0084ff'
	},
	programPanel: {
		boxShadow: '0 0 0'
	},
	root: {
		width: '100%',
	},
	programPanelDetails: {
		padding: '0px 0px 10px 0px'
	},
	programChip: {
		padding: '5px'
	}
};

export default function SetUserAccessForm(props: Props) {
	const { values, programs, initialValues, invalid, form: { change } } = props;
	const history = useHistory();
	const classes = useStyles(style);
	const { companyId } = useParams<MatchProps>();

	const [programsVisible, setProgramsVisible] = useState(true);

	const user = useSelector(state => getCurrentUser(state));
	const company = useSelector(state => getCompanyFromRouteOrUser(state, { match: { params: { companyId } } }));
	const markets = useSelector(state => getMetrixWebMarkets(state));
	const metrixWebRoles = useSelector(state => getMetrixWebRoles(state, { match: { params: { companyId } } }));
	const metrixWebManagers = useSelector(state => getMetrixWebManagers(state));
	const isLoadingUserDependencies = useSelector(state => state.domains.users.isLoadingUserDependencies);
	const selectedPrograms = values?.employeePrograms;


	useEffect(() => {
		if (values?.ceaRoleNames?.some(cr => cr === 'ProductSpecialist') && values?.ceaRoleNames?.some(cr => cr === 'LeadRetailExpert')) {
			change('ceaRoleNames', values.ceaRoleNames.filter(cr => cr !== 'LeadRetailExpert'));
		}
	}, [company, change, values?.ceaRoleNames]);

	const formatMetrixWebManager = useCallback((value: MetrixUser) => {
		if (!value) {
			return '';
		}
		return `${value.firstName} ${value.lastName}`;
	}, []);

	const formatMetrixWebRole = useCallback((value: Role) => {
		return value ? value.name : '';
	}, []);

	const systemAccessTypes = useMemo(() => {
		return [
			{ value: SystemAccessType.Tablet, text: 'Tablet' },
			{ value: SystemAccessType.Web, text: 'Web' },
			{ value: SystemAccessType.Both, text: 'Both' }
		];
	}, []);

	const ceaAdminSystemRoles = useMemo(() => {
		let roles: Role[] = [];
		if (company) {
			const removeGlobalAdminRole = !user!.canDoAction(UserActions.CanCreateEditCompanyAdministrators);
			roles = company?.companyRoles.filter(r => r.sourceSystemId === SourceSystem.CeaAdmin && r.active && (!removeGlobalAdminRole || r.name !== ceaAdminRoles.GlobalAdmin));
		}
		return roles.map(role => ({ value: role.name, text: role.displayName ?? role.name }));
	}, [company, user]);

	const ceaSystemRoles = useMemo(() => {
		let roles: Role[] = [];
		if (company) {
			roles = company?.companyRoles.filter(r => r.sourceSystemId === SourceSystem.Cea && !r.isAddOn && r.active);
		}
		return roles.map(role => ({ value: role.name, text: role.displayName ?? role.name }));
	}, [company]);

	const addOnRoles = useMemo(() => {
		const includeLeadRetailExpert = values?.ceaRoleNames?.some(x => x === 'ProductSpecialist');

		let roles: Role[] = [];
		if (company) {
			roles = company?.companyRoles.filter(r => r.sourceSystemId === SourceSystem.Cea && r.isAddOn && r.active && (includeLeadRetailExpert || r.name !== 'LeadRetailExpert'));
		}
		return roles.map(role => ({ value: role.name, text: role.displayName ?? role.name }));
	}, [values?.ceaRoleNames, company]);

	const ceaDirectSystemRoles = useMemo(() => {

		let roles: Role[] = [];
		if (company) {
			roles = company?.companyRoles.filter(r => r.sourceSystemId === SourceSystem.Cead && r.active);
		}
		return roles.map(role => ({ value: role.name, text: role.displayName ?? role.name }));
	}, [company]);

	const getProgramsFilteredBySystemAccess = useCallback((programs?: Program[]) => {
		let filteredPrograms = programs?.filter(x => !x.masterProgramId || x.masterProgramId !== x.id);

		const canAssignMetrixPrograms = !!(company && company.externalSystems && company.externalSystems.some(x => x.name === 'MetrixWeb'));

		// If a company doesn't have access to MW then we will filter out assignment to any MW programs.
		// This can be done using the programs loaded on the currently logged in user which only includes CEA Programs.
		if (!canAssignMetrixPrograms) {
			const currentUserProgramExternalIds = user!.programs?.map(x => x.externalProgramId);
			filteredPrograms = filteredPrograms?.filter(x => currentUserProgramExternalIds?.some(y => y === x.id));
		}

		return filteredPrograms;
	}, [company, user]);

	const handleProgramExpanderOnChange = useCallback((_event: object, expanded: boolean) => {
		setProgramsVisible(expanded);
	}, []);

	const getPrograms = useMemo(() => {
		const filteredPrograms = getProgramsFilteredBySystemAccess(programs);
		const sortedPrograms = orderBy(filteredPrograms, ['name'], ['desc']);
		return sortedPrograms.map((option: Program) => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>);
	}, [getProgramsFilteredBySystemAccess, programs]);

	const getMarkets = useMemo(() => {
		if (!selectedPrograms?.length || isLoadingUserDependencies) {
			return [];
		}
		let filteredPrograms = programs?.filter(x => x.isActive && selectedPrograms.some(p => p.programId === x.id));
		filteredPrograms = getProgramsFilteredBySystemAccess(filteredPrograms);

		const filteredMarkets = markets?.filter((market: Market) => filteredPrograms?.some(x => x.marketIds.find(y => y.marketId === market.marketId)));
		const uniqueMarkets = uniqBy(filteredMarkets, 'marketId');
		return uniqueMarkets.map((market: Market) => {
			const marketPrograms = filteredPrograms?.filter(program => program.marketIds.find(programMarket => programMarket.marketId === market.marketId));
			return {
				value: market.marketId,
				text: market.shortName,
				helpText: orderBy(marketPrograms, ['name'], ['desc']).map(program => program.name).join(', ')
			};
		});
	}, [getProgramsFilteredBySystemAccess, isLoadingUserDependencies, markets, programs, selectedPrograms]);

	const renderPrograms = useCallback(({ fields }: FieldArrayRenderProps<EmployeeProgram, any>) => {
		const editFieldsDisabled = initialValues && initialValues.azureAdUser && initialValues.azureAdUser.importedFromStaffing;

		const addProgram = (event: any) => {

			let isAlreadySelectedProgram = -1;
			fields.forEach((_fieldMember: string, index: number) => {
				const field: EmployeeProgram = fields.value[index];
				if (field.programId === event.target.value) {
					isAlreadySelectedProgram = index;
				}
			});

			const program = programs?.find(x => x.id === event.target.value);
			if (program) {
				if (isAlreadySelectedProgram > -1) {
					fields.remove(isAlreadySelectedProgram);
				}

				fields.push({
					isActive: true,
					programId: program.id,
				});
			}
		};

		const handleRemoveProgram = (programId: number) => () => {
			let selectedIndex = -1;
			fields.forEach((_fieldMember: string, index: number) => {
				const employeeProgram: EmployeeProgram = fields.value[index];
				if (employeeProgram.programId === programId) {
					selectedIndex = index;
				}
			});
			if (selectedIndex > -1) {
				fields.remove(selectedIndex);
			}
		};

		const getSelectedPrograms = () => {
			const selectedPrograms: EmployeeProgram[] = fields.map((_fieldName: string, index: number) => {
				return fields.value[index];
			});
			const selectedActivePrograms: EmployeeProgram[] = selectedPrograms.filter(x => x.isActive);
			let filteredPrograms = programs?.filter(x => selectedActivePrograms.find(y => y.programId === x.id));
			filteredPrograms = getProgramsFilteredBySystemAccess(filteredPrograms);

			const sortedPrograms = orderBy(filteredPrograms, ['name'], ['desc']);

			return sortedPrograms.map((x: Program) => {
				return (
					<span key={x.id} className={classes.programChip}>
						<Chip color="default" label={x.name} onDelete={!editFieldsDisabled ? handleRemoveProgram(x.id) : undefined} />
					</span>
				);
			});
		}

		if (fields) {
			return (
				<>
					<GridItem xs={12} md={12} lg={12}>
						<InputLabel>Programs</InputLabel>
						<Select
							disabled={!!editFieldsDisabled}
							onChange={addProgram}
							fullWidth
							value=''
						>
							{getPrograms}
						</Select>
					</GridItem>
					<div className={classes.root}>
						<Accordion className={classes.programPanel} defaultExpanded onChange={handleProgramExpanderOnChange}>
							<AccordionSummary className={classes.programExpander} expandIcon={<ExpandMoreIcon />}>
								{programsVisible ? 'HIDE' : 'SHOW'} SELECTED
							</AccordionSummary>
							<AccordionDetails className={classes.programPanelDetails}>
								<GridItem xs={12} md={12} lg={12}>
									{
										getSelectedPrograms()
									}
								</GridItem>
							</AccordionDetails>
						</Accordion>
					</div>
				</>
			);
		}
		return <div />;
	}, [classes, getPrograms, getProgramsFilteredBySystemAccess, handleProgramExpanderOnChange, initialValues, programs, programsVisible]);

	const renderManagerSuggestionLabelText = useCallback((suggestion: ManagerEmployee) => `${suggestion.firstName} ${suggestion.lastName}`, []);

	const renderRoleSuggestionLabelText = useCallback((suggestion: Role) => `${suggestion.name}`, []);

	const editFieldsDisabled = initialValues && initialValues.azureAdUser && initialValues.azureAdUser.importedFromStaffing;
	const submitDisabled = invalid;
	const canAssignAdminRoles = user!.canDoAction(UserActions.CanCreateEditCompanyAdministrators);
	const canAssignMetrixWebRoles = !!(company?.externalSystems?.some(x => x.name === 'MetrixWeb'));

	if (isLoadingUserDependencies) {
		return null;
	}

	return (
		<GridContainer>
			{
				canAssignAdminRoles &&
				<GridItem xs={12} md={12} lg={12}>
					<Field name="adminRoleNames">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="adminRoleNames"
								labelText="Admin Roles"
								multiple
								disabled={editFieldsDisabled}
								options={ceaAdminSystemRoles}
							/>
						)}
					</Field>
				</GridItem>
			}
			<GridItem xs={12} md={12} lg={12}>
				<Field name="ceaRoleNames">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							id="ceaRoleNames"
							labelText="CEA Roles"
							multiple
							disabled={editFieldsDisabled}
							options={ceaSystemRoles}
						/>
					)}
				</Field>
			</GridItem>
			{addOnRoles && addOnRoles.length > 0 &&
				<GridItem xs={12} md={12} lg={12}>
					<Field name="ceaAddonRoleNames">
						{(fieldProps) => (
							<FormSelectAdapter
								{...fieldProps}
								id="ceaAddonRoleNames"
								labelText="CEA Add-On Roles"
								multiple
								options={addOnRoles}
							/>
						)}
					</Field>
				</GridItem>
			}
			<GridItem xs={12} md={12} lg={12}>
				<Field name="systemAccessTypeId"
					required
					validate={validators.required}
				>
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							id="systemAccessTypeId"
							labelText="CEA System Access"
							options={systemAccessTypes}
						/>
					)}
				</Field>
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<Field name="ceaDirectRoleNames">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							id="ceaDirectRoleNames"
							labelText="CEA-D Roles"
							multiple
							disabled={editFieldsDisabled}
							options={ceaDirectSystemRoles}
						/>
					)}
				</Field>
			</GridItem>
			{canAssignMetrixWebRoles &&
				<>
					<GridItem xs={12} md={12} lg={12}>
						<Field
							component={AutosuggestAdapter}
							name="metrixRole"
							labelText="MetrixWeb Roles"
							lookupData={metrixWebRoles}
							suggestionLabelFormat={renderRoleSuggestionLabelText}
							format={formatMetrixWebRole}
							disabled={editFieldsDisabled || !canAssignMetrixWebRoles}
							displayAllSuggestions
							change={change}
							fullWidth
							allowClear
						/>
					</GridItem>
				</>
			}
			<GridItem xs={12} md={12} lg={12}>
				<Field
					id="managerAutocomplete"
					component={AutosuggestAdapter}
					labelText="MetrixWeb Manager"
					lookupData={metrixWebManagers}
					suggestionLabelFormat={renderManagerSuggestionLabelText}
					name="metrixManager"
					format={formatMetrixWebManager}
					change={change}
					fullWidth
					allowClear
				/>
			</GridItem>
			<FieldArray name="employeePrograms" render={renderPrograms} />
			<GridItem xs={12} md={12} lg={12}>
				<Field name="markets">
					{(fieldProps) => (
						<FormSelectAdapter
							{...fieldProps}
							id="markets"
							labelText="Markets"
							disabled={editFieldsDisabled}
							multiple
							options={getMarkets}
						/>
					)}
				</Field>
			</GridItem>
			{initialValues?.azureAdUser &&
				<GridItem>
					<Button color="primary" type="submit" disabled={submitDisabled}>Save</Button>
					<Button simple color="primary" onClick={history.goBack}>Cancel</Button>
				</GridItem>
			}
		</GridContainer>
	);
}
