import React, { ReactElement, useCallback, useState } from 'react';
import { some, orderBy } from 'lodash';
import { compose } from 'recompose';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Table } from 'components/Table';
import { RoleUserAction, RoleActionSortColumn } from 'domains/roles/models';
import { sort } from 'domains/core/helpers';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';

const style = {
	...headerTableStyle,
	headerStyle: {
		cursor: 'pointer'
	},
	rowButton: {
		display: 'block',
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
		margin: '10px 0'
	},
	emptyResult: {
		fontStyle: 'italic'
	},
};
interface OwnProps {
	actions: RoleUserAction[];
	hasActionsRemovedByFilter?: boolean;
}

export type Props = WithStyles & OwnProps;

export function UserRoleDetailsTable(props: Props): ReactElement {
	const { actions, hasActionsRemovedByFilter, classes } = props;
	const hasCategories = some(actions, a => a.action.category);

	const [sortColumn, changeSortColumn] = useState<string>(hasCategories ? RoleActionSortColumn.Category : RoleActionSortColumn.AssignedAction);
	const [sortDirection, changeSortDirection] = useState('asc' as 'asc' | 'desc');

	const sortByCategory = useCallback(() => {
		sort(RoleActionSortColumn.Category, sortColumn, sortDirection, changeSortColumn, changeSortDirection);
	}, [sortColumn, sortDirection]);

	const sortByAssignedAction = useCallback(() => {
		sort(RoleActionSortColumn.AssignedAction, sortColumn, sortDirection, changeSortColumn, changeSortDirection);
	}, [sortColumn, sortDirection]);

	const decorateSortHeader = useCallback((column: RoleActionSortColumn) => {
		if (column !== sortColumn) {
			return undefined;
		}

		return sortDirection === 'desc' ? `sortDesc ${classes.sortDesc}` : `sortAsc ${classes.sortAsc}`;
	}, [classes.sortAsc, classes.sortDesc, sortColumn, sortDirection]);

	const data = actions && actions.length
		?
		orderBy(actions, [`action[${sortColumn}]`], [sortDirection]).map(roleAction => {
			const row = [
				<span key={2} className={classes.rowButton}>{roleAction.action.name}</span>
			];
			if (hasCategories) {
				row.unshift(<span key={1} className={classes.rowButton}>{roleAction.action.category || ''}</span>);
			}
			return row;
		})

		: [[<span className={classes.emptyResult} key={2}>{hasActionsRemovedByFilter ? 'No actions match the current filter.' : 'No actions currently assigned.'}</span>]];

	const tableHeader = [
		<h6 onClick={sortByAssignedAction} key={2} className={`${classes.headerStyle} ${decorateSortHeader(RoleActionSortColumn.AssignedAction)}`}>Assigned Action</h6>
	];
	if (hasCategories) {
		tableHeader.unshift(<h6 onClick={sortByCategory} key={1} className={`${classes.headerStyle} ${decorateSortHeader(RoleActionSortColumn.Category)}`}>Category</h6>);
	}

	return (
		<Table
			striped
			tableData={data}
			tableHead={tableHeader}
		/>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(style)
)(UserRoleDetailsTable);
export default container;
