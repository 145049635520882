const headerTableStyle = {
	sortDesc: {
		'&:after': {
			position: 'relative',
			left: 5,
			content: '"⯆"'
		}
	},
	sortAsc: {
		'&:after': {
			position: 'relative',
			left: 5,
			content: '"⯅"'
		}
	}
};

export default headerTableStyle;
