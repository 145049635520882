import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { Theme } from '@material-ui/core/styles';
import { isLoadingCompanies } from 'domains/companies/selectors';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Search, Clear } from '@material-ui/icons';
import { CompanySearchFormValues } from 'domains/companies/models';

import * as validators from 'utils/fieldValidators';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' }
	};
};

interface Props {
	handleFilter: (value?: string) => void;
}

export default function CompanySearchForm(props: Props) {
	const { handleFilter } = props;
	const classes = useStyles(style);
	const loadingCompanies = useSelector(state => isLoadingCompanies(state));

	const resetForm = useCallback((reset) => () => {
		reset();
		handleFilter('');
	}, [handleFilter]);

	const onSubmit = useCallback((values: CompanySearchFormValues) => {
		handleFilter(values.filter);
	}, [handleFilter]);


	return (
		<Form
			onSubmit={onSubmit}
		>
			{({ handleSubmit, pristine, invalid, submitSucceeded, form: { reset } }) => {
				const buttonDisabled = loadingCompanies || pristine || invalid;
				const fieldDisabled = loadingCompanies || submitSucceeded;

				return (
					<form onSubmit={handleSubmit}>
						<Field
							className={classes.noPadding}
							name="filter"
							id="filterInput"
							component={CustomInputAdapter}
							formControlProps={{ style: { paddingTop: '14px' } }}
							validate={validators.minLength1}
							inputProps={{
								placeholder: 'Search',
								inputProps: {
									'aria-label': 'Search'
								},
								disabled: fieldDisabled,
								endAdornment:
									<InputAdornment position="end">
										{submitSucceeded ?
											<IconButton onClick={resetForm(reset)} disabled={buttonDisabled}>
												<Clear />
											</IconButton> :
											<IconButton type="submit" disabled={buttonDisabled}>
												<Search />
											</IconButton>
										}
									</InputAdornment>
							}}
						/>
					</form>
				)
			}}
		</Form>
	);
}
