// ##############################
// // // Header styles
// #############################

import {
	containerFluid,
	defaultFont,
	primaryColor,
	defaultBoxShadow,
	infoColor,
	successColor,
	warningColor,
	dangerColor
} from "assets/jss/material-dashboard-pro-react";

const headerStyle = () => ({
	appBar: {
		backgroundColor: "transparent",
		boxShadow: "none" as const,
		borderBottom: "0",
		marginBottom: "0",
		position: "absolute" as const,
		width: "100%",
		paddingTop: "10px",
		zIndex: 1029,
		color: "#555555",
		border: "0",
		borderRadius: "3px",
		padding: "10px 0",
		transition: "all 150ms ease 0s",
		minHeight: "50px",
		display: "block" as const
	},
	container: {
		...containerFluid,
		minHeight: "50px"
	},
	flex: {
		flex: 1
	},
	title: {
		...defaultFont,
		lineHeight: "30px",
		fontSize: "18px",
		borderRadius: "3px",
		textTransform: "none" as const,
		color: "inherit",
		paddingTop: "0.625rem",
		paddingBottom: "0.625rem",
		margin: "0 !important",
		"&:hover,&:focus": {
			background: "transparent"
		}
	},
	primary: {
		backgroundColor: primaryColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	info: {
		backgroundColor: infoColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	success: {
		backgroundColor: successColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	warning: {
		backgroundColor: warningColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	danger: {
		backgroundColor: dangerColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	sidebarMinimize: {
		float: "left" as const,
		padding: "0 0 0 15px",
		display: "block" as const,
		color: "#555555"
	},
	sidebarMinimizeRTL: {
		padding: "0 15px 0 0 !important"
	},
	sidebarMiniIcon: {
		width: "20px",
		height: "17px"
	}
});

export default headerStyle;
