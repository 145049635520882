import React, { useCallback, useEffect, useMemo } from 'react';
import { Table } from 'components/Table';
import TableColumnListValue from 'domains/core/components/TableColumnListValue';
import { useHistory } from 'react-router-dom';
import { getUserNameNoSuffix } from 'domains/core/helpers';
import { getCompanyUsers } from 'domains/users/selectors';
import { getMetrixWebPrograms, getMetrixWebMarkets } from 'domains/users/selectors';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';
import { useStyles } from 'styles';
import { useSelector } from 'react-redux';

const style = {
	...headerTableStyle,
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
	},
	tablePadding: {
		paddingLeft: '30px',
	},
	headerColumn: {
		fontWeight: 700
	},
	sortColumn: {
		cursor: 'pointer',
		fontWeight: 800
	},
	columnValuePlusButton: {
		color: '#666666'
	}
};

export interface Props {
	companyId: number;
	active: boolean;
	setActive: (value: boolean) => void;
	sortOrderDesc: boolean;
	changeSortOrder: () => void;
}

export default function CompanyUsers(props: Props) {
	const { active, setActive, companyId, sortOrderDesc, changeSortOrder } = props;
	const classes = useStyles(style);
	const history = useHistory();

	const users = useSelector(state => getCompanyUsers(state, { match: { params: { companyId } } }));
	const programs = useSelector(state => getMetrixWebPrograms(state));
	const markets = useSelector(state => getMetrixWebMarkets(state));


	useEffect(() => {
		setActive(active);
	}, [active, setActive]);

	const routeToUserDetails = useCallback((companyId: number, userPrincipalName: string) => () => {
		if (companyId > 0) {
			history.push(`/users/companies/${companyId}/Users/${getUserNameNoSuffix(userPrincipalName)}`);
		}
	}, [history]);

	const sortColumn = useCallback(() => {
		changeSortOrder();
	}, [changeSortOrder]);

	const decorateSortHeader = useCallback(() => {
		return sortOrderDesc ? classes.sortDesc : classes.sortAsc;
	}, [classes, sortOrderDesc]);

	const findProgramName = useCallback((programId?: number) => {
		return programs?.find(p => programId && p.id === programId)?.name || '';
	}, [programs]);

	const findMarketName = useCallback((marketId?: number) => {
		return markets?.find(m => marketId && m.marketId === marketId)?.shortName || '';
	}, [markets]);


	const tableData = useMemo(() => users
		? users.map(c => [
			<span key={`${c.id}-name`} className={classes.rowButton} onClick={routeToUserDetails(c.companyId, c.azureAdUser!.userPrincipalName || '')}><p className={classes.tablePadding}>{c.azureAdUser ? c.azureAdUser.displayName : ''}</p></span>,
			<span key={`${c.id}-ceaRole`} className={classes.rowButton} onClick={routeToUserDetails(c.companyId, c.azureAdUser!.userPrincipalName || '')}><TableColumnListValue list={c.ceaUser?.roleNames} /></span>,
			<span key={`${c.id}-mwRole`} className={classes.rowButton} onClick={routeToUserDetails(c.companyId, c.azureAdUser!.userPrincipalName || '')}><p className={classes.tablePadding}>{c.metrixUser?.role ? c.metrixUser.role.name : ''}</p></span>,
			<span key={`${c.id}-program`} className={classes.rowButton} onClick={routeToUserDetails(c.companyId, c.azureAdUser!.userPrincipalName || '')}><TableColumnListValue list={c.metrixUser?.employeePrograms} propValue="programId" getPropDisplayValue={findProgramName} /></span>,
			<span key={`${c.id}-market`} className={classes.rowButton} onClick={routeToUserDetails(c.companyId, c.azureAdUser!.userPrincipalName || '')}><TableColumnListValue list={c.metrixUser?.markets} getPropDisplayValue={findMarketName} /></span>
		])
		: [], [classes, findMarketName, findProgramName, routeToUserDetails, users]);

	return (
		<Table striped tableHead={[
			<h6 key={1} onClick={sortColumn} className={`${classes.tablePadding} ${classes.sortColumn} ${decorateSortHeader()}`}>User Name</h6>,
			<h6 key={2} className={`${classes.tablePadding} ${classes.headerColumn}`}>CEA Role(s)</h6>,
			<h6 key={3} className={`${classes.tablePadding} ${classes.headerColumn}`}>MW Role(s)</h6>,
			<h6 key={4} className={`${classes.tablePadding} ${classes.headerColumn}`}>Program(s)</h6>,
			<h6 key={5} className={`${classes.tablePadding} ${classes.headerColumn}`}>Market(s)</h6>
		]} tableData={tableData} />
	);
}

