import React, { ReactElement, useCallback, useState } from 'react';
import { orderBy } from 'lodash';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Table } from 'components/Table';
import { SourceSystemDictionary } from 'domains/core/models';
import { Role, UserRoleSortColumn } from 'domains/roles/models';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';

const style = {
	...headerTableStyle,
	firstColumnStyle: {
		width: '25%',
	},
	headerStyle: {
		paddingLeft: '30px !important',
		cursor: 'pointer'
	},
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
		margin: '10px 0'
	},
	tablePadding: {
		paddingLeft: '30px',
		display: 'block'
	},
};

interface OwnProps {
	roles: Role[];
}

export type Props = OwnProps & RouteComponentProps & WithStyles;

export function UserRoles(props: Props): ReactElement {
	const { roles, classes, history } = props;
	const [sortColumn, changeSortColumn] = useState(UserRoleSortColumn.RoleName);
	const [sortDirection, changeSortDirection] = useState('asc' as 'asc' | 'desc');

	const sort = useCallback((column: UserRoleSortColumn) => {
		if (sortColumn === column) {
			changeSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			changeSortDirection('asc');
			changeSortColumn(column);
		}
	}, [sortColumn, sortDirection]);

	const sortByRole = useCallback(() => {
		sort(UserRoleSortColumn.RoleName);
	}, [sort]);

	const sortBySystem = useCallback(() => {
		sort(UserRoleSortColumn.System);
	}, [sort]);

	const routeToRoleDetails = useCallback((system: string, roleId: number) => {
		history.push(`/users/roles/${system}/${roleId}`);
	}, [history]);

	const router = useCallback((role) => () => {
		routeToRoleDetails(SourceSystemDictionary[role.system], role.roleId);
	}, [routeToRoleDetails]);

	const data = roles && orderBy(roles, [sortColumn], [sortDirection]).map((role: Role) => {
		return [
			<span key={1} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={router(role)}>{role.name}</span>,
			<span key={2} className={`${classes.rowButton} ${classes.tablePadding}`} onClick={router(role)}>{role.system && SourceSystemDictionary[role.system]}</span>
		];
	});

	const decorateSortHeader = (column: UserRoleSortColumn) => {
		if (column !== sortColumn) {
			return undefined;
		}

		return sortDirection === 'desc' ? `sortDesc ${classes.sortDesc}` : `sortAsc ${classes.sortAsc}`;
	}

	const tableHeader = [
		<h6 key={1} onClick={sortByRole} className={`${classes.headerStyle} ${decorateSortHeader(UserRoleSortColumn.RoleName)}`}>Role Name</h6>,
		<h6 key={2} onClick={sortBySystem} className={`${classes.headerStyle} ${decorateSortHeader(UserRoleSortColumn.System)}`}>System</h6>
	];

	return (
		<Table
			striped
			tableData={data}
			tableHead={tableHeader}
			customCellClasses={[classes.firstColumnStyle]}
			customClassesForCells={[0]}
			customHeadCellClasses={[classes.firstColumnStyle]}
			customHeadClassesForCells={[0]}
		/>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(style),
	withRouter
)(UserRoles);

export default container;
