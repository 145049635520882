import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Route, Redirect, Switch } from 'react-router-dom';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles } from '@material-ui/core/styles';
import { Sidebar } from 'components/Sidebar';
import appStyles from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';
import PerfectScrollbar from 'perfect-scrollbar';

import { ApplicationState } from 'applicationTypes';
import { doLogout, doResetReroute, doPaginationScrollTopReset } from 'domains/core/actions';
import { isAuthenticating, isAuthenticated, getCurrentUser } from 'domains/core/selectors';
import { CurrentUser } from 'domains/core/models';
import { getRoutes, getNavigationRoutes } from 'routes/DashboardRoutes';
import SessionTimeoutModal from 'domains/core/components/SessionTimeoutModal';
import AdminHeader from 'domains/core/components/AdminHeader';

interface StateProps {
	user: CurrentUser | undefined | null;
	authenticating: boolean;
	isAuthenticated: boolean;
	routeTo: string | undefined;
	scrollTop: boolean;
}

interface DispatchProps {
	doLogout: typeof doLogout;
	doResetReroute: typeof doResetReroute;
	doPaginationScrollTopReset: typeof doPaginationScrollTopReset;
}

interface ComponentState {
	mobileOpen: boolean;
	miniActive: boolean;
}

export type Props = StateProps & DispatchProps & RouteComponentProps & WithStyles;

export class Dashboard extends React.Component<Props, ComponentState> {
	private ps: PerfectScrollbar | null = null;

	private mainPanel: any = '';

	private mainPanelRef = (c: Element | string | null) => {
		this.mainPanel = c;
	}

	public constructor(props: Props) {
		super(props);
		this.state = {
			mobileOpen: false,
			miniActive: false,
		};
	}

	public componentDidMount = () => {
		if (navigator?.userAgent?.indexOf('Win') > -1 && this.mainPanel) {
			this.ps = new PerfectScrollbar(this.mainPanel, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = 'hidden';
		}
		window.addEventListener('resize', this.resizeFunction);
	}

	public componentWillUnmount = () => {
		if (navigator?.userAgent?.indexOf('Win') > -1 && this.ps) {
			this.ps.destroy();
		}
		window.removeEventListener('resize', this.resizeFunction);
	}

	public componentDidUpdate = () => {
		const { routeTo, history, doResetReroute, scrollTop, doPaginationScrollTopReset } = this.props;
		if (routeTo) {
			history.push(routeTo);
			doResetReroute();
		}

		if (scrollTop) {
			this.mainPanel.scrollTop = 0;
			doPaginationScrollTopReset();
		}
	}

	private handleDrawerToggle = () => {
		const { mobileOpen } = this.state;
		this.setState({ mobileOpen: !mobileOpen });
	};

	private sidebarMinimize = () => {
		const { miniActive } = this.state;
		this.setState({ miniActive: !miniActive });
	}

	private resizeFunction = () => {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}

	private getRoutesForUser = () => {
		const { user } = this.props;
		return getRoutes(user);
	}

	private getNavigationRoutesForUser = () => {
		const { user } = this.props;
		return getNavigationRoutes(user);
	}

	private switchRoutes = (
		<Switch>
			{this.getRoutesForUser().map((prop, index) => {
				if (prop.redirect) {
					return <Redirect from={prop.path} to={prop.pathTo || '/dashboard'} key={index} />;
				}
				if (prop.collapse) {
					if (prop.views) {
						return prop.views.map(view => <Route key={`DashboardRoute-${index}`} path={view.path} component={view.component} />);
					}
				}
				return <Route exact key={`DashboardRoute-${index}`} path={prop.path} component={prop.component} />;
			})}
		</Switch>
	);

	private getSidebarActions = () => {
		return [
			{ to: '#', name: 'Sign Out', onClick: this.handleLogout }
		];
	}

	private handleLogout = () => {
		const { doLogout, history } = this.props;
		doLogout();
		history.push('/');
	}

	public render() {
		const { classes, user, ...rest } = this.props;

		const mainPanel =
			`${classes.mainPanel} ${cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator?.userAgent?.indexOf('Win') > -1
			})}`;

		const currentUserName = user ? `${user.firstName} ${user.lastName}` : 'Welcome';

		const sidebarUserActions = this.getSidebarActions();

		return (
			<div className={classes.wrapper}>
				<SessionTimeoutModal />
				<Sidebar
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color="blue"
					bgColor="black"
					routes={this.getNavigationRoutesForUser()}
					miniActive={this.state.miniActive}
					userName={currentUserName}
					userActions={sidebarUserActions}
					{...rest}
				/>
				<div className={mainPanel} ref={this.mainPanelRef}>
					<AdminHeader
						sidebarMinimize={this.sidebarMinimize.bind(this)}
						miniActive={this.state.miniActive}
						handleDrawerToggle={this.handleDrawerToggle}
						routes={this.getRoutesForUser()}
						{...rest}
					/>
					<div className={classes.content}>
						<div className={classes.container}>
							{this.switchRoutes}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const container = compose<Props, {}>(
	withRouter,
	withStyles(appStyles),
	connect<StateProps, DispatchProps, {}, ApplicationState>(
		(state: ApplicationState) => {
			return {
				authenticating: isAuthenticating(state),
				isAuthenticated: isAuthenticated(state),
				user: getCurrentUser(state),
				routeTo: state.domains.core.routeTo,
				scrollTop: state.domains.core.pagination.scrollTop
			};
		},
		{ doLogout, doResetReroute, doPaginationScrollTopReset }
	)
)(Dashboard);

export default container;
