import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'components/Table';
import useTable from 'hooks/useTable';
import { SecretShopper } from 'domains/secretShopper/models';

const formatDateString = (date: string) => {
	return moment(date).format('MM/DD/YYYY');
};

const sortString = (first: string, second: string) => {
	return first.toUpperCase() > second.toUpperCase() ? 1 : -1;
};

const sortDateString = (first: string, second: string) => {
	var firstDate = moment(first, 'MM/DD/YYYY');
	var secondDate = moment(second, 'MM/DD/YYYY');

	if (!firstDate.isValid())
	{
		return -1;
	}

	if (!secondDate.isValid())
	{
		return 1;
	}

	return firstDate > secondDate ? 1 : -1;
};

const filterSecretShoppers = (secretShoppers: SecretShopper[], filterText: string) => {
	if (!filterText) {
		return secretShoppers;
	}

	const value = filterText.toUpperCase();

	const filteredData = secretShoppers.filter(ss => {
		if (ss.ccn.includes(value)) {
			return true;
		}

		if (ss.description?.toUpperCase().includes(value)) {
			return true;
		}

		if (ss.activatedBy.toUpperCase().includes(value)) {
			return true;
		}

		return false;
	});

	return filteredData;
};

interface Props {
	filterText: string;
}

const SecretShopperTable: React.FC<Props> = ({ filterText }) => {
	const history = useHistory();
	const secretShoppers = useSelector(state => state.domains.secretShoppers.secretShopperList || []);
	const filteredSecretShoppers = filterSecretShoppers(secretShoppers, filterText);

	const goToDetailsPage = (secretShopper: any) => () => {
		history.push(`/consumerInteractions/secretShoppers/view/${secretShopper.id}`);
	};

	const columnDefinitions = [
		{ getOnCellClick: goToDetailsPage, getColumnText: (ss: SecretShopper) => ss.ccn, headerText: "Ccn", sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (ss: SecretShopper) => ss.description || '', headerText: "Description", sortBy: sortString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (ss: SecretShopper) => formatDateString(ss.activatedAt), headerText: "Date Added", sortBy: sortDateString },
		{ getOnCellClick: goToDetailsPage, getColumnText: (ss: SecretShopper) => ss.activatedBy, headerText: "Added By", sortBy: sortString },
	] as any;

	const { tableHead, tableData } = useTable({ columnDefinitions, data: filteredSecretShoppers });

	return (
		<Table
			striped
			tableHead={tableHead}
			tableData={tableData}
			customClassesForCells={[0]}
			customHeadClassesForCells={[0]}
		/>
	);
}

const areEqual = (prev: Props, curr: Props) => {
	return prev.filterText === curr.filterText;
};

export default React.memo(SecretShopperTable, areEqual);
