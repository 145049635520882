import React, { useCallback } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { GridItem } from 'components/Grid';
import DiscountGroupForm from 'domains/discounts/components/DiscountGroupForm';
import { IconButton } from '@material-ui/core';
import AddIcon from 'domains/core/components/AddIcon';

import { DiscountGroupFormValues } from 'domains/discounts/models';
import { ItemRetail } from 'domains/core/models';
import { useStyles } from 'styles';

interface Props extends FieldArrayRenderProps<DiscountGroupFormValues, any> {
	itemRetails: ItemRetail[];
}

const styles = () => ({
	addGroupContainer: {
		marginLeft: '-10px',
		marginTop: '15px'
	},
	boldText: {
		fontWeight: 700,
		cursor: 'pointer',
	},
});

export default function DiscountGroups(props: Props) {
	const { fields, itemRetails } = props;
	const classes = useStyles(styles);

	const addItem = useCallback(() => {
		fields.push({ conditions: [{}] })
	}, [fields]);

	const handleRemoveGroup = useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	return (
		<React.Fragment>
			<GridItem xs={12} md={12} lg={12}>
				{fields.map((discountGroup: string, index: number) => {
					const currentGroup = fields.value[index];

					return (<DiscountGroupForm
						key={index}
						groupName={discountGroup}
						selectedGroupTypeId={currentGroup.discountGroupTypeId}
						conditions={currentGroup.conditions}
						itemRetails={itemRetails}
						totalDiscountGroups={fields.length ?? 0}
						index={index}
						handleRemoveGroup={() => handleRemoveGroup(index)}
					/>);
				})}
			</GridItem>
			<GridItem xs={12} md={12} lg={12}>
				<div className={classes.addGroupContainer}>
					<IconButton onClick={addItem}>
						<AddIcon />
					</IconButton>
					<span className={classes.boldText} onClick={addItem}>ADD ANOTHER REQUIRED ITEM</span>
				</div>
			</GridItem>
		</React.Fragment>
	);
}
