import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader } from 'components/Card';
import { CustomTabs } from 'components/CustomTabs';
import { Button } from 'components/CustomButtons';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doLoadExecutionTags } from 'domains/executionTags/actions';
import { ExecutionTagsTable } from 'domains/executionTags/components';
import { CustomInput } from 'components/CustomInput';
import { debounce } from 'lodash';
import { InputAdornment, InputProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	}
});

const ExecutionTagList = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();

	const [filterValue, setFilterValue] = useState('');
	const searchFilterRef = React.createRef<InputProps>();

	const handleSearch = (event: any) => {
		debounceFilterChange(event.target.value);
	}

	const debounceFilterChange = debounce((value: string) => {
		setFilterValue(value);
	}, 250);

	useEffect(() => {
		dispatch(doLoadExecutionTags());
	}, [dispatch])

	const handleClearFilter = () => {
		setFilterValue('');
		if (searchFilterRef.current) {
			searchFilterRef.current.value = '';
		}
	};

	const filterEndAdornment = filterValue && filterValue.trim() !== ''
		? <InputAdornment id={`clearFilter`} position="end" style={{ cursor: 'pointer' }} onClick={handleClearFilter}><ClearIcon color="disabled" /></InputAdornment>
		: null;

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Special Execution Tags</h2>
				<GridContainer spacing={0}>
					<GridItem>
						<Button color="primary" onClick={() => history.push('/consumerInteractions/executionTags/create')}>Add Tag</Button>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg xl>
						<CustomInput
							id="filterInput"
							formControlProps={{ style: { paddingTop: '14px' } }}
							inputProps={{
								onChange: handleSearch,
								placeholder: 'Search',
								inputProps: {
									'aria-label': 'Search',
								},
								endAdornment: filterEndAdornment,
								inputRef: searchFilterRef
							}}
						/>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<CustomTabs
				plainTabs
				headerColor="info"
				tabs={
					[
						{
							tabName: 'ACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										{<ExecutionTagsTable active={true} filterValue={filterValue} />}
									</GridItem>
								</GridContainer>
							)
						},
						{
							tabName: 'INACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										{<ExecutionTagsTable active={false} filterValue={filterValue} />}
									</GridItem>
								</GridContainer>
							)
						}
					]
				}
			/>
		</Card>
	);
}

export default ExecutionTagList;
