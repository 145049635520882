export const LOAD_AUDIT_FILTER = 'audits/LOAD_AUDIT_FILTER';
export const LOAD_AUDIT_FILTER_SUCCESS = 'audits/LOAD_AUDIT_FILTER_SUCCESS';
export const LOAD_AUDIT_FILTER_FAIL = 'audits/LOAD_AUDIT_FILTER_FAIL';

export const SEARCH_USER_AUDITS = 'audits/SEARCH_USER_AUDITS';
export const SEARCH_USER_AUDITS_SUCCESS = 'audits/SEARCH_USER_AUDITS_SUCCESS';
export const SEARCH_USER_AUDITS_FAIL = 'audits/SEARCH_USER_AUDITS_FAIL';

export const EXPORT_USER_AUDITS = 'audit/EXPORT_USER_AUDITS';
export const EXPORT_USER_AUDITS_SUCCESS = 'audit/EXPORT_USER_AUDITS_SUCCESS';
export const EXPORT_USER_AUDITS_FAIL = 'audit/EXPORT_USER_AUDITS_FAIL';
