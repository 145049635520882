import { take, put, all, fork } from 'redux-saga/effects';
import { callApi } from 'utils/apiSaga';
import history from 'adminHistory';
import * as coreActionTypes from 'domains/core/actionTypes';
import { errorToast } from 'domains/core/components/Toaster';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { SagaIterator } from 'redux-saga';

function* watchForLoadDeviceTerminalSummary(): SagaIterator {
	while (true) {
		yield take(actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY);
		
		try {
			yield put({ type: coreActionTypes.APP_LOADING });
			const result = yield callApi(actions.getDeviceTerminalMappings());
			yield put({ type: actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY_SUCCESS, result });
		} catch (error) {
			yield put({ type: actionTypes.LOAD_DEVICE_TERMINAL_SUMMARY_FAIL, error });
			errorToast(error.status === 400 ? error.message : 'There was an error loading device terminal summary.');
		}
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	}
}

function* watchForLoadDeviceTerminalDetails(): SagaIterator {
	while (true) {
		const action = yield take(actionTypes.LOAD_DEVICE_TERMINAL_DETAILS);

		try {
			yield put({ type: coreActionTypes.APP_LOADING });
			const onlineMerchantTerminals = yield callApi(actions.getOnlineMerchantTerminals());
			const existingDeviceTerminalMapping = action.payload > 0 ? yield callApi(actions.getDeviceTerminalMapping(action.payload)) : undefined;
			yield put({ type: actionTypes.LOAD_DEVICE_TERMINAL_DETAILS_SUCCESS, result: {
				onlineMerchantTerminals,
				existingDeviceTerminalMapping
			}});
		} catch (error) {
			yield put({ type: actionTypes.LOAD_DEVICE_TERMINAL_DETAILS_FAIL, error });
			errorToast(error.status === 400 ? error.message : 'There was an error loading device terminal details.');
		}
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	}
}

function* watchForSaveDeviceTerminalDetails(): SagaIterator {
	while (true) {
		const action = yield take(actionTypes.SAVE_DEVICE_TERMINAL_DETAILS);

		try {
			yield put({ type: coreActionTypes.APP_LOADING });
			yield callApi(actions.saveDeviceTerminalDetails(action.payload));
			yield put({ type: actionTypes.SAVE_DEVICE_TERMINAL_DETAILS_SUCCESS });
			history.push('/devices/terminalMapping');
		} catch (error) {
			errorToast(error.status === 400 ? error.message : 'There was an error saving terminal details.');
		}
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	}
}

function* watchForDeactivateDeviceTerminal(): SagaIterator {
	while (true) {
		const action = yield take(actionTypes.DEACTIVATE_DEVICE_TERMINAL);

		try {
			yield put({ type: coreActionTypes.APP_LOADING });
			yield callApi(actions.deactivateDeviceTerminal(action.payload));
			yield put({ type: actionTypes.SAVE_DEVICE_TERMINAL_DETAILS_SUCCESS });
			history.push('/devices/terminalMapping');
		} catch (error) {
			errorToast(error.status === 400 ? error.message : 'There was an error deactivating device.');
		}
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
	}
}

export default function* watchForDeviceSagas() {
	yield all([
		fork(watchForLoadDeviceTerminalSummary),
		fork(watchForLoadDeviceTerminalDetails),
		fork(watchForSaveDeviceTerminalDetails),
		fork(watchForDeactivateDeviceTerminal)
	]);
}
