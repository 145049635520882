import * as actionTypes from 'domains/secretShopper/actionTypes';
import * as schema from 'domains/secretShopper/schemas';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import { SecretShopper } from 'domains/secretShopper/models';

export const doLoadSecretShoppers = (): Action => {
	return {
		type: actionTypes.LOAD_SECRET_SHOPPERS,
	};
};

export const loadSecretShoppers = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/SecretShopper/Get');
		}
	};
};

export const doSaveSecretShopper = (request: SecretShopper): Action => {
	return {
		type: actionTypes.SAVE_SECRET_SHOPPER,
		payload: request
	};
};

export const saveSecretShopper = (request: SecretShopper): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/SecretShopper/Save', {
				data: request
			});
		}
	};
};

export const doLoadSecretShopper = (id: string): Action => {
	return {
		type: actionTypes.LOAD_SECRET_SHOPPER,
		payload: id
	};
};

export const loadSecretShopper = (id: string): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/SecretShopper/Get/${id}`, {
				schema: schema.secretShoppersSchema
			});
		}
	};
};

export const doDeactivateSecretShopper = (id: number): Action => {
	return {
		type: actionTypes.DEACTIVATE_SECRET_SHOPPER,
		payload: id
	};
};

export const deactivateSecretShopper = (id: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post(`/admin/SecretShopper/Deactivate/${id}`);
		}
	};
};
