import { Theme } from "@material-ui/core";
import {
	defaultFont,
	primaryColor,
	primaryBoxShadow,
	infoColor,
	infoBoxShadow,
	successColor,
	successBoxShadow,
	warningColor,
	warningBoxShadow,
	dangerColor,
	dangerBoxShadow,
	roseColor,
	roseBoxShadow
} from "assets/jss/material-dashboard-pro-react";

const customDropdownStyle = (theme: Theme) => ({
	popperClose: {
		pointerEvents: "none" as const,
		display: "none !important"
	},
	pooperNav: {
		[theme.breakpoints.down("sm")]: {
			position: "static !important",
			left: "unset !important",
			top: "unset !important",
			transform: "none !important",
			willChange: "none !important",
			"& > div": {
				boxShadow: "none !important",
				marginLeft: "1.5rem",
				marginRight: "1.5rem",
				transition: "none !important",
				marginTop: "0px !important",
				marginBottom: "5px !important",
				padding: "0px !important"
			}
		}
	},
	manager: {
		"& > div > button:first-child > span:first-child, & > div > a:first-child > span:first-child": {
			width: "100%"
		}
	},
	innerManager: {
		"& > div > button,& > div > a": {
			margin: "0px !important",
			color: "inherit !important",
			padding: "10px 20px !important",
			"& > span:first-child": {
				width: "100%",
				justifyContent: "flex-start"
			}
		}
	},
	target: {
		"& > button:first-child > span:first-child, & > a:first-child > span:first-child": {
			display: "inline-block" as const
		},
		"& $caret": {
			marginLeft: "0px"
		}
	},
	dropdown: {
		borderRadius: "3px",
		border: "0",
		boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
		top: "100%",
		zIndex: 1000,
		minWidth: "160px",
		padding: "5px 0",
		margin: "2px 0 0",
		fontSize: "14px",
		textAlign: "left"  as const,
		listStyle: "none" as const,
		backgroundColor: "#fff",
		backgroundClip: "padding-box"
	},
	menuList: {
		padding: "0"
	},
	pooperResponsive: {
		zIndex: 1200,
		[theme.breakpoints.down("sm")]: {
			zIndex: 1640,
			position: "static" as const,
			float: "none",
			width: "auto",
			marginTop: "0",
			backgroundColor: "transparent",
			border: "0",
			boxShadow: "none",
			color: "black"
		}
	},
	dropdownItem: {
		...defaultFont,
		fontSize: "13px",
		padding: "10px 20px",
		margin: "0 5px",
		borderRadius: "2px",
		position: "relative" as const,
		transition: "all 150ms linear",
		display: "block" as const,
		clear: "both" as const,
		fontWeight: 400,
		height: "100%",
		color: "#333",
		whiteSpace: "nowrap" as const
	},
	darkHover: {
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)",
			backgroundColor: "#212121",
			color: "#fff"
		}
	},
	primaryHover: {
		"&:hover": {
			backgroundColor: primaryColor,
			color: "#FFFFFF",
			...primaryBoxShadow
		}
	},
	infoHover: {
		"&:hover": {
			backgroundColor: infoColor,
			color: "#FFFFFF",
			...infoBoxShadow
		}
	},
	successHover: {
		"&:hover": {
			backgroundColor: successColor,
			color: "#FFFFFF",
			...successBoxShadow
		}
	},
	warningHover: {
		"&:hover": {
			backgroundColor: warningColor,
			color: "#FFFFFF",
			...warningBoxShadow
		}
	},
	dangerHover: {
		"&:hover": {
			backgroundColor: dangerColor,
			color: "#FFFFFF",
			...dangerBoxShadow
		}
	},
	roseHover: {
		"&:hover": {
			backgroundColor: roseColor,
			color: "#FFFFFF",
			...roseBoxShadow
		}
	},
	dropdownItemRTL: {
		textAlign: "right" as const
	},
	dropdownDividerItem: {
		margin: "5px 0",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
		height: "1px",
		overflow: "hidden" as const
	},
	buttonIcon: {
		width: "20px",
		height: "20px"
	},
	caret: {
		transition: "all 150ms ease-in",
		display: "inline-block" as const,
		width: "0",
		height: "0",
		marginLeft: "4px",
		verticalAlign: "middle",
		borderTop: "4px solid",
		borderRight: "4px solid transparent",
		borderLeft: "4px solid transparent"
	},
	caretActive: {
		transform: "rotate(180deg)"
	},
	caretDropup: {
		transform: "rotate(180deg)"
	},
	caretRTL: {
		marginRight: "4px"
	},
	dropdownHeader: {
		display: "block" as const,
		padding: "0.1875rem 1.25rem",
		fontSize: "0.75rem",
		lineHeight: "1.428571",
		color: "#777",
		whiteSpace: "nowrap" as const,
		fontWeight: "inherit" as const,
		marginTop: "10px",
		"&:hover,&:focus": {
			backgroundColor: "transparent" as const,
			cursor: "auto" as const
		}
	},
	noLiPadding: {
		padding: "0"
	}
});

export default customDropdownStyle;
