import { schema } from 'normalizr';

export const discountsSchema = new schema.Entity('discounts');
export const ceaProgramsSchema = new schema.Entity('ceaPrograms');
export const discountTypesSchema = new schema.Entity('discountTypes');
export const selectedDiscountSchema = new schema.Entity('selectedDiscount');
export const itemRetailsSchema = new schema.Entity('itemRetails');
export const itemRetailTypesSchema = new schema.Entity('itemRetailTypes');
export const itemRetailCategoriesSchema = new schema.Entity('itemRetailCategories');
export const itemRetailUnitOfMeasureSchema = new schema.Entity('itemRetailUnitOfMeasure');
export const discountTimeFrameTypesSchema = new schema.Entity('discountTimeFrameTypes');
export const discountMarketsSchema = new schema.Entity('discountMarkets');
export const discountRetailLocationsSchema = new schema.Entity('discountRetailLocations');
export const retailLocationTypesSchema = new schema.Entity('retailLocationTypes');
export const retailLocationLocationTypesSchema = new schema.Entity('retailLocationLocationTypes');
export const retailLocationMruTypesSchema = new schema.Entity('retailLocationMruTypes');
