export enum Roles {
	GlobalAdmin = 'GlobalAdmin',
	CompanyAdmin = 'CompanyAdmin'
}

export enum LoginActions {
	CanLogin = 'CanLogInCeaAdmin'
}

export enum CompanyActions {
	CanCreateEditCompanies = 'CanCreateEditCompanies',
	CanDeactivateCompanies = 'CanDeactivateCompanies'
}

export enum UserActions {
	CanCreateEditCompanyAdministrators = 'CanCreateEditCompanyAdministrators',
	CanCreateEditUsers = 'CanCreateEditCompanyUsers',
	CanDeactivateCompanyAdmins = 'CanDeactivateCompanyAdminUsers',
	CanResetPin = 'CanResetPin',
	CanAssignAnyExternalSystemRole = 'CanAssignAnyExternalSystemRole',
	CanCreateEditCompanies = 'CanCreateEditCompanies',
	CanManageUserRoles = 'CanManageUserRoles',
	CanModifyEmployeeImportSchedule = 'CanModifyEmployeeImportSchedule',
	CanViewExecutionTags = 'CanViewExecutionTags',
	CanViewSecretShopperAdmin = 'CanViewSecretShopperAdmin'
}

export enum DeviceManagementActions {
	CanUseDeviceManagement = 'CanUseDeviceManagement'
}

export enum PricingActions {
	CanUseDiscountManager = 'CanUseDiscountManager'
}
