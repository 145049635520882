import React, { ReactElement } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

const useStyles = makeStyles({
	...customInputStyle,
	textBox: {
		fontSize: '14px'
	},
	formPaddingAlt: {
		paddingTop: '6px !important',
	}
});

interface OwnProps {
	removeUnderline?: boolean;
}

type Props = FieldRenderProps<string> & StandardTextFieldProps & OwnProps;
const TextFieldAdapter = ({ InputLabelProps, input, meta, smallerFormTopPadding, removeUnderline, ...rest }: Props): ReactElement => {
	const classes = useStyles();
	return (
		<div className={smallerFormTopPadding ? classes.formPaddingAlt : classes.formControl}>
			<TextField
				{...input}
				{...rest}
				InputProps={{
					classes: {
						input: classes.textBox,
					},
					disableUnderline: removeUnderline,
					...rest.InputProps,
				}}
				InputLabelProps={{
					...InputLabelProps,
					classes: {
						root: classes.textBox
					}
				}}
				onChange={(event) => input.onChange(event.target.value)}
				error={meta.touched ? meta.error : false}
				helperText={meta.touched ? meta.error : ''}
			/>
		</div>
	);
};

export default TextFieldAdapter;
