import React, { useState } from 'react';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/CustomButtons';
import { Card, CardHeader } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import { TerminalMappingTable } from 'domains/devices/containers';
import { TerminalMappingSearchForm } from 'domains/devices/components';
import { useStyles } from 'styles';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	customPagination: {
		float: 'right' as const
	}
};


export default function TerminalMappingSummary() {
	const history = useHistory();
	const classes = useStyles(style);

	const [filter, setFilter] = useState<string>();
	const [recordCount, setRecordCount] = useState(0);

	const updateFilter = (value: string) => {
		setFilter(value);
	}

	const updateRecordCount = (value: number) => {
		setRecordCount(value);
	}

	const routeToAddNew = () => {
		history.push('/devices/terminalMapping/add');
	}

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Terminal Mapping</h2>
				<GridContainer>
					<GridItem>
						<Button color="primary" onClick={routeToAddNew}>Add Tablet</Button>
					</GridItem>
					<GridContainer item sm={4} md={6}>
						<GridItem xs={12}>
							<TerminalMappingSearchForm handleFilter={updateFilter} />
						</GridItem>
					</GridContainer>
					<GridItem>
						<div className={classes.customPagination}>
							<PaginationWrapper recordCount={recordCount} />
						</div>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<GridContainer>
				<GridItem xs={12}>
					<TerminalMappingTable filter={filter} onRecordCountUpdated={updateRecordCount} />
				</GridItem>
				<GridItem xs={12}>
					<div className={classes.customPagination}>
						<PaginationWrapper recordCount={recordCount} />
					</div>
				</GridItem>
			</GridContainer>
		</Card>
	);
}
