import { Dictionary } from 'lodash';
import { Company } from 'domains/companies/models';
import { ItemRetailTypeEnum, ItemRetailCategoryEnum, ItemRetailUnitOfMeasureEnum } from 'domains/discounts/models';
import { Moment } from 'moment';

export enum SourceSystem {
	CeaAdmin = 1,
	Cea = 2,
	MetrixWeb = 3,
	Cead = 5,
}

export interface CeaAuthenticationRequest {
	username: string;
	password?: string;
	sourceSystem: SourceSystem;
	refreshToken?: string;
}

export interface CeaAuthenticationResponse {
	message: string;
	accessToken: string;
	refreshToken: string;
	tokenExpiresAt: string;
	username: string;
	userId: string;
	status: number;
	ceaAuthenticationResultStatus: CeaAuthenticationResultStatus;
}

export enum CeaAuthenticationResultStatus {
	success = 0,
	invalid = 1,
	error = 2
}

export interface CurrentUser extends CeaUser {
	canDoAction: (actionName: string) => boolean;
	hasRole: (roleName: string) => boolean;
	isAdmin: boolean;
	pinConfigurationNeeded: boolean;
	programs: CeaProgram[] | null;
}

export interface CeaProgram {
	name: string;
	externalProgramId: number;
	id: number;
	sourceSystemId: number;
}

export interface User {
	id: string;
	azureAdUser?: AzureAdUser;
	ceaUser?: CeaUser;
	metrixUser?: MetrixUser;
	companyId: number;
	onPremisesExtensionAttributes?: OnPremisesExtensionAttributes;
}

export interface DiscountType {
	id: number;
	name: string;
	displayName: string;
}

export interface Discount {
	id: number;
	discountType?: string;
	description?: string;
	discountTypeId: number;
	displayName?: string;
	name: string;
	programName?: string;
	programId: number;
	effectiveStartDate: string | Moment;
	effectiveEndDate?: string | Moment;
	discountAmount: number;
	discountPercentage?: number;
	promoCode?: string;
	isMaxLimitEnabled: boolean;
	maxLimitPerCart: number | null;
	shouldAutoApply: boolean;
	manualApplyPromoCode: boolean;
	displayInCea: boolean;
	isPhotoCaptureRequired: boolean;
	isCcnRequiredForAutoApply: boolean;
	isCcnRequiredForManualApply: boolean;
	isLockedDown: boolean;
	isExclusiveItemDiscount: boolean;
	isCopeExclusiveItemDiscount: boolean;
	isCartMinimumPriceEnabled: boolean;
	cartMinimumPrice: number | null;
	active: boolean;
	discountEffectTypeId: number;
	discountEffectType?: string;
	proofOfPurchaseTypeId: number | null;
	proofOfPurchaseType?: string;
	hasCartConditionItems: boolean;
	discountGroups: DiscountGroup[];
	discountTimeFrameTypeId: number | null;
	timeFrameLimit: number | null;
	hasTimeFrameLimit: boolean;
	discountMarketIds: number[];
	discountMarketNames?: string;
	discountMarketList?: string[];
	retailLocationTypeIds: number[];
	retailLocationTypeNames?: string;
	retailLocationLocationTypeIds: number[];
	retailLocationLocationTypeNames?: string;
	retailLocationMruTypeIds: number[];
	retailLocationMruTypeNames?: string;
	retailLocationIds: number[];
	retailLocationNames?: string;
	allRetailLocationsSelected: boolean;
	rowVersion?: string;
}

export interface DiscountGroup {
	id: number;
	discountId: number;
	discountAmount?: number;
	discountPercentage?: number;
	discountGroupTypeId: number;
	itemRetailUnitOfMeasureId?: number;
	finalPrice?: number;
	quantity: number;
	canOverrideMinimumPrice: boolean;
	conditions: DiscountGroupCondition[];
	rowVersion?: string;
}

export interface DiscountGroupCondition {
	id: number;
	itemRetailTypeId?: ItemRetailTypeEnum
	itemRetailCategoryId?: ItemRetailCategoryEnum;
	itemRetailUnitOfMeasureId?: ItemRetailUnitOfMeasureEnum
	upc?: string;
	rowVersion?: string;
}

export interface OnPremisesExtensionAttributes {
	extensionAttribute1: string;
}

export interface AzureAdUser {
	id: string;
	accountEnabled: boolean;
	displayName?: string;
	mobilePhone: string | null;
	givenName: string | null;
	surname: string | null;
	userPrincipalName?: string;
	otherMails: string[] | null;
	importedFromStaffing: boolean;
	readOnly: boolean;
	employeeId?: string;
}

export interface CeaUser {
	id: number;
	azureUID: string;
	firstName: string | null;
	lastName: string | null;
	hasPIN: boolean;
	active: boolean;
	email: string | null;
	companyId: number;
	fullName?: string;
	roleNames: string[];
	rowVersion?: string;
	mobilePhone: string | null;
	systemAccessTypeId: SystemAccessType;
}

export interface MetrixUser {
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	isActive: boolean;
	externalUserId: string | null;
	mobilePhone: string | null;
	role?: Role;
	employeePrograms: EmployeeProgram[];
	markets?: number[];
	manager?: ManagerEmployee;
	homeAddress?: MetrixPersonAddress;
	gmrEmployeeId?: string;
	employeeType?: string;
}

export interface Role {
	id: number;
	name: string;
	active: boolean;
	companies?: Company[];
	sourceSystemId?: SourceSystem;
	displayName?: string;
	isAddOn: boolean;
}

export interface ManagerEmployee {
	id: number;
	firstName: string;
	lastName: string;
}

export interface Toast {
	message: string;
	info: ToastInfo;
	type: ToastType;
}

export interface ToastInfo {
	timeout?: number;
	action?: () => void;
}

export enum ToastType {
	Success = 0,
	Error = 1
}

export interface Pagination {
	pageNumber?: number;
	pageSize?: number;
}

export interface UserFilter {
	username?: string;
	sourceSystemIds?: number[];
	ceaRoles?: number[];
	metrixRoles?: number[];
	markets?: number[];
	programs?: number[];
}

export interface DiscountFilterValues extends Pagination {
	discountName?: string;
	discountType?: number;
	market?: number;
	program?: number;
	active?: boolean;
	sortOrderDesc?: boolean;
}

export interface SearchRequest extends Pagination {
	active?: boolean;
	filter?: string | UserFilter;
	sortOrderDesc?: boolean;
}

export interface SearchResponse {
	recordCount: number;
}

export interface Market {
	marketId: number;
	marketCode: string;
	shortName: string;
}

export interface RadioButtonListItem {
	value: any;
	label: string;
	disabled?: boolean;
}

export interface Program {
	id: number;
	masterProgramId?: number;
	name: string;
	marketIds: Market[];
	isActive: boolean;
}

export interface EmployeeProgram {
	isActive: boolean;
	programId: number;
}

export interface EmployeeMarket {
	isActive: boolean;
	marketId: number;
}

export enum SystemAccessType {
	Tablet = 1,
	Web = 2,
	Both = 3,
}

export const CeaSourceSystemDictionary: Dictionary<string> = {
	[SourceSystem.Cea]: 'CEA',
	[SourceSystem.CeaAdmin]: 'CEA Admin',
	[SourceSystem.Cead]: 'CEAD',
}

export const SourceSystemDictionary: Dictionary<string> = {
	...CeaSourceSystemDictionary,
	[SourceSystem.MetrixWeb]: 'MetrixWeb',
}

export enum MetrixAddressType {
	Unspecified = 0,
	Business = 1,
	Home = 2,
	Work = 3,
	Primary = 4,
	Remittance = 5
}

export interface MetrixPersonAddress {
	addressLine1?: string;
	addressLine2?: string;
	city?: string;
	state?: string;
	postalCode?: string;
	type?: MetrixAddressType;
}

export interface ExpertConversionProgramConfiguration {
	id: number;
	name: string;
	marketId?: number;
	maximumStartDateDefermentDays: number;
	programStartTimeOfDay: string;
	programEndTimeOfDay: string;
	programLengthInDays: number;
	minimumDaysInProgram: number;
	postProgramCompletionContactableDays: number;
	contactLengthInDays: number;
	effectiveStartDateTime: Date;
	effectiveEndDateTime?: Date;
}

export enum DiscountTypeEnum {
	virtualBundle = 1,
	promoCode = 3,
	priceOverride = 4,
	guidedTrial = 5,
	mfp = 7,
	consumerReferral = 8,
	lending = 9,
	priceDiscount = 10,
}

export interface ItemRetailType {
	id: number;
	name: string;
	displayName: string;
	canOverrideMinimumPrice: boolean;
}

export interface DiscountTimeFrameType {
	id: number;
	name: string;
	displayName: string;
}

export interface ItemRetailCategory {
	id: number;
	name: string;
	displayName: string;
}

export interface ItemRetailUnitOfMeasure {
	id: number;
	name: string;
	displayName: string;
}

export interface ItemRetail {
	id: number;
	itemId: number;
	sku: string;
	price: number;
	minimumPrice: number;
	localityCode: string;
	metadataId: number;
	metadata: ItemRetailMetaDataDto;
	unsaleable: boolean;
}

export interface ItemRetailMetaDataDto {
	id: number;
	name: string;
	upc: string;
	unitQuantity: number;
	itemRetailTypeId?: ItemRetailTypeEnum;
	itemRetailUnitOfMeasureId?: ItemRetailUnitOfMeasureEnum;
	itemRetailCategoryId?: ItemRetailCategoryEnum
}

export interface SourceSystemMarket {
	id: string;
	marketId: number;
	programId: number;
	name: string;
	shortName: string;
	marketCode: string;
}

export interface DiscountRetailLocation {
	id: number;
	programId: number;
	storeType: number;
	storeName: string;
	isActive: boolean;
	marketId: number;
	storageUnitAccountId?: number;
	isExpertRetailVenue: boolean;
	parentRetailLocationId?: number;
	locationType?: number;
	retailLocationMruType?: number;
	localityCode: string;
}

export interface RetailLocationType {
	id: number;
	name: string;
	displayName: string;
}

export interface RetailLocationLocationType {
	id: number;
	name: string;
	displayName: string;
}

export interface RetailLocationMruType {
	id: number;
	name: string;
	displayName: string;
}

export interface RetailLocationAdminDto {
	id: number;
	displayName: string;
	programId: number;
	marketId: number;
	storeType: number;
	physicalLocationSubType?: number;
	mruSubType?: number;
}

export interface SelectOption {
	name: string;
	id?: number;
}

export interface SearchBoxFormValues {
	filter?: string;
}

export interface LoginFormValues {
	username: string;
	password: string;
}
