import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Page } from 'components/Pagination';
import { doSetCurrentPage, doPaginationReset } from 'domains/core/actions';

export interface Props {
	recordCount: number;
}

export default function PaginationWrapper(props: Props) {
	const { recordCount } = props;
	const dispatch = useDispatch();

	const currentPage = useSelector(state => state.domains.core.pagination.currentPage);
	const recordsPerPage = useSelector(state => state.domains.core.pagination.recordsPerPage);


	useEffect(() => () => {
		dispatch(doPaginationReset());
	}, [dispatch]);

	const getPosition1Page = useCallback(() => {
		const totalPages = Math.ceil(recordCount / recordsPerPage);

		// position 1 should always be one if total pages 5 or less
		if (currentPage < 3 || totalPages <= 5) {
			return 1;
		}

		// position 1 should always be n - 4 if last page active
		if (currentPage === totalPages) {
			return currentPage - 4;
		}

		// position 1 should always be n - 3 if second to last page active
		if (currentPage === totalPages - 1) {
			return currentPage - 3;
		}

		// active page will always be in position 3 (middle)
		return currentPage - 2;
	}, [currentPage, recordCount, recordsPerPage]);

	const goBackPage = useCallback(() => {
		dispatch(doSetCurrentPage(currentPage - 1));
	}, [currentPage, dispatch]);

	const goForwardPage = useCallback(() => {
		dispatch(doSetCurrentPage(currentPage + 1));
	}, [currentPage, dispatch]);

	const getPagination = useMemo(() => {
		if (recordCount) {
			const totalPages = Math.ceil(recordCount / recordsPerPage);
			const pages: Page[] = [];
			const position1Page = getPosition1Page();

			// if more than one page
			if (totalPages > 1) {
				// show previous button
				pages.push({
					active: false,
					onClick: currentPage > 1 ? goBackPage : undefined,
					text: 'PREV',
					disabled: currentPage === 1
				});

				// always show the first button
				pages.push({
					active: currentPage === position1Page,
					text: position1Page,
					onClick: () => dispatch(doSetCurrentPage(position1Page!))
				});

				// always show the second button
				pages.push({
					active: currentPage === position1Page! + 1,
					text: position1Page + 1,
					onClick: () => dispatch(doSetCurrentPage(position1Page! + 1))
				});
			}

			// only show the third button if needed
			if (totalPages > 2) {
				pages.push({
					active: currentPage === position1Page + 2,
					text: position1Page + 2,
					onClick: () => dispatch(doSetCurrentPage(position1Page + 2))
				});
			}

			// fourth button will be ellipses on initial load if more than 5 pages
			// otherwise, if needed show n + 3
			if (position1Page === 1 && totalPages > 5 && currentPage <= 3) {
				pages.push({
					active: false,
					disabled: false,
					text: '...'
				});
			} else if (position1Page + 3 <= totalPages) {
				pages.push({
					active: currentPage === position1Page + 3,
					text: position1Page + 3,
					onClick: () => dispatch(doSetCurrentPage(position1Page + 3))
				});
			}

			// show the last page if on the first page and more than 5 pages
			// otherwise show n + 4
			if (position1Page === 1 && totalPages > 5) {
				pages.push({
					active: currentPage === totalPages,
					text: totalPages,
					onClick: () => dispatch(doSetCurrentPage(totalPages))
				});
			} else if (position1Page + 4 <= totalPages && position1Page > 0) {
				pages.push({
					active: currentPage === position1Page + 4,
					text: position1Page + 4,
					onClick: () => dispatch(doSetCurrentPage(position1Page + 4))
				});
			}

			// only show next button if more than one page
			if (totalPages > 1) {
				pages.push({
					active: false,
					onClick: currentPage < totalPages ? goForwardPage : undefined,
					text: 'NEXT',
					disabled: currentPage === totalPages
				});
			}

			return <Pagination color="primary" pages={pages} />;
		}

		return null;
	}, [dispatch, currentPage, getPosition1Page, goBackPage, goForwardPage, recordCount, recordsPerPage]);

	return (getPagination);
}
