import React from 'react';
import { useSelector } from 'react-redux';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { IconButton } from '@material-ui/core';
import { GridContainer, GridItem } from 'components/Grid';
import AddIcon from 'domains/core/components/AddIcon';
import DiscountGroupConditionForm from 'domains/discounts/components/DiscountGroupConditionForm';

import { ItemRetail } from 'domains/core/models';
import { DiscountGroupConditionFormValues } from 'domains/discounts/models';
import { getItemRetailCategories, getItemRetailTypes } from 'domains/discounts/selectors';
import { useStyles } from 'styles';
import CancelIcon from 'domains/core/components/CancelIcon';

interface Props extends FieldArrayRenderProps<DiscountGroupConditionFormValues, any> {
	itemRetails: ItemRetail[];
	selectedGroupTypeId?: number;
	handleRemoveGroup: () => void;
}

const style = () => ({
	addConditionContainer: {
		marginTop: '10px',
		marginLeft: '-13px'
	},
	boldText: {
		fontWeight: 700,
		cursor: 'pointer',
	},
	removeGroupContainer: {
		textAlign: 'right' as const,
		marginRight: '18px',
		marginTop: '10px'
	}
});

export default function DiscountGroupConditions(props: Props) {
	const { fields, itemRetails, selectedGroupTypeId, handleRemoveGroup } = props;
	const classes = useStyles(style);

	const itemRetailCategories = useSelector(state => getItemRetailCategories(state));
	const itemRetailTypes = useSelector(state => getItemRetailTypes(state));

	return (
		<React.Fragment>
			<GridItem xs={12} md={12} lg={12}>
				{fields.map((condition: string, index: number) => {
					const currentCondition = fields.value[index];
					const showAddCondition = index === (fields.length ?? 0 - 1) || fields.length === 1;

					const handleRemoveCondition = (index: number) => {
						fields.remove(index);
					};

					return (
						<React.Fragment key={index}>
							<GridItem xs={12} md={12} lg={12}>
								<DiscountGroupConditionForm
									key={condition}
									groupConditionName={condition}
									discountGroupCondition={currentCondition}
									itemRetailTypes={itemRetailTypes}
									itemRetailCategories={itemRetailCategories}
									itemRetails={itemRetails}
									index={index}
									selectedGroupTypeId={selectedGroupTypeId}
									totalDiscountGroupConditions={fields.length ?? 0}
									handleRemoveCondition={() => handleRemoveCondition(index)}
								/>
							</GridItem>
							{showAddCondition &&
								<GridContainer>
									<GridItem xs={6} md={6} lg={6}>
										<div className={classes.addConditionContainer}>
											<IconButton onClick={() => fields.push({})}>
												<AddIcon />
											</IconButton>
											<span className={classes.boldText} onClick={() => fields.push({})}>OR</span>
										</div>
									</GridItem>
									<GridItem xs={6} md={6} lg={6}>
										<div className={classes.removeGroupContainer}>
											<IconButton onClick={handleRemoveGroup}>
												<CancelIcon />
											</IconButton>
											<span className={classes.boldText} onClick={handleRemoveGroup}>Remove Group</span>
										</div>
									</GridItem>
								</GridContainer>
							}
						</React.Fragment>
					)
				})}
			</GridItem>
		</React.Fragment>
	);
}
