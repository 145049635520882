import { Component, ReactNode } from 'react';
import { IIdleTimer, withIdleTimer } from 'react-idle-timer';

/**
 * Component used to replace the IdleTimer that was removed from react-idle-timer in version 5
 * @link https://idletimer.dev/docs/api/with-idle-timer#idletimer-component
 */
class IdleTimerComponent extends Component<IIdleTimer> {
	public render(): ReactNode {
		return null;
	}
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);
