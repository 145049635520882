import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardHeader } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { Table } from 'components/Table';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import Muted from 'components/Typography/Muted';

import { doLoadCompanyDetail, doActivateCompany, doDeactivateCompany } from 'domains/companies/actions';
import { UpdateCompanyActiveStatusRequest } from 'domains/companies/models';
import { getCurrentUser } from 'domains/core/selectors';
import { CompanyActions } from 'domains/core/enums';
import { getAdminUsers } from 'domains/users/selectors';
import {
	getCompanyActiveUserCount,
	getCompanyInactiveUserCount,
	getCompany
} from 'domains/companies/selectors';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import CancelIcon from 'domains/core/components/CancelIcon';
import AddIcon from 'domains/core/components/AddIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import Modal from 'domains/core/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';

interface MatchParams {
	companyId: string;
}

const style = {
	customCardContentClass: {
		paddingLeft: 0,
		paddingRight: 0
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	},
	noMargin: {
		margin: '0px'
	},
	customCard: {
		marginBottom: '5px'
	},
	buttonPadding: {
		padding: '0px 15px 15px'
	},
	customLinkClass: {
		paddingLeft: '0px',
		paddingRight: '0px'
	}
};

export default function CompanyDetails() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const history = useHistory();

	const [modalOpen, setModalOpen] = useState(false);

	const user = useSelector(state => getCurrentUser(state));
	const companyId = useParams<MatchParams>()?.companyId ?? user?.companyId;
	const company = useSelector(state => getCompany(state, Number(companyId)));
	const activeUserCount = useSelector(state => getCompanyActiveUserCount(state));
	const inactiveUserCount = useSelector(state => getCompanyInactiveUserCount(state));
	const adminUsers = useSelector(state => getAdminUsers(state, { match: { params: { companyId } } }));

	useEffect(() => {
		if (companyId) {
			dispatch(doLoadCompanyDetail(Number(companyId)));
		} else {
			// reroute to dashboard
			history.push('/');
		}
	}, [companyId, dispatch, history]);

	const closeModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const openModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleDeactivateCompany = useCallback(() => {
		if (company) {
			const request: UpdateCompanyActiveStatusRequest = {
				companyId: company.id,
				rowVersion: company.rowVersion
			};
			dispatch(doDeactivateCompany(request));
		}
		closeModal();
	}, [closeModal, company, dispatch]);

	const handleActivateCompany = useCallback(() => {
		if (company) {
			const request: UpdateCompanyActiveStatusRequest = {
				companyId: company.id,
				rowVersion: company.rowVersion
			};
			dispatch(doActivateCompany(request));
		}
		closeModal();
	}, [closeModal, company, dispatch]);

	const navigateToCompanyUsers = useCallback(() => {
		if (company) {
			history.push(`/users/companies/${company.id}/users/`);
		}
	}, [company, history]);

	const navigateToEditCompany = useCallback(() => {
		if (company) {
			history.push(`/users/companies/${company.id}/edit`);
		}
	}, [company, history]);

	const getContextMenuItems = useCallback(() => {

		const contextMenuItems: JSX.Element[] = [];

		if (user && company) {
			if (user.canDoAction(CompanyActions.CanDeactivateCompanies)) {
				contextMenuItems.push(
					<ContextMenuItem
						icon={company.active ? <CancelIcon /> : <AddIcon />}
						action={openModal}
						text={company.active ? 'Deactivate' : 'Activate'}
					/>
				);
			}
		}

		return contextMenuItems;
	}, [company, openModal, user]);

	const modalTitle = useMemo(() => `${company?.active ? 'Deactivate' : 'Activate'} ${company?.name}?`, [company?.active, company?.name]);
	const content = useMemo(() => `Any users associated with this company will ${company?.active ? 'not' : ''} be able to login.`, [company?.active]);
	const actionHandler = useMemo(() => company?.active ? handleDeactivateCompany : handleActivateCompany, [company?.active, handleActivateCompany, handleDeactivateCompany]);
	const actionButtonText = useMemo(() => company?.active ? 'Deactivate' : 'Activate', [company?.active]);
	const adminUsersTableData = useMemo(() => adminUsers ? adminUsers?.map(c => [<div key={1}>{c.azureAdUser ? c.azureAdUser.displayName : ''}</div>]) : [], [adminUsers]);

	if (!company || !user) {
		return null;
	}

	return (
		<>
			<Card>
				<CardHeader>
					<h2 className={classes.cardIconTitle}>Details</h2>
				</CardHeader>
				{user.canDoAction(CompanyActions.CanCreateEditCompanies) &&
					<GridContainer className={classes.buttonPadding}>
						<GridItem>
							<Button color="primary" onClick={navigateToEditCompany}>Edit Details</Button>
						</GridItem>
						<GridItem xs={2}>
							<ContextMenu menuItems={getContextMenuItems()} />
						</GridItem>
					</GridContainer>
				}
				<GridContainer className={classes.buttonPadding}>
					<GridItem xs={12} md={3}>
						<Muted>
							<small>Name</small>
						</Muted>
						<p className={classes.noTopMargin}>{company.name}</p>
					</GridItem>
					<GridItem xs={12} md={3}>
						<Muted>
							<small>Address</small>
						</Muted>
						{company.addressLine1 && company.city && company.state && company.zipCode ?
							<>
								<p className={classes.noMargin}>{company.addressLine1}</p>
								{company.addressLine2 && <p className={classes.noMargin}>{company.addressLine2}</p>}
								<p className={classes.noTopMargin}>{`${company.city}, ${company.state} ${company.zipCode}`}</p>
							</> :
							<p className={classes.noTopMargin}>Not Specified</p>
						}
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Description</small>
						</Muted>
						<p className={classes.noTopMargin}>{company.description ? company.description : 'Not Specified'}</p>
					</GridItem>
				</GridContainer>
			</Card>
			<GridContainer>
				<GridItem xs={12} md={6}>
					<Card>
						<CardHeader>
							<h2 className={classes.cardIconTitle}>Administrators</h2>
						</CardHeader>
						<GridContainer className={classes.buttonPadding}>
							<GridItem xs={12}>
								<Table striped tableData={adminUsersTableData} />
							</GridItem>
						</GridContainer>
					</Card>
				</GridItem>
				<GridItem xs={12} md={6}>
					<Card>
						<CardHeader>
							<h2 className={classes.cardIconTitle}>Users</h2>
						</CardHeader>
						<GridContainer className={classes.buttonPadding}>
							<GridItem xs={12} md={6}>
								<Button color="primary" onClick={navigateToCompanyUsers}>See User List</Button>
							</GridItem>
						</GridContainer>
						<GridContainer className={classes.buttonPadding}>
							<GridItem xs={12}>
								<Muted>
									<small>Active</small>
								</Muted>
								<p className={classes.noTopMargin}>{activeUserCount || 0}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>Inactive</small>
								</Muted>
								<p className={classes.noTopMargin}>{inactiveUserCount || 0}</p>
							</GridItem>
						</GridContainer>
					</Card>
				</GridItem>
			</GridContainer>
			<Modal
				modalOpen={modalOpen}
				title={modalTitle}
				content={content}
				closeButtonText="Cancel"
				handleClose={closeModal}
				actionButtonText={actionButtonText}
				handleAction={actionHandler}
			/>
		</>
	);
}

// const container = compose<Props, {}>(
// 	withRouter,
// 	withStyles(style),
// 	connect<StateProps, DispatchProps, Props, ApplicationState>(
// 		(state: ApplicationState, props: Props) => {
// 			return {
// 			};
// 		},
// 		{ doLoadCompanyDetail, doActivateCompany, doDeactivateCompany }
// 	)
// )(CompanyDetails);

// export default container;
