import React from 'react';
import { Field, FormRenderProps, FormSpy } from 'react-final-form';
import FormInput from 'domains/core/components/FormInput';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { ExecutionTagFormOptions, ExecutionTagFormValues } from 'domains/executionTags/models';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import { getValidMarketOptions, getValidRetailLocationOptions } from 'domains/executionTags/helpers';
import { RetailLocationTypeEnum } from 'domains/discounts/models';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';
import * as formatters from 'utils/fieldFormatters';

interface OwnProps {
	onCancel: () => void;
	options?: ExecutionTagFormOptions;
	isEdit?: boolean;
}

type Props = OwnProps & FormRenderProps<ExecutionTagFormValues>;
const ExecutionTagForm: React.FC<Props> = (props) => {
	const { options, invalid, pristine } = props;
	const submitDisabled = invalid || pristine;

	return (
		<form onSubmit={props.handleSubmit}>
			<GridContainer>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="name"
						validate={validators.maxLength255}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: true, fullWidth: true, disabled: props.isEdit }}
								labelText="Internal Name"
								id="internal-name-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="displayName"
						validate={validators.maxLength255}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: true, fullWidth: true }}
								labelText="Display Name"
								id="display-name-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="description"
						validate={validators.maxLength255}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: false, fullWidth: true }}
								inputProps={{ multiline: true }}
								labelText="Description"
								id="description-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<Field
						name="startDate"
						validate={validators.isDate}
						format={formatters.date}
						parse={normalizers.date}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: true, fullWidth: true }}
								labelText="Start Date"
								id="start-date-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<Field
						name="endDate"
						validate={validators.composeValidators(validators.isDate, validators.isEndDateAfterStartDateFinalForm)}
						format={formatters.date}
						parse={normalizers.date}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: false, fullWidth: true }}
								labelText="End Date"
								id="end-date-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<Field
						name="programId"
						render={(renderProps) => (
							<FormSelectAdapter
								{...renderProps}
								required
								disabled={props.isEdit}
								labelText="Program"
								id="program-select-field"
								options={options?.programs.map(x => ({ value: x.id, text: x.name }))}
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<FormSpy subscription={{ values: true }}>
						{({ values }) => (
							<Field
								name="marketIds"
								render={(renderProps) => (
									<FormSelectAdapter
										{...renderProps}
										required
										labelText="Market(s)"
										id="market-select-field"
										disabled={!values.programId}
										multiple
										options={getValidMarketOptions(values, options).map(x => ({ value: x.marketId, text: x.shortName }))}
									/>
								)}
							/>
						)}
					</FormSpy>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<FormSpy subscription={{ values: true }}>
						{({ values }) => (
							<Field
								name="retailLocationTypeIds"
								render={(renderProps) => (
									<FormSelectAdapter
										{...renderProps}
										required
										labelText="Location Type(s)"
										id="location-types-select-field"
										multiple
										disabled={!values.programId}
										options={options?.retailLocationTypes.map(x => ({ value: x.id, text: x.displayName }))}
									/>
								)}
							/>
						)}
					</FormSpy>
				</GridItem>
				<FormSpy subscription={{ values: true }}>
					{({ values }) => (
						values.retailLocationTypeIds && values.retailLocationTypeIds.includes(RetailLocationTypeEnum.physicalLocation) ? (
							<GridItem xs={12} md={12} lg={12}>
								<Field
									name="physicalLocationSubTypeIds"
									render={(renderProps) => (
										<FormSelectAdapter
											{...renderProps}
											required
											labelText="Physical Location Subtype(s)"
											id="physical-location-subtypes-select-field"
											multiple
											disabled={!values.programId}
											options={options?.physicalLocationSubTypes.map(x => ({ value: x.id, text: x.displayName }))}
										/>
									)}
								/>
							</GridItem>
						) : null
					)}
				</FormSpy>
				<FormSpy subscription={{ values: true }}>
					{({ values }) => (
						values.retailLocationTypeIds && values.retailLocationTypeIds.includes(RetailLocationTypeEnum.mobileRetailUnit) ? (
							<GridItem xs={12} md={12} lg={12}>
								<Field
									name="mruSubTypeIds"
									render={(renderProps) => (
										<FormSelectAdapter
											{...renderProps}
											required
											labelText="MRU Subtype(s)"
											id="mru-subtypes-select-field"
											multiple
											disabled={!values.programId}
											options={options?.mruSubTypes.map(x => ({ value: x.id, text: x.displayName }))}
										/>
									)}
								/>
							</GridItem>
						) : null
					)}
				</FormSpy>
				<GridItem xs={12} md={12} lg={12}>
					<FormSpy subscription={{ values: true }}>
						{({ values }) => (
							<Field
								name="retailLocationIds"
								render={(renderProps) => (
									<FormSelectAdapter
										{...renderProps}
										required
										labelText="Location(s)"
										id="retail-locations-select-field"
										disabled={!values.programId}
										multiple
										options={getValidRetailLocationOptions(values, options).map(x => ({ value: x.id, text: x.displayName }))}
									/>
								)}
							/>
						)}
					</FormSpy>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Button color="primary" type="submit" disabled={submitDisabled}>{props.isEdit ? 'Save' : 'Add'}</Button>
					<Button simple color="primary" onClick={props.onCancel}>Cancel</Button>
				</GridItem>
			</GridContainer>
		</form>
	);
}

export default ExecutionTagForm;
