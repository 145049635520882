import React, { ReactElement, useCallback } from 'react';
import { compose } from 'recompose';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from 'components/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

const style = {
	...customInputStyle,
	label: {
		marginTop: '14px'
	}
}
interface OwnProps extends SelectProps {
	id: string;
	label?: string;
	availableValues: any[];
	change: (value: any) => void;
}

type Props = WithStyles & OwnProps;

export function SearchSelectField(props: Props): ReactElement {
	const { id, label, availableValues, value, change, multiple, classes, ...rest } = props;

	const renderSelected = useCallback((selected: any) => {
		if (selected && selected.length === availableValues.length) {
			return 'All';
		}
		if (multiple) {
			return selected[0].value ? selected.map((x: any) => x.value).join(', ') : selected.join(', ');
		}

		return selected;
	}, [availableValues.length, multiple]);

	const handleChange = useCallback((event: any) => {
		change(event?.target ? event.target.value : event);
	}, [change]);

	return (
		<FormControl fullWidth className={classes.formControl}>
			{label && <InputLabel className={classes.label} htmlFor={id}>{label}</InputLabel>}
			<Select
				id={id}
				fullWidth
				multiple={multiple}
				value={value}
				renderValue={renderSelected}
				onChange={handleChange}
				{...rest}
			>
				{availableValues.map(av => {
					const key = av.key || av;
					const val = av.value || av;

					return (
						<MenuItem key={key} value={val}>
							{multiple && <Checkbox checked={(value as any[]).indexOf(val) > -1} />}
							<ListItemText primary={val} />
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

const container = compose<Props, OwnProps>(
	withStyles(style)
)(SearchSelectField);

export default container;
