import React from 'react';
import { Button } from 'components/CustomButtons';
import { Field, Form } from 'react-final-form';
import CustomInputAdapter from 'domains/core/components/FinalForm/CustomInputAdapter';

interface Props {
	initialValues: { schedule: string }
	onSubmit: (values: { schedule: string }) => void;
}

export default function EmployeeImportUpdateScheduleForm(props: Props) {
	const { initialValues, onSubmit } = props;
	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			{({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Field
						component={CustomInputAdapter}
						name="schedule"
						labelText="Schedule Hours"
						id="schedule-input"
						formControlProps={{
							fullWidth: true,
							required: true
						}}
					/>
					<Button type="submit" color="primary">Update Schedule</Button>
				</form>
			)}
		</Form>
	);
}
