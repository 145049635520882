import React from 'react';
import { VpnKey } from '@material-ui/icons';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import { primaryColor, warningColor } from 'assets/jss/material-dashboard-pro-react';

const style = {
	primaryCustomIconStyle: {
		color: primaryColor,
		fontSize: '16px'
	},
	warningCustomIconStyle: {
		color: warningColor,
		fontSize: '16px'
	}
};

interface ComponentProps {
	color: 'primary' | 'warning';
}

export type Props = ComponentProps & WithStyles;

export const AdminIcon = (props: Props) => {
	const { color, classes } = props;

	let customClass;

	switch (color) {
		case 'primary': {
			customClass = classes.primaryCustomIconStyle;
			break;
		}
		case 'warning': {
			customClass = classes.warningCustomIconStyle;
			break;
		}
		default: customClass = '';
	}

	return (
		<VpnKey className={customClass} />
	);
};

export default withStyles(style)(AdminIcon);
