import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import FormInput from 'domains/core/components/FormInput';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { SecretShopper } from 'domains/secretShopper/models';
import * as validators from 'utils/fieldValidators';
import * as normalizers from 'utils/fieldNormalizers';

interface OwnProps {
	onCancel: () => void;
	isAddingUser?: boolean;
}

type Props = OwnProps & FormRenderProps<SecretShopper>;
const SecretShopperForm: React.FC<Props> = (props) => {
	const { invalid, pristine } = props;
	const submitDisabled = invalid || pristine;

	return (
		<form onSubmit={props.handleSubmit}>
			<GridContainer>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="ccn"
						validate={validators.maxLength12}
						parse={normalizers.getOnlyNumbers}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: true, fullWidth: true, disabled: !props.isAddingUser }}
								labelText="CCN"
								id="ccn-name-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Field
						name="description"
						validate={validators.maxLength255}
						render={(renderProps) => (
							<FormInput
								{...renderProps}
								formControlProps={{ required: false, fullWidth: true, disabled: !props.isAddingUser }}
								inputProps={{ multiline: true }}
								labelText="Description"
								id="description-field"
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} md={12} lg={12}>
					<Button color="primary" type="submit" disabled={submitDisabled}>{props.isAddingUser ? 'Add' : 'Remove'}</Button>
					<Button simple color="primary" onClick={props.onCancel}>Cancel</Button>
				</GridItem>
			</GridContainer>
		</form>
	);
}

export default SecretShopperForm;
