export const LOAD_DEVICE_TERMINAL_SUMMARY = 'devices/LOAD_DEVICE_TERMINAL_SUMMARY';
export const LOAD_DEVICE_TERMINAL_SUMMARY_SUCCESS = 'devices/LOAD_DEVICE_TERMINAL_SUMMARY_SUCCESS';
export const LOAD_DEVICE_TERMINAL_SUMMARY_FAIL = 'devices/LOAD_DEVICE_TERMINAL_SUMMARY_FAIL';

export const LOAD_DEVICE_TERMINAL_DETAILS = 'devices/LOAD_DEVICE_TERMINAL_DETAILS';
export const LOAD_DEVICE_TERMINAL_DETAILS_SUCCESS = 'devices/LOAD_DEVICE_TERMINAL_DETAILS_SUCCESS';
export const LOAD_DEVICE_TERMINAL_DETAILS_FAIL = 'devices/LOAD_DEVICE_TERMINAL_DETAILS_FAIL';

export const SAVE_DEVICE_TERMINAL_DETAILS = 'devices/SAVE_DEVICE_TERMINAL_DETAILS';
export const SAVE_DEVICE_TERMINAL_DETAILS_SUCCESS = 'devices/SAVE_DEVICE_TERMINAL_DETAILS_SUCCESS';
export const SAVE_DEVICE_TERMINAL_DETAILS_FAIL = 'devices/SAVE_DEVICE_TERMINAL_DETAILS_FAIL';

export const DEACTIVATE_DEVICE_TERMINAL = 'devices/DEACTIVATE_DEVICE_TERMINAL';
