import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormControlProps } from '@material-ui/core';
import CheckboxWrapper from 'domains/core/components/CheckboxWrapper';

type OwnProps = {
	label: string;
	className?: string;
	formControlProps?: FormControlProps; 
	useSwitch?: boolean;
	center?: boolean;
};

type Props = OwnProps & FieldRenderProps<any>;

const CheckboxAdapter: React.FC<Props> = ({ input: { onChange, value }, label, disabled, className, formControlProps, handleOnChange, ...rest }) => {
	const change = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (handleOnChange) {
			handleOnChange(event, checked);
		}
		onChange(checked);
	}, [onChange, handleOnChange]);
	
	return (
		<CheckboxWrapper
			id=''
			value=''	
			label={label}
			disabled={disabled}
			handleOnChange={change}
			checked={!!value}
			{...rest}
		/>
	);
}

export default CheckboxAdapter;
