import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import SecretShopperForm from 'domains/secretShopper/components/SecretShopperForm';
import { Form, FormRenderProps } from 'react-final-form';
import { doSaveSecretShopper } from 'domains/secretShopper/actions';
import { SecretShopper } from 'domains/secretShopper/models';


const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	}
});

const SecretShopperAdd: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const onCancel = () => history.push('/consumerInteractions/secretShoppers');

	const onSubmit = (secretShopper: SecretShopper) => {
		dispatch(doSaveSecretShopper(secretShopper));
	};

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Add CCN</h2>
					</CardHeader>
					<CardBody>
						<Form
							subscription={{ pristine: true, invalid: true }}
							onSubmit={onSubmit}
							render={(props: FormRenderProps<SecretShopper>) => (
								<SecretShopperForm
									{...props }
									onCancel={onCancel}
									isAddingUser={true}
								/>
							)}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}

export default SecretShopperAdd;
