import React, { useState, useEffect, useMemo, useRef, ReactNode, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { Select, InputLabel, FormControl, Checkbox, MenuItem, ListItemText, FormHelperText, Tooltip, IconButton, Theme } from '@material-ui/core';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';
import { useStyles } from 'styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { arrowGenerator } from 'utils/arrowGenerator';

const style = (theme: Theme) => ({
	...extendedFormsStyle,
	select: {
		fontSize: '14px'
	},
	selectLabel: {
		textTransform: 'unset' as const,
		fontSize: '14px',
		opacity: 1,
	},
	checked: {
		color: `${primaryColor} !important`
	},
	tooltip: {
		maxWidth: '300px',
		padding: '14px',
		fontSize: '12px',
		fontWeight: 300,
		lineHeight: '18px',
		borderRadius: '8px',
		backgroundColor: theme.palette.grey[900]
	},
	arrowPopper: arrowGenerator(theme.palette.grey[900]),
	arrow: {
		position: 'absolute' as const,
		fontSize: 6,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block' as const,
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
});

interface Option {
	value: any;
	text: string;
	helpText?: string;
}

interface Props {
	id?: string;
	labelText?: string;
	input?: any;
	meta?: any
	disabled?: boolean;
	required?: boolean;
	multiple?: boolean;
	selectAllLabel?: ReactNode;
	options?: Option[];
	className?: string;
	customOnChange?: (value?: any) => void;
	handleSelectAll?: (allSelected: boolean) => void;
}

export default function FormSelectAdapter(props: Props) {
	const { multiple, disabled, required, labelText, id, selectAllLabel,
		input, meta: { error, touched }, options, className, customOnChange, handleSelectAll, ...rest } = props;

	const classes = useStyles(style);
	const [open, setOpen] = useState(false);

	const arrowRef = useRef<HTMLSpanElement>(null);

	const value = useMemo(() => multiple && !input.value ? [] : input.value, [input.value, multiple]);

	const optionsHash = JSON.stringify(props.options?.map(x => x.value)?.sort());

	useEffect(() => {
		if (options && value) {
			// If the options change and a value is selected that's no longer a valid option, remove it
			let invalidValue = false;
			const optionValues = options.map(x => x.value);

			const validValues = [value].flat().reduce((accum, x) => {
				if (optionValues.includes(x)) {
					accum.push(x);
				} else {
					invalidValue = true;
				}
				return accum;
			}, [] as any);

			if (invalidValue) {
				input.onChange(multiple ? validValues : validValues[0]);
			}
		}
	}, [input, multiple, options, optionsHash, value]);

	const allChecked = useMemo(() => multiple && options
		? isEqual(value.sort(), options.map(x => x.value).sort())
		: false, [multiple, options, value]);

	useEffect(() => {
		if (handleSelectAll) {
			handleSelectAll(allChecked);
		}
	}, [allChecked, handleSelectAll]);

	const renderValue = useCallback((selectedValue) => {
		if (multiple) {
			return allChecked ? 'All' : options?.reduce((accum: string[], option) => {
				if (value?.includes && value.includes(option.value)) {
					accum.push(option.text);
				}
				return accum;
			}, []).join(', ');
		}
		return options?.find(x => x.value === selectedValue)?.text;
	}, [multiple, allChecked, options, value]);

	const finalInput = useMemo(() => {
		if (multiple && options) {
			const customInput = { ...input };
			// override on change for select all handling purposes
			customInput.onChange = (event: any, child: any) => {
				const selectAllClicked = child.props.value === 'all';

				if (customOnChange) {
					customOnChange();
				}

				if (allChecked && selectAllClicked) {
					// select none if all are checked and select none is clicked
					input.onChange([]);
				} else if (selectAllClicked) {
					// select all if not all are checked and select all is clicked
					input.onChange(options.map(x => x.value));
				} else {
					input.onChange(event.target.value);
				}
			};

			return customInput;
		}

		return input;
	}, [multiple, options, input, allChecked, customOnChange]);


	// Memoizing expensive part of render
	const allRenderedOptions = useMemo(() => {
		if (!options) {
			return [];
		}

		if (!multiple) {
			return options.map(x => (<MenuItem key={`${id}-option-${x.value}`} value={x.value}>{x.text}</MenuItem>));
		}

		const renderedOptions = [] as any;
		const selectAllText = selectAllLabel ?? (allChecked ? 'Select None' : 'Select All');
		renderedOptions.push(
			<MenuItem key={`${id}-option-all`} value="all">
				<Checkbox classes={{ checked: classes.checked }} checked={!!allChecked} />
				<ListItemText primary={selectAllText} />
			</MenuItem>
		);

		renderedOptions.push(...options.map(x => {
			const listItemText = (
				<React.Fragment>
					{x.text}
					{x.helpText &&
						<Tooltip
							disableFocusListener
							title={
								<React.Fragment>
									{x.helpText}
									<span className={classes.arrow} ref={arrowRef} />
								</React.Fragment>
							}
							classes={{
								popper: classes.arrowPopper,
								tooltip: classes.tooltip
							}}
							PopperProps={{
								popperOptions: {
									modifiers: {
										arrow: {
											enabled: Boolean(arrowRef.current),
											element: arrowRef.current
										}
									}
								}
							}}
						>
							<IconButton aria-label="Help">
								<InfoIcon />
							</IconButton>
						</Tooltip>
					}
				</React.Fragment>
			);
			return (
				<MenuItem key={`${id}-option-${x.value}`} value={x.value}>
					<Checkbox classes={{ checked: classes.checked }} checked={!!(input.value && input.value.includes(x.value))} />
					<ListItemText primary={listItemText} />
				</MenuItem>
			);
		}));

		return renderedOptions;
	}, [options, multiple, selectAllLabel, id, classes, allChecked, input.value, arrowRef]);

	return (
		<FormControl
			required={required}
			disabled={disabled}
			fullWidth
			className={className ?? classes.selectFormControl}
			error={!!touched && !!error}
		>
			{labelText && <InputLabel className={classes.selectLabel}>{labelText}</InputLabel>}
			<Select
				MenuProps={{ keepMounted: true, getContentAnchorEl: () => null }}
				multiple={multiple}
				id={id}
				disabled={disabled}
				classes={{ select: classes.select }}
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				{...finalInput}
				{...rest}
				value={value}
				renderValue={renderValue}
			>
				{allRenderedOptions}
			</Select>
			{
				touched && error && (<FormHelperText error>{error}</FormHelperText>)
			}
		</FormControl>
	);
}
