import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { Card, CardHeader, CardBody } from 'components/Card';
import { Button } from 'components/CustomButtons';
import { GridContainer, GridItem } from 'components/Grid';
import { doLoadDeviceTerminalDetails, doDeactivateDeviceTerminal } from 'domains/devices/actions';
import Muted from 'components/Typography/Muted';
import ContextMenu from 'domains/core/components/ContextMenu';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import CancelIcon from 'domains/core/components/CancelIcon';
import Modal from 'domains/core/components/Modal';
import { useStyles } from 'styles';

const style = {
	cardTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '5px'
	},
	noTopMargin: {
		marginTop: '0px'
	},
	customCard: {
		marginBottom: '5px'
	},
	moreBottomMargin: {
		marginBottom: '15px'
	}
};

export default function TerminalMappingDetailsView() {
	const classes = useStyles(style);
	const dispatch = useDispatch();
	const history = useHistory();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const existingDeviceTerminalMapping = useSelector(state => state.domains.devices.existingDeviceTerminalMapping);
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		dispatch(doLoadDeviceTerminalDetails(Number(id)));
	}, [dispatch, id]);

	const routeToEdit = useCallback(() => {
		history.push(`/devices/terminalMapping/edit/${id}`)
	}, [history, id]);

	const handleDelete = useCallback(() => {
		dispatch(doDeactivateDeviceTerminal(Number(id)));
	}, [dispatch, id]);

	return (
		<GridContainer>
			<GridItem md={12} lg={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardTitle}>Details</h2>
						<GridContainer>
							<GridItem>
								<Button fullWidth color="primary" onClick={routeToEdit}>Edit</Button>
							</GridItem>
							<GridItem xs={2}>
								<ContextMenu menuItems={[
									<ContextMenuItem
										key={1}
										icon={<CancelIcon />}
										action={() => setIsModalOpen(true)}
										text="Delete Tablet"
									/>
								]} />
							</GridItem>
						</GridContainer>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<Muted>
									<small>Tablet Asset Tag</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{existingDeviceTerminalMapping?.deviceName}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>OS</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{existingDeviceTerminalMapping?.osName}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>Terminal</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{existingDeviceTerminalMapping?.terminalName}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>Terminal Asset Tag</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{existingDeviceTerminalMapping?.terminalAssetTag || ''}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>Last Edited</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{moment(existingDeviceTerminalMapping?.modifiedDateTime).format('L')}</p>
							</GridItem>
							<GridItem xs={12}>
								<Muted>
									<small>Last Edited By</small>
								</Muted>
							</GridItem>
							<GridItem className={classes.moreBottomMargin} xs={12}>
								<p className={classes.noTopMargin}>{existingDeviceTerminalMapping?.modifiedUserName}</p>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
			<Modal
				modalOpen={isModalOpen}
				title="Delete Tablet"
				content={`Are you sure you want to delete ${existingDeviceTerminalMapping?.deviceName}?`}
				closeButtonText="Cancel"
				handleClose={() => setIsModalOpen(false)}
				actionButtonText="Delete"
				handleAction={handleDelete}
			/>
		</GridContainer>
	);
}
