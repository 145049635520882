import _ from 'lodash';
import React, {
	DependencyList,
	useCallback,
	useEffect,
	useRef,
} from 'react';

const Else = Symbol('Else');
type Else = typeof Else;

type KindOfBoolean = boolean | null | undefined;
type ConditionalArg = [KindOfBoolean, ...ArgsWithElse[]];
type Arg = (string | null | undefined) | ConditionalArg;
type Args = Arg | Arg[];
type ArgsWithElse = (Arg | Else) | Arg[];

function isConditionalArg(arg: ConditionalArg | Arg[]): arg is ConditionalArg {
	return typeof arg[0] === 'boolean';
}

function getCondition(args: ConditionalArg): [boolean, Arg[], Arg[]] {
	const mutableArgs = [...args] as ConditionalArg;
	const condition = mutableArgs.shift() as boolean;
	let trueValue: Arg[] = [];
	let falseValue: Arg[] = [];

	const elsePosition = mutableArgs.indexOf(Else);
	if (elsePosition !== -1) {
		trueValue = mutableArgs.slice(0, elsePosition) as Arg[];
		const possibleFalseValue = mutableArgs.slice(elsePosition + 1);
		if (possibleFalseValue.indexOf(Else) !== -1) {
			throw new Error(
				`Error in 'className', only one 'Else' can be given in a scope, got: ${JSON.stringify(
					args.map((a) => (a === Else ? 'Symbol.Else' : a)),
				)}.`,
			);
		}
		falseValue = possibleFalseValue as Arg[];
	} else {
		trueValue = mutableArgs as Arg[];
	}

	return [condition, trueValue, falseValue];
}

function reduceArg(args: Args): string[] {
	if (Array.isArray(args)) {
		if (isConditionalArg(args)) {
			const [condition, trueValue, falseValue] = getCondition(args);
			if (condition) {
				// Need to typecast because bad tuple support in TypeScript.
				return reduceArg(trueValue);
			} else {
				return reduceArg(falseValue);
			}
		} else {
			return args.reduce<string[]>((current, next) => {
				return [...current, ...reduceArg(next)];
			}, []);
		}
	} else if (typeof args === 'string') {
		return [args];
	} else {
		return [];
	}
}

/**
 * className
 *
 * Allows combining classNames. Has the added feature of conditional support.
 *
 * Conditions must be in an array. First item is a boolean, rest is a class name.
 * You can supply a className.Else in the condition, anything after the Else
 * will be added if the condition was false.
 *
 * @example className('a','b') = 'a b'
 * @example className(['a','b']) = 'a b'
 * @example className([false,'a'],'b') = 'b'
 * @example className(['a',[false,'b']]) = 'a'
 * @example className(['a',[false,'b',className.Else,'c']]) = 'a c'
 * @example className('a',[false,'b','c',className.Else,'d','e',['f',[true,'g',className.Else,'h']]]) = 'a d e f g'
 */
export function className(...args: Args[]): string {
	return args
		.reduce<string[]>((current, next) => {
			return [...current, ...reduceArg(next)];
		}, [])
		.join(' ');
}

className.Else = Else;

type InferPromise<T> = T extends Promise<infer V> ? V : T;

type UseSafeCallbackRet<T extends (...args: any[]) => any> = (
	...args: Parameters<T>
) => Promise<InferPromise<ReturnType<T>>>;

/**
 * This allows you to call an async function inside a react component and ensure
 * that if you get results back, the component is still mounted. The promise
 * will never resolve if the component is unmounted.
 *
 * Example:
 * const safeCallback = useSafeCallback(someFunction);
 *
 * const callback = useCallback(async () => {
 *     const value = await safeCallback('some','argument');
 *     setState(value);
 * },[safeCallback]);
 */
export function useSafeCallback<T extends (...args: any[]) => Promise<any>>(
	fn: T,): UseSafeCallbackRet<T> {
	const isMounted = React.useRef(true);

	React.useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	return React.useCallback(
		(...args: Parameters<T>) => {
			return fn(...args).then(
				(value) => {
					if (isMounted.current) {
						return value;
					} else {
						/**
						 * I have been assured from an article that if a promise
						 * never resolves, the resources used by the promise are
						 * properly garbage collected.
						 *
						 * https://web.archive.org/web/20200824215516/https://www.dankuck.com/2017/02/24/broken-js-promises.html
						 */
						return new Promise(() => {
							void 0;
						});
					}
				},
				(error) => {
					if (isMounted.current) {
						return Promise.reject(error);
					} else {
						return new Promise(() => {
							void 0;
						});
					}
				},
			);
		},
		[fn],
	);
}

/**
 * This allows you to call a function when the component unmounts. The reason you'd do this
 * as opposed to using useEffect is because you have dependencies that update.
 */
export function useOnUnmount(effect: VoidFunction, deps: DependencyList): void {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const cb = useCallback(effect, deps);
	const ref = useRef<VoidFunction>(cb);
	ref.current = cb;

	useEffect(() => {
		return () => {
			ref.current();
		};
	}, []);
}

/**
 * This allows you to call a function when the component mounts. The reason you'd do this
 * as opposed to using useEffect is because you have dependencies that update. There are
 * no dependencies list because your function will only be called once.
 */
export function useOnMount(effect: VoidFunction): void {
	useEffect(() => {
		effect();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

/**
 * This hook will debounce the effect by a given wait time
 *
 * This will call the proper callback during a debounce even if the callback
 * has been updated.
 *
 * @param effect The callback to debounce
 * @param deps Any dependencies of the callback
 * @param wait How long to wait to debounce
 */
export function useDebounce<T extends (...args: any[]) => any>(
	effect: T,
	deps: DependencyList,
	wait: number,): _.DebouncedFunc<T> {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const callback = useCallback(effect, deps);

	type Ref = {
		callback: T;
		wait: null | number;
		debounce: null | _.DebouncedFunc<T>;
		proxyFunction: null | _.DebouncedFunc<T>;
	};

	const ref = useRef<Ref>({
		callback,
		debounce: null,
		proxyFunction: null,
		wait: null,
	});

	const { current } = ref;

	current.callback = callback;

	if (current.wait !== wait && current.debounce != null) {
		current.debounce.cancel();
		current.debounce = null;
	}

	current.wait = wait;

	if (current.debounce == null) {
		current.debounce = _.debounce(
			((...args) => ref.current.callback(...args)) as T,
			current.wait,
		);
	}

	if (current.proxyFunction == null) {
		current.proxyFunction = (() => {
			const proxyFn = (...args: Parameters<T>) =>
				ref.current.debounce?.(...args);
			proxyFn.cancel = () => ref.current.debounce?.cancel();
			proxyFn.flush = () => ref.current.debounce?.flush();

			return proxyFn;
		})();
	}

	useOnUnmount(() => {
		ref.current.debounce?.cancel();
	}, []);

	return current.proxyFunction;
}

/**
 * This is a lot like useEffect, except it allows you to watch for changes on
 * values that are independent of your effect callback.
 * @param config.onChange Function to execute on a change, gives you the
 *  nextValues and oldValues as parameter.
 * @param config.watch The value to watch. Most of the time you can treat this
 *  as the same as the dependency array to useEffect.
 * @param config.compare A custom comparator, allowing you to pass anything to
 *  `config.watch`.
 * @param config.fireImmediately Call onChange after setting up
 */
export function useWatchForChange<T extends _.List<unknown> | object>(config: {
	onChange: (nextValues: T, oldValues: T) => void;
	watch: T;
	compare?: (nextValues: T, oldValues: T) => boolean;
	fireImmediately?: boolean;
}): void {
	const ref = useRef<{
		oldValues: T;
	}>({
		oldValues: config.watch,
	});

	const oldValues = ref.current.oldValues;
	const hasChange = config.compare
		? config.compare(config.watch, oldValues)
		: _.some(config.watch, (value, key) => {
			return oldValues[key as keyof T] !== value;
		});
	if (hasChange) {
		const onChange = config.onChange;
		_.defer(() => onChange(config.watch, oldValues));
		ref.current.oldValues = config.watch;
	}

	useOnMount(() => {
		if (config.fireImmediately) {
			const onChange = config.onChange;
			_.defer(() => onChange(config.watch, config.watch));
		}
	});
}
