import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/secretShopper/actionTypes';
import { SecretShopper } from 'domains/secretShopper/models';

export interface State {
	secretShopperList: SecretShopper[],
}

export const initialState: State = {
	secretShopperList: []
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_SECRET_SHOPPERS_SUCCESS:
			return {
				...state,
				secretShopperList: action.result
			};

		default:
			return state;
	}
}
