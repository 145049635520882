import { SearchResponse, Pagination } from 'domains/core/models';
import { DurationInputArg2 } from 'moment';

export interface UserAudit {
	date: string;
	sourceSystem: string;
	userAuditType: string;
	company: string;
	affectedUser: string;
	performedBy: string;
	isSuccess: boolean;
	errorMessage?: string;
}

export interface UserAuditFilters {
	sourceSystems: GenericFilterOptionDto[];
	userAuditTypes: GenericFilterOptionDto[];
	companies: GenericFilterOptionDto[];
	filterValues?: UserAuditFilterValues;
}

export interface GenericFilterOptionDto {
	id: number;
	displayName: string;
}

export const UserAuditDateRange = {
	'Last Hour': 'hour',
	'Last Day': 'day',
	'Last Week': 'week',
	'Last Month': 'month',
	'Last 60 Days': -60,
	'Last 90 Days': -90,
}

export interface UserAuditFilterValues {
	logSystem?: LogSystem;
	dateRange?: DurationInputArg2;
	sourceSystem?: number;
	userAuditType?: number;
	company?: number;
}

export interface UserAuditSearchRequest extends Pagination {
	logSystem: LogSystem;
	dateRange: Date;
	company?: number;
	username?: string;
	userAuditType?: number;
	sourceSystem?: number;
}

export interface UserAuditResponseDto extends SearchResponse {
	userAudits: UserAudit[];
}

export enum LogSystem {
	Cea = 1,
	MetrixWeb = 2,
}

export enum SourceSystem {
	All = 0,
	CeaAdmin = 1,
	Cea,
	MetrixWeb,
	StaffingImport
}
