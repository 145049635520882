import { take, put, fork, all, actionChannel, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/companies/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import { errorToast } from 'domains/core/components/Toaster';
import { callApi } from 'utils/apiSaga';
import * as actions from 'domains/companies/actions';
import { Company, UpdateCompanyActiveStatusRequest } from 'domains/companies/models';
import { SearchRequest } from 'domains/core/models';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadCompanies(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANIES);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleLoadCompanies, request.payload);
	}
}

export function* watchForLoadCompaniesNoOverlay(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANIES_NO_OVERLAY);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleLoadCompaniesCall, request.payload);
	}
}

export function* handleLoadCompanies(request: SearchRequest): SagaIterator {
	yield put({ type: coreActionTypes.APP_LOADING });
	yield call(handleLoadCompaniesCall, request);
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* handleLoadCompaniesCall(request: SearchRequest): SagaIterator {
	try {
		const result = yield callApi(actions.loadCompanies(request));
		yield put({ type: actionTypes.LOAD_COMPANIES_SUCCESS, result });
	} catch (error) {
		// TODO: Add Toaster messaging for errors
		yield put({ type: actionTypes.LOAD_COMPANIES_FAIL, error });
	}
}

export function* watchForCreateCompany(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.CREATE_COMPANY);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleCreateCompany, request.payload);
	}
}

export function* handleCreateCompany(company: Company): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const response = yield callApi(actions.saveCompany(company));
		yield put({ type: actionTypes.CREATE_COMPANY_SUCCESS, result: response });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: coreActionTypes.REROUTE, payload: { routeTo: `/users/companies/${response.result}` } });
	} catch (error) {
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: actionTypes.CREATE_COMPANY_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the company');
	}
}

export function* watchForLoadCompany(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANY);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleLoadCompany, request.payload);
	}
}

export function* handleLoadCompany(companyId: number): SagaIterator {
	yield put({ type: coreActionTypes.APP_LOADING });
	yield call(handleLoadCompanyCall, companyId);
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* handleLoadCompanyCall(companyId: number): SagaIterator {
	try {
		const result = yield callApi(actions.loadCompany(companyId));
		yield put({ type: actionTypes.LOAD_COMPANY_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_COMPANY_FAIL, error });
	}
}

export function* watchForLoadCompanyDetail(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_COMPANY_DETAIL);

	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadCompanyDetail, action.payload);
	}
}

export function* handleLoadCompanyDetail(companyId: number): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadCompanyDetail(companyId));
		yield put({ type: actionTypes.LOAD_COMPANY_DETAIL_SUCCESS, result });
	} catch (error) {
		// TODO: Add Toaster messaging for errors
		yield put({ type: actionTypes.LOAD_COMPANY_DETAIL_FAIL, error });
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForActivateCompany(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.ACTIVATE_COMPANY);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleActivateCompany, request.payload);
	}
}

export function* handleActivateCompany(request: UpdateCompanyActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.activateCompany(request));
		yield put({ type: actionTypes.ACTIVATE_COMPANY_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.ACTIVATE_COMPANY_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the company');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForDeactivateCompany(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.DEACTIVATE_COMPANY);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleDeactivateCompany, request.payload);
	}
}

export function* handleDeactivateCompany(request: UpdateCompanyActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.deactivateCompany(request));
		yield put({ type: actionTypes.DEACTIVATE_COMPANY_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_COMPANY_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the company');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export default function* watchForCompaniesSagas() {
	yield all([
		fork(watchForLoadCompanies),
		fork(watchForCreateCompany),
		fork(watchForLoadCompany),
		fork(watchForActivateCompany),
		fork(watchForDeactivateCompany),
		fork(watchForLoadCompanyDetail),
		fork(watchForLoadCompaniesNoOverlay),
	]);
}
