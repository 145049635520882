import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader } from 'components/Card';
import { CustomTabs } from 'components/CustomTabs';
import { Button } from 'components/CustomButtons';
import { useHistory, useParams } from 'react-router-dom';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { CompanyUsers, UserSearchForm, UserFilterForm } from 'domains/users/components';
import { getCompanyIdFromRouteOrUser } from 'domains/companies/selectors';
import { doLoadCompanyUsers, doExportUserList } from 'domains/users/actions';
import { doPaginationReset } from 'domains/core/actions';
import { UserFilter } from 'domains/core/models';
import { getCurrentUser } from 'domains/core/selectors';
import { UserActions } from 'domains/core/enums';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import SaveAltIcon from 'domains/core/components/SaveAltIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import { UsersSearchRequest } from 'domains/users/models';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { Form, FormRenderProps } from 'react-final-form';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	tabCardMargin: {
		marginTop: 0
	},
	customPagination: {
		float: 'right'
	} as any,
};

interface MatchParams {
	companyId: string;
	userPrincipalName: string;
}

export default function CompanyUserList() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const history = useHistory();

	const [filter, setFilter] = useState<UserFilter>({});
	const [active, setActive] = useState(true);
	const [sortOrderDesc, setSortOrderDesc] = useState(false);

	const params = useParams<MatchParams>();

	const companyId = useSelector(state => getCompanyIdFromRouteOrUser(state, { match: { params } }));
	const companyUserCount = useSelector(state => state.domains.users.companyUserListDetails.companyUserCount);
	const currentPage = useSelector(state => state.domains.core.pagination.currentPage);
	const recordsPerPage = useSelector(state => state.domains.core.pagination.recordsPerPage);
	const currentUser = useSelector(state => getCurrentUser(state));


	const loadCompanyUsers = useCallback(() => {
		const request: UsersSearchRequest = {
			companyId,
			filter,
			active: !!active,
			pageNumber: currentPage,
			pageSize: recordsPerPage,
			sortOrderDesc,
			includeRolesProgramsMarkets: true
		};
		dispatch(doLoadCompanyUsers(request));
	}, [active, companyId, currentPage, dispatch, filter, recordsPerPage, sortOrderDesc]);

	useEffect(() => {
		loadCompanyUsers();
	}, [loadCompanyUsers]);

	const changeSortOrder = useCallback(() => {
		dispatch(doPaginationReset());
		setSortOrderDesc(!sortOrderDesc);
	}, [dispatch, sortOrderDesc]);

	const filterUsers = useCallback((value: UserFilter) => {
		dispatch(doPaginationReset());
		setFilter(value);
	}, [dispatch]);

	const toggleActive = useCallback((value: boolean) => {
		dispatch(doPaginationReset());
		setActive(value);
	}, [dispatch]);

	const routeToUserCreation = useCallback(() => {
		history.push(`/users/companies/${companyId}/users/create`);
	}, [companyId, history]);

	const exportList = useCallback(() => {
		const request = {
			companyId,
			filter,
			active: !!active,
			pageNumber: 0,
			pageSize: 999,
			sortOrderDesc
		};
		dispatch(doExportUserList(request));
	}, [active, companyId, dispatch, filter, sortOrderDesc]);

	const getContextMenuItems = useMemo(() => {
		const canExport = !!currentUser?.canDoAction(UserActions.CanDeactivateCompanyAdmins);

		const options: JSX.Element[] = [];

		if (canExport) {
			options.push(
				<ContextMenuItem
					icon={<SaveAltIcon />}
					action={exportList}
					text="Export"
				/>
			);
		}

		return options;
	}, [currentUser, exportList]);

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Users</h2>
				<Form onSubmit={filterUsers}>
					{(formRenderProps: FormRenderProps<UserFilter>) => (

						<React.Fragment>
							<GridContainer spacing={0}>
								<GridItem>
									<Button color="primary" onClick={routeToUserCreation}>Add User</Button>
								</GridItem>
								<GridItem xs={3} sm={3} md={2} lg={2} xl={2}>
									<ContextMenu menuItems={getContextMenuItems} />
								</GridItem>
								<GridItem xs={12} sm={3} md={3} lg xl>
									<UserSearchForm {...formRenderProps} />
								</GridItem>
								<GridItem>
									<div className={classes.customPagination}>
										<PaginationWrapper recordCount={companyUserCount!} />
									</div>
								</GridItem>
							</GridContainer>
							<UserFilterForm  {...formRenderProps} />
						</React.Fragment>
					)}
				</Form>
			</CardHeader>
			<CustomTabs
				plainTabs
				headerColor="info"
				tabs={
					[
						{
							tabName: 'ACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<CompanyUsers
											active
											companyId={companyId}
											setActive={toggleActive}
											changeSortOrder={changeSortOrder}
											sortOrderDesc={sortOrderDesc}
										/>
									</GridItem>
								</GridContainer>
							)
						},
						{
							tabName: 'INACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<CompanyUsers
											active={false}
											companyId={companyId}
											setActive={toggleActive}
											changeSortOrder={changeSortOrder}
											sortOrderDesc={sortOrderDesc}
										/>
									</GridItem>
								</GridContainer>
							)
						}
					]
				}
			/>
			<GridItem xs={12}>
				<div className={classes.customPagination}>
					<PaginationWrapper recordCount={companyUserCount!} />
				</div>
			</GridItem>
		</Card>
	);
}
