import React, { useCallback, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import { dangerColor, primaryColor } from 'assets/jss/material-dashboard-pro-react';
import { Theme } from '@material-ui/core/styles';
import FormSelectAdapter from 'domains/core/components/FinalForm/FormSelectAdapter';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import { GridContainer, GridItem } from 'components/Grid';
import { doLoadDiscountFilters } from 'domains/discounts/actions';
import { getDiscountFilterOptions, getDiscountMarkets } from 'domains/discounts/selectors';
import { DiscountFilterValues } from 'domains/core/models';
import { Field, FormRenderProps } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { useWatchForChange } from 'utils/React';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' },
		applyFilterButton: {
			position: 'relative' as any,
			top: 14,
			'& button': {
				maxWidth: 140,
				color: primaryColor
			}
		},
		clearFilterButton: {
			position: 'relative' as any,
			top: 14,
			'& span': {
				color: 'black' as any
			},
			'& svg': {
				color: dangerColor
			},
			'& button': {
				maxWidth: 140,
			}
		},
		padBottomLess: {
			paddingBottom: '12px'
		}
	};
};

export default function DiscountFilterForm(props: FormRenderProps<DiscountFilterValues>) {
	const { values, handleSubmit } = props;
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const discountFilterOptions = useSelector(state => getDiscountFilterOptions(state));
	const discountMarkets = useSelector(state => getDiscountMarkets(state));

	useEffect(() => {
		dispatch(doLoadDiscountFilters());
	}, [dispatch]);

	useWatchForChange({
		watch: values,
		onChange: (nextValues, oldValues) => {
			if (Object.keys(nextValues).some(key => key !== 'discountName' && key in oldValues && nextValues[key as keyof DiscountFilterValues] !== oldValues[key as keyof DiscountFilterValues])) {
				handleSubmit();
			}
		},
	});

	const getMenuItems = useCallback((items: { id: number, displayName: string }[]) => {
		const sorted = orderBy(items, "displayName", "asc");
		return [
			{ value: 0, text: 'All ' },
			...sorted.map(value => ({ value: value.id, text: value.displayName }))
		];
	}, []);

	const getMarketMenuItems = useCallback((items: any[]) => {
		if (values?.program && values?.program > 0) {
			const programMarkets = discountMarkets.filter(x => x.programId === values.program);
			const sorted = orderBy(programMarkets, "shortName", "asc");
			return [
				{ value: 0, text: 'All' },
				...sorted.map(value => ({ value: value.marketId, text: value.shortName }))
			];
		}
		return getMenuItems(items);
	}, [discountMarkets, getMenuItems, values?.program]);

	return (
		<form onSubmit={handleSubmit} className={classes.padBottomLess}>
			<GridContainer>
				<GridItem xs md lg>
					<Field name="program">
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Program"
								id="program-filter"
								options={getMenuItems(discountFilterOptions.programs)}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem xs md lg>
					<Field name="discountType">
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}
								labelText="Type"
								id="discountType-filter"
								options={getMenuItems(discountFilterOptions.discountTypes)}
							/>
						)}
					</Field>
				</GridItem>
				<GridItem xs md lg>
					<Field name="market">
						{(fieldRenderProps) => (
							<FormSelectAdapter
								{...fieldRenderProps}

								labelText="Market"
								id="market-filter"
								options={getMarketMenuItems(discountFilterOptions.markets)}
							/>
						)}
					</Field>
				</GridItem>
			</GridContainer>
		</form>
	);
}
