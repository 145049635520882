import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';
import { Button } from 'components/CustomButtons';
import { RouteComponentProps }from 'react-router-dom';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import Muted from 'components/Typography/Muted';
import { doLoadExecutionTagDetails, doActivateExecutionTag, doDeactivateExecutionTag } from 'domains/executionTags/actions';
import { getExecutionTagDetails } from 'domains/executionTags/selectors';
import { doPaginationReset } from 'domains/core/actions';
import { UpdateExecutionTagActiveStatusRequest } from 'domains/executionTags/models';
import ContextMenuItem from 'domains/core/components/ContextMenuItem';
import CancelIcon from 'domains/core/components/CancelIcon';
import AddIcon from 'domains/core/components/AddIcon';
import ContextMenu from 'domains/core/components/ContextMenu';
import Modal from 'domains/core/components/Modal';

const useStyles = makeStyles({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	noTopMargin: {
		marginTop: '0px'
	}
});

interface Params {
	id: string;
}

const ExecutionTagDetails: React.FC<RouteComponentProps<Params>> = (props) => {
	const executionTagId = props.match.params.id;
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const executionTag = useSelector(state => getExecutionTagDetails(state, Number(executionTagId)));
	const [modalOpen, setModalOpen] = useState(false);
	
	useEffect(() => {
		dispatch(doPaginationReset());
		dispatch(doLoadExecutionTagDetails(executionTagId));
	}, [dispatch, executionTagId])

	const openModal = () => {
		setModalOpen(true);
	}

	const closeModal = () => {
		setModalOpen(false);
	}

	const contextMenuItems: JSX.Element[] = [];

	if (executionTag) {
		contextMenuItems.push(
			<ContextMenuItem
				icon={executionTag.isActive ? <CancelIcon /> : <AddIcon />}
				action={openModal}
				text={executionTag.isActive ? 'Deactivate' : 'Activate'}
			/>
		);
	}

	const handleDeactivateExecution = () => {
		if (executionTag) {
			const request: UpdateExecutionTagActiveStatusRequest = {
				executionTagId: executionTag.id,
				rowVersion: executionTag.rowVersion
			};
			dispatch(doDeactivateExecutionTag(request));
		}
		closeModal();
	}

	const handleActivateExecution = () => {
		if (executionTag) {
			const request: UpdateExecutionTagActiveStatusRequest = {
				executionTagId: executionTag.id,
				rowVersion: executionTag.rowVersion
			};
			dispatch(doActivateExecutionTag(request));
		}
		closeModal();
	}

	const modalTitle = `${executionTag?.isActive ? 'Deactivate' : 'Activate'} execution tag?`;
	const content = `Execution tag will be set to ${executionTag?.isActive ? 'inactive' : 'active'}.`;
	const actionHandler = executionTag?.isActive ? handleDeactivateExecution : handleActivateExecution;
	const actionButtonText = executionTag?.isActive ? 'Deactivate' : 'Activate';

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Special Execution Tag Details</h2>
				<GridContainer spacing={0}>
					<GridItem>
						<Button color="primary" onClick={() => history.push(`/consumerInteractions/executionTags/${executionTagId}/edit`)}>Edit Tag</Button>
					</GridItem>
					<GridItem xs={2}>
						<ContextMenu menuItems={contextMenuItems} />
					</GridItem>
				</GridContainer>
			</CardHeader>
			{executionTag && <CardBody>
				<GridContainer>
					<GridItem xs={12}>
						<Muted>
							<small>Internal Name</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.name}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Display Name</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.displayName || ''}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Description</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.description || ''}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Program</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.programName}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Market(s)</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.marketNames || 'All'}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Location Type(s)</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.retailLocationTypeNames || 'All'}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Physical Location Subtype(s)</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.physicalLocationSubTypeNames || 'All'}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>MRU Subtype(s)</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.mruSubTypeNames || 'All'}</p>
					</GridItem>
					<GridItem xs={12}>
						<Muted>
							<small>Location(s)</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.retailLocationNames || 'All'}</p>
					</GridItem>
					<GridItem xs={6}>
						<Muted>
							<small>Start Date</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.startDate ? moment(executionTag.startDate).format('L') : '--'}</p>
					</GridItem>
					<GridItem xs={6}>
						<Muted>
							<small>End Date</small>
						</Muted>
						<p className={classes.noTopMargin}>{executionTag.endDate ? moment(executionTag.endDate).format('L') : "--"}</p>
					</GridItem>
				</GridContainer>
			</CardBody>
			}
			{modalOpen && 
				<Modal
					modalOpen={modalOpen}
					title={modalTitle}
					content={content}
					closeButtonText="Cancel"
					handleClose={closeModal}
					actionButtonText={actionButtonText}
					handleAction={actionHandler}
				/>
			}
		</Card>
	);
}

export default ExecutionTagDetails;
