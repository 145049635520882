export const LOAD_DISCOUNTS = 'pricing/LOAD_DISCOUNTS';
export const LOAD_DISCOUNTS_SUCCESS = 'pricing/LOAD_DISCOUNTS_SUCCESS';
export const LOAD_DISCOUNTS_FAIL = 'pricing/LOAD_DISCOUNTS_FAIL';

export const LOAD_DISCOUNT_FILTERS= 'pricing/LOAD_DISCOUNT_FILTERS';
export const LOAD_DISCOUNT_FILTERS_SUCCESS = 'pricing/LOAD_DISCOUNT_FILTERS_SUCCESS';
export const LOAD_DISCOUNT_FILTERS_FAIL = 'pricing/LOAD_DISCOUNT_FILTERS_FAIL';

export const EXPORT_DISCOUNT_LIST = 'pricing/EXPORT_DISCOUNT_LIST';
export const EXPORT_DISCOUNT_LIST_SUCCESS = 'pricing/EXPORT_DISCOUNT_LIST_SUCCESS';
export const EXPORT_DISCOUNT_LIST_FAIL = 'pricing/EXPORT_DISCOUNT_LIST_FAIL';

export const LOAD_DISCOUNT_AND_DEPENDENCIES = 'pricing/LOAD_DISCOUNT_AND_DEPENDENCIES';
export const LOAD_DISCOUNT_AND_DEPENDENCIES_SUCCESS = 'pricing/LOAD_DISCOUNT_AND_DEPENDENCIES_SUCCESS';
export const LOAD_DISCOUNT_AND_DEPENDENCIES_FAIL = 'pricing/LOAD_DISCOUNT_AND_DEPENDENCIES_FAIL';

export const SAVE_DISCOUNT = 'pricing/SAVE_DISCOUNT';
export const SAVE_DISCOUNT_SUCCESS = 'pricing/SAVE_DISCOUNT_SUCCESS';
export const SAVE_DISCOUNT_FAIL = 'pricing/SAVE_DISCOUNT_FAIL';

export const LOAD_CEA_PROGRAMS = 'pricing/LOAD_CEA_PROGRAMS';
export const LOAD_CEA_PROGRAMS_SUCCESS = 'pricing/LOAD_CEA_PROGRAMS_SUCCESS';
export const LOAD_CEA_PROGRAMS_FAIL = 'pricing/LOAD_CEA_PROGRAMS_FAIL';

export const LOAD_DISCOUNT_TYPES = 'pricing/LOAD_DISCOUNT_TYPES';
export const LOAD_DISCOUNT_TYPES_SUCCESS = 'pricing/LOAD_DISCOUNT_TYPES_SUCCESS';
export const LOAD_DISCOUNT_TYPES_FAIL = 'pricing/LOAD_DISCOUNT_TYPES_FAIL';

export const ACTIVATE_DISCOUNT = 'pricing/ACTIVATE_DISCOUNT';
export const ACTIVATE_DISCOUNT_SUCCESS = 'pricing/ACTIVATE_DISCOUNT_SUCCESS';
export const ACTIVATE_DISCOUNT_FAIL = 'pricing/ACTIVATE_DISCOUNT_FAIL';

export const DEACTIVATE_DISCOUNT = 'pricing/DEACTIVATE_DISCOUNT';
export const DEACTIVATE_DISCOUNT_SUCCESS = 'pricing/DEACTIVATE_DISCOUNT_SUCCESS';
export const DEACTIVATE_DISCOUNT_FAIL = 'pricing/DEACTIVATE_DISCOUNT_FAIL';

export const LOAD_ITEM_RETAILS = 'pricing/LOAD_ITEM_RETAILS';
export const LOAD_ITEM_RETAILS_SUCCESS = 'pricing/LOAD_ITEM_RETAILS_SUCCESS';
export const LOAD_ITEM_RETAILS_FAIL = 'pricing/LOAD_ITEM_RETAILS_FAIL';

export const LOAD_ITEM_RETAIL_TYPES = 'pricing/LOAD_ITEM_RETAIL_TYPES';
export const LOAD_ITEM_RETAIL_TYPES_SUCCESS = 'pricing/LOAD_ITEM_RETAIL_TYPES_SUCCESS';
export const LOAD_ITEM_RETAIL_TYPES_FAIL = 'pricing/LOAD_ITEM_RETAIL_TYPES_FAIL';

export const LOAD_ITEM_RETAIL_CATEGORIES = 'pricing/LOAD_ITEM_RETAIL_CATEGORIES';
export const LOAD_ITEM_RETAIL_CATEGORIES_SUCCESS = 'pricing/LOAD_ITEM_RETAIL_CATEGORIES_SUCCESS';
export const LOAD_ITEM_RETAIL_CATEGORIES_FAIL = 'pricing/LOAD_ITEM_RETAIL_CATEGORIES_FAIL';

export const LOAD_ITEM_RETAIL_UNIT_OF_MEASURE = 'pricing/LOAD_ITEM_RETAIL_UNIT_OF_MEASURE';
export const LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_SUCCESS = 'pricing/LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_SUCCESS';
export const LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_FAIL = 'pricing/LOAD_ITEM_RETAIL_UNIT_OF_MEASURE_FAIL';

export const LOAD_DISCOUNT_TIME_FRAME_TYPES = 'pricing/LOAD_DISCOUNT_TIME_FRAME_TYPES';
export const LOAD_DISCOUNT_TIME_FRAME_TYPES_SUCCESS = 'pricing/LOAD_DISCOUNT_TIME_FRAME_TYPES_SUCCESS';
export const LOAD_DISCOUNT_TIME_FRAME_TYPES_FAIL = 'pricing/LOAD_DISCOUNT_TIME_FRAME_TYPES_FAIL';

export const LOAD_DISCOUNT_MARKETS = 'pricing/LOAD_DISCOUNT_MARKETS';
export const LOAD_DISCOUNT_MARKETS_SUCCESS = 'pricing/LOAD_DISCOUNT_MARKETS_SUCCESS';
export const LOAD_DISCOUNT_MARKETS_FAIL = 'pricing/LOAD_DISCOUNT_MARKETS_FAIL';

export const LOAD_DISCOUNT_RETAIL_LOCATIONS = 'pricing/DISCOUNT_RETAIL_LOCATIONS';
export const LOAD_DISCOUNT_RETAIL_LOCATIONS_SUCCESS = 'pricing/DISCOUNT_RETAIL_LOCATIONS_SUCCESS';
export const LOAD_DISCOUNT_RETAIL_LOCATIONS_FAIL = 'pricing/DISCOUNT_RETAIL_LOCATIONS_FAIL';

export const LOAD_RETAIL_LOCATION_TYPES = 'pricing/RETAIL_LOCATION_TYPES';
export const LOAD_RETAIL_LOCATION_TYPES_SUCCESS = 'pricing/RETAIL_LOCATION_TYPES_SUCCESS';
export const LOAD_RETAIL_LOCATION_TYPES_FAIL = 'pricing/RETAIL_LOCATION_TYPES_FAIL';

export const LOAD_RETAIL_LOCATION_LOCATION_TYPES = 'pricing/RETAIL_LOCATION_LOCATION_TYPES';
export const LOAD_RETAIL_LOCATION_LOCATION_TYPES_SUCCESS = 'pricing/RETAIL_LOCATION_LOCATION_TYPES_SUCCESS';
export const LOAD_RETAIL_LOCATION_LOCATION_TYPES_FAIL = 'pricing/RETAIL_LOCATION_LOCATION_TYPES_FAIL';

export const LOAD_RETAIL_LOCATION_MRU_TYPES = 'pricing/RETAIL_LOCATION_MRU_TYPES';
export const LOAD_RETAIL_LOCATION_MRU_TYPES_SUCCESS = 'pricing/RETAIL_LOCATION_MRU_TYPES_SUCCESS';
export const LOAD_RETAIL_LOCATION_MRU_TYPES_FAIL = 'pricing/RETAIL_LOCATION_MRU_TYPES_FAIL';
