import { createSelector } from 'reselect';
import { keys, invert } from 'lodash';
import { denormalize } from 'normalizr';
import { RouteComponentProps } from 'react-router-dom';
import { EntityState } from 'utils/entityReducer';
import { ApplicationState } from 'applicationTypes';
import { roleSchema, userActionSchema } from 'domains/roles/schemas';
import { Role, UserAction } from 'domains/roles/models';
import { SourceSystem, SourceSystemDictionary } from 'domains/core/models';
import { RoleRouteMatchProps } from 'domains/roles/models';

const userRoleEntities = (state: ApplicationState) => state.entities.roles;
const userActionEntities = (state: ApplicationState) => state.entities.userActions;

export const getRoleSystemAndIdFromRoute = (_state: ApplicationState, props: RouteComponentProps<RoleRouteMatchProps>): RoleRouteMatchProps => ({
	system: props?.match?.params?.system,
	id: props?.match?.params?.id
});

export const getCeaRoles = createSelector(
	[userRoleEntities, userActionEntities],
	(userRoleEntities: Partial<EntityState>, userActionEntities: Partial<EntityState>): Role[] => {

		const entities = { roles: userRoleEntities, userActions: userActionEntities };
		const entityKeys = { roles: keys(userRoleEntities) };
		const mySchema = { roles: [roleSchema] };
		return denormalize(entityKeys, mySchema, entities).roles;
	}
);

export const getCeaActions = createSelector(
	[userActionEntities],
	(userActionEntities: Partial<EntityState>): UserAction[] => {
		const entities = { userActions: userActionEntities };
		const entityKeys = { userActions: keys(userActionEntities) };
		const mySchema = { userActions: [userActionSchema] };
		return denormalize(entityKeys, mySchema, entities).userActions;
	}
);

export const getRoleFromRoute = createSelector(
	[getCeaRoles, getRoleSystemAndIdFromRoute],
	(roles: Role[], match: RoleRouteMatchProps) => {
		if (match && match.id && match.system) {
			const system: SourceSystem = parseInt((invert(SourceSystemDictionary))[match.system], 10);
			const id: number = parseInt(match.id, 10);
			return roles.filter(role => role.system === system && role.roleId === id)[0];
		}
	}
);

export const getUserActionsForSystem = createSelector(
	userActionEntities,
	(_: ApplicationState, system: SourceSystem | undefined) => system,
	(userActionEntities: Partial<EntityState>, system?: SourceSystem) => {
		if (!system) {
			return [];
		}
		const entities = { userActions: userActionEntities };
		const entityKeys = { userActions: keys(userActionEntities) };
		const mySchema = { userActions: [userActionSchema] };
		const userActions: UserAction[] = denormalize(entityKeys, mySchema, entities).userActions;
		return userActions.filter((action: UserAction) => action.systems.indexOf(system) > -1);
	}
)
