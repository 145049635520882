import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'styles';
import { GridContainer, GridItem } from 'components/Grid';
import { Card, CardHeader, CardBody } from 'components/Card';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { doSaveUser, doLoadUser } from 'domains/users/actions';
import { getUserFromRoute } from 'domains/users/selectors';
import { CreateEditUserForm } from 'domains/users/components';
import { getCompanyFromRouteOrUser } from 'domains/companies/selectors';
import { Form } from 'react-final-form';
import { CreateEditUserFormValues } from 'domains/users/models';

interface MatchProps {
	userPrincipalName: string;
	companyId: string;
}

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	cardBody: {
		paddingTop: 0
	}
};

export default function EditUser() {
	const dispatch = useDispatch();
	const classes = useStyles(style);
	const { userPrincipalName, companyId } = useParams<MatchProps>();

	const company = useSelector(state => getCompanyFromRouteOrUser(state, { match: { params: { companyId } } }));
	const user = useSelector(state => getUserFromRoute(state, { match: { params: { userPrincipalName } } }));

	useEffect(() => {
		if (userPrincipalName) {
			dispatch(doLoadUser(userPrincipalName));
		}
	}, [dispatch, userPrincipalName]);

	const initialValues = useMemo(() => {
		if (user) {
			return user;
		}
	}, [user]);

	const handleSaveUser = useCallback((data: CreateEditUserFormValues) => {
		if (user) {
			const updatedUser = data;
			updatedUser.companyId = Number(companyId);

			if (user.ceaUser) {
				updatedUser.ceaUser = {
					...user.ceaUser,
					active: true,
					companyId: Number(companyId),
					firstName: data.azureAdUser?.givenName ?? null,
					lastName: data.azureAdUser?.surname ?? null,
					email: data.azureAdUser?.otherMails?.length ? data.azureAdUser.otherMails[0] : null,
					mobilePhone: data.azureAdUser!.mobilePhone
				};
			}

			if (user.metrixUser) {
				updatedUser.metrixUser = {
					...user.metrixUser,
					isActive: true,
					firstName: data.azureAdUser?.givenName ?? null,
					lastName: data.azureAdUser?.surname ?? null,
					gmrEmployeeId: data.azureAdUser?.employeeId,
					email: data.azureAdUser?.otherMails?.length ? data.azureAdUser.otherMails[0] : null,
					mobilePhone: data.azureAdUser!.mobilePhone,
					homeAddress: data.metrixUser?.homeAddress,
				};
			}
			dispatch(doSaveUser(updatedUser));
		}
	}, [companyId, user, dispatch]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader>
						<h2 className={classes.cardIconTitle}>Edit User</h2>
					</CardHeader>
					<CardBody className={classes.cardBody}>
						<Form initialValues={initialValues} onSubmit={handleSaveUser}>
							{(formRenderProps) => (
								<form onSubmit={formRenderProps.handleSubmit}>
									<CreateEditUserForm {...formRenderProps} company={company} />
								</form>
							)}
						</Form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
