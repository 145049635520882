import { fork, all, put, actionChannel, take, call } from 'redux-saga/effects';
import * as actionTypes from 'domains/executionTags/actionTypes';
import * as coreActionTypes from 'domains/core/actionTypes';
import * as actions from 'domains/executionTags/actions';
import history from 'adminHistory';
import { callApi } from 'utils/apiSaga';
import { errorToast } from 'domains/core/components/Toaster';
import { UpdateExecutionTagActiveStatusRequest, ExecutionTagSaveRequest } from 'domains/executionTags/models';
import { SagaIterator } from 'redux-saga';

export function* watchForLoadExecutionTags(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_EXECUTION_TAGS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadExecutionTags);
	}
}

export function* handleLoadExecutionTags(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadExecutionTags());
		yield put({ type: actionTypes.LOAD_EXECUTION_TAGS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_EXECUTION_TAGS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error loading special execution tags.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForLoadExecutionTagDetails(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_EXECUTION_TAG_DETAILS);
	while (true) {
		const action = yield take(requestChan);
		yield call(handleLoadExecutionTagDetails, action.payload);
	}
}

export function* handleLoadExecutionTagDetails(executionTagId: string): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadExecutionTagDetails(executionTagId));
		yield put({ type: actionTypes.LOAD_EXECUTION_TAG_DETAILS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_EXECUTION_TAG_DETAILS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error loading special execution tag details.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForActivateExecutionTag(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.ACTIVATE_EXECUTION_TAG);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleActivateExecutionTag, request.payload);
	}
}

export function* handleActivateExecutionTag(request: UpdateExecutionTagActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.activateExecutionTag(request));
		yield put({ type: actionTypes.ACTIVATE_EXECUTION_TAG_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.ACTIVATE_EXECUTION_TAG_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error activating the execution tag.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForDeactivateExecutionTag(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.DEACTIVATE_EXECUTION_TAG);

	while (true) {
		const request = yield take(requestChan);
		yield call(handleDeactivateExecutionTag, request.payload);
	}
}

export function* handleDeactivateExecutionTag(request: UpdateExecutionTagActiveStatusRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.deactivateExecutionTag(request));
		yield put({ type: actionTypes.DEACTIVATE_EXECUTION_TAG_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_EXECUTION_TAG_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error deactivating the execution tag.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}

export function* watchForLoadFormOptions(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.LOAD_EXECUTION_TAG_FORM_OPTIONS);
	while (true) {
		yield take(requestChan);
		yield call(handleLoadFormOptions);
	}
}

export function* handleLoadFormOptions(): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.loadFormOptions());
		yield put({ type: actionTypes.LOAD_EXECUTION_TAG_FORM_OPTIONS_SUCCESS, result });
	} catch (error) {
		yield put({ type: actionTypes.LOAD_EXECUTION_TAG_FORM_OPTIONS_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error loading the form options.');
	}
	yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
}


export function* watchForSaveExecutionTag(): SagaIterator {
	const requestChan = yield actionChannel(actionTypes.SAVE_EXECUTION_TAG);
	while (true) {
		const action = yield take(requestChan);
		yield call(handleSaveExecutionTag, action.payload);
	}
}

export function* handleSaveExecutionTag(request: ExecutionTagSaveRequest): SagaIterator {
	try {
		yield put({ type: coreActionTypes.APP_LOADING });
		const result = yield callApi(actions.saveExecutionTag(request));
		yield put({ type: actionTypes.SAVE_EXECUTION_TAG_SUCCESS, result });
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		history.push(`/consumerInteractions/executionTags/view/${result.result}`);
	} catch (error) {
		yield put({ type: coreActionTypes.APP_LOADING_COMPLETE });
		yield put({ type: actionTypes.SAVE_EXECUTION_TAG_FAIL, error });
		errorToast(error.status === 400 ? error.message : 'There was an error saving the execution tag.');
	}
}

export default function* watchForExecutionTagSagas() {
	yield all([
		fork(watchForLoadExecutionTags),
		fork(watchForLoadExecutionTagDetails),
		fork(watchForActivateExecutionTag),
		fork(watchForDeactivateExecutionTag),
		fork(watchForLoadFormOptions),
		fork(watchForSaveExecutionTag)
	]);
}
