import { schema } from 'normalizr';
import * as actionTypes from 'domains/discounts/actionTypes';
import { Action, ApiAction, ApiClient } from 'applicationTypes';
import * as discountsSchema from 'domains/discounts/schemas';
import { DiscountSearchRequest, UpdateDiscountActiveStatusRequest } from 'domains/discounts/models';
import { Discount } from 'domains/core/models';

export const doLoadDiscounts = (request: DiscountSearchRequest): Action => {
	return {
		resetEntity: 'discounts',
		type: actionTypes.LOAD_DISCOUNTS,
		payload: request
	};
};

export const loadDiscounts = (request: DiscountSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/Discount/Search', {
				schema: {
					discounts: new schema.Array(discountsSchema.discountsSchema)
				},
				data: request
			});
		}
	};
};

export const doLoadDiscountFilters = (): Action => {
	return {
		type: actionTypes.LOAD_DISCOUNT_FILTERS,
	};
};

export const loadDiscountFilters = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/discount/filters');
		}
	};
};

export const doExportDiscountList = (request: DiscountSearchRequest): Action => {
	return {
		type: actionTypes.EXPORT_DISCOUNT_LIST,
		payload: {
			request
		}
	};
};

export const exportDiscountList = (request: DiscountSearchRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/discount/export', {
				data: request
			});
		}
	};
}

export const doLoadDiscountAndDependencies = (discountId?: number): Action => {
	return {
		resetEntity: 'selectedDiscount',
		type: actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES,
		payload: {
			discountId: discountId
		}
	};
}; 

export const doSaveDiscount = (discount: Discount): Action => {
	return {
		type: actionTypes.SAVE_DISCOUNT,
		payload: discount
	};
};

export const saveDiscount = (discount: Discount): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/discount/save', {
				data: discount
			});
		}
	};
};

export const loadCeaPrograms = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/CeaPrograms', {
				schema: new schema.Array(discountsSchema.ceaProgramsSchema)
			});
		}
	};
};

export const doLoadDiscountTypes = (): Action => {
	return {
		type: actionTypes.LOAD_DISCOUNT_TYPES
	};
};

export const loadDiscountTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/DiscountTypes', {
				schema: new schema.Array(discountsSchema.discountTypesSchema)
			});
		}
	};
};

export const loadSelectedDiscount = (discountId: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/discount/discountdetail/${discountId}`, {
				schema: discountsSchema.selectedDiscountSchema,
			});
		}
	};
};

export const doActivateDiscount = (request: UpdateDiscountActiveStatusRequest): Action => {
	return {
		type: actionTypes.ACTIVATE_DISCOUNT,
		payload: request
	};
};

export const activateDiscount = (request: UpdateDiscountActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/discount/activate', {
				schema: discountsSchema.selectedDiscountSchema,
				data: request
			});
		}
	};
};

export const doDeactivateDiscount = (request: UpdateDiscountActiveStatusRequest): Action => {
	return {
		type: actionTypes.DEACTIVATE_DISCOUNT,
		payload: request
	};
};

export const deactivateDiscount = (request: UpdateDiscountActiveStatusRequest): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.post('/admin/discount/deactivate', {
				schema: discountsSchema.selectedDiscountSchema,
				data: request
			});
		}
	};
};

export const doLoadItemRetails = (programId: number): Action => {
	return {
		resetEntity: 'itemRetails',
		type: actionTypes.LOAD_ITEM_RETAILS,
		payload: programId
	};
};

export const loadItemRetails = (programId: number): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get(`/admin/discount/ItemRetailsByProgram/${programId}`, {
				schema: new schema.Array(discountsSchema.itemRetailsSchema)
			});
		}
	};
};

export const doLoadItemRetailTypes = (): Action => {
	return {
		type: actionTypes.LOAD_ITEM_RETAIL_TYPES
	};
};

export const loadItemRetailTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/ItemRetailTypes', {
				schema: new schema.Array(discountsSchema.itemRetailTypesSchema)
			});
		}
	};
};

export const doLoadItemRetailCategories = (): Action => {
	return {
		type: actionTypes.LOAD_ITEM_RETAIL_CATEGORIES
	};
};

export const loadItemRetailCategories = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/ItemRetailCategories', {
				schema: new schema.Array(discountsSchema.itemRetailCategoriesSchema)
			});
		}
	};
};

export const doLoadDiscountTimeFrameTypes = (): Action => {
	return {
		type: actionTypes.LOAD_DISCOUNT_TIME_FRAME_TYPES
	};
};

export const loadDiscountTimeFrameTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/DiscountTimeFrameTypes', {
				schema: new schema.Array(discountsSchema.discountTimeFrameTypesSchema)
			});
		}
	};
};

export const doLoadItemRetailUnitOfMeasure = (): Action => {
	return {
		type: actionTypes.LOAD_ITEM_RETAIL_UNIT_OF_MEASURE
	};
};

export const loadItemRetailUnitOfMeasure = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/ItemRetailUnitOfMeasure', {
				schema: new schema.Array(discountsSchema.itemRetailUnitOfMeasureSchema)
			});
		}
	};
};

export const doLoadDiscountMarkets = (): Action => {
	return {
		type: actionTypes.LOAD_DISCOUNT_MARKETS
	};
};

export const loadDiscountMarkets = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/DiscountMarkets', {
				schema: new schema.Array(discountsSchema.discountMarketsSchema)
			});
		}
	};
};

export const doLoadRetailLocations = (): Action => {
	return {
		type: actionTypes.LOAD_DISCOUNT_RETAIL_LOCATIONS
	};
};

export const loadRetailLocations = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/RetailLocations', {
				schema: new schema.Array(discountsSchema.discountRetailLocationsSchema)
			});
		}
	};
};

export const doLoadRetailLocationTypes = (): Action => {
	return {
		type: actionTypes.LOAD_RETAIL_LOCATION_TYPES
	};
};

export const loadRetailLocationTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/GetAllRetailLocationTypes', {
				schema: new schema.Array(discountsSchema.retailLocationTypesSchema)
			});
		}
	};
};

export const doLoadRetailLocationLocationTypes = (): Action => {
	return {
		type: actionTypes.LOAD_RETAIL_LOCATION_LOCATION_TYPES
	};
};

export const loadRetailLocationLocationTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/GetAllRetailLocationLocationTypes', {
				schema: new schema.Array(discountsSchema.retailLocationLocationTypesSchema)
			});
		}
	};
};

export const doLoadRetailLocationMruTypes = (): Action => {
	return {
		type: actionTypes.LOAD_RETAIL_LOCATION_MRU_TYPES
	};
};

export const loadRetailLocationMruTypes = (): ApiAction => {
	return {
		promise: (client: ApiClient) => {
			return client.get('/admin/Discount/GetAllRetailLocationMruTypes', {
				schema: new schema.Array(discountsSchema.retailLocationMruTypesSchema)
			});
		}
	};
};
