import React, { useCallback, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import FormInput from 'domains/core/components/FormInput';
import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Search, Clear } from '@material-ui/icons';
import * as validators from 'utils/fieldValidators';
import { DiscountFilterValues } from 'domains/core/models';
import { Field, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useStyles } from 'styles';

const style = (theme: Theme) => {
	return {
		...headerLinksStyle(theme),
		noPadding: { paddingTop: '18px' }
	};
};



export default function DiscountSearchForm(props: FormRenderProps<DiscountFilterValues>) {
	const { handleSubmit, invalid, form: { change }, values, initialValues } = props;
	const classes = useStyles(style);

	const isLoadingDiscounts = useSelector(state => state.domains.discounts.isLoadingDiscounts);

	const resetForm = useCallback(() => {
		change('discountName', undefined);
	}, [change]);

	const hasChanged = useMemo(() => values.discountName !== initialValues?.discountName, [initialValues?.discountName, values?.discountName]);

	const buttonDisabled = useMemo(() => isLoadingDiscounts || invalid, [invalid, isLoadingDiscounts]);
	const fieldDisabled = useMemo(() => isLoadingDiscounts, [isLoadingDiscounts]);

	return (
		<form onSubmit={handleSubmit}>
			<Field
				className={classes.noPadding}
				name="discountName"
				id="filterInput"
				component={FormInput}
				formControlProps={{ style: { paddingTop: '14px' } }}
				validate={validators.minLength1}
				inputProps={{
					placeholder: 'Search',
					inputProps: {
						'aria-label': 'Search'
					},
					disabled: fieldDisabled,
					endAdornment:
						<InputAdornment position="end">
							{hasChanged ?
								<IconButton onClick={resetForm} disabled={buttonDisabled}>
									<Clear />
								</IconButton> :
								<IconButton type="submit" disabled={buttonDisabled}>
									<Search />
								</IconButton>
							}
						</InputAdornment>
				}}
			/>
		</form>
	);
}
