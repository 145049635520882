import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { GridContainer, GridItem } from 'components/Grid';
import { CompanyUserDetails, CompanyUserAccess } from 'domains/users/containers';
import { doLoadMarkets, doLoadPrograms, doLoadCompanyUserDetail, doLoadAllMetrixRoles } from 'domains/users/actions';
import { getCurrentUser } from 'domains/core/selectors';

interface MatchParams {
	companyId: string;
	userPrincipalName: string;
}

export default function CompanyUser() {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector(state => getCurrentUser(state));
	const companyId = useParams<MatchParams>()?.companyId ?? user?.companyId;
	const userPrincipalName = useParams<MatchParams>()?.userPrincipalName;

	useEffect(() => {
		if (userPrincipalName) {
			dispatch(doLoadCompanyUserDetail(Number(companyId), userPrincipalName));
			dispatch(doLoadAllMetrixRoles());
			dispatch(doLoadMarkets());
			dispatch(doLoadPrograms());
		} else {
			history.push('/');
		}
	}, [companyId, dispatch, history, userPrincipalName]);

	return (
		<GridContainer>
			<GridItem xs={6}>
				<CompanyUserDetails />
			</GridItem>
			<GridItem xs={6}>
				<CompanyUserAccess />
			</GridItem>
		</GridContainer>
	);
}
