import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { concat, orderBy, invert, uniq } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from 'components/Checkbox';
import { Table } from 'components/Table';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { Card, CardHeader, CardBody, CardFooter } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { UserAction, RoleActionSortColumn } from 'domains/roles/models';
import { doLoadRoles, doLoadUserActions, doUpdateRoleActions } from 'domains/roles/actions';
import { SourceSystem, SourceSystemDictionary } from 'domains/core/models';
import { LoadUserActionsRequest, UpdateRoleActionsRequest } from 'domains/roles/models';
import { sort } from 'domains/core/helpers';
import headerTableStyle from 'assets/jss/material-dashboard-pro-react/components/headerTableStyle';
import SearchTextField from 'domains/core/components/SearchTextField';
import SearchSelectField from 'domains/core/components/SearchSelectField';
import { useDispatch, useSelector } from 'react-redux';
import { getCeaRoles, getUserActionsForSystem } from 'domains/roles/selectors';
import { useStyles } from 'styles';

const style = {
	...headerTableStyle,
	rowButton: {
		justifyContent: 'left',
		color: 'black',
		cursor: 'pointer',
	},
	title: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	headerStyle: {
		cursor: 'pointer'
	},
	cardBody: {
		paddingTop: 0,
		paddingBottom: '24px'
	},
	firstColumnStyle: {
		width: '66px',
	},
	firstHeaderColumnStyle: {
		paddingLeft: '18px!important',
		width: '66px',
	},
};

interface Params {
	system: string;
	id: string;
}

interface SearchBarProps {
	categories?: string[];
	actionFilter?: string;
	changeActionFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
	categoryFilter: string;
	changeCategoryFilter: React.Dispatch<React.SetStateAction<string>>;
	onSave: () => void;
	showFilters?: boolean;
}

const SearchBar = (props: SearchBarProps) => {
	const { showFilters, categories, actionFilter, changeActionFilter, categoryFilter, changeCategoryFilter, onSave } = props;

	const history = useHistory();

	const clearSearchFilter = useCallback(() => {
		changeActionFilter(undefined);
		changeCategoryFilter('All');
	}, [changeActionFilter, changeCategoryFilter]);

	const routeBack = useCallback(() => {
		history.push(history.location.pathname.replace('/edit', ''));
	}, [history]);

	const hasCategories = useMemo(() => categories && categories.length ? true : false, [categories]);

	return (
		<GridContainer>
			<GridItem lg={8} md={12} sm={12}>
				<GridContainer alignItems="center">
					<GridItem xl={3} lg={4} md={4} sm={8}>
						<Button id="SaveButton" color="primary" onClick={onSave}>Save</Button>
						<Button id="CancelUpdateButton" simple color="info" onClick={routeBack}>Cancel</Button>
					</GridItem>
					{showFilters &&
						<>
							<GridItem xl={5} lg={4} md={4} sm={12}>
								<SearchTextField id="SearchTextField" filterValue={actionFilter} label={hasCategories ? ' ' : undefined} change={changeActionFilter} clearFilter={clearSearchFilter} />
							</GridItem>
							<GridItem xl={4} lg={4} md={4} sm={12}>
								{hasCategories && <SearchSelectField id="SearchForCategoryField" label="Category" value={categoryFilter} change={changeCategoryFilter} availableValues={concat('All', categories)} />}
							</GridItem>
						</>
					}
				</GridContainer>
			</GridItem>
		</GridContainer>
	);
}

export default function EditUserRoleActions(): ReactElement {
	const dispatch = useDispatch();
	const classes = useStyles(style);

	const roles = useSelector(state => getCeaRoles(state));

	const match = useParams<Params>();
	const system = useMemo(() => {
		return match?.system ? parseInt((invert(SourceSystemDictionary))[match.system], 10) : undefined;
	}, [match.system]);

	const role = useMemo(() => {
		if (match && roles && 'filter' in roles) {
			const system: SourceSystem = parseInt((invert(SourceSystemDictionary))[match.system], 10);
			const id: number = parseInt(match.id, 10);
			return roles?.filter(role => role.system === system && role.roleId === id)[0];
		}
	}, [match, roles]);

	const actions = useSelector(state => getUserActionsForSystem(state, system));

	const categories = actions?.filter(a => a.category) && uniq(actions?.filter(a => a.category).map(a => a.category || ''));
	const hasCategories = categories?.length;

	const [actionFilter, changeActionFilter] = useState<string>();
	const [categoryFilter, changeCategoryFilter] = useState<string>('All');
	const [sortColumn, changeSortColumn] = useState<RoleActionSortColumn | string>(hasCategories ? RoleActionSortColumn.Category : RoleActionSortColumn.AssignedAction);
	const [sortDirection, changeSortDirection] = useState('asc' as 'asc' | 'desc');
	const [selectedActions, changeSelectedActions] = useState(role?.roleActions.map(ra => ra.action) || []);

	useEffect(() => {
		if (!role) {
			dispatch(doLoadRoles());
		} else {
			changeSelectedActions(role.roleActions.map(ra => ra.action));
		}
	}, [role, roles, dispatch]);

	useEffect(() => {
		if (system) {
			const request: LoadUserActionsRequest = { system };
			dispatch(doLoadUserActions(request));
		}
	}, [dispatch, system])

	const sortByCategory = useCallback(() => {
		sort(RoleActionSortColumn.Category, sortColumn, sortDirection, changeSortColumn, changeSortDirection);
	}, [sortColumn, sortDirection]);

	const sortByAssignedAction = useCallback(() => {
		sort(RoleActionSortColumn.AssignedAction, sortColumn, sortDirection, changeSortColumn, changeSortDirection);
	}, [sortColumn, sortDirection]);

	const sortByChecked = useCallback(() => {
		sort('checked', sortColumn, sortDirection, changeSortColumn, changeSortDirection);
	}, [sortColumn, sortDirection]);

	const decorateSortHeader = useCallback((column: string) => {
		if (column !== sortColumn) {
			return undefined;
		}

		return sortDirection === 'desc' ? `sortDesc ${classes.sortDesc}` : `sortAsc ${classes.sortAsc}`;
	}, [classes.sortAsc, classes.sortDesc, sortColumn, sortDirection]);

	const filterActions = useCallback((action: UserAction) => {
		return (!actionFilter || actionFilter === '' || actionFilter.trim() === '' || action.name.toLowerCase().indexOf(actionFilter.toLowerCase()) > -1)
			&& (!categoryFilter || categoryFilter === 'All' || action.category?.toLowerCase() === categoryFilter.toLowerCase());
	}, [actionFilter, categoryFilter]);

	const filteredActions = useMemo(() => actions && actions.length ? actions.filter(filterActions) : [], [actions, filterActions]);

	const data = useMemo(() => orderBy(filteredActions, sortColumn === 'checked' ? a => selectedActions.some(sa => sa.actionId === a.actionId) : [sortColumn], [sortDirection]).map(action => {
		const addRemoveAction = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
			if (checked) {
				const currentActions = [...selectedActions];
				const mergedActions = concat(currentActions, [action]);
				changeSelectedActions(mergedActions);
			} else {
				const index = selectedActions.findIndex(a => a.actionId === action.actionId);
				if (index > -1) {
					const updatedActions = [...selectedActions];
					updatedActions.splice(index, 1);
					changeSelectedActions(updatedActions);
				}
			}
		}

		const checked = selectedActions.find(a => a.actionId === action.actionId) ? true : false;
		const row = [
			<span key={0}><Checkbox id={`select-${action.name}`} onChange={addRemoveAction} checked={checked} /></span>,
			<span key={2} className={classes.rowButton}>{action.name}</span>
		];
		if (hasCategories) {
			row.splice(1, 0, <span key={1} className={classes.rowButton}>{action.category || ''}</span>);
		}
		return row;
	}), [classes.rowButton, filteredActions, hasCategories, selectedActions, sortColumn, sortDirection]);

	const tableHeader = useMemo(() => {
		const header = [
			<h6 id="checkedHeader" onClick={sortByChecked} key={0} className={`${classes.headerStyle} ${decorateSortHeader('checked')}`}><CheckIcon /></h6>,
			<h6 id="assignedActionHeader" onClick={sortByAssignedAction} key={2} className={`${classes.headerStyle} ${decorateSortHeader(RoleActionSortColumn.AssignedAction)}`}>Action Name</h6>
		];
		if (hasCategories) {
			header.splice(1, 0, <h6 id="categoryHeader" onClick={sortByCategory} key={1} className={`${classes.headerStyle} ${decorateSortHeader(RoleActionSortColumn.Category)}`}>Category</h6>);
		}
		return header;
	}, [classes.headerStyle, decorateSortHeader, hasCategories, sortByAssignedAction, sortByCategory, sortByChecked]);

	const updateRoleActions = useCallback(() => {
		if (role && system) {
			const request: UpdateRoleActionsRequest = {
				system,
				roleId: role.roleId,
				selectedActions
			};
			dispatch(doUpdateRoleActions(request));
		}
	}, [dispatch, role, selectedActions, system]);

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.title}>Edit Role Actions</h2>
				<SearchBar
					showFilters
					categories={categories}
					onSave={updateRoleActions}
					actionFilter={actionFilter}
					categoryFilter={categoryFilter}
					changeActionFilter={changeActionFilter}
					changeCategoryFilter={changeCategoryFilter}
				/>
			</CardHeader>
			<CardBody className={classes.cardBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Table
							striped
							tableData={data}
							tableHead={tableHeader}
							customCellClasses={[classes.firstColumnStyle]}
							customClassesForCells={[0]}
							customHeadCellClasses={[classes.firstHeaderColumnStyle]}
							customHeadClassesForCells={[0]}
						/>
					</GridItem>
				</GridContainer>
			</CardBody>
			<CardFooter>
				<SearchBar
					categories={categories}
					onSave={updateRoleActions}
					actionFilter={actionFilter}
					categoryFilter={categoryFilter}
					changeActionFilter={changeActionFilter}
					changeCategoryFilter={changeCategoryFilter}
				/>
			</CardFooter>
		</Card>
	);
}
