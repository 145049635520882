import { Action } from 'applicationTypes';
import * as actionTypes from 'domains/discounts/actionTypes';
import { DiscountFilterOptions, DiscountSearchRequest } from 'domains/discounts/models';
import { Discount } from 'domains/core/models';

export interface State {
	totalDiscountRecords: number;
	isLoadingDiscounts: boolean;
	isLoadingDiscountDependencies: boolean;
	isLoadingDiscountFilterOptions: boolean;
	isSavingDiscount: boolean;
	discountFilterOptions: DiscountFilterOptions;
	selectedDiscount?: Discount;
	discountSearchRequestValues: DiscountSearchRequest;
}

export const initialState: State = {
	totalDiscountRecords: 0,
	isLoadingDiscounts: false,
	isLoadingDiscountFilterOptions: false,
	isLoadingDiscountDependencies: false,
	isSavingDiscount: false,
	discountSearchRequestValues: {
		discountType: 0,
		market: 0,
		program: 0,
	},
	discountFilterOptions: {
		discountTypes: [],
		markets: [],
		programs: [],
	},
};

export default function reducer(state: State = initialState, action?: Action): State {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case actionTypes.LOAD_DISCOUNTS:
			return {
				...state,
				isLoadingDiscounts: true,
				discountSearchRequestValues: action.payload,
			};

		case actionTypes.LOAD_DISCOUNTS_SUCCESS:
			return {
				...state,
				totalDiscountRecords: action.result.result.recordCount,
				isLoadingDiscounts: false,
			};

		case actionTypes.LOAD_DISCOUNTS_FAIL:
			return {
				...state,
				totalDiscountRecords: 0,
				isLoadingDiscounts: false,
			};

		case actionTypes.LOAD_DISCOUNT_FILTERS:
			return {
				...state,
				isLoadingDiscountFilterOptions: true,
			};

		case actionTypes.LOAD_DISCOUNT_FILTERS_SUCCESS:
			return {
				...state,
				isLoadingDiscountFilterOptions: false,
				discountFilterOptions: action.result,
			};

		case actionTypes.LOAD_DISCOUNT_FILTERS_FAIL:
			return {
				...state,
				isLoadingDiscountFilterOptions: false,
				discountFilterOptions: initialState.discountFilterOptions,
			};

		case actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES:
			return {
				...state,
				isLoadingDiscountDependencies: true
			};

		case actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_SUCCESS:
			return {
				...state,
				isLoadingDiscountDependencies: false
			};

		case actionTypes.LOAD_DISCOUNT_AND_DEPENDENCIES_FAIL:
			return {
				...state,
				isLoadingDiscountDependencies: false
			};

		case actionTypes.SAVE_DISCOUNT:
			return {
				...state,
				isSavingDiscount: true
			};

		case actionTypes.SAVE_DISCOUNT_SUCCESS:
			return {
				...state,
				isSavingDiscount: false
			};

		case actionTypes.SAVE_DISCOUNT_FAIL:
			return {
				...state,
				isSavingDiscount: false
			};

		case actionTypes.LOAD_CEA_PROGRAMS:
			return {
				...state,
			};

		case actionTypes.LOAD_CEA_PROGRAMS_SUCCESS:
			return {
				...state,
			};

		case actionTypes.LOAD_CEA_PROGRAMS_FAIL:
			return {
				...state,
			};

		case actionTypes.LOAD_DISCOUNT_TYPES:
			return {
				...state,
			};

		case actionTypes.LOAD_DISCOUNT_TYPES_SUCCESS:
			return {
				...state,
			};

		case actionTypes.LOAD_DISCOUNT_TYPES_FAIL:
			return {
				...state,
			};

		default:
			return state;
	}
}
