import { ApplicationState } from "applicationTypes";
import { SecretShopper } from "./models";

export const getSecretShopperDetails = (state: ApplicationState, secretShopperId: number): SecretShopper | undefined  => {
	if (secretShopperId) 
	{
		return state.entities.secretShoppers[secretShopperId];
	}
	return undefined;
};
