import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader } from 'components/Card';
import { GridContainer, GridItem } from 'components/Grid';
import { Button } from 'components/CustomButtons';
import { CustomTabs } from 'components/CustomTabs';
import { Companies, CompanySearchForm } from 'domains/companies/components';
import PaginationWrapper from 'domains/core/components/PaginationWrapper';
import { doLoadCompanies } from 'domains/companies/actions';
import { doPaginationReset } from 'domains/core/actions';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { useStyles } from 'styles';
import { useDispatch, useSelector } from 'react-redux';

const style = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		paddingBottom: '0px'
	},
	tabCardMargin: {
		marginTop: 0
	},
	customPagination: {
		float: 'right'
	} as any
};

export default function CompanyList() {
	const history = useHistory();
	const classes = useStyles(style);
	const dispatch = useDispatch();

	const [filter, setFilter] = useState('');
	const [active, setActive] = useState(true);

	const companyCount = useSelector(state => state.domains.companies.companyListDetails.companyCount);
	const currentPage = useSelector(state => state.domains.core.pagination.currentPage);
	const recordsPerPage = useSelector(state => state.domains.core.pagination.recordsPerPage);

	useEffect(() => {
		const request = {
			filter,
			active: active,
			pageNumber: currentPage,
			pageSize: recordsPerPage
		};
		dispatch(doLoadCompanies(request));
	}, [filter, active, dispatch, currentPage, recordsPerPage]);

	const filterCompanies = useCallback((value?: string) => {
		dispatch(doPaginationReset());
		setFilter(value ?? '');
	}, [dispatch]);

	const toggleActive = useCallback((value: boolean) => {
		dispatch(doPaginationReset());
		setActive(value);
	}, [dispatch]);

	const routeToCompanyCreation = useCallback(() => {
		history.push('/users/companies/create');
	}, [history]);

	return (
		<Card>
			<CardHeader>
				<h2 className={classes.cardIconTitle}>Companies</h2>
				<GridContainer spacing={0}>
					<GridItem xs={12} sm={12} md={12} lg={2} xl>
						<Button color="primary" onClick={routeToCompanyCreation}>Add Company</Button>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg xl>
						<CompanySearchForm handleFilter={filterCompanies} />
					</GridItem>
					<GridItem xs={12} sm={9} md={9} lg xl={9}>
						<div className={classes.customPagination}>
							<PaginationWrapper recordCount={companyCount!} />
						</div>
					</GridItem>
				</GridContainer>
			</CardHeader>
			<CustomTabs
				plainTabs
				headerColor="info"
				tabs={
					[
						{
							tabName: 'ACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<Companies
											active
											setActive={toggleActive}
										/>
									</GridItem>
								</GridContainer>
							)
						},
						{
							tabName: 'INACTIVE',
							tabContent: (
								<GridContainer>
									<GridItem xs={12}>
										<Companies
											active={false}
											setActive={toggleActive}
										/>
									</GridItem>
								</GridContainer>
							)
						}
					]
				}
			/>
			<GridItem xs={12}>
				<div className={classes.customPagination}>
					<PaginationWrapper recordCount={companyCount!} />
				</div>
			</GridItem>
		</Card>
	);
}
